/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsNodeEntity from 'Models/Security/Acl/VisitorsNodeEntity';
import MineUserNodeEntity from 'Models/Security/Acl/MineUserNodeEntity';
import HitachiAdminNodeEntity from 'Models/Security/Acl/HitachiAdminNodeEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { store } from '../Store';
import { omit } from 'lodash';
import SublinkEntity from './SublinkEntity';
import LinkEntity from './LinkEntity';
import { MapObjectErrorsEntity, MapObjectWarningsEntity } from 'Models/Entities';
import { mapObjectState } from '../Enums';
import {RealWorldCoordinates} from "../../Views/MapComponents/Map/Helpers/Coordinates";
import {Direction} from "../../Views/MapComponents/Map/MapStateHandlers/PathTool/Helpers/Waypoint";
import {calcHeading} from "../../Views/MapComponents/Map/Helpers/MapUtils";
import {isNullOrUndefined} from "../../Util/TypeGuards";
// % protected region % [Add any further imports here] end

export interface INodeEntityAttributes extends IModelAttributes {
	nodeId: number;
	sublinkIdNumber: number;
	linkIdNumber: number;
	easting: number;
	northing: number;
	speed: number;
	up: number;
	curvature: number;
	gradient: number;
	task: Enums.nodetask;
	bermRight: number;
	bermLeft: number;
	interNodeDist: number;
	curveDirection: number;
	boundaryOfLink: number;
	boundaryOfSublink: number;
	isImported: boolean;
	state: Enums.mapObjectState;
	bermRightExtensionDistance: number;
	bermLeftExtensionDistance: number;
	isMidWaypoint: boolean;

	mapObjectErrorss: Array<
		| Models.MapObjectErrorsEntity
		| Models.IMapObjectErrorsEntityAttributes
	>;
	mapObjectWarningss: Array<
		| Models.MapObjectWarningsEntity
		| Models.IMapObjectWarningsEntityAttributes
	>;
	sublinkId?: string;
	sublink?: Models.SublinkEntity | Models.ISublinkEntityAttributes;
	areaEntry?: Models.AreaEntity |
		Models.IAreaEntityAttributes;
	areaExit?: Models.AreaEntity |
		Models.IAreaEntityAttributes;
	previousNodeId?: string;
	previousNode?: Models.NodeEntity | Models.INodeEntityAttributes;
	nextNode?: Models.NodeEntity |
		Models.INodeEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] on begin
	heading: number;
	direction: string;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('NodeEntity', 'Node')
// % protected region % [Customise your entity metadata here] end
export default class NodeEntity extends Model
	implements INodeEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsNodeEntity(),
		new MineUserNodeEntity(),
		new HitachiAdminNodeEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Node Id'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Node Id',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public nodeId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Node Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Sublink Id Number'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Sublink Id Number',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public sublinkIdNumber: number;
	// % protected region % [Modify props to the crud options here for attribute 'Sublink Id Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Link Id Number'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Link Id Number',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public linkIdNumber: number;
	// % protected region % [Modify props to the crud options here for attribute 'Link Id Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Easting'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Easting',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public easting: number;
	// % protected region % [Modify props to the crud options here for attribute 'Easting'] end

	// % protected region % [Modify props to the crud options here for attribute 'Northing'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Northing',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public northing: number;
	// % protected region % [Modify props to the crud options here for attribute 'Northing'] end

	// % protected region % [Modify props to the crud options here for attribute 'Speed'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Speed',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public speed: number;
	// % protected region % [Modify props to the crud options here for attribute 'Speed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Up'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Up',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public up: number;
	// % protected region % [Modify props to the crud options here for attribute 'Up'] end

	// % protected region % [Modify props to the crud options here for attribute 'Curvature'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Curvature',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public curvature: number;
	// % protected region % [Modify props to the crud options here for attribute 'Curvature'] end

	// % protected region % [Modify props to the crud options here for attribute 'Gradient'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Gradient',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public gradient: number;
	// % protected region % [Modify props to the crud options here for attribute 'Gradient'] end

	// % protected region % [Modify props to the crud options here for attribute 'Task'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Task',
		displayType: 'enum-combobox',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.nodetaskOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.nodetaskOptions),
		displayFunction: (attr: Enums.nodetask) => Enums.nodetaskOptions[attr],
	})
	public task: Enums.nodetask;
	// % protected region % [Modify props to the crud options here for attribute 'Task'] end

	// % protected region % [Modify props to the crud options here for attribute 'Berm Right'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Berm Right',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public bermRight: number;
	// % protected region % [Modify props to the crud options here for attribute 'Berm Right'] end

	// % protected region % [Modify props to the crud options here for attribute 'Berm Left'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Berm Left',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public bermLeft: number;
	// % protected region % [Modify props to the crud options here for attribute 'Berm Left'] end

	// % protected region % [Modify props to the crud options here for attribute 'Inter Node Dist'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Inter Node Dist',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public interNodeDist: number;
	// % protected region % [Modify props to the crud options here for attribute 'Inter Node Dist'] end

	// % protected region % [Modify props to the crud options here for attribute 'Curve Direction'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Curve Direction',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public curveDirection: number;
	// % protected region % [Modify props to the crud options here for attribute 'Curve Direction'] end

	// % protected region % [Modify props to the crud options here for attribute 'Boundary Of Link'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Boundary Of Link',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public boundaryOfLink: number;
	// % protected region % [Modify props to the crud options here for attribute 'Boundary Of Link'] end

	// % protected region % [Modify props to the crud options here for attribute 'Boundary Of Sublink'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Boundary Of Sublink',
		displayType: 'textfield',
		order: 160,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public boundaryOfSublink: number;
	// % protected region % [Modify props to the crud options here for attribute 'Boundary Of Sublink'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Imported'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Is Imported',
		displayType: 'checkbox',
		order: 170,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isImported: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Imported'] end

	// % protected region % [Modify props to the crud options here for attribute 'State'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'State',
		displayType: 'enum-combobox',
		order: 180,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.mapObjectStateOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.mapObjectStateOptions),
		displayFunction: (attr: Enums.mapObjectState) => Enums.mapObjectStateOptions[attr],
	})
	public state: Enums.mapObjectState;
	// % protected region % [Modify props to the crud options here for attribute 'State'] end

	// % protected region % [Modify props to the crud options here for attribute 'Berm Right Extension Distance'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Berm Right Extension Distance',
		displayType: 'textfield',
		order: 190,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public bermRightExtensionDistance: number;
	// % protected region % [Modify props to the crud options here for attribute 'Berm Right Extension Distance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Berm Left Extension Distance'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Berm Left Extension Distance',
		displayType: 'textfield',
		order: 200,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public bermLeftExtensionDistance: number;
	// % protected region % [Modify props to the crud options here for attribute 'Berm Left Extension Distance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Mid Waypoint'] off begin
	/**
	 * Whether or not the node is a midwaypoint
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Is Mid Waypoint',
		displayType: 'checkbox',
		order: 210,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isMidWaypoint: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Mid Waypoint'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Map Object Errors'] off begin
		name: 'Map Object Errorss',
		displayType: 'reference-multicombobox',
		order: 220,
		referenceTypeFunc: () => Models.MapObjectErrorsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'mapObjectErrorss',
			oppositeEntity: () => Models.MapObjectErrorsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Map Object Errors'] end
	})
	public mapObjectErrorss: Models.MapObjectErrorsEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Map Object Warnings'] off begin
		name: 'Map Object Warningss',
		displayType: 'reference-multicombobox',
		order: 230,
		referenceTypeFunc: () => Models.MapObjectWarningsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'mapObjectWarningss',
			oppositeEntity: () => Models.MapObjectWarningsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Map Object Warnings'] end
	})
	public mapObjectWarningss: Models.MapObjectWarningsEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Sublink'] off begin
		name: 'Sublink',
		displayType: 'reference-combobox',
		order: 240,
		referenceTypeFunc: () => Models.SublinkEntity,
		// % protected region % [Modify props to the crud options here for reference 'Sublink'] end
	})
	public sublinkId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public sublink: Models.SublinkEntity;

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Area Entry'] off begin
		name: 'Area Entry',
		displayType: 'reference-combobox',
		order: 250,
		referenceTypeFunc: () => Models.AreaEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'areaEntrys',
			oppositeEntity: () => Models.AreaEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Area Entry'] end
	})
	public areaEntry?: Models.AreaEntity;

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Area Exit'] off begin
		name: 'Area Exit',
		displayType: 'reference-combobox',
		order: 260,
		referenceTypeFunc: () => Models.AreaEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'areaExits',
			oppositeEntity: () => Models.AreaEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Area Exit'] end
	})
	public areaExit?: Models.AreaEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Previous Node'] off begin
		name: 'Previous Node',
		displayType: 'reference-combobox',
		order: 270,
		referenceTypeFunc: () => Models.NodeEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'previousNodes',
			oppositeEntity: () => Models.NodeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Previous Node'] end
	})
	public previousNodeId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public previousNode: Models.NodeEntity;

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Next Node'] off begin
		name: 'Next Node',
		displayType: 'reference-combobox',
		order: 280,
		referenceTypeFunc: () => Models.NodeEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'nextNodes',
			oppositeEntity: () => Models.NodeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Next Node'] end
	})
	public nextNode?: Models.NodeEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	public heading: number;

	@observable
	public direction: string;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<INodeEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<INodeEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.nodeId !== undefined) {
				this.nodeId = attributes.nodeId;
			}
			if (attributes.sublinkIdNumber !== undefined) {
				this.sublinkIdNumber = attributes.sublinkIdNumber;
			}
			if (attributes.linkIdNumber !== undefined) {
				this.linkIdNumber = attributes.linkIdNumber;
			}
			if (attributes.easting !== undefined) {
				this.easting = attributes.easting;
			}
			if (attributes.northing !== undefined) {
				this.northing = attributes.northing;
			}
			if (attributes.speed !== undefined) {
				this.speed = attributes.speed;
			}
			if (attributes.up !== undefined) {
				this.up = attributes.up;
			}
			if (attributes.curvature !== undefined) {
				this.curvature = attributes.curvature;
			}
			if (attributes.gradient !== undefined) {
				this.gradient = attributes.gradient;
			}
			if (attributes.task !== undefined) {
				this.task = attributes.task;
			}
			if (attributes.bermRight !== undefined) {
				this.bermRight = attributes.bermRight;
			}
			if (attributes.bermLeft !== undefined) {
				this.bermLeft = attributes.bermLeft;
			}
			if (attributes.interNodeDist !== undefined) {
				this.interNodeDist = attributes.interNodeDist;
			}
			if (attributes.curveDirection !== undefined) {
				this.curveDirection = attributes.curveDirection;
			}
			if (attributes.boundaryOfLink !== undefined) {
				this.boundaryOfLink = attributes.boundaryOfLink;
			}
			if (attributes.boundaryOfSublink !== undefined) {
				this.boundaryOfSublink = attributes.boundaryOfSublink;
			}
			if (attributes.isImported !== undefined) {
				this.isImported = attributes.isImported;
			}
			if (attributes.state !== undefined) {
				this.state = attributes.state;
			}
			if (attributes.bermRightExtensionDistance !== undefined) {
				this.bermRightExtensionDistance = attributes.bermRightExtensionDistance;
			}
			if (attributes.bermLeftExtensionDistance !== undefined) {
				this.bermLeftExtensionDistance = attributes.bermLeftExtensionDistance;
			}
			if (attributes.isMidWaypoint !== undefined) {
				this.isMidWaypoint = attributes.isMidWaypoint;
			}
			if (attributes.mapObjectErrorss !== undefined && Array.isArray(attributes.mapObjectErrorss)) {
				for (const model of attributes.mapObjectErrorss) {
					if (model instanceof Models.MapObjectErrorsEntity) {
						this.mapObjectErrorss.push(model);
					} else {
						this.mapObjectErrorss.push(new Models.MapObjectErrorsEntity(model));
					}
				}
			}
			if (attributes.mapObjectWarningss !== undefined && Array.isArray(attributes.mapObjectWarningss)) {
				for (const model of attributes.mapObjectWarningss) {
					if (model instanceof Models.MapObjectWarningsEntity) {
						this.mapObjectWarningss.push(model);
					} else {
						this.mapObjectWarningss.push(new Models.MapObjectWarningsEntity(model));
					}
				}
			}
			if (attributes.sublinkId !== undefined) {
				this.sublinkId = attributes.sublinkId;
			}
			if (attributes.sublink !== undefined) {
				if (attributes.sublink === null) {
					this.sublink = attributes.sublink;
				} else if (attributes.sublink instanceof Models.SublinkEntity) {
					this.sublink = attributes.sublink;
					this.sublinkId = attributes.sublink.id;
				} else {
					this.sublink = new Models.SublinkEntity(attributes.sublink);
					this.sublinkId = this.sublink.id;
				}
			}
			if (attributes.areaEntry !== undefined) {
				if (attributes.areaEntry === null) {
					this.areaEntry = attributes.areaEntry;
				} else if (attributes.areaEntry instanceof Models.AreaEntity) {
					this.areaEntry = attributes.areaEntry;
				} else {
					this.areaEntry = new Models.AreaEntity(attributes.areaEntry);
				}
			}
			if (attributes.areaExit !== undefined) {
				if (attributes.areaExit === null) {
					this.areaExit = attributes.areaExit;
				} else if (attributes.areaExit instanceof Models.AreaEntity) {
					this.areaExit = attributes.areaExit;
				} else {
					this.areaExit = new Models.AreaEntity(attributes.areaExit);
				}
			}
			if (attributes.previousNodeId !== undefined) {
				this.previousNodeId = attributes.previousNodeId;
			}
			if (attributes.previousNode !== undefined) {
				if (attributes.previousNode === null) {
					this.previousNode = attributes.previousNode;
				} else if (attributes.previousNode instanceof Models.NodeEntity) {
					this.previousNode = attributes.previousNode;
					this.previousNodeId = attributes.previousNode.id;
				} else {
					this.previousNode = new Models.NodeEntity(attributes.previousNode);
					this.previousNodeId = this.previousNode.id;
				}
			}
			if (attributes.nextNode !== undefined) {
				if (attributes.nextNode === null) {
					this.nextNode = attributes.nextNode;
				} else if (attributes.nextNode instanceof Models.NodeEntity) {
					this.nextNode = attributes.nextNode;
				} else {
					this.nextNode = new Models.NodeEntity(attributes.nextNode);
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.heading !== undefined) {
				this.heading = attributes.heading;
			}
			// TODO: the code below causes issues in edit mode. Start (HAULING) -> Reverse Point -> End (Reverse point)
			// if (attributes.direction !== undefined) {
			// 	this.direction = attributes.direction;
			// }
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		mapObjectErrorss {
			${Models.MapObjectErrorsEntity.getAttributes().join('\n')}
		}
		mapObjectWarningss {
			${Models.MapObjectWarningsEntity.getAttributes().join('\n')}
		}
		sublink {
			${Models.SublinkEntity.getAttributes().join('\n')}
		}
		areaEntry {
			${Models.AreaEntity.getAttributes().join('\n')}
		}
		areaExit {
			${Models.AreaEntity.getAttributes().join('\n')}
		}
		nextNode {
			${Models.NodeEntity.getAttributes().join('\n')}
		}
		previousNode {
			${Models.NodeEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			mapObjectErrorss: {},
			mapObjectWarningss: {},
			areaEntry: {},
			areaExit: {},
			nextNode: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'mapObjectErrorss',
							'mapObjectWarningss',
							'areaEntry',
							'areaExit',
							'nextNode',
							'previousNode',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.nodeId.toString();
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public getCoordinates(): RealWorldCoordinates {
		return {
			northing: this.northing,
			easting: this.easting,
		};
	}

	public getNextNode(): NodeEntity | undefined {
		if (!!this.nextNode) {
			return this.nextNode;
		}

		return store.mapStore.getNextNodeById(this.getModelId());
	}

	public getNextNodeAcrossConnectivities(): NodeEntity[] {
		let nextNode = this.getNextNodeAcrossSublink();

		if (!nextNode) {
			const link = this.getLink();

			return link?.nextLinks().map(x => x?.firstNode()).filter(x => x !== undefined) as NodeEntity[];
		}

		return [nextNode];
	}

	public getNextNodeAcrossSublink(): NodeEntity | undefined {
		let nextNode = this.getNextNode();

		if (!nextNode) {
			const nextSublink = this.getSublink()?.getNextSublink();
			nextNode = nextSublink?.getFirstNode();
		}

		return nextNode;
	}

	public getPreviousNodeAcrossSublink(): NodeEntity | undefined {
		let previousNode = this.getPreviousNode();

		if (!previousNode) {
			const previousSublink = this.getSublink()?.getPreviousSublink();
			previousNode = previousSublink?.getLastNode();
		}

		return previousNode;
	}

	public isStartOrEndNodeOfLink(): boolean {
		return this.isStartNodeOfLink() || this.isEndNodeOfLink();
	}

	public isStartNodeOfLink(): boolean {
		// If there is no previous node, or no previous sublink, then this is the start of the link
		const previousNode = this.getPreviousNodeAcrossSublink();
		return isNullOrUndefined(previousNode);
	}

	public isEndNodeOfLink(): boolean {
		// If there is no next node, or no next sublink, then this is the end of the link
		const nextNode = this.getNextNodeAcrossSublink();
		return isNullOrUndefined(nextNode);
	}

	public getPreviousNode(): NodeEntity | undefined {
		if (!this.previousNodeId) {
			return undefined;
		}

		if (!!this.previousNode && this.previousNodeId === this.previousNode.id) {
			return this.previousNode;
		}

		return store.mapStore.getEntity(this.previousNodeId, NodeEntity);
	}

	getIdDebugString() {
		return `${this.nodeId}/${this.id}`;
	}

	validateRefs() {
		if (!!this.previousNode) {
			if (this.previousNodeId !== this.previousNode.id) {
				throw Error(`NodeEntity refs mismatch ${this.getIdDebugString()}. Expected ${this.previousNodeId} got ${this.previousNode.id}`);
			}
		}
		if (!!this.sublink) {
			if (this.sublink.id !== this.sublinkId) {
				throw Error(`NodeEntity refs mismatch ${this.getIdDebugString()}. Expected ${this.sublinkId} got ${this.sublink.id}. sublinkIdNumber: ${this.sublinkIdNumber}`);
			}
		}
	}

	public getPreviousNodeAcrossConnectivities(): NodeEntity[] {
		let previousNode = this.getPreviousNodeAcrossSublink();

		// If there is no previous node, traverse to the previous sublink and check for a node
		if (!previousNode) {
			const link = this.getLink();

			// The undefined values are filtered so the return value can be ensured
			return link?.previousLinks()
				.map(x => x?.lastNode())
				.filter(x => x !== undefined) as NodeEntity[];
		}

		return [previousNode];
	}

	public isLastNode(): boolean {
		return !this.getNextNode();
	}

	public isFirstNode(): boolean {
		return !this.previousNodeId; // null OR undefined
	}

	public getSublink(): SublinkEntity | undefined {
		if (!this.sublinkId) {
			return undefined;
		}

		// Return the cached sublink if it exists
		if (!!this.sublink && this.sublink.id === this.sublinkId) {
			return this.sublink;
		}

		return store.mapStore.getEntity(this.sublinkId, SublinkEntity);
	}

	public setSublink(sublink: SublinkEntity) {
		this.sublink = sublink;
		this.sublinkId = sublink.id;
		this.sublinkIdNumber = sublink.sublinkId;
		this.linkIdNumber = sublink.getLink()?.linkId ?? 0;
	}

	public getLink(): LinkEntity | undefined {
		return this.getSublink()?.getLink();
	}

	/**
	 * Update all except
	 * @param node
	 */
	public updateAttributes(node: NodeEntity) {
		this.assignAttributes(
			omit(
				node,
				'id',
				'nodeId',
				'state',
			),
		);
	}

	public isSpecialTask() {
		return this.task === 'PARKING'
			|| this.task === 'REVERSEPOINT'
			|| this.task === 'DUMPINGCRUSHER';
	}

	public isReversePoint() {
		return this.task === 'REVERSEPOINT';
	}

	public isParking() {
		return this.task === 'PARKING';
	}

	public isPathDirectionCompatible(direction: Direction) {
		if (this.isSpecialTask() && this.isStartNodeOfLink()) {
			return this.getDirection() === direction;
		}

		if (this.isReversePoint()) {
			// A reverse point always needs to be a change in direction
			return this.getDirection() !== direction;
		}

		// A parking node at the end of a link, the next direction is always forward (otherwise the directions must match
		if (this.isParking()) {
			return direction === 'forward';
		}

		return this.getDirection() === direction;
	}

	public isAllowedToBeSnapped() {
		return !(this.isParking() && this.isStartNodeOfLink());
	}

	/**
	 * Get the expected next node placement direction for the given paths direction. This works in conjunction with the
	 * getHeading() function below to get the correct placement for the next node
	 *
	 * For example, to get the location of the next node, you can
	 *
	 * @param direction the direction of the path
	 */
	public getStartNodePlacementDirection(direction: Direction): -1 | 1 {
		if (this.getDirection() === direction) {
			return this.getDirection() === 'reverse' ? -1 : 1;
		}

		return this.getDirection() === 'reverse' ? 1 : -1;
	}

	public getEndNodePlacementDirection(direction: Direction): -1 | 1 {
		return this.getStartNodePlacementDirection(direction) === 1 ? -1 : 1;
	}

	/**
	 * Returns the direction of the node (the direction the truck will leave the node)
	 */
	public getDirection(): Direction {
		if (this.direction === 'forward' || this.direction === 'reverse') {
			return this.direction;
		}

		let direction = this.speed > 0 ? 'forward' : 'reverse';

		if (this.isSpecialTask() || this.speed === 0) {
			const neighbourNode = this.getPreviousNodeAcrossSublink() || this.getNextNodeAcrossSublink();
			if (neighbourNode) {
				direction = neighbourNode.getDirection();
			}
		}

		// Cache the result
		this.direction = direction;

		// If the waypoint is a reverse point, reverse the direction
		if (this.task === 'REVERSEPOINT') {
			this.direction = this.direction === 'forward' ? 'reverse' : 'forward';
		}

		return this.direction as Direction;
	}

	public getHeading(): number {
		if (this.heading != undefined) {
			return this.heading;
		}

		let heading = 0;
		const previousNode = this.getPreviousNodeAcrossSublink();
		if (previousNode) {
			heading = calcHeading(previousNode.getCoordinates(), this.getCoordinates());
		}

		const nextNode = this.getNextNodeAcrossSublink();
		if (heading === 0 && nextNode) {
			heading = calcHeading(this.getCoordinates(), nextNode.getCoordinates());
		}

		// If the direction of the path is reverse, we need to switch the heading
		if (this.getDirection() === 'reverse') {
			heading = (heading + 180) % 360;
		}

		this.heading = heading;

		return heading;
	}

	/* *******************
	 * Validation - Error
	 * ****************** */
	public getErrors() {
		if (!this.mapObjectErrorss) {
			this.mapObjectErrorss = [];
		}
		return this.mapObjectErrorss;
	}

	public addErrors(errors: string[]) {
		errors.map(error => this.addError(error));
	}

	public addError(error: string): boolean {
		if (!this.hasError(error)) {
			const newError = new MapObjectErrorsEntity({
				errorMessage: error,
				nodeId: this.getModelId(),
			});
			this.getErrors().push(newError);

			return true;
		}

		return false;
	}

	public resetAndAddNewErrors(errors: string[]) {
		this.resetError();
		this.addErrors(errors);
	}

	public removeError(error: string): boolean {
		const errorToDelete = this.getErrors().findIndex(x => x.errorMessage === error);
		if (errorToDelete !== -1) {
			this.getErrors().splice(errorToDelete, 1);
			return true;
		}

		return false;
	}

	public resetError() {
		this.mapObjectErrorss = [];
	}

	public hasError(error: string): boolean {
		return this.getErrors().find(x => x.errorMessage === error) !== undefined;
	}

	public getErrorCount() {
		return this.getErrors().length;
	}

	/* *******************
	 * Validation - Warning
	 * ****************** */
	public getWarnings() {
		if (!this.mapObjectWarningss) {
			this.mapObjectWarningss = [];
		}
		return this.mapObjectWarningss;
	}

	public addWarnings(warnings: string[]) {
		warnings.map(warning => this.addWarning(warning));
	}

	public addWarning(warning: string): boolean {
		if (!this.hasWarning(warning)) {
			const newWarning = new MapObjectWarningsEntity({
				warningMessage: warning,
				sublinkId: this.getModelId(),
			});
			this.getWarnings().push(newWarning);

			return true;
		}

		return false;
	}

	public resetAndAddNewWarnings(warnings: string[]) {
		this.resetWarnings();
		this.addWarnings(warnings);
	}

	public removeWarning(warning: string): boolean {
		const warningToDelete = this.getWarnings().findIndex(x => x.warningMessage === warning);
		if (warningToDelete !== -1) {
			this.getWarnings().splice(warningToDelete, 1);
			return true;
		}

		return false;
	}

	public resetWarnings() {
		this.mapObjectWarningss = [];
	}

	public hasWarning(warning: string): boolean {
		return this.getWarnings().find(x => x.warningMessage === warning) !== undefined;
	}

	public getWarningCount() {
		return this.getWarnings().length;
	}	

	public setMapObjectState(state: mapObjectState) {
		// Ignore the case where this is already a new object (it doesn't matter if it is modified after, it's still new)
		if (this.state === 'NEW_OBJECT' && state === 'MODIFIED') {
			return;
		}

		this.state = state;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(NodeEntity.prototype, 'created');
CRUD(modifiedAttr)(NodeEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
