/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { DisplayType } from '../Models/Enums';
import * as uuid from 'uuid';
import InputsHelper from '../Helpers/InputsHelper';
import classNames from 'classnames';

interface IPasswordProps<T> {
	model: T;
	modelProperty: string;
	id?: string;
	className?: string;
	name?: string;
	displayType?: DisplayType;
	label?: string;
	labelVisible?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	tooltip?: string;
	subDescription?: string;
	placeholder?: string;
	onAfterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onChangeAndBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	inputProps?: React.InputHTMLAttributes<Element>;
}

@observer
export default class PasswordInput<T> extends React.Component<IPasswordProps<T>, any> {
	public static defaultProps = {
		inputProps: {},
		className: '',
	};

	private uuid = uuid.v4();

	private valueWhenFocused: string = '';

	@observable
	private showPassword: boolean = false;

	@action
	private setShowPassword(showPassword: boolean) {
		this.showPassword = showPassword;
	}

	public render() {
		const {
			model,
			modelProperty,
			name,
			label,
			isDisabled,
			isReadOnly,
			tooltip,
			subDescription,
			placeholder,
			id,
			className,
			labelVisible,
			inputProps,
		} = this.props;
		const Id = id || this.uuid.toString();
		const fieldId = `${Id}-field`;

		const ariaLabel = !((labelVisible === undefined) ? true : labelVisible) ? label : undefined;

		const ariaDescribedby = InputsHelper.getAriaDescribedBy(Id, tooltip, subDescription);

		return (
			<div className={classNames(className, 'password-container')}>
				<input
					type={this.showPassword ? 'text' : 'password'}
					name={name}
					id={fieldId}
					value={model[modelProperty] ? model[modelProperty] : ''}
					onChange={this.onChange}
					onBlur={this.onBlur}
					placeholder={placeholder || (label || undefined)}
					disabled={isDisabled}
					readOnly={isReadOnly}
					aria-label={ariaLabel}
					aria-describedby={ariaDescribedby}
					{...inputProps}
				/>
				<span
					className={classNames('icon', 'icon-only', 'icon-eye-track', 'password-input-icon')}
					onMouseEnter={() => this.setShowPassword(true)}
					onMouseLeave={() => this.setShowPassword(false)}
				/>
			</div>
		);
	}

	@action
	private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.props.model[this.props.modelProperty] = event.target.value;
		if (this.props.onAfterChange) {
			this.props.onAfterChange(event);
		}
	}

	@action
	private onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (this.valueWhenFocused !== event.target.value && this.props.onChangeAndBlur) {
			this.props.onChangeAndBlur(event);
		}
	}

	@action
	private onClickToClear = (event: React.MouseEvent<HTMLButtonElement>) => {
		this.props.model[this.props.modelProperty] = '';
	}
}
