import {IStore, store} from "Models/Store";
import {getUsersName, IUserDetails} from "../../../Models/UserEntity";

const groupToRole = (group: string) => {
	switch (group) {
		case 'Super Administrators':
			return 'Super Admin';
		case 'HitachiAdmin':
			return 'Hitachi Admin';
		case 'MineUser':
			return store.isAHSMineAdmin
				? 'AHS Admin'
				: 'AHS Builder';
		case 'Visitors':
			return 'Vistor';
		default:
			return 'Unknown'
	}
}

/**
 * Returns the logged in user's role
 */
export const getRole = () => {
	let myGroup = 'Unknown';
	const superAdmin = "Super Administrators";
	if (store.userGroups && store.userGroups.length > 0) {
		const groups = store.userGroups;
		const firstGroup = groups[0].name;
		myGroup = firstGroup;
		if (groups.length > 1) {
			if(groups.some(el => el.name === superAdmin)) {
				myGroup = superAdmin;
			} else {
				console.warn(`User is not super but has multiple groups: ${JSON.stringify(groups)}`)
			}
		}
	}
	return groupToRole(myGroup);
}

/**
 * Renders full profile indicator
 */
export const renderProfileIndicator = () => {
	return (
		<div className="profile-indicator">
            <div className="profile-indicator-inner">
                    {UserImage(store)}
                    <div className="name-and-role">
                        <p> {getUsersName( 
								{ id: store.userId!, 
								givenName: store.givenName!, 
								familyName: store.familyName!,
								email: store.email! })} 
						</p>
                        <p> {getRole()} </p>
                    </div>
            </div>
	    </div>
	);
};

export const renderLockOwnerProfileIndicator = (props: IUserDetails, lastSave: string) => {
	return (
		<div className="profile-indicator">
            <div className="profile-indicator-inner">
                    {LockOwnerUserImage(props)}
                    <div className="name-and-role">
                        <p> {getUsersName(props)} </p>
                        <p> {lastSave} </p>
                    </div>
            </div>
	    </div>
	);
};

const LockOwnerUserImage = (props: IUserDetails) => {
	let initials;
	const { givenName, familyName, email } = props;
	const userImageStyle = 'user-image';

	if (!!givenName && !!familyName) {
		initials = `${givenName[0]}${familyName[0]}`.toUpperCase();
	} else {
		initials = email.substr(0, 2).toUpperCase();
	}

	return (
		<span className={userImageStyle}>
			{initials}
		</span>
	);
}

/**
 * Full user image
 */
export function UserImage(userSource : IUserDetails | IStore) {
	return renderUserImage(userSource);
}

/**
 * Text version of user image
 */
export function UserImageText() {
	return renderUserImage(store, true);
}

/**
 * Sets the image for the currently logged in user
 * Currently this is automatically generated from
 * the user's initials 
 */
 const renderUserImage = (userSource : IUserDetails | IStore, textOnly?: boolean) => {
	let initials;
	let userImageStyle : string = 'user-image-text';

	if (userSource !== null) {
		userImageStyle = textOnly ? 'user-image-text' : 'user-image';

		if (userSource?.givenName && userSource?.familyName) {
			initials = `${userSource.givenName[0]}${userSource.familyName[0]}`.toUpperCase();
		} else if (userSource?.email) {
			initials = userSource.email?.substr(0, 2).toUpperCase();
		} else {
			initials = "--";
		}
	}
	else {
		initials = "--";
	}

	return (
		<span className={userImageStyle}>
			{initials}
		</span>
	);
}