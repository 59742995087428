import {SaveCommand, SaveCommandType} from "./SaveCommand";

export default class DeleteAreaCommand extends SaveCommand {
	areaId: string;

	constructor(areaId: string) {
		super(SaveCommandType.DeleteArea);

		this.areaId = areaId;
	}

}