import * as React from 'react';
import axios from 'axios';
import { observer } from 'mobx-react';
import {
	BackgroundImageEntity,
	ImportVersionEntity,
	MapEntity,
} from '../../Models/Entities';
import { ModelCollection } from '../Components/ModelCollection/ModelCollection';
import { ICollectionHeaderProps } from '../Components/Collection/CollectionHeaders';
import moment from 'moment-timezone';
import { Alignment, ButtonGroup } from '../Components/Button/ButtonGroup';
import { Button, Display, Sizes } from '../Components/Button/Button';
import {
	action, computed, observable, runInAction,
} from 'mobx';
import { IWhereCondition } from '../Components/ModelCollection/ModelQuery';
import type { ISearch } from '../Components/CRUD/EntityCollection';
import { store } from '../../Models/Store';
import If from 'Views/Components/If/If';
import SearchForm from 'Views/Components/SearchForm/SearchForm';
import ImportsSidePanel from './ImportsSidePanel';
import classNames from 'classnames';
import { UserImage } from 'Views/Components/Shared/ProfileIndicator';
import { MOMENT_FORMAT_STRING, SERVER_URL } from '../../Constants';
import ImportVersionStatusIndicator from './MapToolbar/ImportVersionStatusIndicator';
import { RenderArchiveModal } from 'Views/Components/Shared/ArchiveModal';
import { setCustomTag } from './Map/Helpers/MapUtils';
import Spinner from 'Views/Components/Spinner/Spinner';
import { getUsersName, IUserDetails } from "../../Models/UserEntity";
import MapImportModal from "../Components/Shared/UploadModals/NewImportModal";

export const importNotArchivedCondition: IWhereCondition<ImportVersionEntity>[] = [
	{ path: 'archived', comparison: 'equal', value: 'true', negate: true }
];

interface AllImportsForMapProps {
	mapEntityId: string;
}

/**
 * Opens the map edit page for the selected import version of the map
 * @param model
 */
const onOpenImportVersion = (model: ImportVersionEntity) => {
	setCustomTag('load-a-map', 'load-map-data');

	// Reload the page to prevent excessive use of memory by the application
	window.location.href = `/mapedit/${model.id}`;
};

const isMapInUse = (model?: ImportVersionEntity) => {
	let isLocked = false;
	if (!!model && !!model.lockOwner) {
		if (store.isMapLockOwner(model)) {
			console.log(`This tab has lock with key ${model.lockKey}`);
		} else {
			isLocked = store.isLockValid(model.lockCreated);
		}
	}
	return isLocked;
};

export const getFullName = (user: IUserDetails) => {
	if (user == null) {
		return 'Invalid user';
	}

	let fullName = '';
	if (user.givenName) {
		fullName = user.givenName;
	}
	if (user.familyName && user.familyName.length) {
		if (fullName.length) {
			fullName += ' ';
		}
		fullName += user.familyName;
	}
	if (!fullName.length) {
		fullName = user.email;
	}
	return fullName;
};

export const getOwnerFullName = (model: MapEntity | ImportVersionEntity) => {
	return getFullName(model.owner);
};

export const getLastModifiedFullName = (model: MapEntity | ImportVersionEntity) => {
	return getFullName(model.lastModifiedUser);
};

export const renderLatestImport = (latestImportVersion: ImportVersionEntity) => {
	return (
		<>
			<div className="imports-title-section import-name-alignment">
				<h4 className="subtitle column latest-import-name"> {latestImportVersion.name} </h4>
				<Button
					className="btn--primary view-map-button"
					display={Display.Outline}
					sizes={Sizes.Medium}
					onClick={() => onOpenImportVersion(latestImportVersion)}
				>
					{isMapInUse(latestImportVersion) ? 'Map in use' : 'View Map'}
				</Button>
			</div>

			<div className={classNames('imports-title-section latest-import-card-bottom')}>
				{renderLastModified(latestImportVersion)}
				{renderStatus(latestImportVersion)}
			</div>
		</>
	);
};

/**
 * Renders the status of the import version
 */
export const renderStatus = (selectedImportVersion: ImportVersionEntity) => {
	return (
		<div className="imports-title-section">
			<ImportVersionStatusIndicator importVersionEntity={selectedImportVersion} />
		</div>
	);
};

/**
 * Renders the last modified component
 */
export const renderLastModified = (importVersion: ImportVersionEntity) => {
	const tz = importVersion.map?.mineSite?.timezone ?? "Australia/Sydney";
	return (
		<div className="column text-color">
			<h6 className="subtitle grey-font side-panel-content-section last-modified-section">LAST MODIFIED</h6>
			<div className="imports-title-section">
				{UserImage(importVersion.lastModifiedUser)}
				<div className="latest-import-owner txt-sm-body">
					<p> {getUsersName(importVersion.lastModifiedUser)}</p>
					{ importVersion?.lastSave
						? <p> {moment.tz(importVersion.lastSave, tz).format(MOMENT_FORMAT_STRING)}</p>
						: <p> N/A </p> }
				</div>
			</div>
		</div>
	);
};

export interface BackgroundImageOption {
	backgroundImageId: string;
	fileName: string;
	created: Date;
}

@observer
export default class AllImportsForMap extends React.Component<AllImportsForMapProps> {
	@observable
	private search: ISearch = { searchTerm: '' };

	@observable
	private mapEntityId: string = this.props.mapEntityId;

	@observable
	private loading: boolean = true;

	@observable
	private openArchiveModal: boolean = false;

	@observable
	private latestImportVersion: ImportVersionEntity | undefined;

	@observable
	private selectedImportVersion: ImportVersionEntity | undefined;

	@observable
	private selectedLatest: boolean = true;

	@observable
	private isOpenModal: boolean = false;

	@observable
	private MapName: string;

	private allNonArchivedImportVersions: ImportVersionEntity[];
	
	@observable
	private allBackgroundImageOptions: BackgroundImageOption[] = [];

	private triggerTableRefresh: (afterRemoveOperation?: boolean) => void;

	@action
	private closeModal = () => {
		this.isOpenModal = false;
	}

	@action
	private onNewImport = () => {
		this.isOpenModal = true;
		setCustomTag('import-a-map', 'click-new-import-btn');
	};

	constructor(props: AllImportsForMapProps) {
		super(props);

		MapEntity.fetchFields<MapEntity>(['name'], { ids: [this.mapEntityId] })
			.then(res => {
				runInAction(() => {
					this.MapName = res?.[0].name;
				});
			});
	}

	private positionArrow = () => {
		// Get the selected item
		const isLatestSelected = this.selectedImportVersion === undefined
			|| (this.selectedImportVersion?.id === this.latestImportVersion?.id && this.selectedLatest);	
	
		const selectedItemClass = isLatestSelected ? '.latest-import-card' : '.collection__item';
		const selectedItem = document.querySelector(`.all-imports-page ${selectedItemClass}.active`);

		const arrow: HTMLDivElement | null = document.querySelector('.arrow');
		const scrollWindow: HTMLDivElement | null = document.querySelector('.body-content');
		const topBar: HTMLDivElement | null = document.querySelector('.admin__top-bar');

		if (!arrow || !scrollWindow) {
			return;
		}

		if (!selectedItem) {
			arrow.style.display = 'none';
			return;
		}

		arrow.style.display = 'block';

		const { scrollTop } = scrollWindow;
		const { top, height } = selectedItem.getBoundingClientRect();
		const arrowMarginTop = 10;
		const topBarHeight = topBar?.getBoundingClientRect().height ?? 0;

		const topOfSelectedItem = scrollTop + top;
		// Position top style sets the distance from the top of the page to the top of the element
		// Find distance from the top of the arrow to the centre in order to offset the position top by that amount
		const centreOfArrow = arrowMarginTop + topBarHeight - 2;
		const middleOfSelectedItem = height / 2;

		arrow.style.top = `${topOfSelectedItem + middleOfSelectedItem - centreOfArrow}px`;
	};

	/**
	 * Defining headers for the collection component of the import version entity
	 * @private
	 */
	private importsCollectionHeaders : Array<ICollectionHeaderProps<ImportVersionEntity>> = [
		{
			name: 'name',
			displayName: 'Name',
			sortable: true,
		},
		{
			name: 'originalFileData',
			displayName: 'Acquired on',
			sortClicked: () => {},
			transformItem: model => {
				const acquiredOn = model.originalFileName?.replace(/\D/g, '') ?? '';
				const tz = model.map?.mineSite?.timezone ?? "Australia/Sydney";
				return moment.tz(acquiredOn, 'YYYYMMDDHHmmss', tz).format(MOMENT_FORMAT_STRING);
			},
			sortable: true,
		},
		{
			name: 'created',
			displayName: 'Imported on',
			transformItem: model => {
				const tz = model.map?.mineSite?.timezone ?? "Australia/Sydney";
				return moment.utc(model.created).tz(tz).format(MOMENT_FORMAT_STRING);
			},
			sortable: true,
		},
		{
			name: 'owner',
			displayName: 'Imported by',
			transformItem: model => {
				return getOwnerFullName(model);
			},
			sortable: true,
		},
		{
			name: 'lastSave',
			displayName: 'Last modified on',
			transformItem: model => {
				if (!model.lastSave) {
					return 'N/A';
				}
				const tz = model.map?.mineSite?.timezone ?? "Australia/Sydney";
				return moment.utc(model.lastSave).tz(tz).format(MOMENT_FORMAT_STRING);
			},
			sortable: true,
		},
		{
			name: 'lastModifiedBy',
			displayName: 'Last modified by',
			transformItem: model => {
				return getLastModifiedFullName(model);
			},
			sortable: true,
		},
		{
			name: 'publishedVersionss',
			displayName: 'Versions',
			sortClicked: () => {},
			transformItem: model => model.publishedVersionss.length,
			sortable: true,
		},
		{
			name: '',
			displayName: ' ',
			sortClicked: () => {},
			transformItem: model => {
				let isLocked = false;
				// It still needs some kind of border and should be greyed out
				const { lockOwner } = model;
				if (!!lockOwner) {
					if (store.isMapLockOwner(model)) {
						console.log(`This tab has lock with key ${model.lockKey}`);
					} else {
						isLocked = store.isLockValid(model.lockCreated);
					}
				}
				const iconName = isLocked ? 'lock' : 'chevron-right';
				return (
					<ButtonGroup
						className="map-edit-btn-group"
						alignment={Alignment.HORIZONTAL}
					>
						<Button
							className="icon-only"
							display={Display.Solid}
							onClick={() => onOpenImportVersion(model)}
							sizes={Sizes.Small}
							icon={{ icon: iconName, iconPos: 'icon-bottom' }}
						/>
					</ButtonGroup>
				);
			},
		},

	];

	/**
	 * Refreshes the collection with refined result based in the search term provided
	 * @param searchTerm
	 */
	@action
	private onSearchTriggered = (searchTerm: string) => {
		this.search.searchTerm = searchTerm;
	}
	
	/**
	 * Generates the result for the collection component of import version entity
	 */
	async componentDidMount() {
		const conditionArgs: Array<Array<IWhereCondition<ImportVersionEntity>>> = [
			[
				{ path: 'mapId', comparison: 'equal', value: this.mapEntityId },
			],
			importNotArchivedCondition,
		];

		const latestImportVersion: ImportVersionEntity[] = await ImportVersionEntity
			.fetch<ImportVersionEntity>({ args: conditionArgs, take: 1, orderBy: [{ path: 'created', descending: true }] });

		const allBackgrounds: BackgroundImageEntity[] = await BackgroundImageEntity.fetch<BackgroundImageEntity>({
			has: [[{
				path: 'importVersionss',
				conditions: [[{path: 'mapId', comparison: 'equal', value: this.mapEntityId }]]
			}]]
		});

		// Get backgroundImage filename and created time
		allBackgrounds?.forEach(b => {
			const _backgroundImageId = b.id;
			if (!_backgroundImageId) return;

			const backgroundImageOpt: BackgroundImageOption = {
				backgroundImageId: _backgroundImageId,
				fileName: "",
				created: new Date(),
			};

			axios.get(`${SERVER_URL}/api/files/metadata/${b.importedImageId}`)
				.then(x => x.data)
				.then(data => {
					backgroundImageOpt.fileName = data.fileName;
					backgroundImageOpt.created = new Date(data.created);

					this.allBackgroundImageOptions.push(backgroundImageOpt);
				});
		});

		runInAction(() => {
			// Assign the first element from the sorted array
			const [latestImport] = latestImportVersion;
			this.latestImportVersion = latestImport;
			this.selectedImportVersion = latestImport;
			this.loading = false;

			this.positionArrow();
		});
	}

	componentDidUpdate() {
		this.positionArrow();
	}

	/**
	 * Filters applied to the collection component of the import version
	 * @private
	 */
	@computed
	private get collectionFilters(): Array<Array<IWhereCondition<ImportVersionEntity>>> {
		const searchConditions = this.convertSearchConditions();
		const otherConditions = this.convertOtherCondition();
		return [...searchConditions, ...otherConditions];
	}

	/**
	 * Additional filters for the collection
	 */
	private convertOtherCondition = (): Array<Array<IWhereCondition<ImportVersionEntity>>> => {
		const filteredResult: Array<IWhereCondition<ImportVersionEntity>> = [
			{ path: 'mapId', comparison: 'equal', value: this.mapEntityId },
		];

		return [filteredResult, importNotArchivedCondition];
	}

	/**
	 * Search filter for the import version collection component
	 */
	private convertSearchConditions = ():Array<Array<IWhereCondition<ImportVersionEntity>>> => {
		if (this.search.searchTerm && this.search.searchTerm.trim() !== '') {
			const validTerm = `%${this.search.searchTerm.trim()}%`;
			const searchConditions: Array<IWhereCondition<ImportVersionEntity>> = [
				{
					path: 'name', comparison: 'like', value: validTerm, case: 'INVARIANT_CULTURE_IGNORE_CASE',
				},
			];

			return [searchConditions];
		}

		return [];
	}

	/**
	 * Set the selected import version for the side panel
	 * @param model
	 */
	@action
	private setSelectedRow = (model: ImportVersionEntity) => {
		this.selectedLatest = false;
		this.selectedImportVersion = model;
	}

	/**
	 * Archives the selected import version
	 */
	private archiveImport = async () => {
		if (!this.selectedImportVersion) {
			return;
		}

		// Set archived true for the selected import version
		this.selectedImportVersion.archived = true;

		await this.selectedImportVersion.updateWhere(
			undefined,
			['archived'],
			[this.selectedImportVersion.id]
		);

		// Handle the case where the selected import version is same as latest import version
		if (this.selectedImportVersion.id === this.latestImportVersion!.id) {
			this.allNonArchivedImportVersions = this.allNonArchivedImportVersions.filter(x => x.id !== this.selectedImportVersion!.id);
			// Handle when there is only one import
			if (this.allNonArchivedImportVersions.length === 0) {
				runInAction(() => {
					this.latestImportVersion = undefined;
				});
				
				store.routerHistory.push(`/maps`);
			}
			else {
				const latestImportVersion: ImportVersionEntity[] = await ImportVersionEntity
				.fetch<ImportVersionEntity>({ args: this.convertOtherCondition(), take: 1, orderBy: [{ path: 'created', descending: true }] });

				runInAction(() => {
					const [latestImport] = latestImportVersion;
					this.latestImportVersion = latestImport;
					this.selectedImportVersion = latestImport;
					this.selectedLatest = true;
					this.openArchiveModal = false;

					this.triggerTableRefresh(true);
				});
			}
		}
		else {
			runInAction(() => {
				this.selectedImportVersion = this.latestImportVersion;
				this.selectedLatest = true;
				this.openArchiveModal = false;

				this.triggerTableRefresh(true);
			});
		}
	}

	/**
	 * Renders the latest import card component
	 */
	private renderLatestImportCard = () => {
		const selectLatest = action(() => {
			this.selectedLatest = true;
			this.selectedImportVersion = this.latestImportVersion;
		});

		const isLatestSelected = this.selectedImportVersion === undefined
			|| (this.selectedImportVersion?.id === this.latestImportVersion?.id && this.selectedLatest);

		return (
			<>
				<div
					className={classNames('latest-import-card', isLatestSelected ? 'active' : null)}
					onClick={selectLatest}
				>
					{
						!this.loading
						&& (
							<>
								{!!this.latestImportVersion && renderLatestImport(this.latestImportVersion)}

								<If condition={!this.latestImportVersion}>
									<p className="no-import-margin"> No latest import for the selected map </p>
								</If>
							</>
						)
					}
				</div>
			</>
		);
	}

	/**
	 * Renders the new import option and collection component for import versions
	 */
	private renderLatestAndAllImports = () => {
		return (
			<div className="main-imports-view">
				<div className="latest-import-title-section">
					<h3 className="imports-title column"> Latest Import </h3>
					<ButtonGroup alignment={Alignment.HORIZONTAL}>
						<Button
							className="btn--primary new-import-button"
							display={Display.Solid}
							sizes={Sizes.Medium}
							onClick={this.onNewImport}
							icon={{ icon: 'plus', iconPos: 'icon-left' }}
						>
							New Import
						</Button>
					</ButtonGroup>
				</div>

				<If condition={this.isOpenModal}>
					<MapImportModal
						title="New Import"
						mapId={this.mapEntityId}
						onCloseModal={this.closeModal}
						hasBackgroundImageOpts={true}
						backgroundImageOptions={this.allBackgroundImageOptions}
					/>
				</If>

				{this.renderLatestImportCard()}

				<h3 className="imports-title column">All imports</h3>

				<ModelCollection
					className="imports-collection"
					{...this.props}
					onRowSelected={model => this.setSelectedRow(model)}
					isRowSelected={model => {
						if (model === undefined) {
							return false;
						}

						const selectedId = this.selectedImportVersion?.id ?? '';

						return !this.selectedLatest && model.id === selectedId;
					}}
					model={ImportVersionEntity}
					headers={this.importsCollectionHeaders}
					conditions={this.collectionFilters}
					orderBy={{
						path: 'created',
						descending: true,
					}}
					perPage={5}
					customSpinner={<div className='spinner-div'><Spinner /></div>}
					setRefetch={(refetch) => {
						this.triggerTableRefresh = refetch;
					}}
					onAfterDataLoaded={this.positionArrow}
					getData={(data) => {
						this.allNonArchivedImportVersions = data;
					}}
				/>

				<p className="imports-footnote txt-sm-body">
					Files will be saved here for 6 months. After that time, these files will be permanently deleted.
				</p>
			</div>
		);
	}

	/**
	 * Renders the side panel showing attributes of the selected import
	 */
	private renderSidePanel = () => {
		return (
			<>
				{!this.loading && this.selectedImportVersion
					&& (
						<ImportsSidePanel
							importVersionEntity={this.selectedImportVersion}
							onOpenModal={() => runInAction(() => {
								this.openArchiveModal = true;
							})}
						/>
					)}
			</>
		);
	};

	/**
	 * Renders components for the latest import with new import option and collection component
	 */
	private renderMainFrame = () => {
		return (
			<div className="main-content">
				<div className="imports-title-section">
					<Button
						display={Display.Solid}
						onClick={() => store.routerHistory.push(`${SERVER_URL}/maps`)}
						icon={{ icon: 'chevron-left', iconPos: 'icon-top' }}
						className="back-button"
					/>
					<p className="name"> {this.MapName} </p>
					<SearchForm
						model={this.search}
						onChange={this.onSearchTriggered}
						label="A search for entities"
						classNameSuffix="import-collection"
					/>
				</div>
				{this.renderLatestAndAllImports()}
			</div>
		);
	}

	render() {
		return (
			<>
				<RenderArchiveModal
					isOpenModal={this.openArchiveModal} 
					isMap={false}
					closeModal={() => {
						runInAction(() => {
							this.openArchiveModal = false;
						});
					}}
					archive={this.archiveImport}
				/>
				<div className="all-imports-page">
					{this.renderMainFrame()}
					{this.renderSidePanel()}
				</div>
			</>
		);
	}
}
