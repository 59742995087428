/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsSegmentPointEntity from 'Models/Security/Acl/VisitorsSegmentPointEntity';
import MineUserSegmentPointEntity from 'Models/Security/Acl/MineUserSegmentPointEntity';
import HitachiAdminSegmentPointEntity from 'Models/Security/Acl/HitachiAdminSegmentPointEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ISegmentPointEntityAttributes extends IModelAttributes {
	sequence: number;

	segmentId?: string;
	segment?: Models.SegmentEntity | Models.ISegmentEntityAttributes;
	previousPointId?: string;
	previousPoint?: Models.SegmentPointEntity | Models.ISegmentPointEntityAttributes;
	nextPoint?: Models.SegmentPointEntity |
		Models.ISegmentPointEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] on begin
	segmentPointLocation: string;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('SegmentPointEntity', 'Segment Point')
// % protected region % [Customise your entity metadata here] end
export default class SegmentPointEntity extends Model
	implements ISegmentPointEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsSegmentPointEntity(),
		new MineUserSegmentPointEntity(),
		new HitachiAdminSegmentPointEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Sequence'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Sequence',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public sequence: number;
	// % protected region % [Modify props to the crud options here for attribute 'Sequence'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Segment'] off begin
		name: 'Segment',
		displayType: 'reference-combobox',
		order: 20,
		referenceTypeFunc: () => Models.SegmentEntity,
		// % protected region % [Modify props to the crud options here for reference 'Segment'] end
	})
	public segmentId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public segment: Models.SegmentEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Previous Point'] off begin
		name: 'Previous Point',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.SegmentPointEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'previousPoints',
			oppositeEntity: () => Models.SegmentPointEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Previous Point'] end
	})
	public previousPointId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public previousPoint: Models.SegmentPointEntity;

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Next Point'] off begin
		name: 'Next Point',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.SegmentPointEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'nextPoints',
			oppositeEntity: () => Models.SegmentPointEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Next Point'] end
	})
	public nextPoint?: Models.SegmentPointEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	@attribute()
	public segmentPointLocation: string;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ISegmentPointEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ISegmentPointEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.sequence !== undefined) {
				this.sequence = attributes.sequence;
			}
			if (attributes.segmentId !== undefined) {
				this.segmentId = attributes.segmentId;
			}
			if (attributes.segment !== undefined) {
				if (attributes.segment === null) {
					this.segment = attributes.segment;
				} else if (attributes.segment instanceof Models.SegmentEntity) {
					this.segment = attributes.segment;
					this.segmentId = attributes.segment.id;
				} else {
					this.segment = new Models.SegmentEntity(attributes.segment);
					this.segmentId = this.segment.id;
				}
			}
			if (attributes.previousPointId !== undefined) {
				this.previousPointId = attributes.previousPointId;
			}
			if (attributes.previousPoint !== undefined) {
				if (attributes.previousPoint === null) {
					this.previousPoint = attributes.previousPoint;
				} else if (attributes.previousPoint instanceof Models.SegmentPointEntity) {
					this.previousPoint = attributes.previousPoint;
					this.previousPointId = attributes.previousPoint.id;
				} else {
					this.previousPoint = new Models.SegmentPointEntity(attributes.previousPoint);
					this.previousPointId = this.previousPoint.id;
				}
			}
			if (attributes.nextPoint !== undefined) {
				if (attributes.nextPoint === null) {
					this.nextPoint = attributes.nextPoint;
				} else if (attributes.nextPoint instanceof Models.SegmentPointEntity) {
					this.nextPoint = attributes.nextPoint;
				} else {
					this.nextPoint = new Models.SegmentPointEntity(attributes.nextPoint);
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.segmentPointLocation !== undefined) {
				this.segmentPointLocation = attributes.segmentPointLocation;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		segment {
			${Models.SegmentEntity.getAttributes().join('\n')}
		}
		nextPoint {
			${Models.SegmentPointEntity.getAttributes().join('\n')}
		}
		previousPoint {
			${Models.SegmentPointEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			nextPoint: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'nextPoint',
							'previousPoint',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(SegmentPointEntity.prototype, 'created');
CRUD(modifiedAttr)(SegmentPointEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
