import MapObject from '../MapObject';
import MapRenderer from '../../MapRenderer';
import * as PIXI from 'pixi.js';
import { AREA_LINE_WIDTH } from './Area';
import { PixiCoordinates, realWorldCoordinates } from '../../Helpers/Coordinates';
import { MapToolParamEntity } from 'Models/Entities';

enum graphicIndex {
	Polygon = 0,
}

const LINE_COLOUR = 0xD3D3D3;

// Sits above all other AHS objects
export default class AhsArea extends MapObject<MapToolParamEntity> {
	private points: PIXI.Point[] = [];
	private mapToolParam: MapToolParamEntity;

	constructor(param: MapToolParamEntity, renderer: MapRenderer) {
		super(renderer, 'ahs_area', param);
		this.mapToolParam = param;
		this.setPoints();

		// Area shape
		this.createGraphic(); // graphicIndex.Polygon
	}

	/**
	 * Calculate the coordinates of the AHS area and set them to PIXI.Point[]
	 */
	setPoints() {

		let pixiPoints: PixiCoordinates[] = [];

		const minEasting = this.mapToolParam.ahsAreaStartingPointX;
		const maxEasting = minEasting + this.mapToolParam.ahsAreaWidthMax;
		const minNorthing = this.mapToolParam.ahsAreaStartingPointY;
		const maxNorthing = minNorthing + this.mapToolParam.ahsAreaLengthMax;

		const southWest = this.renderer.project(realWorldCoordinates(minNorthing, minEasting));
		const southEast = this.renderer.project(realWorldCoordinates(minNorthing, maxEasting));
		const northEast = this.renderer.project(realWorldCoordinates(maxNorthing, maxEasting));
		const northWest = this.renderer.project(realWorldCoordinates(maxNorthing, minEasting));

		pixiPoints.push(southWest);
		pixiPoints.push(southEast);
		pixiPoints.push(northEast);
		pixiPoints.push(northWest);
		pixiPoints.push(southWest);

		pixiPoints.forEach(pixiCoords => {
			const point = new PIXI.Point(pixiCoords.x, pixiCoords.y);
			this.points.push(point);
		})
	}

	private drawPolygon() {
		const graphic = this.getGraphic(graphicIndex.Polygon);
		graphic.clear();
		graphic.lineStyle(AREA_LINE_WIDTH, LINE_COLOUR);
		const polygon = new PIXI.Polygon(this.points);
		graphic.drawPolygon(polygon);
	}

	render() {
		if (this.points.length === 0) {
			this.getGraphic(graphicIndex.Polygon).clear();
			return;
		}

		this.drawPolygon();
	}
}
