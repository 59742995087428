import Modal from "../Modal/Modal";
import React from "react";
import {RenderMapLockedModalContent} from "./MapLockedModal";
import {Button, Colors, Display, Sizes} from "../Button/Button";
import {ButtonGroup} from "../Button/ButtonGroup";

export interface MapLoadErrorModalProps {
    onCloseModal: () => void;
}

export interface MapErrorModalProps {
    onCloseModal: () => void;
    IsExpired?: boolean;
    title: string;
    errorMessage: string;
}

export function MapLoadErrorModal(props: MapLoadErrorModalProps) {
    return <MapErrorModal
        title="Error loading the map"
        errorMessage="There has been an error while loading the map. Please try again"
        {...props}
    />;
}

export default function MapErrorModal(props: MapErrorModalProps) {
    const { onCloseModal } = props;

    return (
        <Modal
            isOpen
            label="Error occurred while loading the map"
            onRequestClose={onCloseModal}
            className="mat-modal"
            overlayClassName="map-file-upload-modal-overlay"
        >
            <RenderMapErrorModalContent {...props} />
        </Modal>
    );
}

export function RenderMapErrorModalContent(props: MapErrorModalProps) {
    const { onCloseModal, errorMessage, title, IsExpired } = props;

    return (
        <>
            <div className="modal__header mat-modal-bottom-border">
                <h3>{title}</h3>
                <Button
                    icon={{icon: 'cross', iconPos: 'icon-right'}}
                    className="cancel-button no-background"
                    display={Display.Text}
                    onClick={onCloseModal}
                    labelVisible={false}
                />
            </div>
            <div className="mat-modal-field">
                <p className="help-text">{errorMessage}</p>
            </div>

            <ButtonGroup className="invite-modal-actions">
                {IsExpired && (
                    <Button
                    className="next-button"
                    colors={Colors.Primary}
                    display={Display.Solid}
                    onClick={() => location.reload()}
                    sizes={Sizes.Medium}
                >
                    Reload map
                </Button>
                )}
                <Button
                    className="next-button"
                    colors={Colors.Primary}
                    display={Display.Solid}
                    onClick={onCloseModal}
                    sizes={Sizes.Medium}
                >
                    Return home
                </Button>
            </ButtonGroup>
        </>
    );
}