import * as React from 'react';
import {
	Button, Colors, Display, Sizes,
} from '../Button/Button';
import { ButtonGroup } from '../Button/ButtonGroup';
import { TextField } from '../TextBox/TextBox';
import FileUpload from '../FileUpload/FileUpload';
import { renderProfileIndicator } from '../Shared/ProfileIndicator';
import { MineSiteEntity } from '../../../Models/Entities';
import alertToast from '../../../Util/ToastifyUtils';
import { SERVER_URL } from '../../../Constants';
import alert from '../../../Util/ToastifyUtils';
import defaultLogo from 'Assets/images/Hitachi-DefaultLogo.png';

interface ISettingsPageComponent {
	mineSiteEntity: MineSiteEntity;
	onUpdateSuccess: () => void;
}

export default function SettingsPageComponent(props: ISettingsPageComponent) {
	const { mineSiteEntity, onUpdateSuccess } = props;
	const logoUrl = !!mineSiteEntity.logoId ? `${SERVER_URL}/api/files/${mineSiteEntity.logoId}` : null;

	const uploadAllFiles = () => {
		if (!validateSettings()) return;

		mineSiteEntity.save({}, { contentType: 'multipart/form-data' })
			.then(_ => {
				alertToast('Mine site details successfully updated', 'success');
				onUpdateSuccess();
			})
			.catch(_ => alertToast('Mine site details could not be updated', 'error'));
	};

	const validateSettings = () => {
		if (!mineSiteEntity.name) {
			alert('Organisation name is missing', 'error');
			return false;
		}

		return true;
	};

	return (
		<div className="settings-page padded-page">
			<div className="row header-row">
				<h2 className="column page-title">
					Settings
				</h2>
				{renderProfileIndicator()}
			</div>
			<div className="settings-component">
				<div className="settings-logo">
					{logoUrl != null && <img src={logoUrl} alt="Mine site icon" />}
					{logoUrl == null && <div className="logo-placeholder" />}
				</div>
				<TextField
					label="Mine site name"
					labelVisible
					isRequired
					model={mineSiteEntity}
					modelProperty="name"
				/>

				<div className="import-file" id="upload-logo">
					<div className="title">
						Replace mine site icon
					</div>
					<FileUpload
						model={mineSiteEntity}
						modelProperty="logo"
						preview
						disableDropArea
						buttonText="Browse files"
					/>
				</div>

				<ButtonGroup className="modal-actions">
					<Button
						className="next-button"
						colors={Colors.Primary}
						icon={{ icon: 'chevron-right', iconPos: 'icon-right' }}
						display={Display.Solid}
						onClick={uploadAllFiles}
						sizes={Sizes.Medium}
					>
						Save Settings
					</Button>
				</ButtonGroup>
			</div>
		</div>
	);
}
