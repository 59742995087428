import {Combobox, ComboboxOption} from "../../../Components/Combobox/Combobox";
import React from "react";

interface IRenderInformationComboboxProps {
    model: unknown;
    label: string;
    modelProperty: string;
    options: ComboboxOption<any>[];
    getOptionValue?: (option: any | undefined) => undefined | boolean | number | string;
    optionEqualFunc?: (modelProperty: string | number | boolean | undefined, option: any | undefined) => boolean;
    readonly?: boolean;
    onAfterChange?: () => void;
    customStyle?: string;
}

/**
 * Map object property input component for combobox
 * @param props
 * @constructor
 */
export function RenderInformationCombobox(props: IRenderInformationComboboxProps) {
    const {
        model, label, modelProperty, readonly, options, getOptionValue, optionEqualFunc, onAfterChange,
        customStyle,
    } = props;

    const className = !!customStyle ? customStyle : 'properties-input';

    const id = `${label.replaceAll(' ', '_')}_${modelProperty}`;

    return (
        <div className={className} id={id}>
            <div className="label">
                <p>{label}</p>
            </div>
            <div className="info-fields">
                <Combobox
                    model={model}
                    options={options}
                    modelProperty={modelProperty}
                    isDisabled={readonly}
                    label={label}
                    labelVisible={false}
                    getOptionValue={getOptionValue}
                    optionEqualFunc={optionEqualFunc}
                    onAfterChange={onAfterChange}
                    searchable={false}
                />
            </div>
        </div>
    );
}