import * as React from 'react';
import {Alignment, ButtonGroup} from "../Components/Button/ButtonGroup";
import {Button, Display, Sizes} from "../Components/Button/Button";
import {ModelCollection} from "../Components/ModelCollection/ModelCollection";
import {ImportVersionEntity, MapEntity} from "../../Models/Entities";
import {action, computed, observable, runInAction} from "mobx";
import If from "../Components/If/If";
import {store} from "../../Models/Store";
import alertToast from "../../Util/ToastifyUtils";
import {ICollectionHeaderProps} from "../Components/Collection/CollectionHeaders";
import moment from "moment-timezone";
import {observer} from "mobx-react";
import {IWhereCondition} from "../Components/ModelCollection/ModelQuery";
import type {ISearch} from "../Components/CRUD/EntityCollection";
import { renderProfileIndicator } from 'Views/Components/Shared/ProfileIndicator';
import SearchForm from '../Components/SearchForm/SearchForm';
import { gql } from '@apollo/client';
import { RenderArchiveModal } from 'Views/Components/Shared/ArchiveModal';
import {getLastModifiedFullName, getOwnerFullName, importNotArchivedCondition} from './AllImportsForMap';
import { MOMENT_FORMAT_STRING } from 'Constants';
import Spinner from "../Components/Spinner/Spinner";
import MapImportModal from "../Components/Shared/UploadModals/NewImportModal";

interface RenderMapsCollectionProps {

}

@observer
export default class RenderMapsCollection extends React.Component<RenderMapsCollectionProps> {
	@observable
	private search: ISearch = { searchTerm: "" };

	@observable
	private isOpenModal: boolean = false;

	@action
	private closeModal = () => {
		this.isOpenModal = false;
	}

	@action
	private onNewMap = () => {
		this.isOpenModal = true;
	};

	@observable
	private numberOfMaps: number;

	@observable
	private isOpenArchiveModal: boolean = false;

	@observable
	private selectedIdForArchive: string = '';

	@action
	private openArchiveModal = (id: string) => {
		this.isOpenArchiveModal = true;
		this.selectedIdForArchive = id;
	}

	private triggerTableRefresh: () => void;

	constructor(props: RenderMapsCollectionProps) {
		super(props);

		const countQuery = gql`
			query countQuery ($has: [[HasConditionType]]) {
				mapEntitys (has: $has) {
					id
					importVersionss {
						id
						archived
					}
				}
			}`;

		const args = {
			"has": [
				[
					{
						"path": "importVersionss",
						"conditions": [importNotArchivedCondition]
					}
				]
			]
		};

		store.apolloClient.query({
			query: countQuery,
			variables: args,
			fetchPolicy: 'no-cache',
		}).then(result => {
			runInAction(() => {
				this.numberOfMaps = result.data.mapEntitys.length;
			})
		}).catch((e: any) => {console.log('error is: ', e)});;

	}
	@action
	private onOpenMap = (model: MapEntity) => {
		// TODO: Call new map modal
		// store.routerHistory.push(`/mapedit/${response.data}`);
		if (model.importVersionss && model.importVersionss.length > 0) {
			store.routerHistory.push(`/maps/${model.id}`);
		} else {
			alertToast(`Open map ${model.name} failed`, 'error');
		}
	};

	private mapCollectionHeaders : Array<ICollectionHeaderProps<MapEntity>> = [
		{
			name: 'name',
			displayName: 'Name',
			sortable: true,
		},
		{
			name: 'created',
			displayName: 'Created on',
			transformItem: model => {
				const tz = model.mineSite?.timezone ?? "Australia/Sydney";
				return moment.tz(model.created, tz).format(MOMENT_FORMAT_STRING);
			},
		},
		{
			name: 'owner',
			displayName: 'Created by',
			transformItem: model => {
				return getOwnerFullName(model);
			},
		},
		{
			name: 'modified',
			displayName: 'Last modified on',
			transformItem: model => {
				const tz = model.mineSite?.timezone ?? "Australia/Sydney";
				return moment.tz(model.modified, tz).format(MOMENT_FORMAT_STRING);
			},
		},
		{
			name: 'lastModifiedUser',
			displayName: 'Last modified by',
			transformItem: model => {
				return getLastModifiedFullName(model);
			},
		},
		{
			name: 'Archive',
			displayName: ' ',
			sortClicked: () => {},
			transformItem: model => {
				return (
					<ButtonGroup alignment={Alignment.HORIZONTAL}>
						<Button
							className="map-list-button"
							display={Display.Solid}
							onClick={() => {
								this.openArchiveModal(model.id);
							}}
							sizes={Sizes.Medium}
						>Archive
						</Button>
					</ButtonGroup>
				);
			},
		},
		{
			name: 'View',
			displayName: ' ',
			sortClicked: () => {},
			transformItem: model => {
				return (
					<ButtonGroup alignment={Alignment.HORIZONTAL}>
						<Button
							className="map-list-button view-map"
							display={Display.Solid}
							onClick={() => this.onOpenMap(model)}
							sizes={Sizes.Medium}
						>View
						</Button>
					</ButtonGroup>
				);
			},
		},

	];

	@action
	private onSearchTriggered = (searchTerm: string) => {
		this.search.searchTerm = searchTerm;
	}

	@computed
	private get collectionFilters():Array<Array<IWhereCondition<MapEntity>>> {
		const searchConditions = this.convertSearchConditions();
		return [...searchConditions];
	}

	private convertSearchConditions = ():Array<Array<IWhereCondition<MapEntity>>> => {
		if (this.search.searchTerm && this.search.searchTerm.trim() !== '') {
			const validTerm = `%${this.search.searchTerm.trim()}%`
			let searchConditions: Array<IWhereCondition<MapEntity>> = [
				{ path: "name", comparison: "like", value: validTerm, case: "INVARIANT_CULTURE_IGNORE_CASE" },
			];

			return [searchConditions];
		}

		return [];
	}

	/**
	 * Archives all import version of selected map
	 */
	private archiveImport = async () => {
		if (this.selectedIdForArchive === '') return;

		const conditionArgs: Array<Array<IWhereCondition<ImportVersionEntity>>> = [
			[
				{ path: 'mapId', comparison: 'equal', value: this.selectedIdForArchive },
			],
			importNotArchivedCondition,
		];

		const allImportVersions: ImportVersionEntity[] = await ImportVersionEntity
			.fetch<ImportVersionEntity>({ args: conditionArgs, orderBy: [{ path: 'created', descending: true }] });

		const importIds = allImportVersions.map(v => {
			v.archived = true;
			return v.id;
		});

		await (new ImportVersionEntity({ archived: true }))
			.updateWhere(undefined, ['archived'], importIds);

		runInAction(() => {
			this.selectedIdForArchive = '';
			this.numberOfMaps -= 1; 
			this.isOpenArchiveModal = false;
		});

		this.triggerTableRefresh();
	}

	render() {
		return (
			<>
				<div className='all-maps-page padded-page'>
					<div className="row header-row">
						<h2 className="column page-title">
							Maps
						</h2>
						<SearchForm
							model={this.search}
							onChange={this.onSearchTriggered}
							label="A search for entities"
							classNameSuffix="import-collection"
						/>
						{renderProfileIndicator()}
					</div>					
					<div className="row align-right">
						<ButtonGroup alignment={Alignment.HORIZONTAL}>
							<Button className="btn--primary" display={Display.Solid} onClick={this.onNewMap} sizes={Sizes.Medium} buttonProps={{ id: 'new_map_button' }} icon={{ icon: 'plus', iconPos: 'icon-left' }}>New Map</Button>
						</ButtonGroup>
					</div>
					
					{
					this.numberOfMaps === 0 ?
							
					<div className="empty-state-section">
						<div className = "empty-state-background"> 
						</div>
						<h1>
							Welcome to the Map Authoring Tool.
						</h1>
						<h2>
							Setup your first map to get started →
						</h2>
						<div>
							<ButtonGroup alignment={Alignment.HORIZONTAL}>
								<Button className='empty-state-new-button' display={Display.Solid} onClick={this.onNewMap} sizes={Sizes.Medium} buttonProps={{ id: 'new_map_button' }} icon={{ icon: 'plus', iconPos: 'icon-top' }}>New Map</Button>
							</ButtonGroup>
						</div>
					</div>
					:
					<ModelCollection
						{...this.props}
						model={MapEntity}
						headers={this.mapCollectionHeaders}
						conditions={this.collectionFilters}
						orderBy={{
							path: 'modified',
							descending: true,
						}}
						has={ [[{ 
							path: 'importVersionss',
							conditions: [[{ path: 'archived', comparison: 'equal', negate: true, value: 'true' }]]
						}]] }
						perPage={10}
						customSpinner={<div className='spinner-div'><Spinner /></div>}
						setRefetch={refetch => {
							this.triggerTableRefresh = refetch;
						}}
						idColumn="id"
					/>
					}
					
					<If condition={this.isOpenModal}>
						<MapImportModal
							title="New Map"
							onCloseModal={this.closeModal}
						/>
					</If>
					<RenderArchiveModal
						isOpenModal={this.isOpenArchiveModal}
						isMap={true}
						closeModal={() => {
							runInAction(() => {
								this.isOpenArchiveModal = false;
							});
						}}
						archive={this.archiveImport}
					/>
				</div>
			</>
		);
	}
}