import React, { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import {
	Button, Colors, Display, Sizes,
} from '../Button/Button';

import { ButtonGroup } from '../Button/ButtonGroup';
import { renderLockOwnerProfileIndicator } from './ProfileIndicator';
import {IUserDetails} from "../../../Models/UserEntity";

interface IMapLockedModalProps {
    onCloseModal: () => void;
	lockOwner: IUserDetails;
	lastSave: string;
}

export function MapLockedModal(props: IMapLockedModalProps) {
	const { onCloseModal } = props;

	return (
		<Modal
			isOpen
			label="This map is currently in use"
			onRequestClose={onCloseModal}
			className="mat-modal"
			overlayClassName="map-file-upload-modal-overlay"
		>
			<RenderMapLockedModalContent {...props} />
		</Modal>
	);
}

export function RenderMapLockedModalContent(props: IMapLockedModalProps) {
	const { onCloseModal, lockOwner, lastSave } = props;

	return (
		<>
			<div className="modal__header mat-modal-bottom-border">
				<h3>This map is currently in use</h3>
				<Button
					icon={{ icon: 'cross', iconPos: 'icon-right' }}
					className="cancel-button no-background"
					display={Display.Text}
					onClick={onCloseModal}
					labelVisible={false}
				/>
			</div>
			<div className="mat-modal-field">
				<p className="help-text">Only one person can have a map open at one time.</p>
			</div>
			<div className="mat-modal-field">
				<p className="grey-font">CURRENT USER</p>
			</div>
			<div className="mat-modal-field">
				{renderLockOwnerProfileIndicator(lockOwner, lastSave)}
			</div>

			<ButtonGroup className="invite-modal-actions">
				<Button
					className="next-button"
					colors={Colors.Primary}
					display={Display.Solid}
					onClick={onCloseModal}
					sizes={Sizes.Medium}
				>
					Return home
				</Button>
			</ButtonGroup>
		</>
	);
}
