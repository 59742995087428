import { BayEntity } from 'Models/Entities';
import { bayType } from 'Models/Enums';

import * as PIXI from 'pixi.js';

export enum BayColors {
	Loading = 0xFFFFFF,
	Parking = 0xA52A2A,
	Fuelling = 0xFFFF00,
	PaddockDumping = 0xFF8000,
	OverEdgeDumping = 0xFF0000,
	CrusherDumping = 0x00FF00,
}

/**
 * Gets bay shape style options
 * @param bayColour 
 *  @param lineWidth 
 * @returns  
 */
export function getBayOutlineStyleOptions(bayColour: BayColors, lineWidth: number) {
	return {
		width: lineWidth,
		color: bayColour,
		alpha: 1,
		cap: PIXI.LINE_CAP.SQUARE,
		join: PIXI.LINE_JOIN.MITER,
		alignment: 1, // The outside edge of the line should be the dimensions of the bay
	};
}

export function getBayColour(bayType: bayType) {
	switch (bayType) {
		case 'DUMPCRUSHER':
			return BayColors.CrusherDumping;
		case 'DUMPOVEREDGE':
			return BayColors.OverEdgeDumping;
		case 'DUMPPADDOCK':
			return BayColors.PaddockDumping;
		case 'FUELLING':
			return BayColors.Fuelling;
		case 'LOADING':
			return BayColors.Loading;
		case 'PARKING':
			return BayColors.Parking;
		default:
			return BayColors.Parking;
	}
	
}

export default BayColors;