import {SaveCommand, SaveCommandType} from "./SaveCommand";

export default class DeleteBayCommand extends SaveCommand {
	bayId: string;

	constructor(bayId: string) {
		super(SaveCommandType.DeleteBay);

		this.bayId = bayId;
	}
}