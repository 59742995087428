import MapObject from '../MapObject';
import { LinkEntity, NodeEntity, SignalSetEntity } from '../../../../../Models/Entities';
import MapRenderer from '../../MapRenderer';
import * as PIXI from 'pixi.js';
import { PixiCoordinates } from '../../Helpers/Coordinates';

const STROKE_COLOR = 0x000000;
const FILL_COLOR = 0xFFFFFF;
const RADIUS_OUTER = 20;
const RADIUS_MIDDLE = 19;
const RADIUS_INNER = 18;
const SCALE_FACTOR = 5;

// Sits above all other AHS objects
export default class Speed extends MapObject<PixiCoordinates> {
	public linkEntity: LinkEntity;
	private speedText: PIXI.Text;
	private speedGraphic: PIXI.Graphics;
	constructor(point: PixiCoordinates, renderer: MapRenderer, linkEntity: LinkEntity) {
		super(renderer, 'speed', point);
		this.linkEntity = linkEntity;
		this.speedGraphic = this.createGraphic();
		const sign = linkEntity.constantSpeed > 0 ? 1 : -1;
		this.speedText = this.createText((sign * Math.ceil(Math.abs(linkEntity.constantSpeed))).toString());
		this.speedText.scale.set(1 / SCALE_FACTOR);
	}

	private drawSpeedCircle(position: PIXI.IPointData) {
		const graphic = this.speedGraphic;
		graphic.beginFill(FILL_COLOR);
		graphic.position.set(position.x, position.y);
		graphic.drawCircle(0, 0, RADIUS_OUTER);
		graphic.endFill();
		graphic.lineStyle(0, STROKE_COLOR);
		graphic.beginFill(0x0);
		graphic.position.set(position.x, position.y);
		graphic.drawCircle(0, 0, RADIUS_MIDDLE);
		graphic.beginHole();
		graphic.drawCircle(0, 0, RADIUS_INNER);
		graphic.endHole();
		graphic.endFill();
	}

	public _updateScale(isReset: boolean) {
		const graphic = this.speedGraphic;
		let newScale = isReset ? 1 : 1 / this.renderer.pixiScale;
		graphic.scale.set(newScale);
		newScale = isReset ? ( 1 / SCALE_FACTOR ) : 1 / (this.renderer.pixiScale * SCALE_FACTOR);
		this.speedText.scale.set(newScale);
		this.renderer.pixiCurrentAppliedZoom = this.renderer.pixiZoom;
		
	}
	render() {
		const graphic = this.speedGraphic;
		const point = this.getEntity();
		graphic.clear();
		this.speedText.position.set(point.x, point.y);
		this.speedText.anchor.set(0.5);
		this.drawSpeedCircle(point);
	}
}
