/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import { Button, Display, Sizes } from '../Components/Button/Button';
import { action, observable } from 'mobx';
import { store } from 'Models/Store';
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import alert from '../../Util/ToastifyUtils';
import { Password } from 'Views/Components/Password/Password';
import * as queryString from 'querystring';
import { resetPassword } from '../../Services/Api/AccountService';
// % protected region % [Add any extra imports here] on begin
import hitachiTrucks from '../../images/HITACHI-TRUCKS-EH5000-LINE-UP-001-2000x2000-1.jpg';
import hitachiLogo from 'Assets/images/HCM logo transparent-360x180.png';
import { PASSWORD_REGEX } from 'Constants';
// % protected region % [Add any extra imports here] end

interface IResetPasswordState {
	token: string;
	username: string;
	password: string;
	confirmPassword: string,
	errors: {
		password?: string,
		confirmPassword?: string,
		[attr: string]: string | undefined,
	};
	// % protected region % [Add extra reset password state properties here] on begin
	errorFlag: boolean
	// % protected region % [Add extra reset password state properties here] end
}

const defaultResetPasswordState: IResetPasswordState = {
	token: '',
	username: '',
	password: '',
	confirmPassword: '',
	errors: {},
	// % protected region % [Instantiate extra reset password state properties here] on begin
	errorFlag: false
	// % protected region % [Instantiate extra reset password state properties here] end
};

@observer
export default class ResetPasswordPage extends React.Component<RouteComponentProps> {
	@observable
	private resetPasswordState: IResetPasswordState = defaultResetPasswordState;

	constructor(props:RouteComponentProps) {
		super(props);
		const { location } = this.props;
		const queryParams = location.search.substring(1);
		const { token, username } = queryString.parse(queryParams);
		this.resetPasswordState.token = token as string;
		this.resetPasswordState.username = username as string;
	}

	public render() {
		let contents = null;

		if (store.loggedIn) {
			// % protected region % [Override redirect here] off begin
			return <Redirect to="/" />;
			// % protected region % [Override redirect here] end
		}

		// % protected region % [Override contents here] on begin
		contents = (
			<div className="body-content">
				<div className="row">
					<div className="column">
						<form className="reset-password" onSubmit={e => this.onResetPasswordClicked(e)} >
							<h1 className="reset-password-headers">Welcome to the</h1>
							<h1 className="reset-password-mat-text-bottom">Map Authoring Tool</h1>
							<h3>Enter a new password.</h3>
							<Password
								id="new_password"
								className="new-password"
								model={this.resetPasswordState}
								modelProperty="password"
								label="Password"
								isRequired
								errors={this.resetPasswordState.errors.password}
							/>
							<div className='row'>
								<ul className={this.resetPasswordState.errorFlag ? "activation-ul-left-error" : "activation-ul-left"}>
									<li>
										12 characters minimum
									</li>
									<li>
										One lower case
									</li>
									<li>
										One uppercase character
									</li>
								</ul>
								<ul className= {this.resetPasswordState.errorFlag ? "activation-ul-right-error" : "activation-ul-right"}>
									<li>
										One number
									</li>
									<li>
										One special character
									</li>
								</ul>
							</div>
							<Password
								id="confirm_password"
								className="confirm-password"
								model={this.resetPasswordState}
								modelProperty="confirmPassword"
								label="Confirm Password"
								isRequired
								errors={this.resetPasswordState.errors.confirmPassword}
							/>
							<Button type='submit' className="reset-password-submit btn--primary"
                                sizes={Sizes.Large} buttonProps={{ id: "reset-password_submit" }} icon={{ icon: "chevron-right", iconPos: 'icon-right' }}>Reset Password</Button>
						</form>
					</div>
					<div className="column">
						<img className="hitachi-logo" src={hitachiLogo} />
						<img className="login-image" src={hitachiTrucks} />
					</div>
				</div>
			</div>
		);
		// % protected region % [Override contents here] end
		return contents;
	}

	@action
	private onResetPasswordClicked = (event: React.FormEvent<HTMLFormElement>) => {
		// % protected region % [Override onResetPasswordClicked here] on begin
		event.preventDefault();

		this.resetPasswordState.errors = {};

        //password field validations
        if (!(PASSWORD_REGEX.test(this.resetPasswordState.password))){
            this.resetPasswordState.errorFlag = true;
            this.resetPasswordState.errors.password =  ' ';
        }
        else {
            this.resetPasswordState.errorFlag = false;
        }
        if (!(PASSWORD_REGEX.test(this.resetPasswordState.confirmPassword))){
            this.resetPasswordState.errors.confirmPassword =  "Password is invalid and must follow the rules above.";
        }
        if (this.resetPasswordState.confirmPassword != this.resetPasswordState.password) {
            this.resetPasswordState.errors.confirmPassword = "Passwords are not matching.";
        }

		if (Object.keys(this.resetPasswordState.errors).length === 0) {
			resetPassword(
				this.resetPasswordState.token,
				this.resetPasswordState.username,
				this.resetPasswordState.password,
			).then(() => {
				this.onConfirmPasswordSent();
			}).catch(errors => {
				const errorMsgs = errors.response.data.errors.map((error: any) => (<p>{error.message}</p>));
				alert(
					<div>
						<h6>Password could not be changed: </h6>
						{errorMsgs}
					</div>,
					'error',
				);
			});
		}
		// % protected region % [Override onResetPasswordClicked here] end
	};

	@action
	private onConfirmPasswordSent = () => {
		// % protected region % [Override login success logic here] off begin
		alert('Your password has been reset', 'success');
		store.routerHistory.push('/login');
		// % protected region % [Override login success logic here] end
	};

	// % protected region % [Add class methods here] off begin
	// % protected region % [Add class methods here] end
}
