import {SaveCommand, SaveCommandType} from "./SaveCommand";
import {BayEntity} from "../../../../Models/Entities";

export default class UpdateBayCommand extends SaveCommand {
	bay: BayEntity;

	constructor(bay: BayEntity) {
		super(SaveCommandType.UpdateBay);

		this.bay = bay;
	}
}