import {SaveCommand, SaveCommandType} from "./SaveCommand";

export default class JoinSublinkCommand extends SaveCommand {

	linkId: string;
	breakIndex: number;

	constructor(linkId: string, breakIndex: number) {
		super(SaveCommandType.JoinSublink);

		this.linkId = linkId;
		this.breakIndex = breakIndex;
	}
}