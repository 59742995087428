import {ImportVersionEntity} from "../../../../Models/Entities";
import {BackgroundImageOption} from "../../../MapComponents/AllImportsForMap";
import {MapController} from "../../../MapComponents";
import {CustomValidator, Validator} from "../../../../Validators/CustomValidator";
import {observable, runInAction} from "mobx";
import {observer} from "mobx-react";
import classNames from "classnames";
import React, {useState} from "react";
import FileUpload from "../../FileUpload/FileUpload";
import {Combobox} from "../../Combobox/Combobox";
import InputWrapper, {InputType} from "../../Inputs/InputWrapper";


export const MAX_NAME_LENGTH = 50;

export interface IFileUploadModalProps {
	title: string;
	mapId?: string;
	importVersion?: ImportVersionEntity;
	onCloseModal: () => void;
	hasBackgroundImageOpts?: boolean;
	backgroundImageOptions?: BackgroundImageOption[];
	map?: MapController;
}

const fileNameRegex = /.+(\d{14})\..+/;
const fileSize1gb = 1000 * 1000 * 1000;
const fileSize10mb = 10 * 1000 * 1000;
const fileSize100mb = 100 * 1000 * 1000;

export async function mapNameValidation(value: string) {
	const name = value?.trim() ?? '';

	if (name.length == 0) {
		return 'The map name is required. Please provide a map name.';
	} else if (name.length > MAX_NAME_LENGTH) {
		return 'The map name can have a maximum of 50 characters. Please provide a shorter map name.';
	}

	return null;
}

export async function mapImportNameValidation(value: string) {
	const name = value?.trim() ?? '';

	if (name.length == 0) {
		return 'The map import name is required. Please provide a map name.';
	} else if (name.length > MAX_NAME_LENGTH) {
		return 'The map import name can have a maximum of 50 characters. Please provide a shorter map name.';
	}

	return null;
}

export async function backgroundImageFileValidation(value: File) {
	if (value == null) {
		return 'The background image data is required. Please select a background image data file.';
	}

	const fileName = value.name;
	const size = value.size;

	if (!fileName.toLowerCase().endsWith('.zip')) {
		return 'The selected file type is invalid. Please select a .zip file.';
	}

	const invalidName = !fileName.startsWith("BackGroundImage");
	const isMissingDate = !fileNameRegex.test(fileName);
	if (invalidName || isMissingDate) {
		return 'The selected file name format is invalid. Please select a file with a valid name format.';
	}

	if (size > fileSize1gb) {
		return 'The selected file size exceeds the allowed size limit. Please select a file less than 1GB in size.';
	}

	return null;
}

export async function areaDataFileValidation(value: File) {
	if (value == null) {
		return 'The AHS current area data is required. Please select a AHS current area data file.';
	}

	const fileName = value.name;
	const size = value.size;

	if (!fileName.toLowerCase().endsWith('.zip')) {
		return 'The selected file type is invalid. Please select a .zip file.';
	}

	const invalidName = !fileName.startsWith("AHSCurrentAreaData");
	const isMissingDate = !fileNameRegex.test(fileName);
	if (invalidName || isMissingDate) {
		return 'The selected file name format is invalid. Please select a file with a valid name format.';
	}

	if (size > fileSize10mb) {
		return 'The selected file size exceeds the allowed size limit. Please select a file less than 10MB in size.';
	}

	return null;
}

export async function mapFileValidation(value: File) {
	if (value == null) {
		return 'The AHS current map data is required. Please select an AHS current map data file.';
	}

	const fileName = value.name;
	const size = value.size;

	if (!fileName.toLowerCase().endsWith('.zip')) {
		return 'The selected file type is invalid. Please select a .zip file.';
	}

	const invalidName = !fileName.startsWith("AHSCurrentMap");
	const isMissingDate = !fileNameRegex.test(fileName);
	if (invalidName || isMissingDate) {
		return 'The selected file name format is invalid. Please select a file with a valid name format.';
	}

	if (size > fileSize100mb) {
		return 'The selected file size exceeds the allowed size limit. Please select a file less than 100MB in size.';
	}

	return null;
}

export async function terrainFileValidation(value: File) {
	if (value == null) {
		return null;
	}

	const fileName = value.name;
	const size = value.size;

	if (!fileName.toLowerCase().endsWith('.zip')) {
		return 'The selected file type is invalid. Please select a .zip file.';
	}

	const invalidName = !fileName.startsWith("TerrainBoundary");
	const isMissingDate = !fileNameRegex.test(fileName);
	if (invalidName || isMissingDate) {
		return 'The selected file name format is invalid. Please select a file with a valid name format.';
	}

	if (size > fileSize100mb) {
		return 'The selected file size exceeds the allowed size limit. Please select a file less than 100MB in size.';
	}

	return null;
}

// This needs to use mobx as FileUpload uses mobx
export class CreateImportClass extends Validator<CreateImportClass> {
	@CustomValidator(async (value, model) => {
		const name = value.trim() ?? '';
		model.mapName = name;

		if (name.length == 0) {
			return'The map name is required. Please provide a map name.';
		} else if (name.length > MAX_NAME_LENGTH) {
			return 'The map name can have a maximum of 50 characters. Please provide a shorter map name.';
		}

		return null;
	})
	@observable
	public mapName: string;

	@observable
	public mapImportName: string;

	@CustomValidator(async (value, model) => {
		if (value == null) {
			return 'The background image data is required. Please select a background image data file.';
		}

		const fileName = value.name;
		const size = value.size;

		if (!fileName.toLowerCase().endsWith('.zip')) {
			return 'The selected file type is invalid. Please select a .zip file.';
		}

		const invalidName = !fileName.startsWith("BackgroundImage");
		const isMissingDate = !fileNameRegex.test(fileName);
		if (invalidName && isMissingDate) {
			return 'The selected file name format is invalid. Please select a file with a valid name format.';
		}

		if (size > fileSize1gb) {
			return 'The selected file size exceeds the allowed size limit. Please select a file less than 1GB in size.';
		}

		return null;
	})
	@observable
	public importedImage: File;

	@observable
	public existingImageId: string;

	@observable
	public mapData: File;

	@observable
	public terrainData: File;

	@observable
	public areaData: File;

	@observable
	public backgroundData: File;
}

export interface IFileUploadSectionProps<T> {
	model: T;
	modelProperty: string;
	title: string;
	fileType: string;
	required: boolean;
	contentType?: string;
	onAfterChange?: () => void;
	hasBackgroundImageOpts?: boolean;
	backgroundImageOptions?: BackgroundImageOption[];
	errors?: string;
}

export const FileUploadSection = observer(<T,>(props: IFileUploadSectionProps<T>) => {
	const {
		model, modelProperty, title, fileType, required, onAfterChange, contentType, hasBackgroundImageOpts, backgroundImageOptions, errors
	} = props;

	return (
		<div className={classNames('import-file', hasBackgroundImageOpts ? 'import-file-with-options' : '')} id={modelProperty}>
			<div className="import-file-info">
				<h5 className="title">{title}</h5>
				<p className="separator">•</p>
				<p className="file-type">{fileType}</p>
				{required ? <p className="required txt-sm-body">Required</p> : <p className="optional txt-sm-body">Optional</p>}
			</div>
			{ hasBackgroundImageOpts ? 
				<OptionsSection
					model={model}
					modelProperty={modelProperty}
					contentType={contentType}
					backgroundImageOptions={backgroundImageOptions}
					errors={errors}
					onAfterChange={onAfterChange}
				/>
			: <FileUpload
					model={model}
					modelProperty={modelProperty}
					preview
					disableDropArea
					onAfterChange={onAfterChange}
					onAfterDelete={onAfterChange}
					buttonText="Browse files"
					contentType={contentType}
					errors={errors}
				/>}			
		</div>
	);
});

export interface IFileOptionsSectionProps<T> {
	model: T;
	modelProperty: string;
	contentType?: string;
	onAfterChange?: () => void;
	backgroundImageOptions?: BackgroundImageOption[];
	errors?: string;
}

export const OptionsSection = observer(<T, >(props: IFileOptionsSectionProps<T>) => {
	const { model, modelProperty, contentType, backgroundImageOptions, errors } = props;
	const initialState = observable({
		backgroundImageId: '',
	});

	const [ isImageOptionChecked, setIsImageOptionChecked ] = useState(false);
	const [ selectedBackgroundImage, setSelectedBackgroundImage ] = useState(initialState);
	const [ isOptionsDisabled, setIsOptionsDisabled ] = useState(false);

	// sort according to created time	
	const _backgroundImageOptions = backgroundImageOptions?.sort((a, b) => {
																			return new Date(b.created).valueOf() - new Date(a.created).valueOf();
																		});

	let fileOptionsCombobox: any = [];
	if (!!backgroundImageOptions) {
		fileOptionsCombobox = _backgroundImageOptions?.map(item => { return { display: item.fileName, value: item.backgroundImageId } })
	}

	const onAfterImageDropdownChange = () => {
		if (selectedBackgroundImage.backgroundImageId !== fileOptionsCombobox[0].value) {
			setIsImageOptionChecked(false);
		} else {
			setIsImageOptionChecked(true);
		}
		runInAction(() => {
			model["existingImageId"] = selectedBackgroundImage.backgroundImageId;
		});

		if (!!props.onAfterChange) {
			props.onAfterChange();
		}
	};

	const onAfterImageUploadChange = () => {
		if (!!model[modelProperty]) {
			runInAction(() => {
				selectedBackgroundImage.backgroundImageId = '';
				setIsImageOptionChecked(false);
			});
			setIsOptionsDisabled(true);
		}

		if (!!props.onAfterChange) {
			props.onAfterChange();
		}
	};

	return (
		<div className='import-options-section'>
			<div className='options-section'>
				<Combobox
					model={selectedBackgroundImage}
					options={fileOptionsCombobox}
					modelProperty="backgroundImageId"
					isDisabled={isOptionsDisabled}
					label=""
					labelVisible={false}
					placeholder="Select existing background image"
					onAfterChange={onAfterImageDropdownChange}
				/>
				<InputWrapper inputType={InputType.CHECKBOX} className="most-recently-uploaded">
					<input
						id="most-recently-uploaded-background"
						type="checkbox"
						checked={isImageOptionChecked}
						disabled={isOptionsDisabled}
						onChange={() => {
							runInAction(() => {
								if (isImageOptionChecked && selectedBackgroundImage.backgroundImageId !== '') {
									selectedBackgroundImage.backgroundImageId = '';
								} else {
									selectedBackgroundImage.backgroundImageId = fileOptionsCombobox[0].value;
								}
								model["existingImageId"] = selectedBackgroundImage.backgroundImageId;
							});
							setIsImageOptionChecked(!isImageOptionChecked);

							if (!!props.onAfterChange) {
								props.onAfterChange();
							}
						}}
					/>
					<label htmlFor="most-recently-uploaded-background">Most Recently Uploaded</label>
				</InputWrapper>
			</div>
			<FileUpload
				model={model}
				modelProperty={modelProperty}
				className={'add-new-background-image'}
				preview
				disableDropArea
				onAfterChange={onAfterImageUploadChange}
				onAfterDelete={() => {
					if (!!props.onAfterChange) {
						props.onAfterChange();
					}

					setIsOptionsDisabled(false);
				}}
				buttonText="+ Add new background image"
				contentType={contentType}
				errors={errors}
			/>
		</div>
	);
});
