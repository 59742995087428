import classNames from "classnames";
import { useEffect, useState } from "react";
import { MapController } from ".";
import { RealWorldCoordinates } from "./Map/Helpers/Coordinates";

interface IMouseCursorCoords {
    map: MapController | undefined;
}

export default function MouseCursorCoords(props: IMouseCursorCoords) {
    const { map } = props;
    const [ displayPropertiesPanel, setDisplayPropertiesPanel ] = useState(true);
    const [ coords, setCoords ] = useState({ northing: 0, easting: 0 });
    const [ elevation, setElevation ] = useState<undefined | number>(undefined);

    useEffect(() => {
        map?.getEventHandler().addListener('onCursorCoordsChange', onCursorCoordsChange);
        map?.getEventHandler().addListener('onShiftCursorLocationPanel', onShiftCursorLocationPanel);

        return () => {
            map?.getEventHandler().removeListener('onCursorCoordsChange', onCursorCoordsChange);
            map?.getEventHandler().removeListener('onShiftCursorLocationPanel', onShiftCursorLocationPanel);
        };
    }, []);

    const onCursorCoordsChange = (location: RealWorldCoordinates) => {
        setCoords(location);
    };

    const onShiftCursorLocationPanel = (displayPropertiesPanel: boolean) => {
        setDisplayPropertiesPanel(displayPropertiesPanel);
    };

    return (
        <div className={classNames('mouse-cursor-location-parent', displayPropertiesPanel ? '' : 'no-side-panel')} id ="mouse-cursor-location-parent">
            <div className="coords icon-location-map icon-left">
                <div className="coords-east">{coords.easting.toFixed(1)} m E</div>
                <div className="coords-north">{coords.northing.toFixed(1)} m N</div>
            </div>
            <div className={classNames('elevation', 'icon-mountain', 'icon-left')}>{elevation ? elevation.toFixed(1) : '-'} m</div>
        </div>
    )
}