import {
	AreaEntity, BayEntity, ImportVersionEntity, LinkEntity, LinkFromLinkTo, NodeEntity, SignalSetEntity, SublinkEntity,
} from '../../../Models/Entities';
import MapRenderer, { CONTAINER_Z_INDEX } from './MapRenderer';
import MapEventHandler from './MapEventHandler';
import MapStore from './MapStore';
import Path from './MapObjects/Path/Path';
import Area from './MapObjects/Area/Area';
import DrivingArea from './MapObjects/Area/DrivingArea';
import MapObject, { MapObjectType } from './MapObjects/MapObject';
import { Model } from '../../../Models/Model';
import Bay from './MapObjects/Bay/Bay';
import Beacon from './MapObjects/Beacon/Beacon';
import Location from './MapObjects/Location/Location';
import Segment from './MapObjects/Segment/Segment';
import {
	Coordinates,
	isPixiCoordinates,
	LeafletCoordinates,
	PixiCoordinates, RealWorldCoordinates,
} from './Helpers/Coordinates';
import rotate from '../../../Assets/images/rotate.svg';
import rotate_path from '../../../Assets/images/rotate_path.svg';
import * as PIXI from 'pixi.js';
import { ToolbarEvent } from '../MapToolbar/Toolbar';
import { getBayName } from '../LayersPanel/AhsMapObjects';
import PathToolHelper from './MapStateHandlerHelpers/PathToolHelper';
import { store } from '../../../Models/Store';
import { trc_disable } from './Helpers/MapUtils';
import Signal from './MapObjects/Signal/Signal';
import TurnSignalHelper from './MapStateHandlerHelpers/TurnSignalHelper';
import AhsArea from './MapObjects/Area/AhsArea';
import ChangeTracker from "../ChangeTracker/ChangeTracker";

const ROTATE_CURSOR = `url(${rotate}) 12 12, auto`;
const ROTATE_PATH_CURSOR = `url(${rotate_path}) 12 12, auto`;

/**
 * Entity types that can be selected
 */
export type MapType = MapObjectType | 'map'
	| 'clothoid'
	| 'node'
	| 'link'
	| 'bay'
	| 'sublink'
	| 'area'
	| 'location'
	| 'beacon'
	| 'segment'
	| 'ruler'
	| 'connection';

export type MapObjectErrorType = BayEntity | AreaEntity | LinkEntity | SublinkEntity | NodeEntity;
interface IEntityInfo {
	userMessage: string;
	mapObjectType: MapObjectType;
}

export interface ImportVersionStatus {
	bayEdited: boolean;
	bayPublished: boolean;
	areaEdited: boolean;
	areaPublished: boolean;
	pathEdited: boolean;
	pathPublished: boolean;
}

/**
 * Controller class which is responsible for
 *  - Initialising the MapRenderer and MapEventHandler
 *  - Constructing MapLookup table
 *  - Parsing map objects to renderer
 *  - Initiating rendering
 *  - Attaching events to MapEventHandler
 */
export default class MapController {
	private readonly renderer: MapRenderer;
	private readonly eventHandler: MapEventHandler;

	private readonly version: ImportVersionEntity;
	private readonly mapLookup: MapStore;

	private readonly tracker: ChangeTracker;


	private selectedTool: ToolbarEvent;

	private highlightedObjectId?: string;
	private highlightedEntityId?: string;
	private autoSaveChangesIntervalId: NodeJS.Timeout | undefined;

	private _isAutoSaveDisabled = false;

	private trc = trc_disable;

	private currentActionErrorCount = 0;
	public isDisplayConfirmButton = false;
	public isActionConfirmAllowed() {
		return this.currentActionErrorCount === 0;
	}

	constructor(version: ImportVersionEntity) {
		this.version = version;
		this.mapLookup = new MapStore(version, version.maptoolparam!);

		// Add global references to these objects for debugging purposes
		document['lookup'] = this.mapLookup;
		document['controller'] = this;
		store.mapController = this;

		this.renderer = new MapRenderer(this.version, this);
		document['renderer'] = this.renderer;
		this.eventHandler = new MapEventHandler(this);
		this.tracker = new ChangeTracker(this);
		this.selectedTool = 'selector';
	}

	public rerenderLink(id: number) {
		const link = this.getMapLookup().getLinkByIdNumber(id);
		this.removeAndReAddPath(link!, true);
	}

	public get isAutoSaveDisabled() {
		return this._isAutoSaveDisabled;
	}

	public set isAutoSaveDisabled(isDisabled: boolean) {
		this._isAutoSaveDisabled = isDisabled;
	}


	public setConfirmButtonStatus(displayed: boolean, enabled: boolean) {
		// If we are transitioning to a confirmable action, reset the count
		if (this.isDisplayConfirmButton !== displayed) {
			this.currentActionErrorCount = 0;
		}

		this.isDisplayConfirmButton = displayed;

		if (!displayed) {
			return;
		}

		// If enabling the button, it means there is one less error
		// If disabling the button, it means there is one more error
		if (enabled) {
			if (this.currentActionErrorCount != 0) {
				this.currentActionErrorCount -= 1;
			}
		} else {
			this.currentActionErrorCount += 1;
		}
	}

	public mountMap() {
		this.trc('Begin mountMap');
		console.time('mountMap');
		// base init of leaflet/pixi
		this.renderer.initRenderer();

		this.eventHandler.startListening();

		this.initialBuildMapObjects();

		// final init of pixi and start render
		this.renderer.mountPixiAndStartRendering();

		this.focusMap();
		console.timeEnd('mountMap');
	}

	public unmountMap() {
		store.isInit = false;
		console.log('unmountMap: Setting isInit to false')
		this.eventHandler.stopListening();
		this.renderer.deInitLeaflet();
	}

	/**
	 * Visually highligh a map object by entityId
	 * @param entityId
	 * @param mapType
	 */
	public highlightObjectByEntityId(entityId: string, mapType: string) {
		const objectId = this.getMapLookup().getMapObjectId(entityId, mapType as MapType);
		this.unhighlightObject(false);
		this.highlightedObjectId = objectId;
		this.highlightedEntityId = entityId;
		const mapObject = this.renderer.getObjectById(objectId);
		if (mapObject) {
			const includeChildren = mapObject.getType() === 'sublink'; // for driving zones
			mapObject.setHighlighted(true, includeChildren);
		}

		this.renderer.rerender();
	}

	/**
	 * Unhighlighted map object (if highlighted)
	 * IMPORTANT: To avoid severe performance hit, do note re-render unless there are actual changes
	 */
	public unhighlightObject(rerender: boolean = true) {
		let isRerendered = false;
		if (!!this.highlightedObjectId) {
			const mapObject = this.renderer.getObjectById(this.highlightedObjectId);
			if (mapObject) {
				const includeChildren = mapObject.getType() === 'sublink'; // for driving zones
				mapObject.setHighlighted(false, includeChildren);
			}
			this.highlightedObjectId = undefined;
			this.highlightedEntityId = undefined;
			if (rerender) {
				this.renderer.rerender();
				isRerendered = true;
			}
		}

		if (isRerendered !== rerender) {
			// Previous bevahiour was rerender even if no unhighlight but this caused performance hit
			console.log('unhighlightObject: Nothing to unhighlight. Ignoring.');
		}
	}

	public getHighlightedEntityId() {
		return this.highlightedEntityId;
	}

	/**
	 * Sets the cursor of the map. Not passing in a value will set the cursor back to default
	 *
	 * @param cursor string to set it to
	 */
	public setCursor(cursor?: string) {
		this.renderer.getRootContainer().cursor = cursor ?? '';
	}

	public setSelectedToolType(tool: ToolbarEvent) {
		this.selectedTool = tool;
	}

	public getSelectedToolType() {
		return this.selectedTool;
	}

	public setDefaultCursor() {
		this.setCursor('');
	}

	public setRotateCursor(isPath: boolean = false) {
		const whichCursor = isPath ? ROTATE_PATH_CURSOR : ROTATE_CURSOR;
		this.setCursor(whichCursor);
	}

	/**
	 * Check if there is an object at the given coordinates. Note that this only checks the hit areas of each object
	 *
	 * @param coords to check; This can be any coordinate type.
	 * @param containers to check; This will be checked in order. If parameter not provided it will check all containers
	 */
	public getMapObjectAtCoordinates(coords: Coordinates, containers?: MapObjectType[]): MapObject<any> | undefined {
		let entity: MapObject<any> | undefined;
		const containersToCheck = !!containers ? containers : Object.keys(CONTAINER_Z_INDEX);

		containersToCheck.find(key => {
			const objectContainer = this.renderer.getContainer(key);

			objectContainer.interactive = true;
			objectContainer.interactiveChildren = true;

			entity = this.getMapObjectInContainer(coords, objectContainer);

			// For performance reasons, interactivity is turned off for the container when not needed
			objectContainer.interactive = false;
			objectContainer.interactiveChildren = false;

			return !!entity;
		});

		return entity;
	}

	public getMapObjectInContainer(coords: Coordinates, container: PIXI.Container): MapObject<any> | undefined {
		const hitObject = this.hitTestContainer(coords, container);
		return hitObject ? this.renderer.getObjectById(hitObject.name ?? '') : undefined;
	}

	public hitTestContainer(coords: Coordinates, container: PIXI.Container): PIXI.DisplayObject | undefined {
		// Check the type of coordinates and cast to the correct type
		const localCoords = isPixiCoordinates(coords) ? coords as PixiCoordinates
			: this.renderer.project(coords as (RealWorldCoordinates | LeafletCoordinates));
		return this.renderer.hitTest(localCoords, container);
		// const globalCoords = this.renderer.getRootContainer().toGlobal(localCoords);
		// return new PIXI.EventBoundary(container).hitTest(globalCoords.x, globalCoords.y);
	}

	getTracker() {
		return this.tracker;
	}

	public getLeafletMap() {
		return this.renderer.getMap();
	}

	public getMapLookup() {
		return this.mapLookup;
	}

	public getMapRenderer() {
		return this.renderer;
	}

	public getEventHandler() {
		return this.eventHandler;
	}

	public getImportVersion() {
		return this.version;
	}

	public getHighlightedMapObject(): MapObject<any> | undefined {
		return !this.highlightedObjectId
			? undefined
			: this.renderer.getObjectById(this.highlightedObjectId);
	}

	/**
	 * Add objects to the renderer that need to be displayed
	 * for the currently loaded map
	 */
	public initialBuildMapObjects() {
		// Parse the map objects
		// Create DrivingArea before Path because new Sublink (within Path) will build
		// a DringZone mapObjectId and DrivingArea mapObject lookup
		this.version.drivingAreass
			.forEach(drivingArea => this.renderer.addObject(new DrivingArea(drivingArea, this.renderer, this.mapLookup)));

		// Used to hide FMS objects on initial load
		const isInitialLoad = true;

		// initialisation of links now in EditMap to prevent renderloop / memory issues
		this.version.linkss.forEach(link => {
			this.renderer.addObject(new Path(link, this.renderer, this.mapLookup));
		});

		this.version.bayss.forEach(bay => this.renderer.addObject(new Bay(bay, this)));
		this.version.areass.forEach(area => {
			this.renderer.addObject(new Area(area, this.renderer, this.mapLookup));

			// Create a location if it is an autonomous area
			if (area.isFmsLocation()) {
				this.renderer.addObject(new Location(area, this, this.mapLookup, isInitialLoad));
			}
		});

		// AHS area
		this.renderer.addObject(new AhsArea(this.version.maptoolparam!, this.renderer));

		// FMS

		this.version.beacons.forEach(beacon => {
			this.renderer.addObject(new Beacon(this.renderer, beacon, this.mapLookup, isInitialLoad));
		});
		this.version.segments.forEach(segment => {
			this.renderer.addObject(new Segment(segment, this.renderer, this.mapLookup, isInitialLoad));
		});
	}

	/**
	 * Add a new object to renderer and map lookup table
	 * @param entity
	 * @param mapObject
	 */
	public addMapObject(entity: Model, mapObject: MapObject<unknown>, addToRerender?: boolean) {
		// add to renderer
		this.renderer.addObject(mapObject, !!addToRerender);

		// add to map lookup table
		this.mapLookup.addEntityToMapObject(entity.id ?? entity._clientId, mapObject);
	}

	/**
	 * Remove an object from renderer and map lookup table
	 * @param entity
	 * @param mapObject
	 * @param skipRemovalFromRenderer
	 */
	public removeMapObject(entity: Model, mapObject: MapObject<unknown>, skipRemovalFromRenderer?: boolean) {
		// remove from renderer
		if (!skipRemovalFromRenderer) {
			mapObject.removeTooltip();
			this.renderer.removeObject(mapObject.getId());
		}

		// remove from map lookup table
		this.mapLookup.removeEntityToMapObject(entity.id ?? entity._clientId, mapObject.getType());
	}

	public focusMap() {
		const element = document.getElementById('leaflet-container');
		if (!!element) {
			element.focus();
		}
	}

	removeAndReAddPath(linkEntity: LinkEntity, isRerenderDrivingZone: boolean = true) {
		const lookup = this.getMapLookup();
		const renderer = this.getMapRenderer();
		// Update original path in renderer
		const originalLinkObjectId = lookup.getMapObjectIdByEntity(linkEntity, 'link');
		const linkMapObject = renderer.getObjectById(originalLinkObjectId);
		renderer.removeObject(linkMapObject.getParent()?.getId() ?? '');
		const updatedPath = new Path(linkEntity, renderer, lookup);
		renderer.addObject(updatedPath);
		if (isRerenderDrivingZone) {
			PathToolHelper.renderDrivingZones(updatedPath, renderer);
		}
		renderer.rerender();
	}
}