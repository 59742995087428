import {
	LinkEntity,
	LinkFromLinkTo, MapToolParamEntity,
} from '../../../../Models/Entities';
import { store } from '../../../../Models/Store';
import _ from 'lodash';

export default function updateDrivingZone() {

}

export const getCalDZDistance = (mapParams: MapToolParamEntity) => {
	const { curveMarginContinuationDistance, nodeDistMax, nodeDistMin } = mapParams;
	return curveMarginContinuationDistance + nodeDistMax + nodeDistMin;
};

const getLink = (id: string, activeLink?: LinkEntity) => {
	return activeLink?.id === id ? activeLink : store.mapStore.getEntity(id, LinkEntity);
}

/**
 * Get affected links from the connectivity to recalculate driving zones
 * @param connectivity
 * @param activeLink
 */
export const getLinksToRecalculateDrivingZone = (connectivity: LinkFromLinkTo, activeLink?: LinkEntity) => {	
	const linkFrom = getLink(connectivity.linkFromId, activeLink);
	const linkTo = getLink(connectivity.linkToId, activeLink);
	const mapParams = store.mapStore.getMapParameters();
	
	if (!linkFrom || !linkTo) {
		throw Error('Unable to find affected links');
	}

	return getAffectedLinks(linkFrom, linkTo, mapParams);
};

/**
 * Get affected links from related links to recalculate driving zones
 * @param linkFrom
 * @param linkTo
 * @param mapParams
 * @param activeLink
 */
export const getAffectedLinks = (linkFrom: LinkEntity, linkTo: LinkEntity, mapParams: MapToolParamEntity, activeLink?: LinkEntity) => {
	// First get all the links in a backward direction
	const links: LinkEntity[] = [];
	travelPathBackward(linkFrom, links, getCalDZDistance(mapParams), activeLink);
	travelPathBackward(linkTo, links, linkTo.getDistance() + getCalDZDistance(mapParams), activeLink);

	travelPathForward(linkTo, links, getCalDZDistance(mapParams), activeLink);
	travelPathForward(linkFrom, links, linkFrom.getDistance() + getCalDZDistance(mapParams), activeLink);

	return _.uniqBy(links, x => x.id);
};

export const travelPathBackward = (currentLink: LinkEntity, links: LinkEntity[], distance: number, activeLink?: LinkEntity) => {
	if (distance < 0) {
		return;
	}

	links.push(currentLink);

	const newDistance = distance - currentLink.getDistance();
	currentLink.linkFroms.forEach(connection => {
		const prevLink = getLink(connection.linkFromId, activeLink);
		if (!!prevLink) {
			travelPathBackward(prevLink, links, newDistance);
		}
	});
};

export const travelPathForward = (currentLink: LinkEntity, links: LinkEntity[], distance: number, activeLink?: LinkEntity) => {
	if (distance < 0) {
		return;
	}

	links.push(currentLink);

	const newDistance = distance - currentLink.getDistance();
	currentLink.linkTos.forEach(connection => {
		const nextLink = getLink(connection.linkToId, activeLink);
		if (!!nextLink) {
			travelPathForward(nextLink, links, newDistance);
		}
	});
};
