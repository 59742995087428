/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsBeaconEntity from 'Models/Security/Acl/VisitorsBeaconEntity';
import MineUserBeaconEntity from 'Models/Security/Acl/MineUserBeaconEntity';
import HitachiAdminBeaconEntity from 'Models/Security/Acl/HitachiAdminBeaconEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IBeaconEntityAttributes extends IModelAttributes {
	beaconId: string;
	isAccess: boolean;
	radius: number;
	direction: Enums.allowedDirection;
	state: Enums.mapObjectState;
	originalId: string;

	areaId?: string;
	area?: Models.AreaEntity | Models.IAreaEntityAttributes;
	importVersionId?: string;
	importVersion?: Models.ImportVersionEntity | Models.IImportVersionEntityAttributes;
	endSegmentss: Array<
		| Models.SegmentEntity
		| Models.ISegmentEntityAttributes
	>;
	startSegmentss: Array<
		| Models.SegmentEntity
		| Models.ISegmentEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] on begin
	beaconLocation: string;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('BeaconEntity', 'Beacon')
// % protected region % [Customise your entity metadata here] end
export default class BeaconEntity extends Model
	implements IBeaconEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsBeaconEntity(),
		new MineUserBeaconEntity(),
		new HitachiAdminBeaconEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Beacon Id'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Beacon Id',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public beaconId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Beacon Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Access'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Is Access',
		displayType: 'checkbox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isAccess: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Access'] end

	// % protected region % [Modify props to the crud options here for attribute 'Radius'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Radius',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public radius: number;
	// % protected region % [Modify props to the crud options here for attribute 'Radius'] end

	// % protected region % [Modify props to the crud options here for attribute 'Direction'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Direction',
		displayType: 'enum-combobox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.allowedDirectionOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.allowedDirectionOptions),
		displayFunction: (attr: Enums.allowedDirection) => Enums.allowedDirectionOptions[attr],
	})
	public direction: Enums.allowedDirection;
	// % protected region % [Modify props to the crud options here for attribute 'Direction'] end

	// % protected region % [Modify props to the crud options here for attribute 'State'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'State',
		displayType: 'enum-combobox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.mapObjectStateOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.mapObjectStateOptions),
		displayFunction: (attr: Enums.mapObjectState) => Enums.mapObjectStateOptions[attr],
	})
	public state: Enums.mapObjectState;
	// % protected region % [Modify props to the crud options here for attribute 'State'] end

	// % protected region % [Modify props to the crud options here for attribute 'Original Id'] off begin
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'Original Id',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public originalId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Original Id'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Area'] off begin
		name: 'Area',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.AreaEntity,
		// % protected region % [Modify props to the crud options here for reference 'Area'] end
	})
	public areaId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public area: Models.AreaEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Import Version'] off begin
		name: 'Import Version',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.ImportVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Import Version'] end
	})
	public importVersionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public importVersion: Models.ImportVersionEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'End Segments'] off begin
		name: 'End Segmentss',
		displayType: 'reference-multicombobox',
		order: 90,
		referenceTypeFunc: () => Models.SegmentEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'endSegmentss',
			oppositeEntity: () => Models.SegmentEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'End Segments'] end
	})
	public endSegmentss: Models.SegmentEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Start Segments'] off begin
		name: 'Start Segmentss',
		displayType: 'reference-multicombobox',
		order: 100,
		referenceTypeFunc: () => Models.SegmentEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'startSegmentss',
			oppositeEntity: () => Models.SegmentEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Start Segments'] end
	})
	public startSegmentss: Models.SegmentEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	@attribute()
	public beaconLocation: string;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IBeaconEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IBeaconEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.beaconId !== undefined) {
				this.beaconId = attributes.beaconId;
			}
			if (attributes.isAccess !== undefined) {
				this.isAccess = attributes.isAccess;
			}
			if (attributes.radius !== undefined) {
				this.radius = attributes.radius;
			}
			if (attributes.direction !== undefined) {
				this.direction = attributes.direction;
			}
			if (attributes.state !== undefined) {
				this.state = attributes.state;
			}
			if (attributes.originalId !== undefined) {
				this.originalId = attributes.originalId;
			}
			if (attributes.areaId !== undefined) {
				this.areaId = attributes.areaId;
			}
			if (attributes.area !== undefined) {
				if (attributes.area === null) {
					this.area = attributes.area;
				} else if (attributes.area instanceof Models.AreaEntity) {
					this.area = attributes.area;
					this.areaId = attributes.area.id;
				} else {
					this.area = new Models.AreaEntity(attributes.area);
					this.areaId = this.area.id;
				}
			}
			if (attributes.importVersionId !== undefined) {
				this.importVersionId = attributes.importVersionId;
			}
			if (attributes.importVersion !== undefined) {
				if (attributes.importVersion === null) {
					this.importVersion = attributes.importVersion;
				} else if (attributes.importVersion instanceof Models.ImportVersionEntity) {
					this.importVersion = attributes.importVersion;
					this.importVersionId = attributes.importVersion.id;
				} else {
					this.importVersion = new Models.ImportVersionEntity(attributes.importVersion);
					this.importVersionId = this.importVersion.id;
				}
			}
			if (attributes.endSegmentss !== undefined && Array.isArray(attributes.endSegmentss)) {
				for (const model of attributes.endSegmentss) {
					if (model instanceof Models.SegmentEntity) {
						this.endSegmentss.push(model);
					} else {
						this.endSegmentss.push(new Models.SegmentEntity(model));
					}
				}
			}
			if (attributes.startSegmentss !== undefined && Array.isArray(attributes.startSegmentss)) {
				for (const model of attributes.startSegmentss) {
					if (model instanceof Models.SegmentEntity) {
						this.startSegmentss.push(model);
					} else {
						this.startSegmentss.push(new Models.SegmentEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.beaconLocation !== undefined) {
				if (typeof attributes.beaconLocation === 'object') {
					this.beaconLocation = JSON.stringify(attributes.beaconLocation);
				} else {
					this.beaconLocation = attributes.beaconLocation;
				}
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		area {
			${Models.AreaEntity.getAttributes().join('\n')}
		}
		importVersion {
			${Models.ImportVersionEntity.getAttributes().join('\n')}
			${Models.ImportVersionEntity.getFiles().map(f => f.name).join('\n')}
		}
		endSegmentss {
			${Models.SegmentEntity.getAttributes().join('\n')}
		}
		startSegmentss {
			${Models.SegmentEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			endSegmentss: {},
			startSegmentss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'endSegmentss',
							'startSegmentss',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(BeaconEntity.prototype, 'created');
CRUD(modifiedAttr)(BeaconEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
