import {SaveCommand, SaveCommandType} from "./SaveCommand";
import {nodetask} from "../../../../Models/Enums";

export class SetNodeTaskCommand extends SaveCommand {

	nodeId: string;
	nodeTask: nodetask;

	constructor(nodeId: string, nodeTask: nodetask) {
		super(SaveCommandType.SetNodeTask);

		this.nodeId = nodeId;
		this.nodeTask = nodeTask;
	}

}