/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsSegmentEntity from 'Models/Security/Acl/VisitorsSegmentEntity';
import MineUserSegmentEntity from 'Models/Security/Acl/MineUserSegmentEntity';
import HitachiAdminSegmentEntity from 'Models/Security/Acl/HitachiAdminSegmentEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ISegmentEntityAttributes extends IModelAttributes {
	description: string;
	state: Enums.mapObjectState;
	originalId: string;

	endBeaconId?: string;
	endBeacon?: Models.BeaconEntity | Models.IBeaconEntityAttributes;
	linkss: Array<
		| Models.LinkEntity
		| Models.ILinkEntityAttributes
	>;
	segmentPointss: Array<
		| Models.SegmentPointEntity
		| Models.ISegmentPointEntityAttributes
	>;
	importVersionId?: string;
	importVersion?: Models.ImportVersionEntity | Models.IImportVersionEntityAttributes;
	startBeaconId?: string;
	startBeacon?: Models.BeaconEntity | Models.IBeaconEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('SegmentEntity', 'Segment')
// % protected region % [Customise your entity metadata here] end
export default class SegmentEntity extends Model
	implements ISegmentEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsSegmentEntity(),
		new MineUserSegmentEntity(),
		new HitachiAdminSegmentEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'State'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'State',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.mapObjectStateOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.mapObjectStateOptions),
		displayFunction: (attr: Enums.mapObjectState) => Enums.mapObjectStateOptions[attr],
	})
	public state: Enums.mapObjectState;
	// % protected region % [Modify props to the crud options here for attribute 'State'] end

	// % protected region % [Modify props to the crud options here for attribute 'Original Id'] off begin
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'Original Id',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public originalId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Original Id'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'End Beacon'] off begin
		name: 'End Beacon',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.BeaconEntity,
		// % protected region % [Modify props to the crud options here for reference 'End Beacon'] end
	})
	public endBeaconId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public endBeacon: Models.BeaconEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Links'] off begin
		name: 'Linkss',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.LinkEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'linkss',
			oppositeEntity: () => Models.LinkEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Links'] end
	})
	public linkss: Models.LinkEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Segment Points'] off begin
		name: 'Segment Pointss',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.SegmentPointEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'segmentPointss',
			oppositeEntity: () => Models.SegmentPointEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Segment Points'] end
	})
	public segmentPointss: Models.SegmentPointEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Import Version'] off begin
		name: 'Import Version',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.ImportVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Import Version'] end
	})
	public importVersionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public importVersion: Models.ImportVersionEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Start Beacon'] off begin
		name: 'Start Beacon',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.BeaconEntity,
		// % protected region % [Modify props to the crud options here for reference 'Start Beacon'] end
	})
	public startBeaconId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public startBeacon: Models.BeaconEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	public status: Enums.fmsObjectStatus;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ISegmentEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ISegmentEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.state !== undefined) {
				this.state = attributes.state;
			}
			if (attributes.originalId !== undefined) {
				this.originalId = attributes.originalId;
			}
			if (attributes.endBeaconId !== undefined) {
				this.endBeaconId = attributes.endBeaconId;
			}
			if (attributes.endBeacon !== undefined) {
				if (attributes.endBeacon === null) {
					this.endBeacon = attributes.endBeacon;
				} else if (attributes.endBeacon instanceof Models.BeaconEntity) {
					this.endBeacon = attributes.endBeacon;
					this.endBeaconId = attributes.endBeacon.id;
				} else {
					this.endBeacon = new Models.BeaconEntity(attributes.endBeacon);
					this.endBeaconId = this.endBeacon.id;
				}
			}
			if (attributes.linkss !== undefined && Array.isArray(attributes.linkss)) {
				for (const model of attributes.linkss) {
					if (model instanceof Models.LinkEntity) {
						this.linkss.push(model);
					} else {
						this.linkss.push(new Models.LinkEntity(model));
					}
				}
			}
			if (attributes.segmentPointss !== undefined && Array.isArray(attributes.segmentPointss)) {
				for (const model of attributes.segmentPointss) {
					if (model instanceof Models.SegmentPointEntity) {
						this.segmentPointss.push(model);
					} else {
						this.segmentPointss.push(new Models.SegmentPointEntity(model));
					}
				}
			}
			if (attributes.importVersionId !== undefined) {
				this.importVersionId = attributes.importVersionId;
			}
			if (attributes.importVersion !== undefined) {
				if (attributes.importVersion === null) {
					this.importVersion = attributes.importVersion;
				} else if (attributes.importVersion instanceof Models.ImportVersionEntity) {
					this.importVersion = attributes.importVersion;
					this.importVersionId = attributes.importVersion.id;
				} else {
					this.importVersion = new Models.ImportVersionEntity(attributes.importVersion);
					this.importVersionId = this.importVersion.id;
				}
			}
			if (attributes.startBeaconId !== undefined) {
				this.startBeaconId = attributes.startBeaconId;
			}
			if (attributes.startBeacon !== undefined) {
				if (attributes.startBeacon === null) {
					this.startBeacon = attributes.startBeacon;
				} else if (attributes.startBeacon instanceof Models.BeaconEntity) {
					this.startBeacon = attributes.startBeacon;
					this.startBeaconId = attributes.startBeacon.id;
				} else {
					this.startBeacon = new Models.BeaconEntity(attributes.startBeacon);
					this.startBeaconId = this.startBeacon.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		endBeacon {
			${Models.BeaconEntity.getAttributes().join('\n')}
		}
		linkss {
			${Models.LinkEntity.getAttributes().join('\n')}
		}
		segmentPointss {
			${Models.SegmentPointEntity.getAttributes().join('\n')}
		}
		importVersion {
			${Models.ImportVersionEntity.getAttributes().join('\n')}
			${Models.ImportVersionEntity.getFiles().map(f => f.name).join('\n')}
		}
		startBeacon {
			${Models.BeaconEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			linkss: {},
			segmentPointss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'linkss',
							'segmentPointss',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(SegmentEntity.prototype, 'created');
CRUD(modifiedAttr)(SegmentEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
