import React, { useEffect, useState } from 'react';
import {
	LinkEntity,
	SublinkEntity,
} from '../../../Models/Entities';
import MapController from '../Map/MapController';
import ErrorsAndWarnings from './ErrorsAndWarnings';
import InputField from "./PropertiesPanelComponents/InputField";

interface DerivedProperties {
	nodeCount: number,
	parentLinkId: number,
	sublinkLength: string,
}

/**
 * Render properties side panel for a selected sublink.
 * Sublink properties panel can be used to view sublink information
 * @param props
 * - entity: SublinkEntity
 * - map: MapController used for getting a lookup table
 * @constructor
 */
export default function SublinkProperties({ entity, map } : { entity: SublinkEntity, map?: MapController }) {
	const sublink = entity;
	const initialState = {
		nodeCount: 0,
		parentLinkId: 0,
		sublinkLength: '0',
	};
	const [derivedProperties, setDerivedProperties] = useState<DerivedProperties>(initialState);

	useEffect(() => {
		setDerivedProperties({
			nodeCount: sublink.nodess.length,
			parentLinkId: getParentLinkId(),
			sublinkLength: getSublinkLength(),
		});
	}, [sublink]);

	function getParentLinkId() {
		if (map && sublink.linkId) {
			return map.getMapLookup().getEntity(sublink.linkId, LinkEntity)?.linkId ?? 0;
		}
		return 0;
	}

	function getSublinkLength() {
		return sublink.getDistance().toFixed(2);
	}

	return (
		<>
			<h6>Sublink Identification</h6>
			<InputField model={sublink} label="ID" modelProperty="sublinkId" propertyUnit="" isNumber isReadOnly />
			<InputField model={sublink} label="Version" modelProperty="version" propertyUnit="" isNumber isReadOnly />
			<InputField model={derivedProperties} label="Parent Link ID" modelProperty="parentLinkId" propertyUnit="" isNumber isReadOnly />
			<InputField model={derivedProperties} label="No. of Nodes" modelProperty="nodeCount" propertyUnit="" isNumber isReadOnly />
			<InputField model={derivedProperties} label="Length" modelProperty="sublinkLength" propertyUnit="m" isReadOnly />
			<div className="section-divider" />
			<ErrorsAndWarnings mapObject={sublink} mapController={map} />
			<div className="section-divider" />
		</>
	);
}
