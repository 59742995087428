import MapObject from '../MapObject';
import { AreaEntity } from '../../../../../Models/Entities';
import { MapController } from '../../../index';
import L, { LatLng } from 'leaflet';
import * as PIXI from 'pixi.js';
import geoJsonToPoints from '../../Helpers/GeoJSON';
import MapStore from '../../MapStore';
import { getLeafletLatLng, pixiCoordinates } from '../../Helpers/Coordinates';
import { store } from 'Models/Store';

const LOCATION_LINE_WIDTH = 4;
const LOCATION_FILL_ALPHA = 0.5;
const HIT_AREA_THICKNESS = 4;

export default class Location extends MapObject<AreaEntity> {
	private points: PIXI.Point[];

	/**
	 * 
	 * @param area 
	 * @param controller 
	 * @param lookup 
	 * @param isInitialLoad used to hide on initial load 
	 */
	constructor(area: AreaEntity, controller: MapController, lookup: MapStore, isInitialLoad?: boolean) {
		super(controller.getMapRenderer(), 'location', area);
		lookup?.addEntityToMapObject(area.id, this);

		const { polygon } = this.getEntity();
		if (!!polygon && polygon !== '') {
			this.points = geoJsonToPoints(polygon, controller.getMapRenderer(), true) as PIXI.Point[];
		}
		// FMS invisible by default
		const isVisible = isInitialLoad !== true;
		this.createGraphic(isVisible);
	}

	panToObject() {
		this.renderer.getMap().panTo(this.getCentrePoint());
	}

	render() {
		const fillOptions = this.getFillOptions();

		const graphic = this.getGraphic();
		graphic.clear();

		const widthMultiplier = this.isHighlighted ? 2 : 1;

		graphic.lineStyle({
			color: 0xFFFFFF,
			width: LOCATION_LINE_WIDTH * widthMultiplier,
		});

		graphic.beginFill(fillOptions.color, fillOptions.alpha);
		graphic.drawPolygon(this.points);
		graphic.endFill();

		this.renderLabel(!store.isInit);
		const allPoints = this.generateHitAreaFromLine(this.points, HIT_AREA_THICKNESS);
		graphic.hitArea = new PIXI.Polygon(allPoints);
	}

	private getCentrePoint(): LatLng {
		return MapObject.getCentreOfPoints(this.points, this.renderer);
	}

	/**
	 * 
	 * @param isHide use to handles edge case on first load
	 */
	private renderLabel(isHide: boolean) {
		this.createTooltip(this.getEntity().areaName, this.getCentrePoint(), undefined, isHide);
	}

	private getFillOptions(): PIXI.IFillStyleOptions {
		const area = this.getEntity();

		const fillOptions: PIXI.IFillStyleOptions = { alpha: LOCATION_FILL_ALPHA };

		switch (area.locType) {
			case 'CRUSHER':
				fillOptions.color = 0x0000ff;
				break;
			case 'DIG':
				fillOptions.color = 0x804040;
				break;
			case 'PARKING':
				fillOptions.color = 0x800000;
				break;
			case 'STOCKPILE':
				fillOptions.color = 0x0000A0;
				break;
			case 'DUMP':
				fillOptions.color = 0x00C462;
				break;
			case 'INVALID':
			default:
				console.error(`Unknown fill colour for area: ${area.areaId}`);
		}

		return fillOptions;
	}
}
