/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsImportVersionEntity from 'Models/Security/Acl/VisitorsImportVersionEntity';
import MineUserImportVersionEntity from 'Models/Security/Acl/MineUserImportVersionEntity';
import HitachiAdminImportVersionEntity from 'Models/Security/Acl/HitachiAdminImportVersionEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FileListPreview } from 'Views/Components/CRUD/Attributes/AttributeFile';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import type {IUserDetails} from "../UserEntity";
import {isNotNullOrEmpty} from "../../Util/TypeGuards";
// % protected region % [Add any further imports here] end

export interface IImportVersionEntityAttributes extends IModelAttributes {
	name: string;
	originalFileId: string;
	originalFile: Blob;
	terrainDataFileId: string;
	terrainDataFile: Blob;
	lastSave: Date;
	lastSaveUser: string;
	archived: boolean;
	fmsFormatVersion: string;
	areaFormatVersion: string;
	bayFormatVersion: string;
	linkFormatVersion: string;
	drivingBoundaryFormatVersion: string;
	sublinkFormatVersion: string;
	nodeFormatVersion: string;
	connectivityFormatVersion: string;
	dynamicConnectionFormatVersion: string;
	lockOwnerID: string;
	lockKey: string;
	lockCreated: Date;
	areaEdited: boolean;
	bayEdited: boolean;
	pathEdited: boolean;
	areaPublished: boolean;
	bayPublished: boolean;
	pathPublished: boolean;
	fmsGenerated: boolean;
	ahsCurrentAreaDataFileId: string;
	ahsCurrentAreaDataFile: Blob;

	areass: Array<
		| Models.AreaEntity
		| Models.IAreaEntityAttributes
	>;
	bayss: Array<
		| Models.BayEntity
		| Models.IBayEntityAttributes
	>;
	beaconss: Array<
		| Models.BeaconEntity
		| Models.IBeaconEntityAttributes
	>;
	drivingAreass: Array<
		| Models.DrivingAreaEntity
		| Models.IDrivingAreaEntityAttributes
	>;
	backgroundImageId?: string;
	backgroundImage?: Models.BackgroundImageEntity | Models.IBackgroundImageEntityAttributes;
	mapId?: string;
	map?: Models.MapEntity | Models.IMapEntityAttributes;
	linkss: Array<
		| Models.LinkEntity
		| Models.ILinkEntityAttributes
	>;
	publishedVersionss: Array<
		| Models.PublishedVersionEntity
		| Models.IPublishedVersionEntityAttributes
	>;
	segmentss: Array<
		| Models.SegmentEntity
		| Models.ISegmentEntityAttributes
	>;
	maptoolparam?: Models.MapToolParamEntity |
		Models.IMapToolParamEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] on begin
	owner: IUserDetails;
	lastModifiedUser: IUserDetails;

	originalFileName: string;
	lockOwner: IUserDetails;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] on begin
@entity('ImportVersionEntity', 'Import Version')
// % protected region % [Customise your entity metadata here] end
export default class ImportVersionEntity extends Model
	implements IImportVersionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsImportVersionEntity(),
		new MineUserImportVersionEntity(),
		new HitachiAdminImportVersionEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	/**
	 * Import version name
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Original File'] off begin
	/**
	 * Imported AHS current map zip file
	 */
	@observable
	@attribute({ file: 'originalFile' })
	@CRUD({
		name: 'Original File',
		displayType: 'file',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'originalFile',
		displayFunction: attr => attr
			? <FileListPreview url={attr} />
			: 'No File Attached',
	})
	public originalFileId: string;

	@observable
	public originalFile: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Original File'] end

	// % protected region % [Modify props to the crud options here for attribute 'Terrain Data File'] off begin
	@observable
	@attribute({ file: 'terrainDataFile' })
	@CRUD({
		name: 'Terrain Data File',
		displayType: 'file',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'terrainDataFile',
		displayFunction: attr => attr
			? <FileListPreview url={attr} />
			: 'No File Attached',
	})
	public terrainDataFileId: string;

	@observable
	public terrainDataFile: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Terrain Data File'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Save'] off begin
	/**
	 * Record when the version was last saved
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Last Save',
		displayType: 'datetimepicker',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public lastSave: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Last Save'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Save User'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Last Save User',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
	})
	public lastSaveUser: string;
	// % protected region % [Modify props to the crud options here for attribute 'Last Save User'] end

	// % protected region % [Modify props to the crud options here for attribute 'Archived'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Archived',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public archived: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Archived'] end

	// % protected region % [Modify props to the crud options here for attribute 'FMS Format Version'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'FMS Format Version',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public fmsFormatVersion: string;
	// % protected region % [Modify props to the crud options here for attribute 'FMS Format Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Area Format Version'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Area Format Version',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public areaFormatVersion: string;
	// % protected region % [Modify props to the crud options here for attribute 'Area Format Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Bay Format Version'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Bay Format Version',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public bayFormatVersion: string;
	// % protected region % [Modify props to the crud options here for attribute 'Bay Format Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Link Format Version'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Link Format Version',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public linkFormatVersion: string;
	// % protected region % [Modify props to the crud options here for attribute 'Link Format Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Driving Boundary Format Version'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Driving Boundary Format Version',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public drivingBoundaryFormatVersion: string;
	// % protected region % [Modify props to the crud options here for attribute 'Driving Boundary Format Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Sublink Format Version'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Sublink Format Version',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public sublinkFormatVersion: string;
	// % protected region % [Modify props to the crud options here for attribute 'Sublink Format Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Node Format Version'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Node Format Version',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public nodeFormatVersion: string;
	// % protected region % [Modify props to the crud options here for attribute 'Node Format Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Connectivity Format Version'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Connectivity Format Version',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public connectivityFormatVersion: string;
	// % protected region % [Modify props to the crud options here for attribute 'Connectivity Format Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dynamic Connection Format Version'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Dynamic Connection Format Version',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public dynamicConnectionFormatVersion: string;
	// % protected region % [Modify props to the crud options here for attribute 'Dynamic Connection Format Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Lock Owner ID'] off begin
	/**
	 * Current lock holder of an import version
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Lock Owner ID',
		displayType: 'textfield',
		order: 160,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lockOwnerID: string;
	// % protected region % [Modify props to the crud options here for attribute 'Lock Owner ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Lock Key'] off begin
	/**
	 * The unique key identifying the current lock
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Lock Key',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lockKey: string;
	// % protected region % [Modify props to the crud options here for attribute 'Lock Key'] end

	// % protected region % [Modify props to the crud options here for attribute 'Lock Created'] off begin
	/**
	 * When the lock was acquired
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Lock Created',
		displayType: 'datetimepicker',
		order: 180,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public lockCreated: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Lock Created'] end

	// % protected region % [Modify props to the crud options here for attribute 'Area Edited'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Area Edited',
		displayType: 'checkbox',
		order: 190,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public areaEdited: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Area Edited'] end

	// % protected region % [Modify props to the crud options here for attribute 'Bay Edited'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Bay Edited',
		displayType: 'checkbox',
		order: 200,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public bayEdited: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Bay Edited'] end

	// % protected region % [Modify props to the crud options here for attribute 'Path Edited'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Path Edited',
		displayType: 'checkbox',
		order: 210,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public pathEdited: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Path Edited'] end

	// % protected region % [Modify props to the crud options here for attribute 'Area Published'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Area Published',
		displayType: 'checkbox',
		order: 220,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public areaPublished: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Area Published'] end

	// % protected region % [Modify props to the crud options here for attribute 'Bay Published'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Bay Published',
		displayType: 'checkbox',
		order: 230,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public bayPublished: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Bay Published'] end

	// % protected region % [Modify props to the crud options here for attribute 'Path Published'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Path Published',
		displayType: 'checkbox',
		order: 240,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public pathPublished: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Path Published'] end

	// % protected region % [Modify props to the crud options here for attribute 'FMS Generated'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'FMS Generated',
		displayType: 'checkbox',
		order: 250,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public fmsGenerated: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'FMS Generated'] end

	// % protected region % [Modify props to the crud options here for attribute 'AHS Current Area Data File'] off begin
	@observable
	@attribute({ file: 'ahsCurrentAreaDataFile' })
	@CRUD({
		name: 'AHS Current Area Data File',
		displayType: 'file',
		order: 260,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'ahsCurrentAreaDataFile',
		displayFunction: attr => attr
			? <FileListPreview url={attr} />
			: 'No File Attached',
	})
	public ahsCurrentAreaDataFileId: string;

	@observable
	public ahsCurrentAreaDataFile: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'AHS Current Area Data File'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Areas'] off begin
		name: 'Areass',
		displayType: 'reference-multicombobox',
		order: 270,
		referenceTypeFunc: () => Models.AreaEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'areass',
			oppositeEntity: () => Models.AreaEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Areas'] end
	})
	public areass: Models.AreaEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Bays'] off begin
		name: 'Bayss',
		displayType: 'reference-multicombobox',
		order: 280,
		referenceTypeFunc: () => Models.BayEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'bayss',
			oppositeEntity: () => Models.BayEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Bays'] end
	})
	public bayss: Models.BayEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Beacons'] off begin
		name: 'Beaconss',
		displayType: 'reference-multicombobox',
		order: 290,
		referenceTypeFunc: () => Models.BeaconEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'beaconss',
			oppositeEntity: () => Models.BeaconEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Beacons'] end
	})
	public beaconss: Models.BeaconEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Driving Areas'] off begin
		name: 'Driving Areass',
		displayType: 'reference-multicombobox',
		order: 300,
		referenceTypeFunc: () => Models.DrivingAreaEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'drivingAreass',
			oppositeEntity: () => Models.DrivingAreaEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Driving Areas'] end
	})
	public drivingAreass: Models.DrivingAreaEntity[] = [];

	/**
	 * Background Image can be used by multiple import versions in the same map
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Background Image'] off begin
		name: 'Background Image',
		displayType: 'reference-combobox',
		order: 310,
		referenceTypeFunc: () => Models.BackgroundImageEntity,
		// % protected region % [Modify props to the crud options here for reference 'Background Image'] end
	})
	public backgroundImageId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public backgroundImage: Models.BackgroundImageEntity;

	/**
	 * Each map can have many map import versions
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Map'] off begin
		name: 'Map',
		displayType: 'reference-combobox',
		order: 320,
		referenceTypeFunc: () => Models.MapEntity,
		// % protected region % [Modify props to the crud options here for reference 'Map'] end
	})
	public mapId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public map: Models.MapEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Links'] off begin
		name: 'Linkss',
		displayType: 'reference-multicombobox',
		order: 330,
		referenceTypeFunc: () => Models.LinkEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'linkss',
			oppositeEntity: () => Models.LinkEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Links'] end
	})
	public linkss: Models.LinkEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Published Versions'] off begin
		name: 'Published Versionss',
		displayType: 'reference-multicombobox',
		order: 340,
		referenceTypeFunc: () => Models.PublishedVersionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'publishedVersionss',
			oppositeEntity: () => Models.PublishedVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Published Versions'] end
	})
	public publishedVersionss: Models.PublishedVersionEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Segments'] off begin
		name: 'Segmentss',
		displayType: 'reference-multicombobox',
		order: 350,
		referenceTypeFunc: () => Models.SegmentEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'segmentss',
			oppositeEntity: () => Models.SegmentEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Segments'] end
	})
	public segmentss: Models.SegmentEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'MapToolParam'] off begin
		name: 'MapToolParam',
		displayType: 'reference-combobox',
		order: 360,
		referenceTypeFunc: () => Models.MapToolParamEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'maptoolparams',
			oppositeEntity: () => Models.MapToolParamEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'MapToolParam'] end
	})
	public maptoolparam?: Models.MapToolParamEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	@CRUD({
		name: "User",
		headerColumn: true,
		displayType: 'hidden',
	})
	public owner: IUserDetails;

	@observable
	@CRUD({
		name: "User",
		headerColumn: true,
		displayType: 'hidden',
	})
	public lastModifiedUser: IUserDetails;

	@observable
	public lockOwner: IUserDetails;

	@observable
	public originalFileName: string;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IImportVersionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IImportVersionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.originalFile !== undefined) {
				this.originalFile = attributes.originalFile;
			}
			if (attributes.originalFileId !== undefined) {
				this.originalFileId = attributes.originalFileId;
			}
			if (attributes.terrainDataFile !== undefined) {
				this.terrainDataFile = attributes.terrainDataFile;
			}
			if (attributes.terrainDataFileId !== undefined) {
				this.terrainDataFileId = attributes.terrainDataFileId;
			}
			if (attributes.lastSave != undefined) {
				this.lastSave = attributes.lastSave;
			}
			if (attributes.lastSaveUser !== undefined) {
				this.lastSaveUser = attributes.lastSaveUser;
			}
			if (attributes.archived !== undefined) {
				this.archived = attributes.archived;
			}
			if (attributes.fmsFormatVersion !== undefined) {
				this.fmsFormatVersion = attributes.fmsFormatVersion;
			}
			if (attributes.areaFormatVersion !== undefined) {
				this.areaFormatVersion = attributes.areaFormatVersion;
			}
			if (attributes.bayFormatVersion !== undefined) {
				this.bayFormatVersion = attributes.bayFormatVersion;
			}
			if (attributes.linkFormatVersion !== undefined) {
				this.linkFormatVersion = attributes.linkFormatVersion;
			}
			if (attributes.drivingBoundaryFormatVersion !== undefined) {
				this.drivingBoundaryFormatVersion = attributes.drivingBoundaryFormatVersion;
			}
			if (attributes.sublinkFormatVersion !== undefined) {
				this.sublinkFormatVersion = attributes.sublinkFormatVersion;
			}
			if (attributes.nodeFormatVersion !== undefined) {
				this.nodeFormatVersion = attributes.nodeFormatVersion;
			}
			if (attributes.connectivityFormatVersion !== undefined) {
				this.connectivityFormatVersion = attributes.connectivityFormatVersion;
			}
			if (attributes.dynamicConnectionFormatVersion !== undefined) {
				this.dynamicConnectionFormatVersion = attributes.dynamicConnectionFormatVersion;
			}
			if (attributes.lockOwnerID !== undefined) {
				this.lockOwnerID = attributes.lockOwnerID;
			}
			if (attributes.lockKey !== undefined) {
				this.lockKey = attributes.lockKey;
			}
			if (attributes.lockCreated !== undefined) {
				if (attributes.lockCreated === null) {
					this.lockCreated = attributes.lockCreated;
				} else {
					this.lockCreated = moment(attributes.lockCreated).toDate();
				}
			}
			if (attributes.areaEdited !== undefined) {
				this.areaEdited = attributes.areaEdited;
			}
			if (attributes.bayEdited !== undefined) {
				this.bayEdited = attributes.bayEdited;
			}
			if (attributes.pathEdited !== undefined) {
				this.pathEdited = attributes.pathEdited;
			}
			if (attributes.areaPublished !== undefined) {
				this.areaPublished = attributes.areaPublished;
			}
			if (attributes.bayPublished !== undefined) {
				this.bayPublished = attributes.bayPublished;
			}
			if (attributes.pathPublished !== undefined) {
				this.pathPublished = attributes.pathPublished;
			}
			if (attributes.fmsGenerated !== undefined) {
				this.fmsGenerated = attributes.fmsGenerated;
			}
			if (attributes.ahsCurrentAreaDataFile !== undefined) {
				this.ahsCurrentAreaDataFile = attributes.ahsCurrentAreaDataFile;
			}
			if (attributes.ahsCurrentAreaDataFileId !== undefined) {
				this.ahsCurrentAreaDataFileId = attributes.ahsCurrentAreaDataFileId;
			}
			if (attributes.areass !== undefined && Array.isArray(attributes.areass)) {
				for (const model of attributes.areass) {
					if (model instanceof Models.AreaEntity) {
						this.areass.push(model);
					} else {
						this.areass.push(new Models.AreaEntity(model));
					}
				}
			}
			if (attributes.bayss !== undefined && Array.isArray(attributes.bayss)) {
				for (const model of attributes.bayss) {
					if (model instanceof Models.BayEntity) {
						this.bayss.push(model);
					} else {
						this.bayss.push(new Models.BayEntity(model));
					}
				}
			}
			if (attributes.beaconss !== undefined && Array.isArray(attributes.beaconss)) {
				for (const model of attributes.beaconss) {
					if (model instanceof Models.BeaconEntity) {
						this.beaconss.push(model);
					} else {
						this.beaconss.push(new Models.BeaconEntity(model));
					}
				}
			}
			if (attributes.drivingAreass !== undefined && Array.isArray(attributes.drivingAreass)) {
				for (const model of attributes.drivingAreass) {
					if (model instanceof Models.DrivingAreaEntity) {
						this.drivingAreass.push(model);
					} else {
						this.drivingAreass.push(new Models.DrivingAreaEntity(model));
					}
				}
			}
			if (attributes.backgroundImageId !== undefined) {
				this.backgroundImageId = attributes.backgroundImageId;
			}
			if (attributes.backgroundImage !== undefined) {
				if (attributes.backgroundImage === null) {
					this.backgroundImage = attributes.backgroundImage;
				} else if (attributes.backgroundImage instanceof Models.BackgroundImageEntity) {
					this.backgroundImage = attributes.backgroundImage;
					this.backgroundImageId = attributes.backgroundImage.id;
				} else {
					this.backgroundImage = new Models.BackgroundImageEntity(attributes.backgroundImage);
					this.backgroundImageId = this.backgroundImage.id;
				}
			}
			if (attributes.mapId !== undefined) {
				this.mapId = attributes.mapId;
			}
			if (attributes.map !== undefined) {
				if (attributes.map === null) {
					this.map = attributes.map;
				} else if (attributes.map instanceof Models.MapEntity) {
					this.map = attributes.map;
					this.mapId = attributes.map.id;
				} else {
					this.map = new Models.MapEntity(attributes.map);
					this.mapId = this.map.id;
				}
			}
			if (attributes.linkss !== undefined && Array.isArray(attributes.linkss)) {
				for (const model of attributes.linkss) {
					if (model instanceof Models.LinkEntity) {
						this.linkss.push(model);
					} else {
						this.linkss.push(new Models.LinkEntity(model));
					}
				}
			}
			if (attributes.publishedVersionss !== undefined && Array.isArray(attributes.publishedVersionss)) {
				for (const model of attributes.publishedVersionss) {
					if (model instanceof Models.PublishedVersionEntity) {
						this.publishedVersionss.push(model);
					} else {
						this.publishedVersionss.push(new Models.PublishedVersionEntity(model));
					}
				}
			}
			if (attributes.segmentss !== undefined && Array.isArray(attributes.segmentss)) {
				for (const model of attributes.segmentss) {
					if (model instanceof Models.SegmentEntity) {
						this.segmentss.push(model);
					} else {
						this.segmentss.push(new Models.SegmentEntity(model));
					}
				}
			}
			if (attributes.maptoolparam !== undefined) {
				if (attributes.maptoolparam === null) {
					this.maptoolparam = attributes.maptoolparam;
				} else if (attributes.maptoolparam instanceof Models.MapToolParamEntity) {
					this.maptoolparam = attributes.maptoolparam;
				} else {
					this.maptoolparam = new Models.MapToolParamEntity(attributes.maptoolparam);
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.owner !== undefined) {
				this.owner = attributes.owner;
			}
			if (attributes?.lastModifiedUser !== undefined) {
				this.lastModifiedUser = attributes.lastModifiedUser;
			}
			if (attributes.lockOwner !== undefined) {
				this.lockOwner = attributes.lockOwner;
			}
			if (attributes.originalFileName !== undefined) {
				this.originalFileName = attributes.originalFileName;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
public defaultExpands = `
		map {
			id
			name
			mineSite {
				id
				name
				timezone
			}
		}
		owner {
			id
			email
			familyName
			givenName
		}
		lastModifiedUser {
			id
			email
			givenName
			familyName
		}
		lockOwner {
			id
			email
			familyName
			givenName
		}
		originalFileName
		originalFileId
		backgroundImage {
			${Models.BackgroundImageEntity.getAttributes().join('\n')}
			${Models.BackgroundImageEntity.getFiles().map(f => f.name).join('\n')}
		}
		publishedVersionss {
			versionId
			publishedMapDataId
			exportType
			importVersionId
			created
			owner {
				id
				email
				givenName
				familyName
			}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			areass: {},
			bayss: {},
			beaconss: {},
			drivingAreass: {},
			linkss: {},
			publishedVersionss: {},
			segmentss: {},
			maptoolparam: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'areass',
							'bayss',
							'beaconss',
							'drivingAreass',
							'linkss',
							'publishedVersionss',
							'segmentss',
							'maptoolparam',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public saveMapStatus() {
		return this.save(undefined, {
			options: [{
				key: 'fieldsToUpdate',
				graphQlType: '[String]',
				value:  [
					'ahsCurrentAreaDataFileId',
					'bayEdited',
					'bayPublished',
					'areaEdited',
					'areaPublished',
					'pathEdited',
					'pathPublished',
					'fmsGenerated'
				]
			}]
		});
	}

	public get segments() {
		const updatedFmsObjects = new Set<string>();
		this.segmentss.forEach(x => {
			if (isNotNullOrEmpty(x.originalId)) {
				updatedFmsObjects.add(x.originalId);
			}
		});

		return this.segmentss
			.filter(x => !updatedFmsObjects.has(x.id));
	}

	public get beacons() {
		const updatedFmsObjects = new Set<string>();
		this.beaconss.forEach(x => {
			if (isNotNullOrEmpty(x.originalId)) {
				updatedFmsObjects.add(x.originalId);
			}
		});

		return this.beaconss
			.filter(x => !updatedFmsObjects.has(x.id));
	}

	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ImportVersionEntity.prototype, 'created');
CRUD(modifiedAttr)(ImportVersionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
