import MapObject from '../MapObject';
import MapRenderer from '../../MapRenderer';
import L from 'leaflet';
import * as PIXI from 'pixi.js';
import MapStore from '../../MapStore';
import { SegmentEntity } from '../../../../../Models/Entities';
import geoJsonToPoints from '../../Helpers/GeoJSON';
import { mapObjectState } from '../../../../../Models/Enums';

const LINE_THICKNESS = 1.6;
const HIT_AREA_THICKNESS = 1;

const getSegmentStateZIndex = (state: mapObjectState): number => {
	if (state === 'DELETED')
		return 0;
	if (state === 'IMPORTED')
		return 1;
	return 2; // NEW_OBJECT or MODIFIED
}

// zIndex of Segment sublayer within Segment layer
const getSegmentLineColour = (state: mapObjectState) => {
	if (state === 'DELETED')
		return 0x464170;
	if (state === 'IMPORTED')
		return 0x704F41;
	return 0x41704A; // NEW_OBJECT or MODIFIED
}

/**
 * Render and style a segment in Pixi
 */
export default class Segment extends MapObject<SegmentEntity> {
	private segmentPixiPoints: PIXI.Point[];

	/**
	 * 
	 * @param segment 
	 * @param renderer 
	 * @param lookup 
	 * @param isInitialLoad used to hide on initial load 
	 */
	constructor(segment: SegmentEntity, renderer: MapRenderer, lookup: MapStore, isInitialLoad?: boolean) {
		super(renderer, 'segment', segment);
		lookup?.addEntityToMapObject(segment.id, this);

		const orderedSegmentPoints = segment.segmentPointss.slice()
			.sort((a, b) => a.sequence - b.sequence);
		this.segmentPixiPoints = orderedSegmentPoints.map(segmentPoint => geoJsonToPoints(
			segmentPoint.segmentPointLocation, this.renderer,
		) as PIXI.Point);

		this.zIndexBase = getSegmentStateZIndex(segment.state);
		this.zIndexTop = 100;
		// FMS invisible by default
		const isVisible = isInitialLoad !== true;
		this.createGraphic(isVisible);
	}

	panToObject() {
		this.renderer.getMap().panTo(this.getCentreOfPoints(this.segmentPixiPoints, this.renderer));
	}

	render() {
		const points = this.segmentPixiPoints;
		let state = this.getEntity().state;
		if (!state) {
			console.log(`State not defined for segment (${this.getEntity().getModelId()}), setting to DELETED`);
			state = 'DELETED';
		}
		const graphic = this.getGraphic();
		graphic.clear();
		graphic.zIndex = this.isHighlighted ? this.zIndexTop : getSegmentStateZIndex(state);

		const widthMultiplier = this.isHighlighted ? 2 : 1;

		if (points.length === 0) {
			return;
		}

		const firstPoint = points[0];
		graphic.lineStyle(LINE_THICKNESS * widthMultiplier, getSegmentLineColour(state))
			.moveTo(firstPoint.x, firstPoint.y);

		for (let i = 1; i < points.length; i++) {
			graphic.lineTo(points[i].x, points[i].y);
		}

		const allPoints = this.generateHitAreaFromLine(points, HIT_AREA_THICKNESS);
		graphic.hitArea = new PIXI.Polygon(allPoints);
	}
}
