/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsLinkToReferenceManyToMany from '../Security/Acl/VisitorsLinkToReferenceManyToMany';
import MineUserLinkToReferenceManyToMany from '../Security/Acl/MineUserLinkToReferenceManyToMany';
import HitachiAdminLinkToReferenceManyToMany from '../Security/Acl/HitachiAdminLinkToReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ILinkFromLinkToAttributes extends IModelAttributes {
	linkFromId: string;
	linkToId: string;

	linkFrom: Models.LinkEntity | Models.ILinkEntityAttributes;
	linkTo: Models.LinkEntity | Models.ILinkEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('LinkFromLinkTo')
export default class LinkFromLinkTo
	extends Model
	implements ILinkFromLinkToAttributes {
	public static acls: IAcl[] = [
		new VisitorsLinkToReferenceManyToMany(),
		new MineUserLinkToReferenceManyToMany(),
		new HitachiAdminLinkToReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public linkFromId: string;

	@observable
	@attribute()
	public linkToId: string;

	@observable
	@attribute({ isReference: true })
	public linkFrom: Models.LinkEntity;

	@observable
	@attribute({ isReference: true })
	public linkTo: Models.LinkEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ILinkFromLinkToAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.linkFromId) {
				this.linkFromId = attributes.linkFromId;
			}
			if (attributes.linkToId) {
				this.linkToId = attributes.linkToId;
			}

			if (attributes.linkFrom) {
				if (attributes.linkFrom instanceof Models.LinkEntity) {
					this.linkFrom = attributes.linkFrom;
					this.linkFromId = attributes.linkFrom.id;
				} else {
					this.linkFrom = new Models.LinkEntity(attributes.linkFrom);
					this.linkFromId = this.linkFrom.id;
				}
			} else if (attributes.linkFromId !== undefined) {
				this.linkFromId = attributes.linkFromId;
			}

			if (attributes.linkTo) {
				if (attributes.linkTo instanceof Models.LinkEntity) {
					this.linkTo = attributes.linkTo;
					this.linkToId = attributes.linkTo.id;
				} else {
					this.linkTo = new Models.LinkEntity(attributes.linkTo);
					this.linkToId = this.linkTo.id;
				}
			} else if (attributes.linkToId !== undefined) {
				this.linkToId = attributes.linkToId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
