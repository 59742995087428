import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import CSS from "csstype";

export interface ILoadViewProps {
	text: string;
	timerInterval: number;
}

export function LoadView(props: ILoadViewProps) {
	const { text, timerInterval } = props;
	const [progress, setProgress] = useState(0);
	const timerRef = useRef<NodeJS.Timeout>();

	useEffect(() => {
		timerRef.current = setInterval(updateProgress, timerInterval);
		return () => {
			if (!!timerRef && !!timerRef.current) {
				clearInterval(timerRef.current);
			}
		};
	}, []);

	const updateProgress = () => {
		setProgress(prev => prev === 100 ? 100 : prev + 1);
	};

	const fillerStyles: CSS.Properties = {
		width: `${progress}%`,
	};

	return (
		<div className="project-building-overlay">
			<div className="overlay-content-wrap">
				<h3 className="building-text">{text}</h3>
				<div id="prog-bar" className="progress">
					<div style={fillerStyles} className="progress-bar" />
				</div>
			</div>
		</div>
	);
}
