/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import Navigation, { Orientation, ILink } from 'Views/Components/Navigation/Navigation';
import { RouteComponentProps } from 'react-router';
import * as Models from 'Models/Entities';
import { Model } from 'Models/Model';
import { IIconProps } from 'Views/Components/Helpers/Common';
import SecurityService from 'Services/SecurityService';
import { getModelDisplayName } from 'Util/EntityUtils';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

interface AdminLink extends IIconProps {
	path: string;
	label: string;
	entity: {new (args: any): Model};
	isMember?: boolean;
	// % protected region % [Add extra AdminLink fields here] off begin
	// % protected region % [Add extra AdminLink fields here] end
}

const getPageLinks = (): AdminLink[] => [
	{
		// % protected region % [Override navigation link for BackgroundImageEntity here] off begin
		path: '/admin/backgroundimageentity',
		label: getModelDisplayName(Models.BackgroundImageEntity),
		entity: Models.BackgroundImageEntity,
		isMember: false,
		// % protected region % [Override navigation link for BackgroundImageEntity here] end
	},
	{
		// % protected region % [Override navigation link for DrivingAreaEntity here] off begin
		path: '/admin/drivingareaentity',
		label: getModelDisplayName(Models.DrivingAreaEntity),
		entity: Models.DrivingAreaEntity,
		isMember: false,
		// % protected region % [Override navigation link for DrivingAreaEntity here] end
	},
	{
		// % protected region % [Override navigation link for HitachiAdminEntity here] off begin
		path: '/admin/hitachiadminentity',
		label: getModelDisplayName(Models.HitachiAdminEntity),
		entity: Models.HitachiAdminEntity,
		isMember: true,
		// % protected region % [Override navigation link for HitachiAdminEntity here] end
	},
	{
		// % protected region % [Override navigation link for ImportVersionEntity here] off begin
		path: '/admin/importversionentity',
		label: getModelDisplayName(Models.ImportVersionEntity),
		entity: Models.ImportVersionEntity,
		isMember: false,
		// % protected region % [Override navigation link for ImportVersionEntity here] end
	},
	{
		// % protected region % [Override navigation link for MapEntity here] off begin
		path: '/admin/mapentity',
		label: getModelDisplayName(Models.MapEntity),
		entity: Models.MapEntity,
		isMember: false,
		// % protected region % [Override navigation link for MapEntity here] end
	},
	{
		// % protected region % [Override navigation link for MapToolParamEntity here] off begin
		path: '/admin/maptoolparamentity',
		label: getModelDisplayName(Models.MapToolParamEntity),
		entity: Models.MapToolParamEntity,
		isMember: false,
		// % protected region % [Override navigation link for MapToolParamEntity here] end
	},
	{
		// % protected region % [Override navigation link for MineSiteEntity here] off begin
		path: '/admin/minesiteentity',
		label: getModelDisplayName(Models.MineSiteEntity),
		entity: Models.MineSiteEntity,
		isMember: false,
		// % protected region % [Override navigation link for MineSiteEntity here] end
	},
	{
		// % protected region % [Override navigation link for MineUserEntity here] off begin
		path: '/admin/mineuserentity',
		label: getModelDisplayName(Models.MineUserEntity),
		entity: Models.MineUserEntity,
		isMember: true,
		// % protected region % [Override navigation link for MineUserEntity here] end
	},
	{
		// % protected region % [Override navigation link for PublishedVersionEntity here] off begin
		path: '/admin/publishedversionentity',
		label: getModelDisplayName(Models.PublishedVersionEntity),
		entity: Models.PublishedVersionEntity,
		isMember: false,
		// % protected region % [Override navigation link for PublishedVersionEntity here] end
	},
	{
		// % protected region % [Override navigation link for AreaEntity here] off begin
		path: '/admin/areaentity',
		label: getModelDisplayName(Models.AreaEntity),
		entity: Models.AreaEntity,
		isMember: false,
		// % protected region % [Override navigation link for AreaEntity here] end
	},
	{
		// % protected region % [Override navigation link for BayEntity here] off begin
		path: '/admin/bayentity',
		label: getModelDisplayName(Models.BayEntity),
		entity: Models.BayEntity,
		isMember: false,
		// % protected region % [Override navigation link for BayEntity here] end
	},
	{
		// % protected region % [Override navigation link for BeaconEntity here] off begin
		path: '/admin/beaconentity',
		label: getModelDisplayName(Models.BeaconEntity),
		entity: Models.BeaconEntity,
		isMember: false,
		// % protected region % [Override navigation link for BeaconEntity here] end
	},
	{
		// % protected region % [Override navigation link for LinkEntity here] off begin
		path: '/admin/linkentity',
		label: getModelDisplayName(Models.LinkEntity),
		entity: Models.LinkEntity,
		isMember: false,
		// % protected region % [Override navigation link for LinkEntity here] end
	},
	{
		// % protected region % [Override navigation link for MapObjectErrorsEntity here] off begin
		path: '/admin/mapobjecterrorsentity',
		label: getModelDisplayName(Models.MapObjectErrorsEntity),
		entity: Models.MapObjectErrorsEntity,
		isMember: false,
		// % protected region % [Override navigation link for MapObjectErrorsEntity here] end
	},
	{
		// % protected region % [Override navigation link for MapObjectWarningsEntity here] off begin
		path: '/admin/mapobjectwarningsentity',
		label: getModelDisplayName(Models.MapObjectWarningsEntity),
		entity: Models.MapObjectWarningsEntity,
		isMember: false,
		// % protected region % [Override navigation link for MapObjectWarningsEntity here] end
	},
	{
		// % protected region % [Override navigation link for NodeEntity here] off begin
		path: '/admin/nodeentity',
		label: getModelDisplayName(Models.NodeEntity),
		entity: Models.NodeEntity,
		isMember: false,
		// % protected region % [Override navigation link for NodeEntity here] end
	},
	{
		// % protected region % [Override navigation link for SegmentEntity here] off begin
		path: '/admin/segmententity',
		label: getModelDisplayName(Models.SegmentEntity),
		entity: Models.SegmentEntity,
		isMember: false,
		// % protected region % [Override navigation link for SegmentEntity here] end
	},
	{
		// % protected region % [Override navigation link for SegmentPointEntity here] off begin
		path: '/admin/segmentpointentity',
		label: getModelDisplayName(Models.SegmentPointEntity),
		entity: Models.SegmentPointEntity,
		isMember: false,
		// % protected region % [Override navigation link for SegmentPointEntity here] end
	},
	{
		// % protected region % [Override navigation link for SignalSetEntity here] off begin
		path: '/admin/signalsetentity',
		label: getModelDisplayName(Models.SignalSetEntity),
		entity: Models.SignalSetEntity,
		isMember: false,
		// % protected region % [Override navigation link for SignalSetEntity here] end
	},
	{
		// % protected region % [Override navigation link for SublinkEntity here] off begin
		path: '/admin/sublinkentity',
		label: getModelDisplayName(Models.SublinkEntity),
		entity: Models.SublinkEntity,
		isMember: false,
		// % protected region % [Override navigation link for SublinkEntity here] end
	},
	// % protected region % [Add any extra page links here] off begin
	// % protected region % [Add any extra page links here] end
];

export default class PageLinks extends React.Component<RouteComponentProps> {
	private filter = (link: AdminLink) => {
		return SecurityService.canRead(link.entity);
	}

	public render() {
		return (
			<Navigation
				className="nav__admin"
				orientation={Orientation.VERTICAL}
				linkGroups={this.getAdminNavLinks()}
				{...this.props}
			/>
		);
	}

	private getAdminNavLinks = () : ILink[][] => {
		// % protected region % [Add custom logic before all here] off begin
		// % protected region % [Add custom logic before all here] end

		const links = getPageLinks();
		const userLinks = links.filter(link => link.isMember).filter(this.filter);
		const entityLinks = links.filter(link => !link.isMember).filter(this.filter);
		const linkGroups: ILink[][] = [];

		// % protected region % [Add any custom logic here before groups are made] off begin
		// % protected region % [Add any custom logic here before groups are made] end

		const homeLinkGroup: ILink[] = [
			{
				path: '/admin',
				label: 'Home',
				icon: 'home',
				iconPos: 'icon-left',
			},
			// % protected region % [Updated your home link group here] off begin
			// % protected region % [Updated your home link group here] end
		];
		linkGroups.push(homeLinkGroup);

		const entityLinkGroup: ILink[] = [];
		if (userLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/users',
					label: 'Users',
					icon: 'person-group',
					iconPos: 'icon-left',
					subLinks: [
						{ path: '/admin/user', label: 'All Users' },
						...userLinks.map(link => ({ path: link.path, label: link.label })),
					],
				},
			);
		}
		if (entityLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/entities',
					label: 'Entities',
					icon: 'list',
					iconPos: 'icon-left',
					subLinks: entityLinks.map(link => {
						return {
							path: link.path,
							label: link.label,
						};
					}),
				},
			);
		}
		linkGroups.push(entityLinkGroup);

		// % protected region % [Add any new link groups here before other and bottom] off begin
		// % protected region % [Add any new link groups here before other and bottom] end

		const otherlinkGroup: ILink[] = [];

		// % protected region % [Add any additional links to otherLinkGroup here] off begin
		// % protected region % [Add any additional links to otherLinkGroup here] end

		if (otherlinkGroup.length > 0) {
			linkGroups.push(otherlinkGroup);
		}

		const bottomlinkGroup: ILink[] = [];
		bottomlinkGroup.push(
			// % protected region % [Modify the utility link group here] off begin
			{
				path: '/admin/',
				label: 'Utility',
				icon: 'file',
				iconPos: 'icon-left',
				subLinks: [
					{
						path: '/admin/styleguide',
						label: 'Style Guide',
						useATag: false,
					},
					{
						path: '/admin/graphiql',
						label: 'GraphiQL',
						useATag: true,
					},
					{
						path: '/api/hangfire',
						label: 'Hangfire',
						useATag: true,
					},
					{
						path: '/api/swagger',
						label: 'OpenAPI',
						useATag: true,
					},
					{
						path: 'https://gitlab.codebots.dev',
						label: 'Git',
						useATag: true,
					},
				],
			},
			// % protected region % [Modify the utility link group here] end
		);

		// % protected region % [Update the logout link here] off begin
		bottomlinkGroup.push(
			{
				path: '/logout',
				label: 'Logout',
				icon: 'room',
				iconPos: 'icon-left',
			},
		);
		// % protected region % [Update the logout link here] end

		// % protected region % [Add any additional links to bottomlinkGroup here] off begin
		// % protected region % [Add any additional links to bottomlinkGroup here] end

		linkGroups.push(bottomlinkGroup);

		// % protected region % [Modify your link groups here before returning] off begin
		// % protected region % [Modify your link groups here before returning] end

		return linkGroups;
	}

	// % protected region % [Add custom methods here] off begin
	// % protected region % [Add custom methods here] end
}
