import MapObject from '../MapObject';
import MapRenderer from '../../MapRenderer';
import L, { GeoJSON } from 'leaflet';
import * as PIXI from 'pixi.js';
import geoJsonToPoints from '../../Helpers/GeoJSON';
import MapStore from '../../MapStore';
import MapController from '../../MapController';
import PathValidator from '../../MapValidators/PathValidator';

const LINE_COLOUR = 0x464646;
const LINE_COLOUR_HIGHLIGHTED = 0xFF6600;
const LINE_COLOUR_INTERFERENCE = 0x394EC4;
const LINE_THICKNESS = 2;
const FILL_OPACITY = 0.4;
const ERROR_COLOUR = 0xFF3A3A;
const ERROR_OPACITY = 0.3;
const WARNING_COLOUR = 0xEED202;
const WARNING_OPACITY = 0.3;
const INTERFERENCE_COLOUR = 0x394EC4;
const INTERFERENCE_OPACITY = 0.3;

/**
 * Render and style driving zones in Pixi
 */
export default class DrivingZone extends MapObject<PIXI.Point[]> {

	private mapLookup: MapStore;

	private _isInterference: boolean = false;

	constructor(geoJSON: string, renderer: MapRenderer) {
		super(renderer, 'driving_zone', geoJsonToPoints(geoJSON, renderer) as PIXI.Point[]);

		this.mapLookup = renderer.getController().getMapLookup();
		// Create two graphics
		this.createGraphic();
	}

	public set isInterference(isInterference: boolean) {
		this._isInterference = isInterference;
	}

	public get isInterference() {
		return this._isInterference;
	}

	render() {
		const checkPathInterference = this.mapLookup.isCheckPathInterference;
		const graphic = this.getGraphic();
		const points = this.getEntity();
		const _borderColour_warning = (!checkPathInterference && this.isWarning) ? WARNING_COLOUR : LINE_COLOUR;
		const _borderColour_error = (!checkPathInterference && this.isError) ? ERROR_COLOUR : _borderColour_warning;
		const _borderColour = (checkPathInterference && this.isInterference) ? LINE_COLOUR_INTERFERENCE : _borderColour_error;
		const borderColour = this.isHighlighted ? LINE_COLOUR_HIGHLIGHTED : _borderColour;
		graphic.clear();
		graphic.zIndex = this.isHighlighted ? this.zIndexTop : this.zIndexBase;
		graphic.lineStyle(LINE_THICKNESS, borderColour);
		const _fillColour_warning = (!checkPathInterference && this.isWarning) ? WARNING_COLOUR : LINE_COLOUR; 
		const _fillColour_error = (!checkPathInterference && this.isError) ? ERROR_COLOUR : _fillColour_warning;
		const fillColour = (checkPathInterference && this.isInterference) ? INTERFERENCE_COLOUR : _fillColour_error;
		const _fillOpacity_warning = (!checkPathInterference && this.isWarning) ? WARNING_OPACITY : FILL_OPACITY; 
		const _fillOpacity_error = (!checkPathInterference && this.isError) ? ERROR_OPACITY : _fillOpacity_warning;
		const fillOpacity = (checkPathInterference && this.isInterference) ? INTERFERENCE_OPACITY : _fillOpacity_error;
		graphic.beginFill(fillColour, fillOpacity);
		graphic.drawPolygon(points);
		graphic.endFill();
	}
}
