/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath, geoJsonField,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsBayEntity from 'Models/Security/Acl/VisitorsBayEntity';
import MineUserBayEntity from 'Models/Security/Acl/MineUserBayEntity';
import HitachiAdminBayEntity from 'Models/Security/Acl/HitachiAdminBayEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { jsonReplacerFn } from 'Models/Model';
import { MapObjectErrorsEntity, MapObjectWarningsEntity } from 'Models/Entities';
import axios from 'axios';
import { SERVER_URL } from '../../Constants';
// % protected region % [Add any further imports here] end

export interface IBayEntityAttributes extends IModelAttributes {
	bayId: number;
	baySeq: number;
	heading: number;
	areaName: string;
	bayVersion: string;
	bayType: Enums.bayType;
	spotDir: Enums.spotDir;
	crossDir: Enums.crossDir;
	callingType: Enums.callingType;
	endPointType: Enums.endType;
	baySource: Enums.baySource;
	bayState: Enums.baystate;
	isImported: boolean;
	state: Enums.mapObjectState;

	areaId?: string;
	area?: Models.AreaEntity | Models.IAreaEntityAttributes;
	importVersionId?: string;
	importVersion?: Models.ImportVersionEntity | Models.IImportVersionEntityAttributes;
	mapObjectErrorss: Array<
		| Models.MapObjectErrorsEntity
		| Models.IMapObjectErrorsEntityAttributes
	>;
	mapObjectWarningss: Array<
		| Models.MapObjectWarningsEntity
		| Models.IMapObjectWarningsEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] on begin
	bayLocation: string;
	elevation: number;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('BayEntity', 'Bay')
// % protected region % [Customise your entity metadata here] end
export default class BayEntity extends Model
	implements IBayEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsBayEntity(),
		new MineUserBayEntity(),
		new HitachiAdminBayEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Bay Id'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Bay Id',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public bayId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Bay Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Bay Seq'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Bay Seq',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public baySeq: number;
	// % protected region % [Modify props to the crud options here for attribute 'Bay Seq'] end

	// % protected region % [Modify props to the crud options here for attribute 'Heading'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Heading',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public heading: number;
	// % protected region % [Modify props to the crud options here for attribute 'Heading'] end

	// % protected region % [Modify props to the crud options here for attribute 'Area Name'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Area Name',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public areaName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Area Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Bay Version'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Bay Version',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public bayVersion: string;
	// % protected region % [Modify props to the crud options here for attribute 'Bay Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Bay Type'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Bay Type',
		displayType: 'enum-combobox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.bayTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.bayTypeOptions),
		displayFunction: (attr: Enums.bayType) => Enums.bayTypeOptions[attr],
	})
	public bayType: Enums.bayType;
	// % protected region % [Modify props to the crud options here for attribute 'Bay Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Spot Dir'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Spot Dir',
		displayType: 'enum-combobox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.spotDirOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.spotDirOptions),
		displayFunction: (attr: Enums.spotDir) => Enums.spotDirOptions[attr],
	})
	public spotDir: Enums.spotDir;
	// % protected region % [Modify props to the crud options here for attribute 'Spot Dir'] end

	// % protected region % [Modify props to the crud options here for attribute 'Cross Dir'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Cross Dir',
		displayType: 'enum-combobox',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.crossDirOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.crossDirOptions),
		displayFunction: (attr: Enums.crossDir) => Enums.crossDirOptions[attr],
	})
	public crossDir: Enums.crossDir;
	// % protected region % [Modify props to the crud options here for attribute 'Cross Dir'] end

	// % protected region % [Modify props to the crud options here for attribute 'Calling Type'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Calling Type',
		displayType: 'enum-combobox',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.callingTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.callingTypeOptions),
		displayFunction: (attr: Enums.callingType) => Enums.callingTypeOptions[attr],
	})
	public callingType: Enums.callingType;
	// % protected region % [Modify props to the crud options here for attribute 'Calling Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'End Point Type'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'End Point Type',
		displayType: 'enum-combobox',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.endTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.endTypeOptions),
		displayFunction: (attr: Enums.endType) => Enums.endTypeOptions[attr],
	})
	public endPointType: Enums.endType;
	// % protected region % [Modify props to the crud options here for attribute 'End Point Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Bay Source'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Bay Source',
		displayType: 'enum-combobox',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.baySourceOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.baySourceOptions),
		displayFunction: (attr: Enums.baySource) => Enums.baySourceOptions[attr],
	})
	public baySource: Enums.baySource;
	// % protected region % [Modify props to the crud options here for attribute 'Bay Source'] end

	// % protected region % [Modify props to the crud options here for attribute 'Bay State'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Bay State',
		displayType: 'enum-combobox',
		order: 120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.baystateOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.baystateOptions),
		displayFunction: (attr: Enums.baystate) => Enums.baystateOptions[attr],
	})
	public bayState: Enums.baystate;
	// % protected region % [Modify props to the crud options here for attribute 'Bay State'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Imported'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Is Imported',
		displayType: 'checkbox',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isImported: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Imported'] end

	// % protected region % [Modify props to the crud options here for attribute 'State'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'State',
		displayType: 'enum-combobox',
		order: 140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.mapObjectStateOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.mapObjectStateOptions),
		displayFunction: (attr: Enums.mapObjectState) => Enums.mapObjectStateOptions[attr],
	})
	public state: Enums.mapObjectState;
	// % protected region % [Modify props to the crud options here for attribute 'State'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Area'] off begin
		name: 'Area',
		displayType: 'reference-combobox',
		order: 150,
		referenceTypeFunc: () => Models.AreaEntity,
		// % protected region % [Modify props to the crud options here for reference 'Area'] end
	})
	public areaId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public area: Models.AreaEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Import Version'] off begin
		name: 'Import Version',
		displayType: 'reference-combobox',
		order: 160,
		referenceTypeFunc: () => Models.ImportVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Import Version'] end
	})
	public importVersionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public importVersion: Models.ImportVersionEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Map Object Errors'] off begin
		name: 'Map Object Errorss',
		displayType: 'reference-multicombobox',
		order: 170,
		referenceTypeFunc: () => Models.MapObjectErrorsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'mapObjectErrorss',
			oppositeEntity: () => Models.MapObjectErrorsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Map Object Errors'] end
	})
	public mapObjectErrorss: Models.MapObjectErrorsEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Map Object Warnings'] off begin
		name: 'Map Object Warningss',
		displayType: 'reference-multicombobox',
		order: 180,
		referenceTypeFunc: () => Models.MapObjectWarningsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'mapObjectWarningss',
			oppositeEntity: () => Models.MapObjectWarningsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Map Object Warnings'] end
	})
	public mapObjectWarningss: Models.MapObjectWarningsEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	@attribute()
	@geoJsonField()
	public bayLocation: string;

	@observable
	@attribute()
	public elevation: number;

	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IBayEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IBayEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.bayId !== undefined) {
				this.bayId = attributes.bayId;
			}
			if (attributes.baySeq !== undefined) {
				this.baySeq = attributes.baySeq;
			}
			if (attributes.heading !== undefined) {
				this.heading = attributes.heading;
			}
			if (attributes.areaName !== undefined) {
				this.areaName = attributes.areaName;
			}
			if (attributes.bayVersion !== undefined) {
				this.bayVersion = attributes.bayVersion;
			}
			if (attributes.bayType !== undefined) {
				this.bayType = attributes.bayType;
			}
			if (attributes.spotDir !== undefined) {
				this.spotDir = attributes.spotDir;
			}
			if (attributes.crossDir !== undefined) {
				this.crossDir = attributes.crossDir;
			}
			if (attributes.callingType !== undefined) {
				this.callingType = attributes.callingType;
			}
			if (attributes.endPointType !== undefined) {
				this.endPointType = attributes.endPointType;
			}
			if (attributes.baySource !== undefined) {
				this.baySource = attributes.baySource;
			}
			if (attributes.bayState !== undefined) {
				this.bayState = attributes.bayState;
			}
			if (attributes.isImported !== undefined) {
				this.isImported = attributes.isImported;
			}
			if (attributes.state !== undefined) {
				this.state = attributes.state;
			}
			if (attributes.areaId !== undefined) {
				this.areaId = attributes.areaId;
			}
			if (attributes.area !== undefined) {
				if (attributes.area === null) {
					this.area = attributes.area;
				} else if (attributes.area instanceof Models.AreaEntity) {
					this.area = attributes.area;
					this.areaId = attributes.area.id;
				} else {
					this.area = new Models.AreaEntity(attributes.area);
					this.areaId = this.area.id;
				}
			}
			if (attributes.importVersionId !== undefined) {
				this.importVersionId = attributes.importVersionId;
			}
			if (attributes.importVersion !== undefined) {
				if (attributes.importVersion === null) {
					this.importVersion = attributes.importVersion;
				} else if (attributes.importVersion instanceof Models.ImportVersionEntity) {
					this.importVersion = attributes.importVersion;
					this.importVersionId = attributes.importVersion.id;
				} else {
					this.importVersion = new Models.ImportVersionEntity(attributes.importVersion);
					this.importVersionId = this.importVersion.id;
				}
			}
			if (attributes.mapObjectErrorss !== undefined && Array.isArray(attributes.mapObjectErrorss)) {
				for (const model of attributes.mapObjectErrorss) {
					if (model instanceof Models.MapObjectErrorsEntity) {
						this.mapObjectErrorss.push(model);
					} else {
						this.mapObjectErrorss.push(new Models.MapObjectErrorsEntity(model));
					}
				}
			}
			if (attributes.mapObjectWarningss !== undefined && Array.isArray(attributes.mapObjectWarningss)) {
				for (const model of attributes.mapObjectWarningss) {
					if (model instanceof Models.MapObjectWarningsEntity) {
						this.mapObjectWarningss.push(model);
					} else {
						this.mapObjectWarningss.push(new Models.MapObjectWarningsEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.bayLocation !== undefined) {
				this.bayLocation = attributes.bayLocation;
			}
			if (attributes.elevation !== undefined) {
				this.elevation = attributes.elevation;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		area {
			${Models.AreaEntity.getAttributes().join('\n')}
		}
		importVersion {
			${Models.ImportVersionEntity.getAttributes().join('\n')}
			${Models.ImportVersionEntity.getFiles().map(f => f.name).join('\n')}
		}
		mapObjectErrorss {
			${Models.MapObjectErrorsEntity.getAttributes().join('\n')}
		}
		mapObjectWarningss {
			${Models.MapObjectWarningsEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			mapObjectErrorss: {},
			mapObjectWarningss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'mapObjectErrorss',
							'mapObjectWarningss',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin

	/* *******************
	 * Validation - Error
	 * ****************** */
	public getErrors() {
		if (!this.mapObjectErrorss) {
			this.mapObjectErrorss = [];
		}
		return this.mapObjectErrorss;
	}

	public addErrors(errors: string[]) {
		errors.map(error => this.addError(error));
	}

	public addError(error: string): boolean {
		if (!this.hasError(error)) {
			const newError = new MapObjectErrorsEntity({
				errorMessage: error,
				bayId: this.getModelId(),
			});
			this.getErrors().push(newError);

			return true;
		}

		return false;
	}

	public resetAndAddNewErrors(errors: string[]) {
		this.resetErrors();
		this.addErrors(errors);
	}

	public removeError(error: string): boolean {
		const errorToDelete = this.getErrors().findIndex(x => x.errorMessage === error);
		if (errorToDelete !== -1) {
			this.getErrors().splice(errorToDelete, 1);
			return true;
		}

		return false;
	}

	public resetErrors() {
		this.mapObjectErrorss = [];
	}

	public hasError(error: string): boolean {
		return this.getErrors().find(x => x.errorMessage === error) !== undefined;
	}

	public getErrorCount() {
		return this.getErrors().length;
	}

	/* *******************
	 * Validation - Warning
	 * ****************** */
	public getWarnings() {
		if (!this.mapObjectWarningss) {
			this.mapObjectWarningss = [];
		}
		return this.mapObjectWarningss;
	}

	public addWarnings(warnings: string[]) {
		warnings.map(warning => this.addWarning(warning));
	}

	public addWarning(warning: string): boolean {
		if (!this.hasWarning(warning)) {
			const newWarning = new MapObjectWarningsEntity({
				warningMessage: warning,
				sublinkId: this.getModelId(),
			});
			this.getWarnings().push(newWarning);

			return true;
		}

		return false;
	}

	public resetAndAddNewWarnings(warnings: string[]) {
		this.resetWarnings();
		this.addWarnings(warnings);
	}

	public removeWarning(warning: string): boolean {
		const warningToDelete = this.getWarnings().findIndex(x => x.warningMessage === warning);
		if (warningToDelete !== -1) {
			this.getWarnings().splice(warningToDelete, 1);
			return true;
		}

		return false;
	}

	public resetWarnings() {
		this.mapObjectWarningss = [];
	}

	public hasWarning(warning: string): boolean {
		return this.getWarnings().find(x => x.warningMessage === warning) !== undefined;
	}

	public getWarningCount() {
		return this.getWarnings().length;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(BayEntity.prototype, 'created');
CRUD(modifiedAttr)(BayEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
