/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import MapStateHandler from './MapStateHandler';
import {
	AreaEntity,
	BayEntity,
} from '../../../../Models/Entities';
import { LeafletMouseEvent } from 'leaflet';
import { LeafletCoordinates, RealWorldCoordinates } from '../Helpers/Coordinates';
import alertToast from '../../../../Util/ToastifyUtils';
import { Bay } from '../../index';
import BayEditHandler from './BayEditHandler';
import { isCtrlKeyHeld } from './MapGlobalEventHandler';
import BayValidator, {
	BAY_AHS_BOUNDARY_ERROR,
	BAY_LOCATION_ERROR
} from '../MapValidators/BayValidator';
import {action} from 'mobx';
import { setCustomTag } from '../Helpers/MapUtils';
import * as Coordinates from '../Helpers/Coordinates';

interface BayLocation {
	type: string;
	coordinates: string[];
}

export interface BayTypeParams {
	isReadOnly: boolean;
	bayTypeOptions?: any;
}

export default class BayToolHandler extends MapStateHandler<never> {
	private newBay: BayEntity;

	private bayMapObject: Bay | undefined;
	private isSnapped: boolean = false;

	onInit() {
		this.createNewBayEntity();
		this.getController().setCursor('crosshair');
		this.getEventHandler().emit('onPropertiesPanel', 'bay', this.newBay);
	}

	private createNewBayEntity() {
		this.newBay = new BayEntity({
			bayType: 'DUMPPADDOCK',
			spotDir: 'BACKIN',
			crossDir: 'AUTO',
			callingType: 'SINGLEAHT',
			bayState: 'OPEN',
			endPointType: 'AREA',
			importVersionId: this.getController().getImportVersion().id,
		});
		this.newBay.id = this.newBay._clientId;
	}

	/**
	 * Place new bay so long as it's location is valid
	 * Show error toast for invalid location
	 * @param event
	 * @returns
	 */
	async onClick(event: LeafletMouseEvent) {
		const controller = this.getController();
		const coordinates = this.getRenderer().getRealWorldCoords(event.latlng);
		const bayArea = BayValidator.getBayArea(coordinates, controller);
		if (!bayArea) {
			alertToast(BAY_LOCATION_ERROR, 'error');
			return;
		}
		if (!BayValidator.validateBayCoordsInMapBounds(coordinates, controller)) {
			alertToast(BAY_AHS_BOUNDARY_ERROR, 'error');
			return;
		}
		// Change of area not permitted after being set here
		this.setBayArea(bayArea);

		await this.waitForConfirmingStatus();
		controller.getMapLookup().confirmButtonWillShow = true;
		
		try {
			await this.createBayMapObject(event, coordinates);
		} catch (e) {
			console.error(e);
			controller.getMapLookup().confirmButtonWillShow = false;
			alertToast((e as Error).message, 'error');
			return;
		}
		
		setCustomTag('map-interface', 'create-a-bay (short click)');

		// Once bay is placed, switch to edit handler
		this.getEventHandler().setMapEventState('edit_bay', {
			mapObject: this.bayMapObject
		});
	}

	onMove(event: LeafletMouseEvent) {
	}

	@action
	setBayArea(bayArea: AreaEntity) {
		BayEditHandler.setBayArea(this.newBay, bayArea);
	}

	/**
	 * Place bay (if location is valid) and initiate rotation of heading
	 * Show error toast for invalid location
	 * @param event
	 * @param originalCoordinates
	 * @returns
	 */
	onDragStart(event: LeafletMouseEvent, originalCoordinates: LeafletCoordinates) {
		// Dragging in this context is heading only (not location).
		// Bay is placed as per coords here, and set upon drag end
		const controller = this.getController();
		const coordinates = this.getRenderer().getRealWorldCoords(originalCoordinates);
		const bayArea = BayValidator.getBayArea(coordinates, controller);
		if (!bayArea) {
			alertToast(BAY_LOCATION_ERROR, 'error');
			return;
		}
		if (!BayValidator.validateBayCoordsInMapBounds(coordinates, controller)) {
			alertToast(BAY_AHS_BOUNDARY_ERROR, 'error');
			return;
		}
		// // Change of area not permitted after being set here
		this.setBayArea(bayArea);

		controller.setRotateCursor();
		this.createBayMapObject(event, coordinates).catch(e => {
			console.error(e as any);
			controller.setCursor('crosshair');
			alertToast((e as Error).message, 'error');
		});
	}

	/**
	 * Rotate bay and dynmically update heading
	 * @param event
	 * @returns
	 */
	onDragMove(event: LeafletMouseEvent) {
		if (!this.bayMapObject) {
			return;
		}

		if (this.isSnapped) {
			return;
		}
		const coordinates = this.getRenderer().getRealWorldCoords(event.latlng);		

		BayEditHandler.setBayHeading(this.newBay, coordinates);
		BayEditHandler.updateBayMapObject(this.bayMapObject, this.getRenderer());
	}

	/**
	 * Set the initial bay heading after rotation and enter edit_bay mode
	 * @param event
	 */
	async onDragEnd(event: LeafletMouseEvent) {
		if (!this.bayMapObject) {
			return;
		}

		const mapLookup = this.getController().getMapLookup();
		if (mapLookup.confirmButtonConfirming) {
			await this.waitForConfirmingStatus();
			mapLookup.confirmButtonWillShow = true;
		}
		this.getController().setDefaultCursor();

		setCustomTag('map-interface', 'create-a-bay (long click)');
		this.getEventHandler().setMapEventState('edit_bay', {
			mapObject: this.bayMapObject,
		});
	}

	onEscapePressed(event: KeyboardEvent) {
		this.getEventHandler().setActiveTool('selector');
	}

	dispose() {
		this.getController().setDefaultCursor();
	}

	/**
	 * Create new bay. Using clientId (as entity id is not yet set)
	 */
	private async createBayMapObject(event: LeafletMouseEvent, coordinates: RealWorldCoordinates){
		const map = this.getController();
		if (!!this.bayMapObject) {
			/* this.getRenderer().removeObject(this.bayMapObject.getId()); */// HITMAT-1356 this was causing the bay to be removed but the tooltip to remain when double clicking and moving mouse while in bay creation mode. return; instead...
			return;
		}
		this.bayMapObject = new Bay(this.newBay, map);

		this.newBay.state = 'NEW_OBJECT';
		BayValidator.setBayTypeBeforeConfirm(this.newBay, map);
		// Get bay type parameters based on values for the properties panel
		const bayTypeParams = BayValidator.getBayTypeParamsBeforeConfirm(this.newBay, map, true);

		if (isCtrlKeyHeld(event.originalEvent)) {
			const snapParams = BayEditHandler
				.isSnappableAndSetBayHeading(this.newBay, map, coordinates, true);
			if (!snapParams.isSnappable) {
				BayEditHandler.setBayHeading(this.newBay);
			}

			this.isSnapped = snapParams.isSnappable;
			BayEditHandler.setBayLocation(this.newBay, coordinates, snapParams);
			
			const snappedBayLocationCoords: number[] = JSON.parse(this.newBay.bayLocation).coordinates;
			const bayCoordsSnapped: RealWorldCoordinates = Coordinates.realWorldCoordinates(snappedBayLocationCoords[1], snappedBayLocationCoords[0]);
			if (!BayValidator.getBayArea(bayCoordsSnapped, map)) {
				this.bayMapObject = undefined;
				throw new Error(BAY_LOCATION_ERROR);
			}
			
			BayEditHandler.setBaySpotdir(this.newBay, snapParams);
			const isSnapToEndParkingNode = !!snapParams.previousNode;
			// set spotdir in the properties panel to read-only
			// in case when snapping bay, releasing the mouse button on the properties panel,
			// and then updating spotdir before it is set to read-only when handler turned to edit_bay
			this.getEventHandler().emit('onPropertiesPanel', 'bay', this.newBay, {
				isSnapped: this.isSnapped && isSnapToEndParkingNode,
				isReadOnly: bayTypeParams?.isReadOnly,
				bayTypeOptions: bayTypeParams?.bayTypeOptions,
			});
		} else {
			BayEditHandler.setBayHeading(this.newBay);
			BayEditHandler.setBayLocation(this.newBay, coordinates);
			if (!!bayTypeParams) {
				this.getEventHandler().emit('onPropertiesPanel', 'bay', this.newBay, {
					isReadOnly: bayTypeParams.isReadOnly,
					bayTypeOptions: bayTypeParams.bayTypeOptions,
				});
			}
		}

		this.getRenderer().addObject(this.bayMapObject, true);

		// highlight bay after object has been added to container to be rendered
		map.highlightObjectByEntityId(this.newBay.getModelId(), 'bay');

		this.getRenderer().rerender();

		console.log('new bay : state changed to Created.');
	}
}
