import React from 'react';
import Modal from '../Modal/Modal';
import {
	Button, Colors, Display, Sizes,
} from '../Button/Button';
import { ButtonGroup } from '../Button/ButtonGroup';

interface IUserActivationToggleModalProps {
    onCloseModal: () => void;
    reactivate: boolean;
    onConfirm: () => void;
}

export function UserActivationToggleModal(props: IUserActivationToggleModalProps) {
	const { onCloseModal } = props;

	return (
		<Modal
			isOpen
			label=""
			onRequestClose={onCloseModal}
			className="mat-modal user-permissions-modal"
			overlayClassName="map-file-upload-modal-overlay"
		>
			<RenderUserActivationToggleModalContent {...props} />
		</Modal>
	);
}

export function RenderUserActivationToggleModalContent(props: IUserActivationToggleModalProps) {
	const { onCloseModal, reactivate, onConfirm } = props;
	const action = reactivate ? 'reactivate' : 'deactivate';

	return (
		<>
			<div className="modal__header user-permissions-modal">
				<h3>Are you sure you would like to {action} this user?</h3>
				<Button
					icon={{ icon: 'square-x', iconPos: 'icon-right' }}
					className="cancel-button no-background"
					display={Display.Text}
					onClick={onCloseModal}
					labelVisible={false}
				/>
			</div>

			<p className="user-permissions-modal-subheading">You can revert this action anytime.</p>

			<ButtonGroup className="invite-modal-actions">
				<Button
					colors={Colors.Primary}
					display={Display.Outline}
					onClick={onCloseModal}
					sizes={Sizes.Medium}
				>
					Cancel
				</Button>
				<Button
					className="next-button"
					colors={Colors.Primary}
					display={Display.Solid}
					onClick={onConfirm}
					sizes={Sizes.Medium}
				>
					Confirm
				</Button>
			</ButtonGroup>
		</>
	);
}
