import {SaveCommand, SaveCommandType} from "./SaveCommand";
import {AreaEntity} from "../../../../Models/Entities";

export default class UpdateAreaCommand extends SaveCommand {
	area: AreaEntity;

	constructor(areaEntity: AreaEntity) {
		super(SaveCommandType.UpdateArea);

		this.area = areaEntity;
	}
}