import {observable, runInAction} from "mobx";

type ValidationResults<T> = {
    [key in keyof T]: string
};

type Key<T> = keyof T;

type ValidationFunction<T, K extends keyof T> = (value: T[K], model: T) => Promise<string | null>;

type ValidationMap<T> = {
    [key in keyof T]?: ValidationFunction<T, key>[];
};

type ValidationKeys<T> = Set<keyof T>;

export function CustomValidator<T extends Validator<T>, K extends keyof T>(validatorFunc: ValidationFunction<T, K>) {
    return (model: T, key: K) => {
        initValidation(model);

        model.validatorKeys.add(key);

        const validations = model.validatorMap[key] ?? [];

        validations.push(validatorFunc as ValidationFunction<T, K>);

        model.validatorMap[key] = validations;
    };
}

function initValidation<T extends Validator<T>>(instance: Validator<T>) {
    if (!instance.validatorKeys) {
        instance.validatorKeys = new Set<keyof T>();
    }

    if (!instance.validatorMap) {
        instance.validatorMap = {};
    }
}

export abstract class Validator<T extends Validator<T>> {
    // These get store on the prototype definition of the class, these are just here for typing
    public validatorMap!: ValidationMap<T>;
    public validatorKeys!: ValidationKeys<T>;

    // Stores the errors for each field in each instance of the class
    private validationErrors = observable.map<Key<T>, string | undefined>({});

    public setError(key: Key<T>, error?: string) {
        if (this.validationErrors.get(key) === error) {
            return;
        }

        runInAction(() => {
            if (error === undefined) {
                // Delete the key if there are no longer errors
                this.validationErrors.delete(key);
            } else {
                this.validationErrors.set(key, error);
            }
        });
    }

    @observable
    public getError(key: Key<T>): string | undefined {
        return this.validationErrors.get(key);
    }

    public clearErrors() {
        runInAction(() => {
            this.validationErrors.clear();
        });
    }

    public hasValidationErrors(key?: Key<T>): boolean {
        if (key !== undefined) {
            return this.validationErrors.has(key);
        }

        const values = this.validationErrors.values();
        const nextValue = values.next();

        return nextValue.done !== true;
    }

    public async validate(key?: Key<T>, clearAllErrors: boolean = true): Promise<boolean> {
        if (clearAllErrors) {
            runInAction(() => {
                if (key === undefined) {
                    this.clearErrors();
                } else {
                    // Set error to nothing
                    this.setError(key);
                }
            });
        }

        const that = this as unknown as T;
        let validators: Promise<{ key: Key<T>, result: string | null }>[] = [];

        const prototype = Object.getPrototypeOf(that);

        const keys = !!key ? [key] : prototype.validatorKeys;

        keys.forEach((key: keyof T) => {
            const keyValidators = prototype.validatorMap[key]!
                .map((x: ValidationFunction<T, keyof T>) => (async () => ({
                    key: key,
                    result: await x(that[key], that)
                }))())

            validators = validators.concat(keyValidators);
        });

        const results = await Promise.all(validators);

        results.forEach(({ key, result }) => {
            if (result != null && !this.hasValidationErrors(key)) {
                this.setError(key, result);
            }
        });

        return this.hasValidationErrors();
    }
}
