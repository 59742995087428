import {SaveCommand, SaveCommandType} from "./SaveCommand";
import {LinkEntity} from "../../../../Models/Entities";

export default class UpdatePathCommand extends SaveCommand {

	path: LinkEntity;

	constructor(path: LinkEntity) {
		super(SaveCommandType.UpdatePath);

		this.path = path;
	}

	public getData(): any {
		return {
			path: this.path.toJSON({
				linkFroms: {},
				linkTos: {},
				sublinkss: { nodess: {} },
			}),
		};
	}
}