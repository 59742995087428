/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/anchor-has-content */
import { SERVER_URL } from 'Constants';
import { PublishedVersionEntity } from 'Models/Entities';
import moment from 'moment-timezone';
import {getUsersName} from "../../Models/UserEntity";
import {MOMENT_FORMAT_STRING} from "../../Constants";

interface IPublishedVersionPreviewProps {
    publishVersions: PublishedVersionEntity;
	timezone: string;
}

export default function PublishedVersionsPreview(props: IPublishedVersionPreviewProps) {
	const { publishVersions, timezone } = props;
	const dateDisplayed = moment.tz(publishVersions.created, timezone).format(MOMENT_FORMAT_STRING);
	let contains = '';
	if (publishVersions.exportType === 'AREA') {
		contains = 'Area';
	}
	if (publishVersions.exportType === 'BAY') {
		contains = 'Bay';
	}
	if (publishVersions.exportType === 'PATH') {
		contains = 'Path';
	}
	if (publishVersions.exportType === 'PATHANDBAY') {
		contains = 'Bay, Path';
	}

	const { length } = publishVersions.versionId.toLocaleString();
	return (
		<div className="publishedVersionBox">
			<a
				href={`${SERVER_URL}/api/files/${publishVersions.publishedMapDataId}?download=true`}
				target="_blank"
				rel="noopener noreferrer"
			>
				<div className="container">
					<div className={length === 3 ? 'version tripleDigit' : 'version'}>
						<h5 className={length === 2 ? 'doubleDigit'
							: (length === 3) ? 'tripleDigit' : ''}
						>
							v{publishVersions.versionId}
						</h5>
					</div>
					<div className={length === 3 ? 'date-published tripleDigit' : 'date-published'}>
						{dateDisplayed}
					</div>

					<a
						href={`${SERVER_URL}/api/files/${publishVersions.publishedMapDataId}?download=true`}
						target="_blank"
						rel="noopener noreferrer"
						className="btn btn--icon icon-download icon-left download-icon-styling"
					/>
				</div>
				<div className="published-contains">
					<div>
						Published by {getUsersName(publishVersions.owner)}
					</div>
					<div>
						Contains: {contains}
					</div>
				</div>
			</a>
		</div>

	);
}
