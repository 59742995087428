import Modal from "../../Modal/Modal";
import React, {useState} from "react";
import alert from "../../../../Util/ToastifyUtils";
import {setCustomTag} from "../../../MapComponents/Map/Helpers/MapUtils";
import axios from "axios";
import {SERVER_URL} from "../../../../Constants";
import {store} from "../../../../Models/Store";
import {LoadView} from "../LoadView";
import {Button, Colors, Display, Sizes} from "../../Button/Button";
import {TextField} from "../../TextBox/TextBox";
import {ButtonGroup} from "../../Button/ButtonGroup";
import {mapFileValidation, mapImportNameValidation, MAX_NAME_LENGTH} from "./FileUploadModal";
import {CreateImportClass, FileUploadSection, IFileUploadModalProps} from "./FileUploadModal";
import {observer} from "mobx-react";
import {CustomValidator, Validator} from "../../../../Validators/CustomValidator";
import {observable} from "mobx";

class CurrentMapImportModel extends Validator<CurrentMapImportModel> {

    importVersionId: string;

    @CustomValidator(async (value, model) => {
        return await mapImportNameValidation(value);
    })
    @observable
    public mapImportName: string;

    @CustomValidator(async (value, model) => {
        return await mapFileValidation(value);
    })
    @observable
    public mapData: File;

    constructor(importVersionId: string) {
        super();

        this.importVersionId = importVersionId;
    }
}

export default function CurrentMapImportModal(props: IFileUploadModalProps) {
    const { onCloseModal } = props;

    return (
        <Modal
            isOpen
            label="File upload"
            onRequestClose={onCloseModal}
            className="map-file-upload-modal"
            overlayClassName="map-file-upload-modal-overlay"
        >
            <RenderCurrentMapImportModalContent {...props} />
        </Modal>
    );
}

export const RenderCurrentMapImportModalContent = observer((props: IFileUploadModalProps) => {
    const { title, importVersion, onCloseModal } = props;
    const [createImport, setCreateImport] = useState(new CurrentMapImportModel(importVersion!.id));
    const [displaySpinner, setDisplaySpinner] = useState(false);

    const uploadAllFiles = async () => {
        if (await createImport.validate()) {
            return;
        }

        setCustomTag('import-a-map', 'import-current-map-data');
        setDisplaySpinner(true);

        try {
            const formData = new FormData();
            formData.append('CurrentMapData', createImport.mapData);
            formData.append('ImportVersionName', createImport.mapImportName.trim());

            formData.append('ImportVersionId', createImport.importVersionId);

            const response = await axios.post(
                `${SERVER_URL}/api/entity/ImportVersionEntity/UploadCurrentMapData`,
                formData,
                {
                    headers: {
                        'Content-Type': 'mulitipart/form-data',
                    },
                },
            );

            alert('Map files uploaded successfully', 'success');
            setDisplaySpinner(false);
            store.routerHistory.push(`/mapedit/${response.data}`);
            window.location.reload();
        } catch (e: any) {
            setDisplaySpinner(false);

            const errors = e?.response?.data?.errors ?? [];
            const errorMsg = errors.length > 0 ? errors[0]?.message : undefined;
            alert(errorMsg ?? 'Updating current map data failed', 'error');
        }
    };

    return (
        <>
            {displaySpinner && <LoadView text="Importing map data..." timerInterval={150} />}
            <div className="modal__header">
                <h3>{title}</h3>
                <Button
                    icon={{ icon: 'cross', iconPos: 'icon-right' }}
                    className="cancel-button no-background"
                    display={Display.Text}
                    onClick={onCloseModal}
                    labelVisible={false}
                />
            </div>

            <div className="import-file">
                <div className="map-name-input">
                    <TextField
                        label="Map import name"
                        labelVisible
                        isRequired
                        model={createImport}
                        modelProperty="mapImportName"
                        onAfterChange={() => createImport.validate('mapImportName')}
                        errors={createImport.getError('mapImportName')}
                    />
                </div>
            </div>

            <h4 className="subtitle">Import Map Files</h4>
            <FileUploadSection
                model={createImport}
                modelProperty="mapData"
                title="AHS CURRENT MAP DATA FILE"
                fileType="AHSCurrentMapYYYYMMDDHHMMSS.zip"
                onAfterChange={async () => await createImport.validate('mapData')}
                errors={createImport.getError('mapData')}
                required
                contentType=".zip"
            />

            <div className="modal-note">
                <span className="icon icon-information icon-only" />
                <p>
                    Use this function to import the latest map data from the AHC server while conserving most of the important changes made to the existing map import up to this point.
                    This will create a new map import. <br/>
                    The existing map import will be saved in its current state so that edits made before importing the AHS Current Map Data are not lost.
                </p>
            </div>

            <ButtonGroup className="modal-actions">
                <Button
                    colors={Colors.Primary}
                    display={Display.Outline}
                    onClick={onCloseModal}
                    sizes={Sizes.Medium}
                >
                    Cancel
                </Button>
                <Button
                    className="next-button"
                    colors={Colors.Primary}
                    icon={{ icon: 'chevron-right', iconPos: 'icon-right' }}
                    display={Display.Solid}
                    onClick={uploadAllFiles}
                    sizes={Sizes.Medium}
                >
                    Next
                </Button>
            </ButtonGroup>
        </>
    );
});