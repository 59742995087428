import {AreaEntity} from "../../../../Models/Entities";
import {SaveCommand, SaveCommandType} from "./SaveCommand";

export default class CreateAreaCommand extends SaveCommand {
	area: AreaEntity;

	constructor(areaEntity: AreaEntity) {
		super(SaveCommandType.CreateArea);

		this.area = areaEntity;
	}
}