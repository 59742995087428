import {
	AreaEntity,
	BayEntity,
	LinkEntity,
	NodeEntity,
	SublinkEntity,
} from '../../../Models/Entities';
import CollapsibleProperty from '../CollapsibleProperty';
import React, { useEffect, useState } from 'react';
import MapController from '../Map/MapController';
import { getErrorMessage, getWarningMessage } from "../Map/ServerValidation";

interface IErrorsAndWarningProps {
	mapObject: AreaEntity | BayEntity | LinkEntity | SublinkEntity | NodeEntity;
	mapController?: MapController;
}

const ErrorsAndWarnings = ((props: IErrorsAndWarningProps) => {
	const { mapObject, mapController } = props;
	const [errors, setErrors] = useState(mapObject.mapObjectErrorss);
	const [warnings, setWarnings] = useState(mapObject.mapObjectWarningss);

	useEffect(() => {
		mapController?.getEventHandler().addListener('onMapObjectUpdate', handleUpdate);
		mapController?.getEventHandler().addListener('onClearPropertiesPanelErrors', onClearPropertiesPanelErrors);
		return () => {
			mapController?.getEventHandler().removeListener('onMapObjectUpdate', handleUpdate);
			mapController?.getEventHandler().removeListener('onClearPropertiesPanelErrors', onClearPropertiesPanelErrors);
		};
	}, []);

	useEffect(() => {
		setErrors(mapObject.mapObjectErrorss);
		setWarnings(mapObject.mapObjectWarningss);
	}, [mapObject]);

	const onClearPropertiesPanelErrors = () => {
		setErrors([]);
	};

	// We emit onMapObjectUpdate with a different entity from the entity of ErrorsAndWarnings sometimes
	// remember to add entityId for comparing
	const handleUpdate = (entity: unknown, entityId?: string) => {
		// @ts-ignore
		if (mapObject.id === entityId || entity['id'] === mapObject.id) {
			// @ts-ignore
			setErrors(Array.from(entity['mapObjectErrorss']));
			// @ts-ignore
			setWarnings(Array.from(entity['mapObjectWarningss']));
		}
	};

	const mapObjectErrors = errors;
	const mapObjectWarnings = warnings;

	return (
		<CollapsibleProperty
			propertyTitle={`Errors and Warnings (${mapObjectErrors.length + mapObjectWarnings.length})`}
			displayProperty
		>
			{
				((mapObjectErrors.length > 0 || mapObjectWarnings.length > 0)
				&& (
					<div className="errors-and-warnings">
						{
							mapObjectErrors.map(item => {
								return (
									<div className="errors-and-warnings-item"
										key={item.errorMessage}>
										<span className="error" />
										<p>
											{getErrorMessage(item.errorMessage)}
										</p>
									</div>
								);
							})
						}
						{
							mapObjectWarnings.map(item => {
								return (
									<div className="errors-and-warnings-item"
										key={item.warningMessage}>
										<span className="warning" />
										<p>
											{getWarningMessage(item.warningMessage)}
										</p>
									</div>
								);
							})
						}
					</div>
				))
				|| (
					<div className="errors-and-warnings">
						No errors
					</div>
				)
			}
		</CollapsibleProperty>
	);
});

export default ErrorsAndWarnings;
