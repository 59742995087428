import {SaveCommand, SaveCommandType} from "./SaveCommand";

export default class UpdateConnectivityCommand extends SaveCommand {
	linkFromId: string;
	linkToId: string;
	newLinkFromId: string;
	newLinkToId: string;

	constructor(linkFromId: string, linkToId: string, newLinkFromId: string, newLinkToId: string) {
		super(SaveCommandType.UpdateConnectivity);

		this.linkFromId = linkFromId;
		this.linkToId = linkToId;
		this.newLinkFromId = newLinkFromId;
		this.newLinkToId = newLinkToId;
	}
}
