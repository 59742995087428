import {SaveCommand, SaveCommandType} from "./SaveCommand";

export default class BreakSublinkCommand extends SaveCommand {
	linkId: string;
	breakIndex: number;
	sublinkId: string;

	/* We won't calculate the next available ids on the server for now, so we need to pass them in */
	newSublinkIdNumber: number;

	constructor(linkId: string, breakIndex: number, sublinkId: string, newSublinkIdNumber: number) {
		super(SaveCommandType.BreakSublink);

		this.linkId = linkId;
		this.breakIndex = breakIndex;
		this.sublinkId = sublinkId;
		this.newSublinkIdNumber = newSublinkIdNumber;
	}
}