/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { ImportVersionEntity } from '../../Models/Entities';
import { FileListPreview } from '../Components/CRUD/Attributes/AttributeFile';
import { Alignment, ButtonGroup } from '../Components/Button/ButtonGroup';
import {
	Button, Colors, Display, Sizes,
} from '../Components/Button/Button';
import { renderLastModified, renderStatus } from './AllImportsForMap';
import { observer } from 'mobx-react';
import { renderProfileIndicator } from 'Views/Components/Shared/ProfileIndicator';
import PublishedVersionsPreview from './PublishedVersionsPreview';

interface importsSidePanelProps {
	importVersionEntity: ImportVersionEntity;
	onOpenModal: () => void;
}

@observer
export default class ImportsSidePanel extends React.Component<importsSidePanelProps> {
	render() {
		const publishedVersions = this.props.importVersionEntity.publishedVersionss.map(_a => _a);
		const tz = this.props.importVersionEntity.map?.mineSite?.timezone ?? "Australia/Sydney";
		let sortedPublishedVersions = [];
		for (let i = 0; i < publishedVersions.length; i++) {
			sortedPublishedVersions[publishedVersions[i].versionId - 1] = publishedVersions[i];
		}
		sortedPublishedVersions = sortedPublishedVersions.reverse();
		return (
			<div className="import-page-side-panel">
				<div className="arrow" />
				<div className="side-panel-main-frame">
					{renderProfileIndicator()}
					<h3 className="sidebar-title">Version information</h3>
					{renderStatus(this.props.importVersionEntity)}
					<div className="side-panel-content-margin">
						<h4 className="subtitle column latest-import-name side-panel-import-name">
							{this.props.importVersionEntity.name}
						</h4>
						{renderLastModified(this.props.importVersionEntity)}

						<div className="text-color">
							<h6 className="subtitle side-panel-content-section grey-font">ORIGINAL DATA</h6>
							<FileListPreview url={this.props.importVersionEntity?.originalFileId!} />
						</div>

						<div className="text-color">
							<h6 className="subtitle side-panel-content-section grey-font">LATEST IMPORTED FILES</h6>
							<div>
								<FileListPreview url={this.props.importVersionEntity?.backgroundImage.importedImageId!} />
							</div>
							{this.props.importVersionEntity?.terrainDataFileId
							&& (
								<div>
									<FileListPreview url={this.props.importVersionEntity?.terrainDataFileId!} />
								</div>
							)}
							{this.props.importVersionEntity?.ahsCurrentAreaDataFileId
							&& (
								<div>
									<FileListPreview url={this.props.importVersionEntity?.ahsCurrentAreaDataFileId!} />
								</div>
							)}
						</div>

						<div className="text-color">
							<h6 className="subtitle side-panel-content-section grey-font">PUBLISHED VERSIONS</h6>
							{this.props.importVersionEntity?.publishedVersionss.length > 0
							&& sortedPublishedVersions.map(publishedVersion => {
								return (
									<div key={publishedVersion.versionId} >
										<PublishedVersionsPreview publishVersions={publishedVersion} timezone={tz} />
									</div>
								);
							})}
							{this.props.importVersionEntity?.publishedVersionss.length <= 0
								&& <p className="side-panel-no-published txt-sm-body">No published versions</p>}
						</div>
					</div>				
				</div>
				<div className='archive-btn-div'>
					<Button
						className="archive-button"
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						onClick={this.props.onOpenModal}
						icon={{ icon: 'folder-copy', iconPos: 'icon-left' }}
					>
						Archive
					</Button>
				</div>
			</div>
		);
	}
}
