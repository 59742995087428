import MapObject from '../MapObject';
import MapRenderer from '../../MapRenderer';
import { LatLng, Point } from 'leaflet';
import * as PIXI from 'pixi.js';
import MapStore from '../../MapStore';
import { LinkEntity } from '../../../../../Models/Entities';
import { getLeafletLatLng, PixiCoordinates, pixiCoordinates } from '../../Helpers/Coordinates';
import { COLOR_SUPPORT_RED } from 'Constants';
import Speed from '../Speed/Speed';
import { calcHeadingPixi, offsetAlongHeadingPixi } from '../../Helpers/MapUtils';

const LINE_COLOUR = 0xFFFFFF;
const LINE_THICKNESS = 1.5;
const HIT_AREA_THICKNESS = 1;
const SPEEDLIMIT_DISTANCE_FROM_LINK = 30;
const SPEEDLIMIT_DEFAULT_ANGLE_FROM_LINK = 45;

/**
 * Render and style a link in Pixi
 */
export default class Link extends MapObject<PixiCoordinates[]> {
	private readonly _link: LinkEntity;
	public _isDisplaySpeedLimit?: boolean;
	public speed?: Speed;

	constructor(points: PixiCoordinates[], renderer: MapRenderer, link: LinkEntity, lookup?: MapStore) {
		super(renderer, 'link', points);
		lookup?.addEntityToMapObject(link.id, this);
		this.createGraphic();
		this._link = link;

		this.isError = this._link.getErrorCount() !== 0;
	}

	public get isDisplaySpeedLimit() {
		return this._isDisplaySpeedLimit === true;
	}

	/**
	 * Sets whether is display speed limit and adds/removes speed graphic
	 * Setter with side-effects to maintain consistency with the other scalable objects
	 * Speed as separate MapObject so that it can sit above other AHS objects as per requirements
	 */
	public set isDisplaySpeedLimit(isDisplayed: boolean) {
		if (this._isDisplaySpeedLimit !== isDisplayed) {
			// Only perform operation on state update
			if (isDisplayed) {
				if (!!this.speed) {
					console.warn("Speed object not found");
				}
				// Speed graphic visibility is coupled with link
				// Determine visibility by testing the first graphic of link
				const firstGraphic = this.getGraphic();
				if (!firstGraphic) {
					return;
				}
				if (!firstGraphic.visible) {
					return;
				}
				const points = this.getEntity();
				// Speed graphic is to be displayed at an offset from link to minimise overlapping with nodes
				const index = points.length > 2 ? Math.floor(points.length / 2) : 0;
				const nextIndex = index + 1;
				const pointA = points[index];
				// two points will use default angle
				let heading = SPEEDLIMIT_DEFAULT_ANGLE_FROM_LINK;
				if (nextIndex < points.length) {
					// Find midpoint of link and calc heading at that position
					const pointB = points[nextIndex];
					heading = calcHeadingPixi(pointA, pointB);
				}
				// Create speed graphic at determined position and tie it to link by adding as child
				const offset = offsetAlongHeadingPixi(SPEEDLIMIT_DISTANCE_FROM_LINK, heading);
				const pointC = pixiCoordinates(pointA.x + offset.x, pointA.y + offset.y);
				this.speed = new Speed(pointC, this.renderer, this._link);
				this.addChild(this.speed);
				this.renderer.addObject(this.speed, true);
			} else {
				if (!!this.speed) {
					this.removeChild(this.speed.getId());
					this.renderer.removeObject(this.speed.getId());
					this.speed = undefined;
				}
			}
		}
		this._isDisplaySpeedLimit = isDisplayed;
	}

	panToObject() {
		this.renderer.getMap().panTo(this.getCentrePoint());
	}

	getLinkEntity() {
		return this._link;
	}

	private getCentrePoint(): LatLng {
		return MapObject.getCentreOfPoints(this.getEntity(), this.renderer);
	}

	render() {
		const widthMultiplier = this.isHighlighted ? 2 : 1;
		const points = this.getEntity();
		const lineColor = this.isError ? COLOR_SUPPORT_RED : LINE_COLOUR;

		const graphic = this.getGraphic();
		graphic.clear();
		const lineOptions: PIXI.ILineStyleOptions = {
			width: LINE_THICKNESS * widthMultiplier,
			join: PIXI.LINE_JOIN.MITER,
			color: lineColor,
			alpha: 1,
			alignment: 0.5,
		}
		graphic.lineStyle(lineOptions);
		const polygon = new PIXI.Polygon(points);
		polygon.closeStroke = false;
		graphic.zIndex = this.isHighlighted ? this.zIndexTop : this.zIndexBase;
		graphic.drawPolygon(polygon);

		const allPoints = this.generateHitAreaFromLine(points, HIT_AREA_THICKNESS);
		graphic.hitArea = new PIXI.Polygon(allPoints);
	}

	public _updateScale(isReset: boolean) {
		if (!!this.speed) {
			this.speed._updateScale(isReset);
		}
	}
}
