import React, { useEffect, useState } from 'react';
import { ImportVersionEntity } from '../../../Models/Entities';
import MapEventHandler from '../Map/MapEventHandler';
import classNames from 'classnames';
import { ImportVersionStatus } from '../Map/MapController';

interface ImportVersionStatusIndicatorProps {
	importVersionEntity: ImportVersionEntity;
	eventHandler?: MapEventHandler;
}

export default function ImportVersionStatusIndicator(props: ImportVersionStatusIndicatorProps) {
	const { importVersionEntity, eventHandler } = props;
	const initialStatus = {
		areaEdited: importVersionEntity.areaEdited,
		bayEdited: importVersionEntity.bayEdited,
		pathEdited: importVersionEntity.pathEdited,
		areaPublished: importVersionEntity.areaPublished,
		bayPublished: importVersionEntity.bayPublished,
		pathPublished: importVersionEntity.pathPublished,
	};
	const [status, setStatus] = useState(initialStatus);

	useEffect(() => {
		eventHandler?.addListener('onImportVersionStatusChanged', handleStatusChanged);
		return () => {
			eventHandler?.removeListener('onImportVersionStatusChanged', handleStatusChanged);
		};
	});

	useEffect(() => {
		// rerender status if clicking on different importVersionEntity on all imports page.
	}, [importVersionEntity]);

	useEffect(() => {
		setStatus(() => ({
			areaEdited: importVersionEntity.areaEdited,
			bayEdited: importVersionEntity.bayEdited,
			pathEdited: importVersionEntity.pathEdited,
			areaPublished: importVersionEntity.areaPublished,
			bayPublished: importVersionEntity.bayPublished,
			pathPublished: importVersionEntity.pathPublished,
		}));
	}, [importVersionEntity.areaEdited,
		importVersionEntity.bayEdited,
		importVersionEntity.pathEdited,
		importVersionEntity.areaPublished,
		importVersionEntity.bayPublished,
		importVersionEntity.pathPublished]);

	/**
	 * Handle status changes
	 * @param mapObjectType
	 * @param editedOrPublished true means edited while false means published
	 */
	const handleStatusChanged = (mapObjectType: string | ImportVersionStatus, editedOrPublished: boolean) => {
		// typeof mapObjectType is ImportVersionStatus, coming from undo/redo actions
		if (typeof mapObjectType !== 'string') {
			setStatus(() => ({
				areaEdited: mapObjectType.areaEdited,
				bayEdited: mapObjectType.bayEdited,
				pathEdited: mapObjectType.pathEdited,
				areaPublished: mapObjectType.areaPublished,
				bayPublished: mapObjectType.bayPublished,
				pathPublished: mapObjectType.pathPublished,
			}));
			return;
		}

		// If typeof mapObjectType is string
		switch (mapObjectType) {
			case 'area':
				setStatus(prev => ({
					...prev,
					areaEdited: editedOrPublished,
					areaPublished: !editedOrPublished,
				}));
				break;

			case 'bay':
				setStatus(prev => ({
					...prev,
					bayEdited: editedOrPublished,
					bayPublished: !editedOrPublished,
				}));
				break;

			case 'path':
				setStatus(prev => ({
					...prev,
					pathEdited: editedOrPublished,
					pathPublished: !editedOrPublished,
				}));
				break;
		}
	};

	const getImportVersionStatus = () => {
		const mapObjectEdited = status.bayEdited || status.areaEdited || status.pathEdited;
		const areaOriginal = !status.areaEdited && !status.areaPublished;
		const bayOriginal = !status.bayEdited && !status.bayPublished;
		const pathOriginal = !status.pathEdited && !status.pathPublished;
		const mapObjectsNotEditedOrPublished = areaOriginal && bayOriginal && pathOriginal;
		const mapObjectPublished = (areaOriginal || status.areaPublished)
								&& (bayOriginal || status.bayPublished)
								&& (pathOriginal || status.pathPublished)
								&& !mapObjectsNotEditedOrPublished;

		if (mapObjectsNotEditedOrPublished) {
			return 'Original';
		}
		if (mapObjectPublished) {
			return 'Published';
		}
		if (mapObjectEdited) {
			return 'Unpublished';
		}
		return 'Original';
	};

	const getAreaStatus = () => {
		if (!status.areaEdited && status.areaPublished) {
			return 'published'
		} else if (status.areaEdited && !status.areaPublished) {
			return 'unpublished'
		} else {
			return ''
		}
	};
	const getBayStatus = () => {
		if (!status.bayEdited && status.bayPublished) {
			return 'published'
		} else if (status.bayEdited && !status.bayPublished) {
			return 'unpublished'
		} else {
			return ''
		}
	};
	const getPathStatus = () => {
		if (!status.pathEdited && status.pathPublished) {
			return 'published'
		} else if (status.pathEdited && !status.pathPublished) {
			return 'unpublished'
		} else {
			return ''
		}
	};

	return (
		<>
			<div className={classNames('version-status-label', getImportVersionStatus())}>
				{getImportVersionStatus()}
			</div>
			{
				getImportVersionStatus() === 'Unpublished' && (
					<div className="map-object-indicators">
						<div className={classNames('map-object-indicator', getAreaStatus())}
						> A
						</div>
						<div className={classNames('map-object-indicator', getBayStatus())}
						> B
						</div>
						<div className={classNames('map-object-indicator', getPathStatus())}
						> P
						</div>
					</div>
				)
			}
		</>
	);
}
