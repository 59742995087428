import { Point, IPointData } from 'pixi.js';
import MapRenderer from '../MapRenderer';
import { RealWorldCoordinates } from './Coordinates';
import _ from "lodash";

interface GeoJson {
	type: 'MultiPolygon' | 'Polygon' | 'Point',

	coordinates: Array<Array<[number, number]>>
}


export function getJsonObject(geoJson: any) {
	if (geoJson instanceof Object) {
		return _.cloneDeep(geoJson);
	}

	return JSON.parse(geoJson);
}

export default function geoJsonToPoints(geoJSON: string | object, renderer: MapRenderer,
	removeConsecutiveDuplicates: boolean = false): Point | Point[] | Point[][] {

	const json = getJsonObject(geoJSON);

	switch (json.type) {
		case 'MultiPolygon':
			return json.coordinates.map((c: number[][][]) => coordinatesToPoints(
				c[0], renderer, removeConsecutiveDuplicates,
			));
		case 'Polygon':
			return coordinatesToPoints(json.coordinates[0], renderer, removeConsecutiveDuplicates);
		case 'Point':
			return coordinateToPoint(json.coordinates, renderer);
		default:
			return new Point(0, 0);
	}
}

export function geoJson(geoJSON: string | object) {
	const json = getJsonObject(geoJSON);
	switch (json.type) {
		case 'MultiPolygon':
			return json.coordinates.map((c: number[][]) => toRealWorldCoordinates(c));
		case 'Polygon':
			return toRealWorldCoordinates(json.coordinates[0]);
		case 'Point':
			return toRealWorldCoordinates(json.coordinates);
		default:
			return new Point(0, 0);
	}
}

export function toRealWorldCoordinates(coordinates: number[][]): RealWorldCoordinates[] {
	return coordinates.map(([easting, northing]) => ({
		northing,
		easting
	}));
}

export function realWordCoordsToGeoJSONPoint(coordinates: RealWorldCoordinates) {
	return {
		type: 'Point',
		coordinates: [coordinates.easting, coordinates.northing],
	};
}

/**
 * Converts an array of pixi point data and to geojson polygon (with realworld coords)
 * @param pixiPoints
 * @param renderer
 * @returns json object of type Polygon
 */
 export function pixiPointsToGeoJSONPolygon(pixiPoints: IPointData[], renderer: MapRenderer) {
	const realWorldCoords = pixiPoints.map(x => {
		const { northing, easting } = renderer.getRealWorldCoords(renderer.unproject(x));
		return [easting, northing];
	});
	const geoJsonPolygonObject = {
		type: "Polygon",
		coordinates: [realWorldCoords],
	};
	return geoJsonPolygonObject;
}

export function geoJsonPointToRealWorldCoords(geoJson: any) {
	const json = getJsonObject(geoJson);
	return {
		northing: json.coordinates[1] as number,
		easting: json.coordinates[0] as number,
	};
}

function coordinatesToPoints(coordinates: number[][], renderer: MapRenderer,
	removeConsecutiveDuplicates: boolean = false): Point[] {
	if (removeConsecutiveDuplicates) {
		// When two consecutive points are the same, polygon is not rendered correctly
		// This code excludes such duplicates from the returned points
		const pointsNoConsecutiveDupes: Point[] = [];
		if (coordinates && coordinates.length > 0) {
			const start = coordinates[0];
			let point = renderer.project({
				northing: start[1],
				easting: start[0],
			});
			pointsNoConsecutiveDupes.push(new Point(point.x, point.y));
			for (let i = 1; i < coordinates.length; i++) {
				const previous = coordinates[i - 1];
				const current = coordinates[i];
				if (current[0] === previous[0] && current[1] === previous[1]) {
					console.log(`Skip duplicate point [${current[0]}:${current[1]}] at index ${i}`);
				} else {
					point = renderer.project({
						northing: current[1],
						easting: current[0],
					});
					pointsNoConsecutiveDupes.push(new Point(point.x, point.y));
				}
			}
		}
		return pointsNoConsecutiveDupes;
	}

	return coordinates.map((coordinate: number[]) => coordinateToPoint(coordinate, renderer));
}

function coordinateToPoint(coordinate: number[], renderer: MapRenderer): Point {
	const point = renderer.project({
		northing: coordinate[1],
		easting: coordinate[0],
	});
	return new Point(point.x, point.y);
}

function normalisePolygon(polygon: GeoJson | string | undefined): GeoJson | undefined {
	 if (polygon === undefined) {
		 return undefined;
	 }

	if (typeof polygon === 'string') {
		if (polygon.trim() === '') {
			return undefined;
		}

		return JSON.parse(polygon);
	}

	return polygon;
}

export function isSameShape(
		originalPolygon: GeoJson | string | undefined,
		newPolygon: GeoJson | string | undefined
	): boolean {
	 const _originalPolygon = normalisePolygon(originalPolygon);
	 const _newPolygon = normalisePolygon(newPolygon);

	 if (!_originalPolygon || !_newPolygon) {
		 return _originalPolygon === _newPolygon;
	 }

	 if (_originalPolygon.type !== _newPolygon.type && _originalPolygon.type.toLowerCase() !== 'polygon') {
		 return false;
	 }

	 const originalCoords = _originalPolygon.coordinates[0];
	 const newCoords = _newPolygon.coordinates[0];

	 if (originalCoords.length !== newCoords.length) {
		 return false;
	 }

	 for (let i = 0; i < originalCoords.length; i++) {
		 const [x1, y1] = originalCoords[i];
		 const [x2, y2] = newCoords[i];

		 if (x1 !== x2 || y1 !== y2) {
			 return false;
		 }
	 }

	 return true;
}