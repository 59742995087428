import { LinkEntity, NodeEntity, SignalSetEntity } from "Models/Entities";
import { MapRenderer } from "Views/MapComponents";
import { PixiCoordinates, realWorldCoordinates } from "../Helpers/Coordinates";
import { calcDistanceBetweenNodes, calcHeading, offsetAlongHeadingRealWorld } from "../Helpers/MapUtils";
import MapController from "../MapController";
import Signal from "../MapObjects/Signal/Signal";
import MapStore from "../MapStore";

export default class TurnSignalHelper {

	/**
	 * Get all node entities of a link for creating turn signal points afterwards
	 * 
	 * @param linkId
	 * @param lookup
	 */
	private static getLinkNodeEntities(linkId: string, lookup: MapStore): NodeEntity[] | undefined {
		const nodes: NodeEntity[] = [];
		let sublink = lookup.getFirstSublinkForLink(linkId);

		while (sublink) {
			let node = lookup.getFirstNodeForSublink(sublink.id);
			while(node) {
				nodes.push(node);
				node = lookup.getNextNodeById(node.id);
			}
			sublink = lookup.getNextSublinkById(sublink.id);
		}

		return nodes.length !== 0 ? nodes : undefined;
	}

	/**
	 * Get all node points with PixiCoordinates of a link for creating turn signal points afterwards
	 * 
	 * @param linkId
	 * @param controller 
	 */
	private static getLinkNodePixiCoordinates(linkId: string, controller: MapController): PixiCoordinates[] | undefined {
		const linkPoints: PixiCoordinates[] = [];
		const lookup = controller.getMapLookup();
		let sublink = lookup.getFirstSublinkForLink(linkId);

		while (sublink) {
			let node = lookup.getFirstNodeForSublink(sublink.id);
			while(node) {
				const nodePoint = controller.getMapRenderer().project(node);
				linkPoints.push(nodePoint);
				node = lookup.getNextNodeById(node.id);
			}
			sublink = lookup.getNextSublinkById(sublink.id);
		}

		return linkPoints.length !== 0 ? linkPoints : undefined;
	}

	/**
	 * Create a turn signal mapObject and add to pathObject
	 * 
	 * @param turnSignal
	 * @param controller
	 * @param {boolean} [isDisplay] - true to display
	 */
	public static createSignalMapObject(turnSignal: SignalSetEntity, controller: MapController, isDisplay?: boolean) {
		if (!!turnSignal.linkId) {
			const linkNodes = this.getLinkNodeEntities(turnSignal.linkId, controller.getMapLookup());
			const linkPoints = this.getLinkNodePixiCoordinates(turnSignal.linkId, controller);

			if(!linkNodes && !linkPoints) return undefined;

			const distSignalStartToLinkStart = turnSignal.signalStart;
			const signalLength = turnSignal.signalEnd - turnSignal.signalStart;

			if(!!linkNodes && !!linkPoints) {
				const renderer = controller.getMapRenderer();
				const lookup = controller.getMapLookup();
				const signalPoints = this.getSignalPoints(linkNodes, linkPoints, distSignalStartToLinkStart, signalLength, renderer);
				// Undo/Redo will de-select a link -> display signal according to signal ViewMenu status
				const isViewMenuShown = lookup.getIsViewMenuShown();
				const signalMapObject = new Signal(signalPoints, renderer, turnSignal, lookup, isDisplay ?? isViewMenuShown.signalSet);
				const linkMapObjectId = lookup.getMapObjectId(turnSignal.linkId, 'link');
				const pathObject = renderer.getObjectById(linkMapObjectId).getParent();
				pathObject?.addChild(signalMapObject);

				return signalMapObject;
			}
			return undefined;
		}
		return undefined;
	}

	/**
	 * Get signal points to create a turn signal mapObject.
	 */
	public static getSignalPoints(
		linkNodes: NodeEntity[],
		linkPoints: PixiCoordinates[],
		distSignalStartToLinkStart: number,
		signalLength: number,
		renderer: MapRenderer,
	) {
		const signalPoints: PixiCoordinates[] = [];
		let distFromPreviousLinkNodeToLinkStart = 0; // This is used for a signal falling between two nodes.
		let dist = 0; // The distance from the start node of the link to a node of the link (p1)
		let pos = 0;

		// find the start node of the signal
		for (let i = 0; i < linkNodes.length; i++) {
			pos = i + 1;
			const p1 = linkNodes[i];
			if (i < linkNodes.length - 1) {
				const p2 = linkNodes[i + 1];
				const _dist = calcDistanceBetweenNodes(p1, p2);
				// If "the distance from the start node of the link to p2" is longer than "the distance from the start node of the link to the start of the signal"
				// the start of the signal must fall in p1 and p2
				if (dist + _dist > distSignalStartToLinkStart) {
					const signalStart = this.getSignalPoint(p1, p2, distSignalStartToLinkStart - dist, renderer);
					signalPoints.push(signalStart);
					distFromPreviousLinkNodeToLinkStart = dist;
					dist += _dist;
					break;
				}
				dist += _dist;
			}
		}

		// find the nodes along with the link and the end node of the signal
		let distFromNextLinkNodeToSignalStartPoint = dist - distSignalStartToLinkStart;		
		// The whole signal falls between p1 and p2
		if (signalLength < distFromNextLinkNodeToSignalStartPoint) {
			// Signal(start)├───┤
			//        ───────────────────
			// Link     o           o    
			//        ───────────────────
			//          p1          p2   
			const distance = (distSignalStartToLinkStart + signalLength) - distFromPreviousLinkNodeToLinkStart;
			const signalEnd = this.getSignalPoint(linkNodes[pos - 1], linkNodes[pos], distance, renderer);
			signalPoints.push(signalEnd);

		} else {
			// Signal(start)├─────────────┤
			//        ───────────────────────────
			// Link     o           o        o
			//        ───────────────────────────
			//          p1          p2       p3
			let dist = distFromNextLinkNodeToSignalStartPoint; // The distance from the start point of the signal to a node of the link
			for (let i = pos; i < linkNodes.length; i++) {
				signalPoints.push(linkPoints[i]);
				const p2 = linkNodes[i];
				if (i < linkNodes.length - 1) {
					const p3 = linkNodes[i + 1];
					const _dist = calcDistanceBetweenNodes(p2, p3);
					// If "the distance from the start point of the signal to p3" is longer than "the signal length"
					// the end of the signal must fall in p2 and p3
					if (dist + _dist > signalLength) {
						const signalEnd = this.getSignalPoint(p2, p3, signalLength - dist, renderer);
						signalPoints.push(signalEnd);
						dist += _dist;
						break;
					}
					dist += _dist;
				}
			}
		}

		return signalPoints;
	}

	/**
	 * Get a signal start or end point between two nodes of a link.
	 */
	private static getSignalPoint(p1: NodeEntity, p2: NodeEntity, distance: number, renderer: MapRenderer): PixiCoordinates {
		const fromPoint = { northing: p1.northing, easting: p1.easting };
		const toPoint = { northing: p2.northing, easting: p2.easting };
		const headingDegrees = calcHeading(fromPoint, toPoint);
		const offset = offsetAlongHeadingRealWorld(distance, headingDegrees);
		const northing = p1.northing + offset.northing;
		const easting = p1.easting + offset.easting;
		return renderer.project(realWorldCoordinates(northing, easting));
	}

	/**
	 * Remove the old turn signal mapObject and create a new one
	 * 
	 * @param turnSignal
	 * @param controller
	 * @param isDisplay
	 */
	public static regenerateSignalMapObject(turnSignal: SignalSetEntity, controller: MapController, isDisplay: boolean) {
		const renderer = controller.getMapRenderer();
		const lookup = controller.getMapLookup();

		// remove old mapobject from container and lookup
		const oldSignalMapObject = lookup.getMapObjectByEntity(turnSignal, 'signal') as Signal;
		renderer.removeObject(oldSignalMapObject.getId());
		lookup.removeEntityToMapObject(turnSignal.id, 'signal');
		// remove old mapobject from path
		const linkEntity = lookup.getEntity(turnSignal.linkId!, LinkEntity);
		const pathObject = lookup.getMapObjectByEntity(linkEntity, 'link')?.getParent();
		pathObject?.removeChild(oldSignalMapObject.getId());
		
		// regenerate a new mapObject
		const newSignalMapObject = this.createSignalMapObject(turnSignal, controller, isDisplay);
		if (!!newSignalMapObject) {
			renderer.addObject(newSignalMapObject, true);
			renderer.rerender();
			controller.getEventHandler().emit('onUpdateSignal', newSignalMapObject);
		}	
	}

	/**
	 * Add a turn signal entity to a link entity
	 */
	public static addSignalToLinkEntity (turnSignal: SignalSetEntity, link: LinkEntity) {
		// We assume there is just one signal for each link
		// However, considering there are negative values in the database.
		// The first signal might be invalid and won't be shown in the link properties panel.
		// For not showing any signal, which means "truly" no signal associated to the link for a user,
		// the user can still add a signal, and it should be before invalid signal in the array.
		// TODO: check can be removed if there is no any invalid signal
		let inValidSignals = link.signalSetss.map(s => s.signalStart < 0 || s.signalEnd < 0);
		if (inValidSignals.length > 0) {
			link.signalSetss.unshift(turnSignal);
		} else {
			link.signalSetss.push(turnSignal);
		}
	}
}
