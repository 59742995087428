import { cloneDeep, isNumber } from 'lodash';
import { observable } from 'mobx';
import ImportVersionEntity from 'Models/Entities/ImportVersionEntity';
import { parametersOptions } from 'Models/Enums';
import { useEffect, useState } from 'react';
import { Button, Display } from 'Views/Components/Button/Button';
import Collection from 'Views/Components/Collection/Collection';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import Modal from 'Views/Components/Modal/Modal';
import {
	AHSAreaParameters,
	AHTParameters,
	AreaExtensionParameters,
	DrivableAreaBoundaryParameters,
	DrivingPermissionParameters,
	DumpParameters,
	DynamicPathParameters,
	ErrorsWarningsParameters,
	FMSMapParameters,
	GeneralPathParameters,
	IMapParameters,
	MapDisplayCoordsParameters,
	MIVParameters,
	SpeedParameters,
	StaticPathParameters,
	UncategorisedParameters
} from './MapParameters';

const paramsCollectionHeaders : Array<ICollectionHeaderProps<any>> = [
	{
		name: 'name',
		displayName: 'Name',
	},
	{
		name: 'description',
		displayName: 'Description',
	},
	{
		name: 'value',
		displayName: 'Value',
		transformItem: model => {
			if (model.name === 'NodeDistMax') {
				const value = model.value - 0.01;
				// In theory NodeDistMax could be a float but we don't know if it will ever have decimal places in the parameter files
				// because this parameter has never changed since the begining of AHS operation.
				if (Number(value.toFixed()) == Number(value.toFixed(2)) ) {
					return value.toFixed();
				} else {
					return value.toFixed(2);
				}
			} else {
				return model.value;
			}
		},
	},
	{
		name: 'unit',
		displayName: 'Unit',
		transformItem: model => {
			if (model.unit === "m-1") {
				return (
					<div>m<span className='superscript-unit'>-1</span></div>
				);
			} else {
				return model.unit;
			}
		},
	}
];

interface IMapParametersModalProps {
	title: string;
	importVersion: ImportVersionEntity;
    onCloseModal: () => void;
}

export function MapParametersModal(props: IMapParametersModalProps) {
	const { onCloseModal } = props;

	return (
		<Modal
			isOpen
			label="File export"
			onRequestClose={onCloseModal}
			className={"map-parameters-modal"}
			overlayClassName="map-file-upload-modal-overlay"
		>
			<RenderMapParametersModalContent {...props} />
		</Modal>
	);
}

function RenderMapParametersModalContent(props: IMapParametersModalProps) {
	const { title, importVersion, onCloseModal } = props;
	const initialState = observable({
		category: 'AHT',
	});
	const [ selectedParametersOption, setSelectedParametersOption ] = useState(initialState);
	const [ parameterList, setParameterList ] = useState<IMapParameters[]>([]);
	const parametersOptionsCombobox = Object
			.entries(parametersOptions)
			.map(([value, display]) => ({ display, value }));
	// Deep clone
	const [ ahtParameters, setAhtParameters ] = useState(cloneDeep(AHTParameters));
	const [ speedParameters, setSpeedParameters ] = useState(cloneDeep(SpeedParameters));
	const [ generalPathParameters, setGeneralPathParameters ] = useState(cloneDeep(GeneralPathParameters));
	const [ staticPathParameters, setStaticPathParameters ] = useState(cloneDeep(StaticPathParameters));
	const [ dynamicPathParameters, setDynamicPathParameters ] = useState(cloneDeep(DynamicPathParameters));
	const [ errorsWarningsParameters, setErrorsWarningsParameters ] = useState(cloneDeep(ErrorsWarningsParameters));
	const [ drivableAreaBoundaryParameters, setDrivableAreaBoundaryParameters ] = useState(cloneDeep(DrivableAreaBoundaryParameters));
	const [ drivingPermissionParameters, setDrivingPermissionParameters ] = useState(cloneDeep(DrivingPermissionParameters));
	const [ dumpParameters, setDumpParameters ] = useState(cloneDeep(DumpParameters));
	const [ ahsAreaParameters, setAhsAreaParameters ] = useState(cloneDeep(AHSAreaParameters));
	const [ areaExtensionParameters, setAreaExtensionParameters ] = useState(cloneDeep(AreaExtensionParameters));
	const [ fmsMapParameters, setFmsMapParameters ] = useState(cloneDeep(FMSMapParameters));
	const [ mapDisplayCoordsParameters, setMapDisplayCoordsParameters ] = useState(cloneDeep(MapDisplayCoordsParameters));
	const [ mivParameters, setMivParameters ] = useState(cloneDeep(MIVParameters));
	const [ uncategorisedParameters, setUncategorisedParameters ] = useState([...UncategorisedParameters]);

	useEffect(() => {
		const maptoolparam = Object.entries(importVersion.maptoolparam as object).filter(([param, value]) => isNumber(value));

		if (!!maptoolparam) {
			maptoolparam.forEach(param => {
				let hasCategory = false;
				let index = ahtParameters.findIndex(_param => _param.name.replaceAll("_", "").toLowerCase() === param[0].toLowerCase())
				if (index > -1) {
					hasCategory = true;
					ahtParameters[index].value = param[1];
				}

				index = speedParameters.findIndex(_param => _param.name.replaceAll("_", "").toLowerCase() === param[0].toLowerCase())
				if (index > -1) {
					hasCategory = true;
					speedParameters[index].value = param[1];
				}

				index = generalPathParameters.findIndex(_param => _param.name.replaceAll("_", "").toLowerCase() === param[0].toLowerCase())
				if (index > -1) {
					hasCategory = true;
					generalPathParameters[index].value = param[1];
				}

				index = staticPathParameters.findIndex(_param => _param.name.replaceAll("_", "").toLowerCase() === param[0].toLowerCase())
				if (index > -1) {
					hasCategory = true;
					staticPathParameters[index].value = param[1];
				}

				index = dynamicPathParameters.findIndex(_param => _param.name.replaceAll("_", "").toLowerCase() === param[0].toLowerCase())
				if (index > -1) {
					hasCategory = true;
					dynamicPathParameters[index].value = param[1];
				}

				index = errorsWarningsParameters.findIndex(_param => _param.name.replaceAll("_", "").toLowerCase() === param[0].toLowerCase())
				if (index > -1) {
					hasCategory = true;
					errorsWarningsParameters[index].value = param[1];
				}

				index = drivableAreaBoundaryParameters.findIndex(_param => _param.name.replaceAll("_", "").toLowerCase() === param[0].toLowerCase())
				if (index > -1) {
					hasCategory = true;
					drivableAreaBoundaryParameters[index].value = param[1] ? "True" : "False";
				}

				index = drivingPermissionParameters.findIndex(_param => _param.name.replaceAll("_", "").toLowerCase() === param[0].toLowerCase())
				if (index > -1) {
					hasCategory = true;
					if (param[0].toLowerCase() === 'CheckBlockControl'.toLowerCase()) {
						drivingPermissionParameters[index].value = param[1] ? "True" : "False";
					} else {
						drivingPermissionParameters[index].value = param[1];
					}
				}

				index = dumpParameters.findIndex(_param => _param.name.replaceAll("_", "").toLowerCase() === param[0].toLowerCase())
				if (index > -1) {
					hasCategory = true;
					dumpParameters[index].value = param[1];
				}

				index = ahsAreaParameters.findIndex(_param => _param.name.replaceAll("_", "").toLowerCase() === param[0].toLowerCase())
				if (index > -1) {
					hasCategory = true;
					ahsAreaParameters[index].value = param[1];
				}

				index = areaExtensionParameters.findIndex(_param => _param.name.replaceAll("_", "").toLowerCase() === param[0].toLowerCase())
				if (index > -1) {
					hasCategory = true;
					areaExtensionParameters[index].value = param[1];
				}

				index = fmsMapParameters.findIndex(_param => _param.name.replaceAll("_", "").toLowerCase() === param[0].toLowerCase())
				if (index > -1) {
					hasCategory = true;
					fmsMapParameters[index].value = param[1];
				}

				index = mapDisplayCoordsParameters.findIndex(_param => _param.name.replaceAll("_", "").toLowerCase() === param[0].toLowerCase())
				if (index > -1) {
					hasCategory = true;
					mapDisplayCoordsParameters[index].value = param[1];
				}

				index = mivParameters.findIndex(_param => _param.name.replaceAll("_", "").toLowerCase() === param[0].toLowerCase())
				if (index > -1) {
					hasCategory = true;
					mivParameters[index].value = param[1];
				}

				if (!hasCategory) {
					// Add an uncategorised category for all parameters from other MAT versions that don’t exist in any pre-configured category.
					const uncategorisedParam: IMapParameters = {
						name: param[0],
						description: "",
						value: param[1],
						unit: ""
					};
					uncategorisedParameters.push(uncategorisedParam);
				}
			});

			// don't display the non-existing parameters in the category list
			setAhtParameters(ahtParameters.filter(item => item.value !== undefined));
			setSpeedParameters(speedParameters.filter(item => item.value !== undefined));
			setGeneralPathParameters(generalPathParameters.filter(item => item.value !== undefined));
			setStaticPathParameters(staticPathParameters.filter(item => item.value !== undefined));
			setDynamicPathParameters(dynamicPathParameters.filter(item => item.value !== undefined));
			setErrorsWarningsParameters(errorsWarningsParameters.filter(item => item.value !== undefined));
			setDrivableAreaBoundaryParameters(drivableAreaBoundaryParameters.filter(item => item.value !== undefined));
			setDrivingPermissionParameters(drivingPermissionParameters.filter(item => item.value !== undefined));
			setDumpParameters(dumpParameters.filter(item => item.value !== undefined));
			setAhsAreaParameters(ahsAreaParameters.filter(item => item.value !== undefined));
			setAreaExtensionParameters(areaExtensionParameters.filter(item => item.value !== undefined));
			setFmsMapParameters(fmsMapParameters.filter(item => item.value !== undefined));
			setMapDisplayCoordsParameters(mapDisplayCoordsParameters.filter(item => item.value !== undefined));
			setMivParameters(mivParameters.filter(item => item.value !== undefined));

		}
		setParameterList(ahtParameters.filter(item => item.value !== undefined));
	},[]);


	const onAfterDropdownChange = () => {
		switch (selectedParametersOption.category) {
			case 'AHT':
				setParameterList(ahtParameters);
				break;
			case 'SPEED':
				setParameterList(speedParameters);
				break;
			case 'GENERALPATH':
				setParameterList(generalPathParameters);
				break;
			case 'STATICPATH':
				setParameterList(staticPathParameters);
				break;
			case 'DYNAMICPATH':
				setParameterList(dynamicPathParameters);
				break;
			case 'ERRORSWARNINGS':
				setParameterList(errorsWarningsParameters);
				break;
			case 'DRIVABLEAREABOUNDARY':
				setParameterList(drivableAreaBoundaryParameters);
				break;
			case 'DRIVINGPERMISSION':
				setParameterList(drivingPermissionParameters);
				break;
			case 'DUMP':
				setParameterList(dumpParameters);
				break;
			case 'AHSAREA':
				setParameterList(ahsAreaParameters);
				break;
			case 'AREAEXTENSION':
				setParameterList(areaExtensionParameters);
				break;
			case 'FMSMAP':
				setParameterList(fmsMapParameters);
				break;
			case 'MAPDISPLAYCOORDS':
				setParameterList(mapDisplayCoordsParameters);
				break;
			case 'MIV':
				setParameterList(mivParameters);
				break;			
			case 'UNCATEGORISED':
				setParameterList(uncategorisedParameters);
				break;
		}
	};

	return (
		<>
			<div className="modal__header">
				<h3>{title}</h3>
				<Button
					icon={{ icon: 'cross', iconPos: 'icon-right' }}
					className="cancel-button no-background"
					display={Display.Text}
					onClick={onCloseModal}
					labelVisible={false}
				/>
			</div>
			<div className="map-parameters-modal-content">
				<Combobox
					model={selectedParametersOption}
					options={parametersOptionsCombobox}
					modelProperty="category"
					label="Parameter list"
					labelVisible={true}
					placeholder="Select existing background image"
					onAfterChange={onAfterDropdownChange}
				/>
				<div className="parameters-list-container">
					<Collection
						className="parameters"
						collection={parameterList}
						headers={paramsCollectionHeaders}
						hidePagination={true}
						perPage={100}
					></Collection>
				</div>
			</div>
		</>
	);
}

