import { TypedEmitter } from 'tiny-typed-emitter';
import { ToolbarEvent } from '../MapToolbar/Toolbar';
import MapRenderer from './MapRenderer';
import MapController, {ImportVersionStatus, MapType} from './MapController';
import {
	LeafletCoordinates, realWorldCoordinates, RealWorldCoordinates,
} from './Helpers/Coordinates';
import MapStateHandler from './MapStateHandlers/MapStateHandler';
import ConnectivityToolHandler from './MapStateHandlers/ConnectivityToolHandler';
import checkForGlobalEvents, {keyLifted} from './MapStateHandlers/MapGlobalEventHandler';
import BayToolHandler from './MapStateHandlers/BayToolHandler';
import SelectToolHandler from './MapStateHandlers/SelectToolHandler';
import BayEditHandler from './MapStateHandlers/BayEditHandler';
import AreaEditHandler, {IAreaNodeLocation} from './MapStateHandlers/AreaEditHandler';
import AreaToolHandler from './MapStateHandlers/AreaToolHandler';
import RulerToolHandler from './MapStateHandlers/RulerToolHandler';
import { LeafletMouseEvent } from 'leaflet';
import { IRightClickContextMenuOptions } from '../RightClickContextMenu';
import { Model } from 'Models/Model';
import alertToast from 'Util/ToastifyUtils';
import { MapObjectType } from './MapObjects/MapObject';
import { IMenuShownStatus } from '../EditMap';
import { IPropertiesPanelParams } from '../MapProperties/PropertiesSidePanel';
import RulerEditHandler from './MapStateHandlers/RulerEditHandler';
import MapDebugHelper from "./Helpers/MapDebugHelper";
import PathToolHandler from "./MapStateHandlers/PathTool/PathToolHandler";
import PathEditHandler from "./MapStateHandlers/PathTool/PathEditHandler";
import ConfirmedPathEditHandler from "./MapStateHandlers/PathTool/ConfirmedPathEditHandler";
import ConfirmedPathSelectHandler from "./MapStateHandlers/PathTool/ConfirmedPathSelectHandler";
import Signal from "./MapObjects/Signal/Signal";

export interface MapEvents {
	// show item in properties panel
	onPropertiesPanel: (type: MapType, entity?: unknown, params?: IPropertiesPanelParams) => void;
	
	onZoomChange: (zoomLevel: number) => void;
	setActiveTool: (tool: ToolbarEvent) => void;
	onAreaStateChange: (areaNodeLocation: IAreaNodeLocation, isEditable?: boolean) => void;
	onBackToSelector: (type: MapType, entity: unknown, unselectHandler?: () => void) => void;
	// Used for when properties validation interacts with state handler
	onPropertiesUpdate: (prop?: any) => void;
	// Request an update from the tool that is active
	requestUpdate: (entity?: unknown) => void;

	onCustomContextMenu: (contextMenuOptions: IRightClickContextMenuOptions[], event: LeafletMouseEvent) => void;
	onMapObjectCreateConfirm: (object: unknown) => void;
	onMapObjectDelete: (entity: Model) => void;
	onMapObjectUpdate: (object: unknown, entityId?: string) => void;
	onErrorCountUpdate: (newCount: number) => void;

	// Enable confirm button on the toolbar
	toggleConfirmCreation: (showConfirmButton: boolean, isDisabled?: boolean) => void;
	onConfirmMapObjectCreation: (isFromPropertiesPanel?: boolean) => void;

	// Obj selection
	onMapObjectSelectedInMap: (entity: Model | undefined, mapObjectType?: MapObjectType) => void;

	onUpdateMapObjectsPanel: (entityId?: string, entityType?: MapObjectType) => void;

	onItemSelectedInLayersPanel: (entityId?: string, entityMapType?: string) => void;
	onToggleItemsFromViewMenu: (type: MapObjectType, isViewMenuShown: IMenuShownStatus, checkItems: boolean) => void;

	// Auto-save events
	onAutoSave: (isSaved: boolean, saveTimeStamp?: boolean | Date) => void;

	onCursorCoordsChange: (location: RealWorldCoordinates) => void;
	onShiftCursorLocationPanel: (displayPropertiesPanel: boolean) => void;
	onUpdateMeasuredLength: (realCoordsList: RealWorldCoordinates[]) => void;

	onUpdateSignal: (signalSetMapObject: Signal) => void;
	onImportVersionStatusChanged: (mapObjectType: string | ImportVersionStatus, editedOrPublished: boolean, entityTypes: string[]) => void;
}

// Event handler states that can't be reached through the toolbar
export type EventMode = ToolbarEvent |
	'edit_bay' |
	'edit_path' |
	'edit_confirmed_path' |
	'select_confirmed_path' |
	'edit_area' |
	'edit_ruler';

// Distance the mouse moves before we consider it a drag & drop
const DRAG_DELTA = 0.01;

/**
 * Event handler class which is responsible for
 * - Defining leaflet map events
 * - Defining pixi events
 * - Emitting events
 * - Setting map mode (eg. drawing clothoid)
 */
export default class MapEventHandler extends TypedEmitter<MapEvents> {

	// model handlers map an event mode to state handler
	private mapModeHandlers: { [key in EventMode]: { new(eventHandler: MapEventHandler, controller: MapController): MapStateHandler<any> } | undefined } = {
		// Undo/Redo are always actions and not states of the map
		undo: undefined,
		redo: undefined,

		ruler: RulerToolHandler, // toolbarEvent
		edit_ruler: RulerEditHandler,

		selector: SelectToolHandler, // toolbarEvent

		connection: ConnectivityToolHandler, // toolbarEvent

		bay: BayToolHandler, // toolbarEvent
		edit_bay: BayEditHandler,

		area: AreaToolHandler, // toolbarEvent
		edit_area: AreaEditHandler,

		path: PathToolHandler,
		edit_path: PathEditHandler,
		select_confirmed_path: ConfirmedPathSelectHandler,
		edit_confirmed_path: ConfirmedPathEditHandler,
	};

	private readonly controller: MapController;
	public readonly renderer: MapRenderer;

	private currentStateHandler: EventMode;
	private previousStateHandler: EventMode; // Some states depend on the previous state
	// If state updates are made too quickly we can prevent the intermediate state from being created
	private stateUpdateTimeout: ReturnType<typeof setTimeout> | undefined;
	public isConfirmableActionPending: boolean = false;

	private stateHandler: MapStateHandler<any> | undefined;

	private mouseDownCoordinates: LeafletCoordinates | undefined;
	private isIgnoreKeyEvent: boolean = false;
	private isDragging: boolean = false;

	private isToSelector: boolean = false;

	private isContextMenuOpen: boolean = false;
	// private isSpaceBarPressed: boolean = false;

	private manualSetClickDelay = false;
	private singleClickTimer : 0 | any = 0;
	private singleClickDelay = 0;

	private debugModeEnabled = false;
	private debugHelper: MapDebugHelper;

	private ignoreDispose: boolean = false;

	constructor(controller: MapController) {
		super();

		this.renderer = controller.getMapRenderer();
		this.debugHelper = new MapDebugHelper(this);
		this.controller = controller;
		// Set the default state
		this.setMapEventState('selector');
		// this.undoRedo = new UndoRedoEventHandlers(this);
	}

	public setToSelector(isToSelector: boolean) {
		this.isToSelector = isToSelector;
	}

	/**
	 * 
	 * @param tool tool to select and corresponding event handler
	 * @param initialState 
	 */
	public setActiveTool(tool: ToolbarEvent, initialState?: any) {
		// Highlight tool on toolbar and set state variable
		this.emit('setActiveTool', tool);
		// Set state handler (note that ToolbarEvent is a subset of EventMode)
		this.setMapEventState(tool, initialState);
	}

	public getToSelector() {
		return this.isToSelector;
	}

	public getEventMode() {
		return this.currentStateHandler;
	}

	public getMap() {
		return this.renderer.getMap();
	}

	public getRenderer() {
		return this.renderer;
	}

	public getLookup() {
		return this.controller.getMapLookup();
	}

	public getController() {
		return this.controller;
	}

	public getPreviousState() {
		return this.previousStateHandler;
	}

	public openContextMenu() {
		this.isContextMenuOpen = true;
	}

	public closeContextMenu() {
		this.isContextMenuOpen = false;
	}

	public setDebugMode(enableDebugMode: boolean) {
		this.debugModeEnabled = enableDebugMode;
	}

	public isDebugMode() {
		return this.debugModeEnabled;
	}

	public async setMapEventState(newState: EventMode, initialState?: unknown) {
		// Track how we got to this state
		this.previousStateHandler = this.currentStateHandler;

		this.currentStateHandler = newState;

		if (!!this.stateUpdateTimeout) {
			// We don't want to call the update state method twice in a row
			clearTimeout(this.stateUpdateTimeout);
		}

		// Run the state handlers dispose and init after the ui has been updated
		this.stateUpdateTimeout = setTimeout(() => {
			this.updateStateHandler(initialState);

			// Reset the timeout after the state has been updated
			this.stateUpdateTimeout = undefined;
		}, 10);
	}
	
	public startListeningKeyDown() {
		document.addEventListener('keydown', this.onKeyDown); //HITMAT-1809
	}
	
	public stopListeningKeyDown() {
		document.removeEventListener('keydown', this.onKeyDown); //HITMAT-1809
	}
	
	public startListening() {
		const map = this.renderer.getMap();

		// Map events
		map.on('mouseup', this.onMouseUp);
		map.on('mousedown', this.onMouseDown);
		map.on('mousemove', this.onMouseMove);
		map.on('zoomend', this.onZoomEnd);

		document.addEventListener('keydown', this.onKeyDown);
		document.addEventListener('keyup', this.onKeyUp);
		this.addListener('requestUpdate', this.onRequestUpdate);
		this.addListener('onBackToSelector', this.onBackToSelector);
		this.addListener('toggleConfirmCreation', this.onToggleConfirmCreation);
		this.addListener('onConfirmMapObjectCreation', this.onConfirm);

		document.querySelector('.leaflet-map-pane')?.addEventListener('contextmenu', e => {
			e.preventDefault();
			e.stopPropagation();
		});
	}

	/**
	 * It's necessary to keep track of whether there are confirmable actions pending
	 * as such changes will be deleted on undo
	 * @param showConfirmButton
	 * @param isDisabled
	 */
	public onToggleConfirmCreation(showConfirmButton: boolean, isDisabled?: boolean) {
		this.isConfirmableActionPending = showConfirmButton;
	}

	/**
	 * Handles logic where user was working in tool other than selector and then
	 * performs action where a map object is selected and the selector tool is activated.
	 * For example, user is in Clothoid/Bay tool and then clicks on item in layers panel
	 * (item in selected via layers will be highlighed and shown in properties with selector tool active)
	 * @param type
	 * @param entity
	 * @param unselectHandler
	 */
	onBackToSelector = (type: MapType, entity: unknown, unselectHandler?: () => void) => {
		this.setToSelector(true);
		this.setActiveTool('selector', { mapObject: entity, unselectHandler });
	}

	public stopListening() {
		const map = this.renderer.getMap();

		map.off();

		document.querySelector('.leaflet-map-pane')?.removeEventListener('contextmenu', e => {
			e.preventDefault();
			e.stopPropagation();
		});
		document.removeEventListener('keydown', this.onKeyDown);
		document.removeEventListener('keyup', this.onKeyUp);

		this.removeListener('requestUpdate', this.onRequestUpdate);
		this.removeListener('onBackToSelector', this.onBackToSelector);
		this.removeListener('toggleConfirmCreation', this.onToggleConfirmCreation);
	}

	/**
	 * Logic to determine the type of mouse event (for state handlers) and call the
	 * appropriate method (onDragEnd, onDoubleClick, onClick)
	 * Ignored if panning is in progress
	 * @param event
	 * @returns
	 */
	private onMouseUp = (event: L.LeafletMouseEvent) => {
		if (this.isIgnoreKeyEvent) {
			this.isIgnoreKeyEvent = false;
		}
		if (event.originalEvent.button === 2) {
			this.getStateHandler()?.onRightClick(event);
			return; // Ignore right click
		}
		if (this.isContextMenuOpen) {
			// Fix after click away for the context menu
			// clicking on the map again cannot deselect link issue or
			// double-clicking on the map enters into clothoid path edit mode issue
			this.mouseDownCoordinates = undefined;
			return;
		}
		this.mouseDownCoordinates = undefined;
		this.renderer.mousePosition = this.renderer.getRealWorldCoords(event.latlng);
		if (this.getMap().dragging.enabled()) {
			return; // Don't trigger anything
		}

		if (this.isDragging) {
			this.onDragEnd(event);
			return;
		}
		if (event.originalEvent.detail > 2) {
			return;
		}
		if (event.originalEvent.detail === 2) {
			clearTimeout(this.singleClickTimer);

			this.getStateHandler()?.onDoubleClick(event);
		} else {
			this.singleClickTimer = setTimeout(() => this.singleClickAction(event), this.singleClickDelay);
		}
	}

	private singleClickAction = (event: L.LeafletMouseEvent) => {
		// diagnostic info to check coordinates of objects
		const { northing, easting } = this.renderer.mousePosition;
		this.renderer.prevClickPos = realWorldCoordinates(this.renderer.clickPos.northing, this.renderer.clickPos.easting);
		this.renderer.clickPos = realWorldCoordinates(northing, easting);

		console.log(`Easting: ${easting} Northing: ${northing}`);

		this.getStateHandler()?.onClick(event);
	}

	/**
	 * Trigger onDragStart only if mouse has been moved by DRAG_DELTA
	 * @param event
	 * @returns
	 */
	private onMouseMove = (event: L.LeafletMouseEvent) => {
		if (event.originalEvent.button === 2) {
			return; // Ignore right click
		}

		this.renderer.mousePosition = this.renderer.getRealWorldCoords(event.latlng);

		this.getStateHandler()?.getLatLng(event);

		if (this.getMap().dragging.enabled()) {
			return;
		}
		if (!!this.mouseDownCoordinates && !this.isDragging) {
			// Calculate the delta
			const { lng, lat } = event.latlng;

			const deltaX = Math.abs(this.mouseDownCoordinates.lng - lng);
			const deltaY = Math.abs(this.mouseDownCoordinates.lat - lat);

			if (deltaX > DRAG_DELTA || deltaY > DRAG_DELTA) {
				this.onDragStart(event);
				return;
			}
		}

		if (this.isDragging) {
			event.originalEvent.preventDefault();

			this.onDragMove(event);
		} else {
			this.getStateHandler()?.onMove(event);
		}
	}

	/**
	 * Record mouse position on initial press for use in onDragStart
	 * @param event
	 */
	private onMouseDown = (event: L.LeafletMouseEvent) => {
		if (event.originalEvent.button === 2) {
			return; // Ignore right click
		}

		this.isIgnoreKeyEvent = true;

		this.debugHelper.addDebugPointFromMouseLocation(event);

		this.renderer.mousePosition = this.renderer.getRealWorldCoords(event.latlng);
		this.mouseDownCoordinates = event.latlng;
	}

	/**
	 * Trigger dragStart on statehandler and pass in mouseDownCoordinates
	 * @param event
	 * @returns
	 */
	private onDragStart = (event: L.LeafletMouseEvent) => {
		this.isDragging = true;

		if (!this.mouseDownCoordinates) {
			return;
		}

		this.debugHelper.addDebugLineFromMouseLocation(event);

		this.getStateHandler()?.onDragStart(event, this.mouseDownCoordinates);
	}

	private onDragMove = (event: L.LeafletMouseEvent) => {
		this.debugHelper.updateDebugLineFromMouseLocation(event);
		this.getStateHandler()?.onDragMove(event);
	}
	
	private onDragEnd = async (event: L.LeafletMouseEvent) => {
		this.isDragging = false;
		this.debugHelper.addDebugLineFromMouseLocation(event);
		try {
			await this.getStateHandler()?.onDragEnd(event);
		} catch (err) {
			const errorMessage: string = (err as Error).message;
			console.error(`onDragEnd error: ${errorMessage}`);
			alertToast(errorMessage, 'error');
		}
	}

	/**
	 * Process keydown events. Enable map panning if space key is pressed
	 * Ignore if any html element is selected
	 * @param event
	 * @returns
	 */
	private onKeyDown = (event: KeyboardEvent) => {
		if (this.getLookup().confirmButtonConfirming) { //HITMAT-1809
			return;
		}
		if (!!event.target && event.target instanceof HTMLInputElement) {
			checkForGlobalEvents(event, this);
			return;
		}

		if (!this.isDragging && event.key === ' ' && !this.getMap().dragging.enabled()) {
			this.getMap().dragging.enable();
			return;
		}
		checkForGlobalEvents(event, this);
	}

	/**
	 * Disable panning of map if space is released
	 * Call escape key handler if escape key is released
	 * Call default keypress handler for any other key release
	 * @param event
	 * @returns
	 */
	private onKeyUp = (event: KeyboardEvent) => {
		// Track when a key is lifted for global events
		keyLifted(event);

		if (event.key === ' ' && this.getMap().dragging.enabled()) {
			this.getMap().dragging.disable();
			// this.isSpaceBarPressed = false;
			return;
		}

		if (this.isIgnoreKeyEvent) {
			return;
		}

		if (!!event.target && event.target instanceof HTMLInputElement) {
			return;
		}

		if (event.key === 'Escape') {
			this.getStateHandler()?.onEscapePressed(event);
			return;
		}

		this.getStateHandler()?.onKeyPress(event);
	}

	/**
	 * At the end of each zoom operation, reculculate map bound
	 * and emit onZoomChange to update zoom value on toolbar
	 * @param event
	 */
	private onZoomEnd = (event: L.LeafletEvent) => {
		const zoomLevel = this.getMap().getZoom();
		this.emit('onZoomChange', zoomLevel);
	}

	/**
	 * Event handler for requestUpdate
	 */
	private onRequestUpdate = (entity?: unknown) => {
		this.getStateHandler()?.onRequestUpdate(entity);
	}

	private onConfirm = () => {
		this.getStateHandler()?.onConfirm();
	}

	/**
	 * Cleanup previous state handler and call init of new state handler
	 * @param initialState
	 */
	private async updateStateHandler<T>(initialState?: T) {
		const StateHandlerClass = this.mapModeHandlers[this.currentStateHandler];

		// Run the cleanup method of the old state handler
		if (!!this.stateHandler && this.stateHandler.isInitialized) {
			this.stateHandler.dispose();
			this.stateHandler.isInitialized = false;
		}

		this.stateHandler = !!StateHandlerClass ? new StateHandlerClass(this, this.controller) : undefined;
		console.log(`Changing state to ${this.stateHandler?.getEventHandler().getEventMode()}`);
		if (!!this.stateHandler) {
			this.stateHandler?.onInit(initialState);
			this.stateHandler.isInitialized = true;
		}

		// Skip setting the delay if it's been manually set
		if (this.manualSetClickDelay) return;

		if (this.getStateHandler() instanceof BayToolHandler ||
			this.getStateHandler() instanceof BayEditHandler)
		{
			this.singleClickDelay = 200;
		} else {
			this.singleClickDelay = 0;
		}
	}

	// Set the delay for when a single click will become a double click
	// This will degrade the interface responsiveness
	public updateSingleClickDelay(delay: number) {
		this.manualSetClickDelay = delay !== 0;
		this.singleClickDelay = delay;
	}

	public getStateHandler() {
		return this.stateHandler;
	}

	public get isDraggingMouse() {
		return this.isDragging;
	}

	public isEnter(event: KeyboardEvent) {
		return event.key === 'Enter';
	}

	private static BACKSPACE_KEYS = ['Backspace', 'Delete'];
	public isBackspace(event: KeyboardEvent) {
		return MapEventHandler.BACKSPACE_KEYS.includes(event.key);
	}

	public get isIgnoreDispose() {
		return this.ignoreDispose;
	}
	public set isIgnoreDispose(value: boolean) {
		this.ignoreDispose = value;
	}
}
