import {
	Area, Bay, MapController, Sublink, DrivingZone,
	MapRenderer,
	MapLookup,
} from 'Views/MapComponents';
import { PixiCoordinates } from '../Helpers/Coordinates';
import * as PIXI from 'pixi.js';
import DrivingArea from '../MapObjects/Area/DrivingArea';
import alertToast from '../../../../Util/ToastifyUtils';
import MapValidator from './MapValidator';
import {
	AreaEntity, BayEntity, DrivingAreaEntity, MapObjectWarningsEntity, NodeEntity, SublinkEntity,
} from '../../../../Models/Entities';
import axios, {AxiosError} from 'axios';
import {SERVER_URL} from '../../../../Constants';
import PathValidator from "./PathValidator";
import L from 'leaflet';
export const AREA_ERROR_MAP_BOUNDARY = 'This operation is not allowed. An Area Node must remain within the allowed boundary.';
export const AREA_ERROR_SELF_INTERSECT = 'This operation is not allowed. An area cannot be self-intersecting.';
// eslint-disable-next-line max-len
export const AREA_ERROR_NON_DRIVABLE = 'This operation is not allowed. An Autonomous Area cannot intersect with a non drivable area.';
// eslint-disable-next-line max-len
export const AREA_ERROR_AUTONOMOUS_INTERSECT = 'This operation is not allowed. An Autonomous Area cannot intersect with another Autonomous Area.';
export const AREA_ERROR_BAYS = 'This operation is not allowed. Bays must remain inside their Autonomous Area.';

export const AREA_ERROR_ORIGINAL_DATA = 'Areas from the original map data cannot be deleted.';
// eslint-disable-next-line max-len
export const AREA_ERROR_MINIMUM_NODES_CLOSE = 'This operation is not allowed. An Area needs at least 3 nodes to be closed.';
export const AREA_ERROR_MINIMUM_NODES_DELETE = 'Area node cannot be deleted. An area must contain at least 3 nodes.';
export const AREA_ERROR_MAXIMUM_NODES = 'This operation is not allowed. An area must contain less than 1000 nodes.';
export const MAXIMUM_AREA_NODES = 1000;

interface IActiveNodeEdges {
	startLineA: PIXI.IPointData;
	endLineA: PIXI.IPointData;
	startLineB: PIXI.IPointData;
	endLineB: PIXI.IPointData;
}

export default class AreaValidator extends MapValidator {
	private areaMapObject: Area;
	private originalSelectedPoint: PixiCoordinates;
	private selectPointIndex: number = -1;

	/**
	 * Check if location is valid for area node
	 * @param coords
	 * @param areaMapObject
	 * @param originalSelectedPoint
	 * @param isClosingPoint
	 * @returns empty string upon success or err message on failure
	 */
	public validateLocation(coords: L.LatLng, areaMapObject: Area, originalSelectedPoint: PixiCoordinates, isClosingPoint: boolean = false): string {
		const isClosed = areaMapObject.isClosed();
		this.areaMapObject = areaMapObject;
		this.originalSelectedPoint = originalSelectedPoint;
		const points = areaMapObject.getPoints();

		const isFirstPoint = points.length === 1;

		if (isClosed) {
			if (this.areaMapObject.selectPointIndex === -1) {
				// we're checking the last point to be placed. Since last point is duplicated it's len - 2
				this.selectPointIndex = points.length - 2;
			} else {
				this.selectPointIndex = this.areaMapObject.selectPointIndex;
			}
		} else {
			// Open shape. take last point as selected index
			this.selectPointIndex = points.length - 1;
		}

		if (!this.checkMapBounds(coords)) {
			return AREA_ERROR_MAP_BOUNDARY;
		}

		if (!isFirstPoint) { // skip check for first point
			const selfIntersectionCheck = isClosed ? this.checkSelfIntersection()
				: this.checkSingleEdgeSelfIntersection();

			if (!selfIntersectionCheck) {
				return AREA_ERROR_SELF_INTERSECT;
			}
		}

		const entity = this.areaMapObject.getEntity();
		if (entity.areaType === 'AREAAUTONOMOUS') {
			if (isFirstPoint) {
				if (!this.checkNonDrivablePoint(coords)) {
					return AREA_ERROR_NON_DRIVABLE;
				}
				if (!this.checkAutonomousAreaIntersectPoint(coords)) {
					return AREA_ERROR_AUTONOMOUS_INTERSECT;
				}
			} else {
				const drivableCheck = isClosed ? this.checkNonDrivable(isClosingPoint)
					: this.checkSingleEdgeNonDrivable();
				if (!drivableCheck) {
					return AREA_ERROR_NON_DRIVABLE;
				}
				const autonomousIntersectCheck = isClosed ? this.checkAutonomousAreaIntersect()
					: this.checkSingleEdgeAutonomousAreaIntersect();
				if (!autonomousIntersectCheck) {
					return AREA_ERROR_AUTONOMOUS_INTERSECT;
				}

				if (isClosed && this.areaMapObject.selectPointIndex !== -1) {
					// Closed shape that is not new (don't change to else if)
					if (!this.checkBaysOnAreaNodeDragAndDrop()) {
						return AREA_ERROR_BAYS;
					}
				}
			}
		}

		return '';
	}

	/**
	 * Get all bayIds inside given polygon
	 * @param polygon
	 * @returns
	 */
	private getBayIdsForPolygon(polygon: PIXI.Polygon): Record<string, boolean> {
		const bayIds: Record<string, boolean> = {};
		this.lookup.getAllEntities(BayEntity).forEach((bay: BayEntity) => {
			const bayObjectId = this.lookup.getMapObjectId(bay.id, 'bay');
			if (bayObjectId) {
				const bayObject = this.renderer.getObjectById(bayObjectId) as Bay;
				const { center } = bayObject.getBayPoints();
				if (polygon.contains(center.x, center.y)) {
					bayIds[bayObject.getEntity().id] = true;
				}
			}
		});
		return bayIds;
	}

	/**
	 * Check if any bays are excluded from new polygon
	 * @returns True if no bays are excluded
	 */
	public checkBaysOnAreaNodeDragAndDrop() {
		const points = this.areaMapObject.getPoints();
		const originalPolygonPoints = points.map((point, index) => {
			if (index === this.selectPointIndex) {
				const { x, y } = this.originalSelectedPoint;
				return new PIXI.Point(x, y);
			}
			return point;
		});
		originalPolygonPoints[points.length - 1] = originalPolygonPoints[0];
		const originalAreaPolygon = new PIXI.Polygon(originalPolygonPoints);
		const originalBayIds = this.getBayIdsForPolygon(originalAreaPolygon);
		const newPolygon = new PIXI.Polygon(points);
		const newBayIds = this.getBayIdsForPolygon(newPolygon);
		const isMatchingBays = Object.keys(originalBayIds).every(id => originalBayIds[id] === newBayIds[id]);
		return isMatchingBays;
	}

	/**
	 * Check if new are node coordinate would cause intersection with autonomous area
	 * @returns
	 */
	public checkAutonomousAreaIntersect() {
		const areaPointsWithoutClose = this.areaMapObject.getPointsWithoutClose();

		const edges = this.getDynamicEdges(areaPointsWithoutClose);

		if (edges === undefined) {
			return false;
		}

		const areaPolygon = new PIXI.Polygon(this.areaMapObject.getPoints());

		const {
			startLineA, endLineA, startLineB, endLineB,
		} = edges;

		return !this.lookup.getAllEntities(AreaEntity)
			.filter((area: AreaEntity) => area.areaType === 'AREAAUTONOMOUS')
			.some((area: AreaEntity) => {
				const areaObjectId = this.lookup.getMapObjectId(area.id, 'area');
				if (!areaObjectId) {
					return false;
				}
				if (this.areaMapObject.getId() !== areaObjectId) {
					const areaObject = this.renderer.getObjectById(areaObjectId) as Area;
					const points = areaObject.getPointsWithoutClose();
					const { x, y } = points[0];
					// Is completely enclosed?
					let hasIntersection = areaPolygon.contains(x, y);
					if (!hasIntersection) {
						// edges intersect?
						const numerOfPoints = points.length;
						hasIntersection = points.some((currentPoint, i) => {
							const nextIndex = (i + 1) % numerOfPoints;
							return this.checkInterSegmentIntersection(currentPoint, points[nextIndex], startLineA, endLineA)
								|| this.checkInterSegmentIntersection(currentPoint, points[nextIndex], startLineB, endLineB);
						});
					}
					return hasIntersection;
				}
				return false;
			});
	}

	checkAutonomousAreaIntersectPoint(coords: L.LatLng) {
		const { renderer } = this;

		const pixiCoords = renderer.project(coords);
		const { x, y } = pixiCoords;
		return !this.lookup.getAllEntities(AreaEntity)
			.filter((area: AreaEntity) => area.areaType === 'AREAAUTONOMOUS')
			.some((area: AreaEntity) => {
				const areaObjectId = this.lookup.getMapObjectId(area.id, 'area');
				if (!areaObjectId) {
					return false;
				}
				if (this.areaMapObject.getId() !== areaObjectId) {
					const areaObject = this.renderer.getObjectById(areaObjectId) as Area;
					const polygon = new PIXI.Polygon(areaObject.getPoints());
					return polygon.contains(x, y);
				}
				return false;
			});
	}

	/**
	 * Check if any orphan bays are created due to delete operation
	 * @param originalPoints
	 * @returns Ture if there are no orphan bays created, otherwise false
	 */
	private checkBaysOnDelete = (originalPoints: PIXI.Point[]): boolean => {
		const originalPolygon = new PIXI.Polygon(originalPoints);
		const updatedPolygon = new PIXI.Polygon(this.areaMapObject.getPoints());
		const allOriginalBays = this.getBayIdsForPolygon(originalPolygon);
		const allUpdatedBays = this.getBayIdsForPolygon(updatedPolygon);
		const noOrphanBays = Object.keys(allOriginalBays).every(id => allOriginalBays[id] === allUpdatedBays[id]);
		return noOrphanBays;
	}

	/**
	 * Validate the node getting deleted
	 * @param areaMapObject
	 * @param originalAreaNodes
	 * @param deleteNodeParams
	 */
	public validateDeleteNode(areaMapObject: Area,
		originalAreaNodes: PIXI.Point[],
		deleteNodeParams: { startIndex: number, endIndex: number }): boolean {
		this.areaMapObject = areaMapObject;
		const entity = this.areaMapObject.getEntity();
		if (entity.areaType === 'AREAAUTONOMOUS') {
			// extra checks for autonomous areas
			const ensureNoOrphanNodes = this.checkBaysOnDelete(originalAreaNodes);
			if (!ensureNoOrphanNodes) {
				alertToast(AREA_ERROR_BAYS, 'error');
				return false;
			}
			const singleEdgeAutonomousAreaIntersection = this.checkSingleEdgeAutonomousAreaIntersect(deleteNodeParams);
			if (!singleEdgeAutonomousAreaIntersection) {
				alertToast(AREA_ERROR_AUTONOMOUS_INTERSECT, 'error');
				return false;
			}
			const checkIfNonDrivableIntersection = this.checkSingleEdgeNonDrivable(deleteNodeParams);
			if (!checkIfNonDrivableIntersection) {
				alertToast(AREA_ERROR_NON_DRIVABLE, 'error');
				return false;
			}
		}
		// check self-intersection for all area types
		const checkIfSelfIntersection = this.checkSingleEdgeSelfIntersection(deleteNodeParams);
		if (!checkIfSelfIntersection) {
			alertToast(AREA_ERROR_SELF_INTERSECT, 'error');
			return false;
		}
		return true;
	}

	/**
	 * Check for single edge intersection of autonomous area
	 * For delete node validation, if existing area has nodes 0,1,2,3,4,5 and node 3 is deleted,
	 * the new nodes are 0,1,2,3,4 with startIndex/endIndex being 2 and 3, respectively
	 * In addition, if deleteNodeParams area set a check for enclosed autonomous areas will also be done
	 * @param deleteNodeParams if these params are set, this validation is for when a node is deleted
	 * @returns
	 */
	public checkSingleEdgeAutonomousAreaIntersect(deleteNodeParams?: { startIndex: number, endIndex: number }) {
		const areaPoints = this.areaMapObject.getPoints();
		if (areaPoints.length < 2 || (this.areaMapObject.isClosed() && !deleteNodeParams)) {
			console.error('This method can only be used for open shapes of at least 2 points');
			return false;
		}

		const selectedIndex = areaPoints.length - 1;

		const isDeleteNodeCheck = !!deleteNodeParams;

		const indexA = isDeleteNodeCheck ? deleteNodeParams.startIndex : selectedIndex;
		const indexB = isDeleteNodeCheck ? deleteNodeParams.endIndex : selectedIndex - 1;

		const startLineA = areaPoints[indexA];
		const endLineA = areaPoints[indexB];

		let selectedPolygon: PIXI.Polygon | undefined;
		if (isDeleteNodeCheck) {
			selectedPolygon = new PIXI.Polygon(this.areaMapObject.getPoints());
		}

		return !this.lookup.getAllEntities(AreaEntity)
			.filter((area: AreaEntity) => area.areaType === 'AREAAUTONOMOUS')
			.some((area: AreaEntity) => {
				const areaObjectId = this.lookup.getMapObjectId(area.id, 'area');
				if (!areaObjectId) {
					return false;
				}
				if (this.areaMapObject.getId() !== areaObjectId) {
					const areaObject = this.renderer.getObjectById(areaObjectId) as Area;
					const points = areaObject.getPointsWithoutClose();

					let hasIntersection = false;
					if (isDeleteNodeCheck && !!selectedPolygon) {
						// Is completely enclosed?
						const { x, y } = points[0];
						hasIntersection = selectedPolygon.contains(x, y);
					}
					if (!hasIntersection) {
						const numerOfPoints = points.length;
						hasIntersection = points.some((currentPoint, i) => {
							const nextIndex = (i + 1) % numerOfPoints;
							return this.checkInterSegmentIntersection(currentPoint, points[nextIndex],
								startLineA, endLineA);
						});
					}
					return hasIntersection;
				}
				return false;
			});
	}

	checkNonDrivablePoint(coords: L.LatLng) {
		const { controller } = this;
		const { lookup } = this;
		const { renderer } = this;

		const pixiCoords = renderer.project(coords);
		const isFound = lookup.getDrivingAreas().some((entity: DrivingAreaEntity) => {
			const id = lookup.getMapObjectId(entity.id, 'driving_area');
			if (!id) {
				return false;
			}
			const drivingArea = renderer.getObjectById(id) as DrivingArea;
			return DrivingArea.isPointDrivable(drivingArea, pixiCoords);
		});
		return isFound;
	}

	/**
	 * Get bounding drivable area points
	 * @param testPoint
	 * @returns bounding points (drivable area perimeter of hole)
	 */
	private getDrivableAreaBoundingPoints(testPoint: PixiCoordinates):
		{ points: PIXI.Point[], isContainsHole: boolean } | undefined {
		const drivingAreas = this.lookup.getDrivingAreas();
		for (let i = 0; i < drivingAreas.length; i++) {
			const entity = drivingAreas[i];
			const id = this.lookup.getMapObjectId(entity.id, 'driving_area');
			if (!!id) {
				const drivingArea = this.renderer.getObjectById(id) as DrivingArea;
				// Test individual driving areas
				const boundingPoints = MapValidator.getBoundingPoints(drivingArea, testPoint);
				if (!!boundingPoints) {
					const innerPoints = drivingArea.getInnerPoints();
					let isContainsHole = false;
					if (!!innerPoints) {
						// eslint-disable-next-line max-len
						const { x, y } = this.originalSelectedPoint;
						const originalPixiPoint = new PIXI.Point(x, y);
						const previousPoints = this.areaMapObject.getCopyPoints()
							.map((value, index) => index === this.selectPointIndex ? originalPixiPoint : value);
						if (this.selectPointIndex === 0 && this.areaMapObject.isClosed()) {
							// if the selected point is the first point and the area is closed, update last point to match first point
							const lastIndex = previousPoints.length - 1;
							previousPoints[lastIndex].x = previousPoints[0].x;
							previousPoints[lastIndex].y = previousPoints[0].y;
						}
						const previousAreaPolygon = new PIXI.Polygon(previousPoints);
						// eslint-disable-next-line max-len
						isContainsHole = innerPoints.some(holePoints => holePoints.some(holePoint => previousAreaPolygon.contains(holePoint.x, holePoint.y)));
					}
					return { points: boundingPoints.points, isContainsHole: isContainsHole };
				}
			}
		}
		return undefined;
	}

	/**
	 * Check if coords are within drivable area
	 * This method has some strange conditions to deal with edge cases related to
	 * existing areas not conforming with rules
	 * @returns true if test is passed, false if test fails (e.g. has intersection)
	 */
	public checkNonDrivable(isClosingPoint: boolean) {
		const boundingPointInformation = this.getDrivableAreaBoundingPoints(this.originalSelectedPoint);

		if (!!boundingPointInformation) {
			const { points, isContainsHole } = boundingPointInformation;
			const numberOfPoints = points.length;
			const edges = this.getDynamicEdges(this.areaMapObject.getPointsWithoutClose());

			if (edges === undefined) {
				console.log('Edges undefined');
				return false;
			}

			const {
				startLineA, endLineA, startLineB, endLineB,
			} = edges;
			const hasIntersection = points.some((currentPoint, i) => {
				const nextIndex = (i + 1) % numberOfPoints;
				return this.checkInterSegmentIntersection(currentPoint, points[nextIndex], startLineA, endLineA)
					|| this.checkInterSegmentIntersection(currentPoint, points[nextIndex], startLineB, endLineB);
			});

			if (!hasIntersection) {
				if (isClosingPoint || !isContainsHole) {
					// Not checking for holes when isContainsHole is true because this must be an imported
					// driving area already containing holes. isClosingPoint is for newly created areas
					// that are being closed.
					return this.checkNonDrivableHoles();
				}
				console.log('Skipping checkNonDrivableHoles as area already contains holes');

				return true;
			}
		}

		console.log('No driable area.');
		return false;
	}

	/**
	 * Check if coords are within drivable area
	 * @returns
	 */
	public checkNonDrivableHoles() {
		const areaPoints = this.areaMapObject.getPoints();

		const drivingArea = this.getDrivingAreaOfPoint(this.originalSelectedPoint);

		if (drivingArea === undefined) {
			console.error('Driving area check failed: Area was already invalid.');
			return false;
		}

		const areaPolygon = new PIXI.Polygon(areaPoints);

		let hasIntersection = false;

		// Check if the edge intersects with any of the holes
		const innerPoints = drivingArea.getInnerPoints();
		if (!!innerPoints) {
			// check intersection for each hole
			// eslint-disable-next-line max-len
			hasIntersection = innerPoints.some(innerPointsArray => innerPointsArray.some(currentPoint => areaPolygon.contains(currentPoint.x, currentPoint.y)));
		}
		return !hasIntersection;
	}

	private getDrivingAreaOfPoint(testPoint: PixiCoordinates): DrivingArea | undefined {
		const { x, y } = testPoint;
		const drivingAreas = this.lookup.getDrivingAreas();
		let result: DrivingArea | undefined;
		for (let i = 0; i < drivingAreas.length; i++) {
			const entity = drivingAreas[i];
			const id = this.lookup.getMapObjectId(entity.id, 'driving_area');
			if (!!id) {
				const drivingArea = this.renderer.getObjectById(id) as DrivingArea;
				const outerPolygon = new PIXI.Polygon(drivingArea.getOuterPoints());
				if (outerPolygon.contains(x, y)) {
					result = drivingArea;
					break;
				}
			}
		}
		return result;
	}

	/**
	 * Checks single edge for non-drivable intersection
	 * @returns true if passes the test (no intersection)
	 */
	checkSingleEdgeNonDrivable(deleteNodeParams?: { startIndex: number, endIndex: number }) {
		const areaPoints = this.areaMapObject.getPoints();
		const selectedIndex = areaPoints.length - 1;
		if (areaPoints.length < 2 || (this.areaMapObject.isClosed() && !deleteNodeParams)) {
			console.error('This method cannot be used with closed shapes except for checking deleted nodes');
			return false;
		}

		const isDeleteNodeCheck = !!deleteNodeParams;

		const indexA = isDeleteNodeCheck ? deleteNodeParams.startIndex : selectedIndex - 1;
		const indexB = isDeleteNodeCheck ? deleteNodeParams.endIndex : selectedIndex;

		// new edge being checked
		const startLineA = areaPoints[indexA];
		const endLineA = areaPoints[indexB];

		let selectedPolygon: PIXI.Polygon | undefined;
		if (isDeleteNodeCheck) {
			selectedPolygon = new PIXI.Polygon(this.areaMapObject.getPoints());
		}
		const drivingArea = this.getDrivingAreaOfPoint(areaPoints[indexA]);

		if (drivingArea === undefined) {
			console.error('Driving area check failed: Area was already invalid.');
			return false;
		}

		// check for intersection of outer perimeter
		const outerPoints = drivingArea.getOuterPoints();
		let hasIntersection = outerPoints.some((currentPoint, index, array) => {
			const nextIndex = (index + 1) % array.length;
			return this.checkInterSegmentIntersection(currentPoint, array[nextIndex], startLineA, endLineA);
		});

		if (hasIntersection) {
			return false;
		}

		// Check if the edge intersects with any of the holes
		const innerPoints = drivingArea.getInnerPoints();
		if (!!innerPoints) {
			hasIntersection = innerPoints.some(innerPointsArray => {
				if (!!selectedPolygon) {
					// If any point of hole is within the selected polygon, it means a hole is fully contained
					// within the polygon. Simply take the first point as the test point.
					const { x, y } = innerPointsArray[0];
					if (selectedPolygon.contains(x, y)) {
						return true;
					}
				}
				// check intersection for each hole
				const intersection = innerPointsArray.some((currentPoint, index, array) => {
					const nextIndex = (index + 1) % array.length;
					return this.checkInterSegmentIntersection(currentPoint, array[nextIndex], startLineA, endLineA);
				});
				return intersection;
			});
		}

		return !hasIntersection;
	}

	public static validateAreasInMapBounds (map: MapController) {
		const areaEntities = map.getMapLookup().getAllEntities(AreaEntity);
		areaEntities.forEach(a => {
			this.validateAreaInMapBounds(a, map);
		});
		console.log("validateAreasInMapBounds is finished.");
	}

	public static validateAreaInMapBounds (areaEntity: AreaEntity, map: MapController) {
		const lookup = map.getMapLookup();
		const areaMapObject = lookup.getMapObjectByEntity(areaEntity, 'area') as Area;
		if (!areaMapObject) {
			return;
		}
		const isInMapBound = areaMapObject.getPoints().every(p => {
								const renderer = map.getMapRenderer();
								const _p = renderer.unproject(p);
								return renderer.isPointInMapBounds(renderer.getRealWorldCoords(_p));
							});
		const errorMessage = `Area_${areaEntity.areaId} is outside the allowed boundary for AHS objects`;
		if (!isInMapBound) {			
			lookup.addObjectError(areaEntity.id, AreaEntity, errorMessage);
		} else {
			lookup.removeObjectError(areaEntity.id, AreaEntity, errorMessage);
		}

		// Check entity error and warning count and render error or warning styling
		const hasError = areaEntity.getErrorCount() > 0;
		const hasWarning = areaEntity.getWarningCount() > 0;
		MapValidator.setMapObjectTooltipErrorWarning(areaMapObject, hasError, hasWarning);
		// Update the label in the layers panel
		map.getEventHandler().emit('onMapObjectUpdate', areaEntity, areaEntity.id);
	}

	/**
	* Check if area node is within map boundary
	* @param coords
	* @returns
	*/
	public checkMapBounds(coords: L.LatLng): boolean {
		return this.renderer.isPointInMapBounds(this.renderer.getRealWorldCoords(coords));
	}

	private getDynamicEdges(points: PIXI.Point[]): IActiveNodeEdges | undefined {
		const selectedIndex = this.selectPointIndex;
		if (selectedIndex === -1) {
			return undefined;
		}
		const selectedPoint = points[selectedIndex];
		return {
			startLineA: selectedPoint,
			startLineB: selectedPoint,
			endLineA: points[(selectedIndex + 1) % points.length],
			endLineB: selectedIndex === 0 ? points[points.length - 1] : points[selectedIndex - 1],
		};
	}

	checkSingleEdgeSelfIntersection(deleteNodeParams?: { startIndex: number, endIndex: number }) {
		const points = this.areaMapObject.getPointsWithoutClose(); // TODO: this will need to be updated for create area

		if (points.length < 2 || (this.areaMapObject.isClosed() && !deleteNodeParams)) {
			console.error('This method cannot be used with closed shapes except for checking deleted nodes');
			return false;
		}

		const isDeleteNodeCheck = !!deleteNodeParams;

		const selectedIndex = isDeleteNodeCheck ? deleteNodeParams.startIndex : points.length - 1;
		const endIndex = isDeleteNodeCheck ? deleteNodeParams.endIndex : selectedIndex - 1;
		const numerOfPoints = points.length;
		const startLineA = points[selectedIndex];
		const endLineA = points[endIndex];

		return !points.some((currentPoint, i) => {
			const nextIndex = (i + 1) % numerOfPoints;
			if (i !== selectedIndex) {
				const startTestLine = currentPoint;
				const endTestLine = points[nextIndex];
				return this.checkInterSegmentIntersection(startTestLine, endTestLine, startLineA, endLineA);
			}
			return false;
		});
	}

	/**
	 * Take the two edges that have been modified due to shifting the area node
	 * and see if they intersect with any other edges
	 * @returns true if there's an intersection
	 */
	public checkSelfIntersection() {
		const { areaMapObject } = this;
		const points = areaMapObject.getPointsWithoutClose(); // TODO: this will need to be updated for create area
		const edges = this.getDynamicEdges(points);

		if (edges === undefined) {
			return false;
		}

		const selectedIndex = this.selectPointIndex;
		const numerOfPoints = points.length;

		const {
			startLineA, endLineA, startLineB, endLineB,
		} = edges;

		return !points.some((currentPoint, i) => {
			const nextIndex = (i + 1) % numerOfPoints;
			if (i !== selectedIndex && nextIndex !== selectedIndex) {
				const startTestLine = currentPoint;
				const endTestLine = points[nextIndex];
				return this.checkInterSegmentIntersection(startTestLine, endTestLine, startLineA, endLineA)
				|| this.checkInterSegmentIntersection(startTestLine, endTestLine, startLineB, endLineB);
			}
			return false;
		});
	}

	public static clearTempSublinkWarnings(controller: MapController, isClearTempWarnings: boolean, isRerender: boolean) {
		const lookup = controller.getMapLookup();
		const warnings = lookup.getTempMapWarningsClientSide();

		if (!!warnings) {
			const storedTempSublinkIds = warnings["SublinkEntity"] || [];
			this.clearSublinkWarningStyle(storedTempSublinkIds, controller, isRerender);
		}
		if (isClearTempWarnings) {
			lookup.clearTempMapWarnings();
		}
	}

	public static clearSublinkWarningStyle(storedTempSublinkIds: string[], controller: MapController, isRerender: boolean) {
		if (storedTempSublinkIds.length > 0) {
			const renderer = controller.getMapRenderer();
			const lookup = controller.getMapLookup();

			storedTempSublinkIds.forEach(subLinkId => {
				const sublinkMapObject = lookup.getMapObjectByEntityId(subLinkId!, 'sublink') as Sublink;
				const dz = sublinkMapObject.getDrivingZoneObject();
				if (!!dz) {
					MapValidator.setMapObjectWarning(renderer, dz, isRerender, false);
				}
			});
		}
	}


	private static debounce<T>(fn: (arg: T) => Promise<any>, delay: number) {
		let timeoutId: NodeJS.Timeout | undefined;

		return (inputValue: T): Promise<any> => {
			return new Promise((resolve) => {
				if (timeoutId) {
					clearTimeout(timeoutId);
				}
				timeoutId = setTimeout(async () => {
					const result = await fn(inputValue);
					resolve(result);
				}, delay);
			});
		};
	}

	public static async fetchAreaSublinkWarnings(importVersionEntityId: string, area: Area, isAreaDeleted: boolean): Promise<any> {
		const areaEntity = area.getEntity();
		const fetchSublinkWarnings = async () => {
			try {
			const result = await axios.post(`${SERVER_URL}/api/entity/AreaEntity/validateAreaSublinkWarnings`, {
				importVersionId: importVersionEntityId,
				areaPolygon: area.getPointsAsGeoJSONString(),
				areaId: areaEntity.id,
				areaType: areaEntity.areaType,
				isAreaDeleted
			});
			return result;
			} catch (error) {				
				return false;
			}
		};
		const debouncedFetchWarnings = this.debounce(fetchSublinkWarnings, 200);
		return await debouncedFetchWarnings(area.getPointsAsGeoJSONString()); 
	}

	public static async checkDynamicAreaSublinkWarnings(area: Area, controller: MapController, isClearTempWarnings: boolean = true, addorClearWarningsToEntity: boolean = false, isAreaDeleted: boolean = false): Promise<boolean> {
		const renderer = controller.getMapRenderer();
		const lookup = controller.getMapLookup();
		const importVersionEntityId = controller.getImportVersion().id;
		let processResults = false;
		try {
			const response = await this.fetchAreaSublinkWarnings(importVersionEntityId, area, isAreaDeleted);
			processResults = !!response && (response.data['noWarningSublinkIds'].length > 0 || response.data['warningsResults'] != undefined);
			if (processResults) {
				const areaEntity = area.getEntity();
				const areaType = areaEntity.areaType;
				const noWarningSublinkIds = response.data['noWarningSublinkIds'];
				const sublinkWarningResults = response.data['warningsResults'];

				this.clearSublinkWarnings(controller, isClearTempWarnings, noWarningSublinkIds, areaType, addorClearWarningsToEntity);

				let sublinkIds = this.processSublinkWarnings(controller, sublinkWarningResults, addorClearWarningsToEntity);

				if (!addorClearWarningsToEntity) {
					this.updateTempSublinkWarnings(sublinkIds, isClearTempWarnings, lookup);
				}
			}
		} catch (err) {
			this.clearTempSublinkWarnings(controller, isClearTempWarnings, false);
			 console.error('error in axios call ', err);
		} finally {
			renderer.rerender();
		}
		return processResults;
	}

	private static clearSublinkWarnings(controller: MapController, isClearTempWarnings: boolean, noWarningSublinkIds: [], areaType: any, addorClearWarningsToEntity: boolean) {
		this.clearTempSublinkWarnings(controller, isClearTempWarnings, false);
		if (noWarningSublinkIds.length > 0) {
			const lookup = controller.getMapLookup();
			this.clearSublinkWarningStyle(noWarningSublinkIds, controller, false);
			if (addorClearWarningsToEntity) {
				noWarningSublinkIds.forEach((clearSublinkId: string) => {
					const clearSublink = lookup.getMapObjectByEntityId(clearSublinkId!, 'sublink') as Sublink;
					const expectedWarningCode = this.GetWarningCodeByAreaType(areaType);
					const pathValidator = new PathValidator(controller);
					pathValidator.removeSublinkWarning(expectedWarningCode, clearSublink.sublinkEntity);
				});
			}
		}
	}

	private static processSublinkWarnings( controller: MapController, sublinkWarningResults: any, addorClearWarningsToEntity: boolean) {
		const renderer = controller.getMapRenderer();
		const lookup = controller.getMapLookup();
		let sublinkIds: string[] = [];
		Object.keys(sublinkWarningResults).forEach((subLinkId, index) => {
			const sublink = lookup.getMapObjectByEntityId(subLinkId!, 'sublink') as Sublink;
			if (!!sublink) {
				const drivingZone = sublink.getDrivingZoneObject();
				if (!!drivingZone) {
					const sublinkEntity = sublink.getSublinkEntity();
					const warningTypes = sublinkWarningResults[subLinkId]['warnings'];
					const hasSublinkWarnings = sublinkEntity.mapObjectWarningss.length > 0;
					if (!hasSublinkWarnings || !drivingZone.isWarning) {
						// add warning style
						MapValidator.setMapObjectWarning(renderer, drivingZone, false, true);
						if (!hasSublinkWarnings) {
							sublinkIds.push(subLinkId);
						}
					}
					if (addorClearWarningsToEntity) {
						// render all warnings and clear no warning sublink ids in entity and map object
						const sublinkWarningMessages = sublinkEntity.mapObjectWarningss;
						const pathValidator = new PathValidator(controller);
						warningTypes.forEach((warningCode: string) => {
							let isSublinkWarningAlreadyExists = false;
							sublinkWarningMessages.forEach(sublinkWarningMessage => {
								if (sublinkWarningMessage.warningMessage === warningCode) {
									isSublinkWarningAlreadyExists = true;
									return;
								}
							});
							if (!isSublinkWarningAlreadyExists) {
								pathValidator.addSublinkWarning(warningCode, sublinkEntity);
							}
						});

						// clear all entity warnings only which are not existed anymore
						sublinkWarningMessages.forEach(sublinkWarningMessage => {
							if (warningTypes.indexOf(sublinkWarningMessage.warningMessage) == -1) {
								pathValidator.removeSublinkWarning(sublinkWarningMessage.warningMessage, sublinkEntity);
							}
						});
					}

				}
			}
		});
		return sublinkIds;
	}

	public static updateTempSublinkWarnings(sublinkIds: string[], isClearTempWarnings: boolean, lookup: MapLookup) {
		// add all temporary existing sublink warnings to cleanup in future - in case of DragMove we want to keep the Ids to re-validate
		const existingTempSublinkWarnings = lookup.getTempMapWarningsClientSide();
		if (!isClearTempWarnings && existingTempSublinkWarnings) {
			const storedTempSublinkIds = existingTempSublinkWarnings["SublinkEntity"] || [];
			const uniqueSublinkIdsSet = new Set(sublinkIds);

			storedTempSublinkIds.forEach((tempSublinkId) => {
				uniqueSublinkIdsSet.add(tempSublinkId);
			});

			sublinkIds = Array.from(uniqueSublinkIdsSet);
		}

		lookup.setTempMapWarningsClientSide({"SublinkEntity": sublinkIds});
	}

	private static GetWarningCodeByAreaType(areaType: string) {
		const expectedWarningCode =
			areaType == "AREABARRIER" ? "SublinkBarrierIntersection" : areaType == "AREAOBSTACLE" ? 'SublinkObstacleIntersection' : "";
		return expectedWarningCode;
	}

	public static async checkAreaErrors(controller: MapController): Promise<void> {
		const importVersionEntityId = controller.getImportVersion().id;

		// serverside validation
		await axios.post(`${SERVER_URL}/api/entity/AreaEntity/validateAreaErrors`, {
			importVersionId: importVersionEntityId,
		}).then(data => {
			// populate areaEntity mapObjectErros with returned error
			if (!!data) {
				Object.keys(data.data).forEach(async (key, index) => {
					// for each area, add errors
					// store the errorCode and areaId
					const {errorCodes} = data.data[key];
					const areaId = key;
					const area = controller.getMapLookup().getEntity(areaId, AreaEntity);
					if (!!area) {
						controller.getMapLookup()
							.addNewErrorsForObject(area.getModelId(), AreaEntity, errorCodes);

						controller.getEventHandler().emit('onMapObjectUpdate', area);
						// re-render error information
						const areaMapObjectId = controller.getMapLookup().getMapObjectId(area.id, 'area');
						const areaMapObject = controller.getMapRenderer().getObjectById(areaMapObjectId);
						const hasErrors = area.mapObjectErrorss.length > 0;
						areaMapObject?.setTooltipDisplay(true, hasErrors);
						controller.getEventHandler().emit('onMapObjectUpdate', area);
					}
				});
			}
			console.log('serverside errors', data.data);
		}).catch((err: AxiosError) => {
			console.log('error in axios call ', err);
		});
	}
}

