/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import RenderMapsCollection from '../MapComponents/AllMaps';
import AllImportsForMap from '../MapComponents/AllImportsForMap';
// % protected region % [Add any extra imports here] end

export interface MapsPageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] on begin
class MapsPage extends React.Component<RouteComponentProps<{ id?: string }>> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] off begin
	// % protected region % [Add class properties here] end

	render() {
		const {
			match,
			location,
			history,
			staticContext,
			// % protected region % [Destructure any additional props here] off begin
			// % protected region % [Destructure any additional props here] end
		} = this.props;

		// % protected region % [Add logic before rendering contents here] on begin
		const mapEntityId = this.props.match.params.id;
		// % protected region % [Add logic before rendering contents here] end

		// eslint-disable-next-line prefer-const
		let contents = (
			// eslint-disable-next-line max-len
			<SecuredPage groups={['Super Administrators', 'MineUser', 'HitachiAdmin']}>
				{
				// % protected region % [Alter navigation here] on begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					{...this.props}
					alwaysExpanded
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
					{
					// % protected region % [Add code for 571d596f-8e04-4e78-a372-81fb46149f66 here] on begin
					}
					{
						mapEntityId ? <AllImportsForMap mapEntityId={mapEntityId} /> : <RenderMapsCollection />
					}
					{
					// % protected region % [Add code for 571d596f-8e04-4e78-a372-81fb46149f66 here] end
					}
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] off begin
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default MapsPage;
// % protected region % [Override export here] end
