import React, { useEffect, useState } from 'react';
import { AreaEntity, BeaconEntity } from '../../../Models/Entities';
import MapController from '../Map/MapController';
import { allowedDirectionOptions } from 'Models/Enums';
import If from 'Views/Components/If/If';
import CollapsibleProperty from '../CollapsibleProperty';
import { store } from "../../../Models/Store";
import {RenderInformationCombobox} from "./PropertiesPanelComponents/RenderInformationCombobox";
import InputField from "./PropertiesPanelComponents/InputField";

interface DerivedProperties {
	locationType: string;
	locationName: string;
}

export default function BeaconProperties({ entity } : { entity: BeaconEntity, map?: MapController }) {
	const initialState = {
		locationType: '',
		locationName: '',
	};
	const [derivedProperties, setDerivedProperties] = useState<DerivedProperties>(initialState);

	useEffect(() => {
		// Generally better to use the map store
		const areaEntity = entity.areaId != null ? store.mapStore.getEntity<AreaEntity>(entity.areaId, AreaEntity) : null;

		setDerivedProperties({
			locationName: areaEntity != null ? areaEntity.areaName : '',
			locationType: entity.isAccess ? 'Access' : 'Route',
		});
	}, [entity]);

	const directionOptionsCombobox = Object
		.entries(allowedDirectionOptions)
		.map(([value, display]) => ({ display, value }));

	return (
		<>
			<h6>Beacon Identification</h6>
			<InputField model={entity} label="ID" modelProperty="beaconId" isReadOnly />

			<div className="section-divider" />

			<CollapsibleProperty propertyTitle="Beacon Properties" displayProperty>
				<InputField model={entity} label="Radius" modelProperty="radius" isReadOnly isNumber propertyUnit='m' />
				<InputField model={derivedProperties} label="Type" modelProperty="locationType" isReadOnly />
			</CollapsibleProperty>

			<div className="section-divider" />

			<If condition={derivedProperties.locationType==='Access'} >
				<CollapsibleProperty propertyTitle="Access Beacon Associations" displayProperty>
					<InputField model={derivedProperties} label="Location" modelProperty="locationName" isReadOnly />

					<RenderInformationCombobox
						model={entity}
						label="Direction"
						modelProperty="direction"
						options={directionOptionsCombobox}
						readonly
					/>
				</CollapsibleProperty>

				<div className="section-divider" />
			</If>
		</>
	);
}