/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsBackgroundImageEntity from 'Models/Security/Acl/VisitorsBackgroundImageEntity';
import MineUserBackgroundImageEntity from 'Models/Security/Acl/MineUserBackgroundImageEntity';
import HitachiAdminBackgroundImageEntity from 'Models/Security/Acl/HitachiAdminBackgroundImageEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FileListPreview } from 'Views/Components/CRUD/Attributes/AttributeFile';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IBackgroundImageEntityAttributes extends IModelAttributes {
	importedImageId: string;
	importedImage: Blob;
	compressedImageId: string;
	compressedImage: Blob;
	xScale: number;
	yScale: number;
	xCoordinateNW: number;
	yCoordinateNW: number;
	xCoordinateSE: number;
	yCoordinateSE: number;
	imageHeight: number;
	imageWidth: number;

	importVersionss: Array<
		| Models.ImportVersionEntity
		| Models.IImportVersionEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('BackgroundImageEntity', 'Background Image')
// % protected region % [Customise your entity metadata here] end
export default class BackgroundImageEntity extends Model
	implements IBackgroundImageEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsBackgroundImageEntity(),
		new MineUserBackgroundImageEntity(),
		new HitachiAdminBackgroundImageEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Imported Image'] off begin
	@observable
	@attribute({ file: 'importedImage' })
	@CRUD({
		name: 'Imported Image',
		displayType: 'file',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'importedImage',
		displayFunction: attr => attr
			? <FileListPreview url={attr} />
			: 'No File Attached',
	})
	public importedImageId: string;

	@observable
	public importedImage: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Imported Image'] end

	// % protected region % [Modify props to the crud options here for attribute 'Compressed Image'] off begin
	@observable
	@attribute({ file: 'compressedImage' })
	@CRUD({
		name: 'Compressed Image',
		displayType: 'file',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'compressedImage',
		displayFunction: attr => attr
			? <FileListPreview url={attr} />
			: 'No File Attached',
	})
	public compressedImageId: string;

	@observable
	public compressedImage: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Compressed Image'] end

	// % protected region % [Modify props to the crud options here for attribute 'X scale'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'X scale',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public xScale: number;
	// % protected region % [Modify props to the crud options here for attribute 'X scale'] end

	// % protected region % [Modify props to the crud options here for attribute 'Y scale'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Y scale',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public yScale: number;
	// % protected region % [Modify props to the crud options here for attribute 'Y scale'] end

	// % protected region % [Modify props to the crud options here for attribute 'X coordinate NW'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'X coordinate NW',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public xCoordinateNW: number;
	// % protected region % [Modify props to the crud options here for attribute 'X coordinate NW'] end

	// % protected region % [Modify props to the crud options here for attribute 'Y coordinate NW'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Y coordinate NW',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public yCoordinateNW: number;
	// % protected region % [Modify props to the crud options here for attribute 'Y coordinate NW'] end

	// % protected region % [Modify props to the crud options here for attribute 'X coordinate SE'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'X coordinate SE',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public xCoordinateSE: number;
	// % protected region % [Modify props to the crud options here for attribute 'X coordinate SE'] end

	// % protected region % [Modify props to the crud options here for attribute 'Y coordinate SE'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Y coordinate SE',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public yCoordinateSE: number;
	// % protected region % [Modify props to the crud options here for attribute 'Y coordinate SE'] end

	// % protected region % [Modify props to the crud options here for attribute 'Image Height'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Image Height',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public imageHeight: number;
	// % protected region % [Modify props to the crud options here for attribute 'Image Height'] end

	// % protected region % [Modify props to the crud options here for attribute 'Image Width'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Image Width',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public imageWidth: number;
	// % protected region % [Modify props to the crud options here for attribute 'Image Width'] end

	/**
	 * Background Image can be used by multiple import versions in the same map
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Import Versions'] off begin
		name: 'Import Versionss',
		displayType: 'reference-multicombobox',
		order: 110,
		referenceTypeFunc: () => Models.ImportVersionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'importVersionss',
			oppositeEntity: () => Models.ImportVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Import Versions'] end
	})
	public importVersionss: Models.ImportVersionEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IBackgroundImageEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IBackgroundImageEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.importedImage !== undefined) {
				this.importedImage = attributes.importedImage;
			}
			if (attributes.importedImageId !== undefined) {
				this.importedImageId = attributes.importedImageId;
			}
			if (attributes.compressedImage !== undefined) {
				this.compressedImage = attributes.compressedImage;
			}
			if (attributes.compressedImageId !== undefined) {
				this.compressedImageId = attributes.compressedImageId;
			}
			if (attributes.xScale !== undefined) {
				this.xScale = attributes.xScale;
			}
			if (attributes.yScale !== undefined) {
				this.yScale = attributes.yScale;
			}
			if (attributes.xCoordinateNW !== undefined) {
				this.xCoordinateNW = attributes.xCoordinateNW;
			}
			if (attributes.yCoordinateNW !== undefined) {
				this.yCoordinateNW = attributes.yCoordinateNW;
			}
			if (attributes.xCoordinateSE !== undefined) {
				this.xCoordinateSE = attributes.xCoordinateSE;
			}
			if (attributes.yCoordinateSE !== undefined) {
				this.yCoordinateSE = attributes.yCoordinateSE;
			}
			if (attributes.imageHeight !== undefined) {
				this.imageHeight = attributes.imageHeight;
			}
			if (attributes.imageWidth !== undefined) {
				this.imageWidth = attributes.imageWidth;
			}
			if (attributes.importVersionss !== undefined && Array.isArray(attributes.importVersionss)) {
				for (const model of attributes.importVersionss) {
					if (model instanceof Models.ImportVersionEntity) {
						this.importVersionss.push(model);
					} else {
						this.importVersionss.push(new Models.ImportVersionEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		importVersionss {
			${Models.ImportVersionEntity.getAttributes().join('\n')}
			${Models.ImportVersionEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			importVersionss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'importVersionss',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(BackgroundImageEntity.prototype, 'created');
CRUD(modifiedAttr)(BackgroundImageEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
