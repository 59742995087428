/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

export interface IUserDetails {
	id: string;
	email: string;
	givenName: string | null;
	familyName: string | null;
}

export interface IUserEntity {
	email: string,
	password: string,
	_confirmPassword: string,
}

export const UserFields: ReadonlyArray<keyof IUserEntity> = [
	'email',
	'password',
	'_confirmPassword',
];

/**
 * Returns the users full name or email
 */
export const getUsersName = (props: IUserDetails): string => {
	if (!!props) {
		const {givenName, familyName, email} = props;
		if (!!givenName || !!familyName) {
			return `${givenName} ${familyName}`;
		}
		if (!!email) {
			return email.split('@')[0]!;
		}
		return "Invalid user";
	}
	else {
		return "Invalid user";
	}
};
