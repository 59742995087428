import {Area, MapController, MapLookup, MapRenderer, NodeGraphic, Sublink} from 'Views/MapComponents';
import MapValidator from './MapValidator';
import {AreaEntity, LinkEntity, NodeEntity, SublinkEntity} from 'Models/Entities';
import {PixiCoordinates} from '../Helpers/Coordinates';
import PathValidator from './PathValidator';
import {Polygon} from 'pixi.js';
import {getNodeName} from 'Views/MapComponents/LayersPanel/AhsMapObjects';

export default class NodeValidator extends MapValidator {

	public static isMatchStartParkingNodeRules(nodeEntity: NodeEntity, map: MapController) {
		const lookup = map.getMapLookup();
		const renderer = map.getMapRenderer();
		const pathValidator = new PathValidator(map);
		const nodeMapObject = lookup.getMapObjectByEntity(nodeEntity, 'node') as NodeGraphic;
		const linkEntity = nodeMapObject?.getParent()?.getEntity() as LinkEntity;

		if (!!nodeMapObject && !!linkEntity) {
			// HITMAT-653: The node is NOT the first node of a link or the link has at least a previous connectivity. -> mismatch
			const previousLinks = linkEntity.previousLinks() as LinkEntity[];
			const firstNode = lookup.getFirstNodeForLink(linkEntity.id);
			if (firstNode?.id !== nodeEntity.id || previousLinks?.length !== 0) {
				return false;
			}

			// HITMAT-653: The node is NOT within an Autonomous Parking Area. -> mismatch
			const areaEntity = NodeValidator.getNodeArea(nodeMapObject.coordinates, lookup, renderer);
			const isOutsideArea = !areaEntity;
			const isNotParkingArea = !!areaEntity && areaEntity.locType !== 'PARKING';
			if (isOutsideArea || isNotParkingArea) {
				return false;
			}

			// HITMAT-841: NOT positive speed value. -> mismatch
			const speed = nodeEntity.task === 'HAULING' ? nodeEntity.speed : nodeEntity.getNextNode()?.speed ?? 0;
			if (speed <= 0) {
				return false;
			}

			// HITMAT-733: The node belongs to the link that is NOT fully contained within an Autonomous Parking Area. -> mismatch
			const allLinkDrivingZonesInArea = pathValidator.checkLinkDrivingZoneInArea(lookup, renderer, linkEntity, areaEntity);
			if (!allLinkDrivingZonesInArea) {
				return false;
			}
			// HITMAT-841: This is NOT the only link that is fully contained within the Autonomous Parking Area. -> mismatch
			const lastNodeInAreaLinks = lookup.getAllEntities(LinkEntity).filter(l => {
				if (l.lastNode()) {
					const lastNode = lookup.getMapObjectByEntity(l.lastNode()!, 'node') as NodeGraphic;
					return NodeValidator.isAreaContainNode(areaEntity, lastNode.coordinates, lookup, renderer);
				}
				return false;
			});
			const firstlastNodesInAreaLinks = lastNodeInAreaLinks.filter(l => {
				if (l.firstNode()) {
					const firstNode = lookup.getMapObjectByEntity(l.firstNode()!, 'node') as NodeGraphic;
					return NodeValidator.isAreaContainNode(areaEntity, firstNode.coordinates, lookup, renderer);
				}
				return false;
			});
			const allDrivingZonesInAreaLinks = firstlastNodesInAreaLinks.filter(l => {
				return pathValidator.checkLinkDrivingZoneInArea(lookup, renderer, l, areaEntity);
			});
			if (allDrivingZonesInAreaLinks.length !== 1 || allDrivingZonesInAreaLinks[0].id !== linkEntity.id) {
				return false;
			}

			// HITMAT-841: The link does NOT have exactly one "Next" connectivity. -> mismatch
			const nextLinks = linkEntity.nextLinks() as LinkEntity[];
			if (nextLinks?.length !== 1) {
				return false;
			}
			// HITMAT-841: The one "Next" connectivity is NOT a outgoing link. -> mismatch
			// Actually, if the one "Next" connectivity is NOT a outgoing link, it must be fully contained within the area.
			// Won't come here because allDrivingZonesInAreaLinks.length !== 1 
			const firstNodeOfNextLink = nextLinks[0].firstNode();
			const lastNodeOfNextLink = nextLinks[0].lastNode();
			if (!!firstNodeOfNextLink && !!lastNodeOfNextLink) {
				const firstNodeMapObject = lookup.getMapObjectByEntity(firstNodeOfNextLink, 'node') as NodeGraphic;
				const lastNodeMapObject = lookup.getMapObjectByEntity(lastNodeOfNextLink, 'node') as NodeGraphic;

				const isNextLinkFirstNodeInArea = NodeValidator.isAreaContainNode(areaEntity, firstNodeMapObject.coordinates, lookup, renderer);
				const isNextLinkLastNodeInArea = NodeValidator.isAreaContainNode(areaEntity, lastNodeMapObject.coordinates, lookup, renderer);
				if (!isNextLinkFirstNodeInArea || isNextLinkLastNodeInArea) {
					return false;
				}
			}

			// HITMAT-841: There is incoming link. -> mismatch
			const lastNodeInFirstNodeOutAreaLink = lastNodeInAreaLinks.filter(l => {
				if (l.firstNode()) {
					const firstNode = lookup.getMapObjectByEntity(l.firstNode()!, 'node') as NodeGraphic;
					return !NodeValidator.isAreaContainNode(areaEntity, firstNode.coordinates, lookup, renderer);
				}
				return false;
			});
			if (lastNodeInFirstNodeOutAreaLink.length !== 0) {
				return false;
			}
		}

		return true;
	}

	/**
	 * Returns the autonomous area of the current node
	 * @param coords - coordinates of the node
	 * @param lookup
	 * @param renderer
	 */
	private static getNodeArea({
								   x,
								   y
							   }: PixiCoordinates, lookup: MapLookup, renderer: MapRenderer): AreaEntity | undefined {
		return lookup.getAllEntities(AreaEntity)
			.filter(area => area.areaType == 'AREAAUTONOMOUS')
			.find(area => NodeValidator.isAreaContainNode(area, {x, y}, lookup, renderer));
	}

	/**
	 * Checks whether or not a point that is within an area
	 * @param area
	 * @param coords - coordinates of the node
	 * @param lookup
	 * @param renderer
	 * @returns true if a link that is fully contained, otherwise false
	 */
	private static isAreaContainNode(area: AreaEntity, {
		x,
		y
	}: PixiCoordinates, lookup: MapLookup, renderer: MapRenderer) {
		const areaObjectId = lookup.getMapObjectId(area.id, 'area');
		if (!areaObjectId) {
			return false;
		}

		const areaObject = renderer.getObjectById(areaObjectId) as Area;
		const polygon = new Polygon(areaObject.getPoints());

		return polygon.contains(x, y);
	}

	public static validateStartParkingNodes(map: MapController) {
		const startParkingNodeEntities = map.getMapLookup().getAllEntities(NodeEntity)
			.filter(n => n.isFirstNode() && n.task === 'PARKING');
		startParkingNodeEntities.forEach(n => {
			this.validateStartParkingNode(n, map);
		});
		console.log("validateStartParkingNodes is finished.");
	}

	public static validateStartParkingNode(nodeEntity: NodeEntity, map: MapController) {
		const lookup = map.getMapLookup();
		const renderer = map.getMapRenderer();
		const nodeMapObject = lookup.getMapObjectByEntity(nodeEntity, 'node') as NodeGraphic;
		if (!nodeMapObject || nodeEntity.task !== 'PARKING' || !nodeEntity.isFirstNode()) {
			return;
		}

		const isValid = nodeEntity.task === 'PARKING' ? NodeValidator.isMatchStartParkingNodeRules(nodeEntity, map) : true;
		const nodeInfo = !!nodeEntity.nodeId ? `${getNodeName(nodeEntity.nodeId)}` : '';
		const errorMessage = `${nodeInfo} does not meet the Parking task conditions`;
		if (!isValid) {
			lookup.addObjectError(nodeEntity.id, NodeEntity, errorMessage);
		} else {
			lookup.removeObjectError(nodeEntity.id, NodeEntity, errorMessage);
		}

		// Check entity error and warning count and render error or warning styling
		const hasError = nodeEntity.getErrorCount() > 0;
		const isRerender = false;
		MapValidator.setMapObjectError(renderer, nodeMapObject, isRerender, hasError);
		// Update the label in the layers panel
		map.getEventHandler().emit('onMapObjectUpdate', nodeEntity, nodeEntity.id);
	}

	public static validateNodesInMapBounds(map: MapController) {
		const nodeEntities = map.getMapLookup().getAllEntities(NodeEntity);
		nodeEntities.forEach(n => {
			this.validateNodeInMapBounds(n, map);
		});

		console.log("validateNodesInMapBounds is finished.");
	}

	public static validateNodeInMapBounds(nodeEntity: NodeEntity, map: MapController) {
		const lookup = map.getMapLookup();
		const renderer = map.getMapRenderer();
		const isInMapBound = renderer.isPointInMapBounds(nodeEntity);
		const nodeMapObject = lookup.getMapObjectByEntity(nodeEntity, 'node') as NodeGraphic;
		if (!nodeMapObject) {
			console.warn(`nodeMapObject not found`);
			return;
		}
		const nodeInfo = !!nodeEntity.nodeId ? `${getNodeName(nodeEntity.nodeId)}` : '';
		const errorMessage = `${nodeInfo} is outside the allowed boundary for AHS objects`;
		if (!isInMapBound) {
			lookup.addObjectError(nodeEntity.id, NodeEntity, errorMessage);
		} else {
			lookup.removeObjectError(nodeEntity.id, NodeEntity, errorMessage);
		}

		// Check entity error count and render error styling
		const hasError = nodeEntity.getErrorCount() > 0;
		const isRerender = false;
		MapValidator.setMapObjectError(renderer, nodeMapObject, isRerender, hasError);
		// Update the label in the layers panel
		map.getEventHandler().emit('onMapObjectUpdate', nodeEntity, nodeEntity.id);
	}

	/**
	 * Check if the heading is valid for a node
	 *
	 * @param heading of the node
	 * @returns true if the heading is valid
	 */
	public static validateHeading(heading?: number): boolean {
		return !(heading == undefined || heading < 0);
	}
}
