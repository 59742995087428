import { DrivingAreaEntity } from '../../../../../Models/Entities';
import MapObject from '../MapObject';
import MapRenderer from '../../MapRenderer';
import * as PIXI from 'pixi.js';
import geoJsonToPoints from '../../Helpers/GeoJSON';
import MapStore from '../../MapStore';
import { PixiCoordinates } from '../../Helpers/Coordinates';

const LINE_COLOUR = 0xA9A9A9;
const LINE_THICKNESS = 1;
const Fill_COLOUR = 0xF5F5F5;
const FILL_OPACITY = 0.5;

/**
 * Render DrivingArea in Pixi
 */
export default class DrivingArea extends MapObject<DrivingAreaEntity> {
	private readonly outerPoints: PIXI.Point[];
	private readonly innerPoints: PIXI.Point[][];

	constructor(drivingArea: DrivingAreaEntity, renderer: MapRenderer, lookup: MapStore) {
		super(renderer, 'driving_area', drivingArea);
		lookup?.addEntityToMapObject(drivingArea.id, this);
		this.outerPoints = geoJsonToPoints(this.getEntity().outerPerimeter, renderer) as PIXI.Point[];
		if (!!this.getEntity().innerPerimeters) {
			this.innerPoints = geoJsonToPoints(this.getEntity().innerPerimeters, renderer) as PIXI.Point[][];
		}

		this.createGraphic();
	}

	public getOuterPoints() {
		return this.outerPoints;
	}

	public getInnerPoints() {
		return this.innerPoints;
	}

	/**
	 * Checks if point is within drivable area
	 * Must satisfy conditions:
	 * 1) Inside perimeter
	 * 2) If inside perimeter, not in any holes
	 * @param drivingArea 
	 * @param pixiCoords 
	 * @returns 
	 */
	public static isPointDrivable(drivingArea: DrivingArea, pixiCoords: PixiCoordinates): boolean {
		const outerPolygon = new PIXI.Polygon(drivingArea.outerPoints);
		const insidePermimeter = outerPolygon.contains(pixiCoords.x, pixiCoords.y);
		if (insidePermimeter) {
			// Inside permimeter, check if it falls into hole
			let insideHole = false;
			if (!!drivingArea.innerPoints) {
				insideHole = drivingArea.innerPoints.some(points => {
					const hole = new PIXI.Polygon(points);
					return hole.contains(pixiCoords.x, pixiCoords.y);
				});
			}
			return !insideHole;
		}
		// Not in perimeter of driving zone
		return false;
	}

	render() {
		const graphic = this.getGraphic();

		// Draw the outer perimeter
		graphic.clear();
		graphic.lineStyle(LINE_THICKNESS, LINE_COLOUR);
		graphic.beginFill(Fill_COLOUR, FILL_OPACITY);
		graphic.drawPolygon(this.outerPoints);

		// Draw the inner perimeters as holes
		if (!!this.innerPoints) {
			graphic.beginHole();
			this.innerPoints.map(p => graphic.drawPolygon(p));
			graphic.endHole();
		}

		graphic.endFill();
	}
}
