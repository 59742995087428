/**
 * Coords from database are in RealWorldCoordinates. These coordinates need to be translated to Leaflet coordinates
 * and then to Pixi coordinates before being displayed on the screen.
 *
 * Leaflet coordinates are used for labels.
 */
import { LatLng } from 'leaflet';

export interface PixiCoordinates {
	x: number; // easting (lng)
	y: number; // northing (lat)
}

export interface RealWorldCoordinates {
	northing: number; // lat (y)
	easting: number; // lng (x)
}

export interface LeafletCoordinates {
	lat: number; // northing (y)
	lng: number; // easting (x)
}

export type Coordinates = PixiCoordinates | RealWorldCoordinates | LeafletCoordinates;

export function isRealWorldCoordinates(coords: Coordinates): boolean {
	return 'northing' in coords;
}

export function isLeafletCoordinates(coords: Coordinates): boolean {
	return 'lat' in coords;
}

export function isPixiCoordinates(coords: Coordinates): boolean {
	return 'y' in coords;
}

export function getLeafletLatLng(coords: LeafletCoordinates): LatLng {
	return new LatLng(coords.lat, coords.lng);
}

export function pixiCoordinates(x: number, y: number): PixiCoordinates {
	return { x, y };
}

export function leafletCoordinates(lat: number, lng: number): LeafletCoordinates {
	return { lat, lng };
}

export function realWorldCoordinates(northing: number, easting: number): RealWorldCoordinates {
	return { northing, easting };
}
