import {observer} from "mobx-react";
import React, {useEffect, useRef, useState} from "react";
import alert from "../../../../Util/ToastifyUtils";
import {setCustomTag} from "../../../MapComponents/Map/Helpers/MapUtils";
import {isSelenium} from "../../../MapComponents/Map/MapStateHandlers/MapGlobalEventHandler";
import axios from "axios";
import {SERVER_URL} from "../../../../Constants";
import {store} from "../../../../Models/Store";
import {LoadView} from "../LoadView";
import {Button, Colors, Display, Sizes} from "../../Button/Button";
import {TextField} from "../../TextBox/TextBox";
import {ButtonGroup} from "../../Button/ButtonGroup";
import Modal from "../../Modal/Modal";
import {
    backgroundImageFileValidation,
    FileUploadSection,
    IFileUploadModalProps, mapFileValidation,
    mapImportNameValidation,
    mapNameValidation, terrainFileValidation
} from "./FileUploadModal";
import {CustomValidator, Validator} from "../../../../Validators/CustomValidator";
import {observable} from "mobx";

class NewImport extends Validator<NewImport> {

    mapId: string | undefined;

    @CustomValidator(async (value, model) => {
        if (!!model.mapId) {
            return null;
        }

        return await mapNameValidation(value);
    })
    @observable
    public mapName: string;

    @CustomValidator(async (value, model) => {
        if (!model.mapId) {
            return null;
        }

        return await mapImportNameValidation(value);
    })
    @observable
    public mapImportName: string;

    @CustomValidator(async (value, model) => {
        // If we are uploading an import version and the existing image id has been set, no need to validate
        if (!!model.mapId && !!model.existingImageId) {
            return null;
        }
        return await backgroundImageFileValidation(value);
    })
    @observable
    public importedImage: File;

    @observable
    public existingImageId: string;

    @CustomValidator(async (value, model) => {
        return await mapFileValidation(value);
    })
    @observable
    public mapData: File;

    @CustomValidator(async (value, model) => {
        return await terrainFileValidation(value);
    })
    @observable
    public terrainData: File;

    constructor(mapId?: string) {
        super();

        this.mapId = mapId;
    }

    public async sendRequest() {

    }
}

// Jest cannot easily unit test a modal. Therefore content was taken out as its own component.
export default function MapImportModal(props: IFileUploadModalProps) {
    const { onCloseModal } = props;

    return (
        <Modal
            isOpen
            label="File upload"
            onRequestClose={onCloseModal}
            className="map-file-upload-modal"
            overlayClassName="map-file-upload-modal-overlay"
        >
            <RenderMapImportModalContent {...props} />
        </Modal>
    );
}

export const RenderMapImportModalContent = observer((props: IFileUploadModalProps) => {
    const { title, mapId, onCloseModal } = props;
    const [createImport, setCreateImport] = useState(new NewImport(mapId));
    const [displaySpinner, setDisplaySpinner] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const uploadAllFiles = async () => {
        if (await createImport.validate()) {
            return;
        }

        setCustomTag('import-a-map', 'import-map-data');
        setDisplaySpinner(true);
        try {
            const formData = new FormData();
            formData.append('CurrentMapData', createImport.mapData);
            formData.append('MapName', createImport.mapName?.trim() ?? '');
            formData.append('MapImportName', createImport.mapImportName?.trim() ?? '');

            if (createImport.importedImage) {
                formData.append('BackgroundImage', createImport.importedImage);
            } else if (createImport.existingImageId) {
                formData.append('ExistingImageId', createImport.existingImageId);
            }

            if (createImport.mapId) {
                formData.append('MapId', createImport.mapId);
            }

            if (!!createImport.terrainData) {
                formData.append('TerrainData', createImport.terrainData);
            }

            formData.append('IsSelenium', isSelenium().toString());

            const response = await axios.post(
                `${SERVER_URL}/api/entity/ImportVersionEntity/createNewImportVersion`,
                formData,
                {
                    headers: {
                        'Content-Type': 'mulitipart/form-data',
                    },
                },
            );

            alert('Map files uploaded successfully', 'success');
            setDisplaySpinner(false);

            store.routerHistory.push(`/mapedit/${response.data}`);
        } catch (e: any) {
            setDisplaySpinner(false);

            // TODO better handle errors from the server
            alert(e.response.data.errors[0]?.message ?? 'Uploading files failed', 'error');
        }
    };

    useEffect(() => {
        console.log(inputRef.current);
        inputRef.current?.focus();

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                uploadAllFiles();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <>
            {displaySpinner && <LoadView text="Importing map data..." timerInterval={150} />}
            <div className="modal__header">
                <h3>{title}</h3>
                <Button
                    icon={{ icon: 'cross', iconPos: 'icon-right' }}
                    className="cancel-button no-background"
                    display={Display.Text}
                    onClick={onCloseModal}
                    labelVisible={false}
                />
            </div>

            <div className="import-file">
                <div className="map-name-input">
                    <TextField
                        label={!mapId ? "Map name" : "Map import name"}
                        labelVisible
                        isRequired
                        model={createImport}
                        modelProperty={!mapId ? "mapName" : "mapImportName"}
                        onAfterChange={async () => {
                            const key = !mapId ? "mapName" : "mapImportName";
                            await createImport.validate(key);
                        }}
                        errors={createImport.getError(!mapId ? "mapName" : "mapImportName")}
                        inputProps={{ ref: inputRef }}
                    />
                </div>
            </div>

            <h4 className="subtitle">Import Map Files</h4>
            <FileUploadSection
                model={createImport}
                modelProperty="importedImage"
                title="BACKGROUND IMAGE DATA FILE"
                fileType="BackGroundImageYYYYMMDDHHMMSS.zip"
                required
                onAfterChange={async () => await createImport.validate('importedImage')}
                errors={createImport.getError('importedImage')}
                hasBackgroundImageOpts={props.hasBackgroundImageOpts}
                backgroundImageOptions={props.backgroundImageOptions}
                contentType=".zip"
            />
            <FileUploadSection
                model={createImport}
                modelProperty="mapData"
                title="AHS CURRENT MAP DATA FILE"
                fileType="AHSCurrentMapYYYYMMDDHHMMSS.zip"
                onAfterChange={async () => await createImport.validate('mapData')}
                errors={createImport.getError('mapData')}
                required
                contentType=".zip"
            />
            <FileUploadSection
                model={createImport}
                modelProperty="terrainData"
                title="DRIVABLE AREA DATA FILE"
                fileType="TerrainBoundaryYYYYMMDDHHMMSS.zip"
                required={false}
                onAfterChange={async () => await createImport.validate('terrainData')}
                errors={createImport.getError('terrainData')}
                contentType=".zip"
            />

            <ButtonGroup className="modal-actions">
                <Button
                    colors={Colors.Primary}
                    display={Display.Outline}
                    onClick={onCloseModal}
                    sizes={Sizes.Medium}
                >
                    Cancel
                </Button>
                <Button
                    className="next-button"
                    colors={Colors.Primary}
                    icon={{ icon: 'chevron-right', iconPos: 'icon-right' }}
                    display={Display.Solid}
                    onClick={uploadAllFiles}
                    sizes={Sizes.Medium}
                >
                    Next
                </Button>
            </ButtonGroup>
        </>
    );
});

