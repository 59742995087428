import SearchForm from '../SearchForm/SearchForm';
import { renderProfileIndicator } from '../Shared/ProfileIndicator';
import * as React from 'react';
import { Alignment, ButtonGroup } from '../Button/ButtonGroup';
import { Button, Display, Sizes } from '../Button/Button';
import { ModelCollection } from '../ModelCollection/ModelCollection';
import { observer } from 'mobx-react';
import {
	action, computed, observable,
} from 'mobx';
import type { ISearch } from '../CRUD/EntityCollection';
import { ICollectionHeaderProps } from '../Collection/CollectionHeaders';
import { MineUserEntity } from 'Models/Entities';
import { IWhereCondition } from '../ModelCollection/ModelQuery';
import If from '../If/If';
import { InviteUserModal } from '../Shared/InviteUserModal';
import { store } from 'Models/Store';
import MineUserPermissionsCombobox from '../Shared/MineUserPermissionsCombobox';
import { UserActivationToggleModal } from '../Shared/UserActivationToggleModal';
import axios from 'axios';
import alertToast from '../../../Util/ToastifyUtils';
import { SERVER_URL } from 'Constants';
import Spinner from '../Spinner/Spinner';
interface TeamPageComponentProps {
}

@observer
export default class TeamPageComponent extends React.Component<TeamPageComponentProps> {
	private modelCollectionRef = React.createRef<ModelCollection<MineUserEntity>>();

	@observable
	private search: ISearch = { searchTerm: '' };

	@observable
	private isOpenModal: boolean = false;

	@observable
	private isOpenActivationToggleModal: boolean = false;

	@observable
	private mineUser: MineUserEntity;

	@action
	private closeModal = () => {
		this.isOpenModal = false;
		this.isOpenActivationToggleModal = false;
	};

	@action
	private onNewUser = () => {
		this.isOpenModal = true;
	};

	/**
	 * On confirm click for either deactivate or reactivate account
	 */
	private onConfirm = async () => {
		if (this.mineUser.lockoutEnabled) {
			await axios.post(`${SERVER_URL}/api/account/reactivate`, { username: this.mineUser.userName })
				.then(() => {
					this.modelCollectionRef.current?.refetch();
				})
				.catch(() => {
					alertToast('Could not deactivate this user!', 'error');
				});
		} else {
			await axios.post(`${SERVER_URL}/api/account/deactivate`, { username: this.mineUser.userName })
				.then(() => {
					this.modelCollectionRef.current?.refetch();
					store.userId === this.mineUser.id ? store.routerHistory.push('/logout') : null;
				})
				.catch(() => {
					alertToast('Could not reactivate this user!', 'error');
				});
		}
		this.closeModal();
	};

	/**
	 * onActivationToggleClick
	 * @param mineUser
	 */
	@action
	private onActivationToggleClick = (mineUser: MineUserEntity) => {
		this.mineUser = mineUser;
		this.isOpenActivationToggleModal = true;
	};

	private userCollectionHeaders : Array<ICollectionHeaderProps<MineUserEntity>> = [
		{
			name: 'givenName',
			displayName: 'First name',
		},
		{
			name: 'familyName',
			displayName: 'Last name',
		},
		{
			name: 'email',
			displayName: 'Email',
		},
		{
			name: '',
			displayName: 'Permission',
			sortClicked: () => {},
			transformItem: model => {
				return (
					<div className="info-combobox">
						<MineUserPermissionsCombobox mineUser={model} />
					</div>
				);
			},
		},
		{
			name: '',
			displayName: 'Status',
			sortClicked: () => {},
			transformItem: model => {
				if (model.lockoutEnabled) return 'Deactivated';
				return model.emailConfirmed ? 'Activated' : 'Pending';
			},
		},
		{
			name: 'Deactivate',
			displayName: ' ',
			sortClicked: () => {},
			transformItem: model => {
				const buttonText = model.lockoutEnabled ? 'Reactivate' : 'Deactivate';
				const isDisabled = !store.isHitachiAdmin && !store.isAHSMineAdmin;

				return (
					<ButtonGroup alignment={Alignment.HORIZONTAL}>
						<Button
							className="map-list-button"
							display={Display.Solid}
							onClick={() => this.onActivationToggleClick(model)}
							sizes={Sizes.Medium}
							disabled={isDisabled}
						>{buttonText}
						</Button>
					</ButtonGroup>
				);
			},
		},

	];

	@action
	private onSearchTriggered = (searchTerm: string) => {
		this.search.searchTerm = searchTerm;
	}

	@computed
	private get collectionFilters():Array<Array<IWhereCondition<MineUserEntity>>> {
		const searchConditions = this.convertSearchConditions();
		return [...searchConditions];
	}

	private convertSearchConditions = ():Array<Array<IWhereCondition<MineUserEntity>>> => {
		if (this.search.searchTerm && this.search.searchTerm.trim() !== '') {
			const validTerm = `%${this.search.searchTerm.trim()}%`;
			const searchConditions: Array<IWhereCondition<MineUserEntity>> = [
				{
					path: 'givenName', comparison: 'like', value: validTerm, case: 'INVARIANT_CULTURE_IGNORE_CASE',
				},
				{
					path: 'familyName', comparison: 'like', value: validTerm, case: 'INVARIANT_CULTURE_IGNORE_CASE',
				},
				{
					path: 'email', comparison: 'like', value: validTerm, case: 'INVARIANT_CULTURE_IGNORE_CASE',
				},
			];

			return [searchConditions];
		}

		return [];
	}

	render() {
		return (
			<div className="team-page padded-page">
				<div className="row header-row">
					<h2 className="column page-title">
						Team
					</h2>
					<SearchForm
						model={this.search}
						onChange={this.onSearchTriggered}
						label="A search for entities"
						classNameSuffix="import-collection"
					/>
					{renderProfileIndicator()}
				</div>
				<div className="row align-right">
					<If condition={store.isAHSMineAdmin && !store.hasBackendAccess}>
						<ButtonGroup alignment={Alignment.HORIZONTAL}>
							<Button
								className="btn--primary"
								display={Display.Solid}
								onClick={this.onNewUser}
								sizes={Sizes.Medium}
								buttonProps={{ id: 'new_team__member_button' }}
								icon={{ icon: 'plus', iconPos: 'icon-left' }}
							>
								New Team Member
							</Button>
						</ButtonGroup>
					</If>
				</div>
				<ModelCollection
					{...this.props}
					ref={this.modelCollectionRef}
					model={MineUserEntity}
					headers={this.userCollectionHeaders}
					conditions={this.collectionFilters}
					orderBy={{
						path: 'modified',
						descending: true,
					}}
					perPage={10}
					customSpinner={<div className='spinner-div'><Spinner /></div>}
				/>
				<If condition={this.isOpenModal}>
					<InviteUserModal
						onCloseModal={this.closeModal}
					/>
				</If>
				<If condition={this.isOpenActivationToggleModal}>
					<UserActivationToggleModal
						onConfirm={this.onConfirm}
						reactivate={this.mineUser?.lockoutEnabled}
						onCloseModal={this.closeModal}
					/>
				</If>
			</div>
		);
	}
}
