import MapObject from '../MapObject';
import { NodeEntity, SignalSetEntity } from '../../../../../Models/Entities';
import MapRenderer from '../../MapRenderer';
import { LatLng, Point } from 'leaflet';
import * as PIXI from 'pixi.js';
import MapStore from "../../MapStore";
import DrivingZone from '../DrivingZone/DrivingZone';
import { getLeafletLatLng, pixiCoordinates, PixiCoordinates } from '../../Helpers/Coordinates';
import geoJsonToPoints from '../../Helpers/GeoJSON';

const RIGHT_SIGNAL_COLOUR = 0x90BEDE;
const LEFT_SIGNAL_COLOUR = 0xFB62F6;
const LINE_OPACITY = 0.7;
const LINE_THICKNESS = 20;
const HIT_AREA_THICKNESS = 40;

/**
 * Signal class - styles and renders signals
 */
export default class Signal extends MapObject<PixiCoordinates[]> {

	public signalSetEntity: SignalSetEntity;

	constructor(points: PixiCoordinates[], renderer: MapRenderer, signalSetEntity: SignalSetEntity, lookup?: MapStore, isDisplay?: boolean) {
		super(renderer, 'signal', points);

		lookup?.addEntityToMapObject(signalSetEntity.id, this);
		this.signalSetEntity = signalSetEntity;
		this.createGraphic(isDisplay ?? false);
	}

	public getSignalSetEntity() {
		return this.signalSetEntity;
	}

	panToObject() {
		this.renderer.getMap().panTo(this.getCentrePoint());
	}

	private getCentrePoint(): LatLng {
		return MapObject.getCentreOfPoints(this.getEntity(), this.renderer);
	}

	/**
	 * Renders signal in Pixi
	 */
	render() {
		const graphic = this.getGraphic();
		const points = this.getEntity();

		const widthMultiplier = this.isHighlighted ? 2 : 1;

		graphic.clear();

		graphic.zIndex = this.isHighlighted ? this.zIndexTop : this.zIndexBase;

		if (points.length === 0) {
			return;
		}

		let signalColour = RIGHT_SIGNAL_COLOUR;
		if(this.signalSetEntity.signalType === 'LEFT') {
			signalColour = LEFT_SIGNAL_COLOUR;
		}
		const firstPoint = points[0];
		graphic.lineStyle(LINE_THICKNESS * widthMultiplier, signalColour, LINE_OPACITY)
			.moveTo(firstPoint.x, firstPoint.y);

		for (let i = 1; i < points.length; i++) {
			graphic.lineTo(points[i].x, points[i].y);
		}
		// const allPoints = this.generateHitAreaFromLine(points, HIT_AREA_THICKNESS);
		// graphic.hitArea = new PIXI.Polygon(allPoints);
	}
}
