import {SaveCommand, SaveCommandType} from "./SaveCommand";

export default class DeletePathCommand extends SaveCommand {
	pathId: string;

	constructor(pathId: string) {
		super(SaveCommandType.DeletePath);

		this.pathId = pathId;
	}
}