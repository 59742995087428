/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsDrivingAreaEntity from 'Models/Security/Acl/VisitorsDrivingAreaEntity';
import MineUserDrivingAreaEntity from 'Models/Security/Acl/MineUserDrivingAreaEntity';
import HitachiAdminDrivingAreaEntity from 'Models/Security/Acl/HitachiAdminDrivingAreaEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IDrivingAreaEntityAttributes extends IModelAttributes {
	groupId: number;
	dataType: Enums.terraindatatype;
	dataSequence: number;
	formatVersion: string;

	importVersionId?: string;
	importVersion?: Models.ImportVersionEntity | Models.IImportVersionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] on begin
	outerPerimeter: string; // polygon
	innerPerimeters: string; // multiPolygon
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('DrivingAreaEntity', 'Driving Area')
// % protected region % [Customise your entity metadata here] end
export default class DrivingAreaEntity extends Model
	implements IDrivingAreaEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsDrivingAreaEntity(),
		new MineUserDrivingAreaEntity(),
		new HitachiAdminDrivingAreaEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Group Id'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Group Id',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public groupId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Group Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Data Type'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Data Type',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.terraindatatypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.terraindatatypeOptions),
		displayFunction: (attr: Enums.terraindatatype) => Enums.terraindatatypeOptions[attr],
	})
	public dataType: Enums.terraindatatype;
	// % protected region % [Modify props to the crud options here for attribute 'Data Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Data Sequence'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Data Sequence',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public dataSequence: number;
	// % protected region % [Modify props to the crud options here for attribute 'Data Sequence'] end

	// % protected region % [Modify props to the crud options here for attribute 'Format Version'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Format Version',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public formatVersion: string;
	// % protected region % [Modify props to the crud options here for attribute 'Format Version'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Import Version'] off begin
		name: 'Import Version',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.ImportVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Import Version'] end
	})
	public importVersionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public importVersion: Models.ImportVersionEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	@attribute()
	public innerPerimeters: string;

	@observable
	@attribute()
	public outerPerimeter: string;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IDrivingAreaEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IDrivingAreaEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.groupId !== undefined) {
				this.groupId = attributes.groupId;
			}
			if (attributes.dataType !== undefined) {
				this.dataType = attributes.dataType;
			}
			if (attributes.dataSequence !== undefined) {
				this.dataSequence = attributes.dataSequence;
			}
			if (attributes.formatVersion !== undefined) {
				this.formatVersion = attributes.formatVersion;
			}
			if (attributes.importVersionId !== undefined) {
				this.importVersionId = attributes.importVersionId;
			}
			if (attributes.importVersion !== undefined) {
				if (attributes.importVersion === null) {
					this.importVersion = attributes.importVersion;
				} else if (attributes.importVersion instanceof Models.ImportVersionEntity) {
					this.importVersion = attributes.importVersion;
					this.importVersionId = attributes.importVersion.id;
				} else {
					this.importVersion = new Models.ImportVersionEntity(attributes.importVersion);
					this.importVersionId = this.importVersion.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.outerPerimeter !== undefined) {
				this.outerPerimeter = attributes.outerPerimeter;
			}

			if (attributes.innerPerimeters !== undefined) {
				this.innerPerimeters = attributes.innerPerimeters;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		importVersion {
			${Models.ImportVersionEntity.getAttributes().join('\n')}
			${Models.ImportVersionEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(DrivingAreaEntity.prototype, 'created');
CRUD(modifiedAttr)(DrivingAreaEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
