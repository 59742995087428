import {Link, MapController, MapRenderer, NodeGraphic, Sublink} from "../../../../index";
import Path from "../../../MapObjects/Path/Path";
import {LinkEntity, NodeEntity} from "../../../../../../Models/Entities";
import {WaypointList} from "./Waypoint";
import {HoverState} from "../../../MapStateHandlerHelpers/PathToolHelper";
import {LeafletCoordinates, PixiCoordinates} from "../../../Helpers/Coordinates";
import {offsetAlongHeadingPixi} from "../../../Helpers/MapUtils";
import * as PIXI from "pixi.js";
import MapStore from "../../../MapStore";

export default class PathRenderHelper {
	private isRendering: boolean = false;

	private readonly mapController: MapController;
	private readonly mapRenderer: MapRenderer;
	private readonly mapStore: MapStore;

	private readonly waypoints: WaypointList;

	// All references needed to update the display
	private mapObjects: string[] = [];

	private lastReturnedLink: LinkEntity | undefined;

	private displayBuildModeLine = true;
	private lastWaypointId: string | undefined = undefined;

	private isConfirmingPath: boolean = false;

	private otherLinksChanged: { [key in string]: { oldId: string, newId: string }} = {};

	constructor(mapController: MapController, waypoint: WaypointList) {
		this.mapController = mapController;
		this.mapRenderer = mapController.getMapRenderer();
		this.mapStore = mapController.getMapLookup();
		this.waypoints = waypoint;
	}

	public dispose() {
		this.setDefaultCursor();
		this.lastReturnedLink = undefined;
	}

	public getPathObject(): Path | undefined {
		return this.mapObjects
			.map(x => this.mapRenderer.getObjectById(x))
			.find(x => x.getType() === 'path') as Path | undefined;
	}

	public getNodeObjects(): NodeGraphic[] {
		return this.mapObjects
			.map(x => this.mapRenderer.getObjectById(x))
			.filter(x => x.getType() === 'node') as NodeGraphic[];
	}

	public clearLastReturnedLink() {
		this.lastReturnedLink = undefined;
	}

	private startRendering() {
		this.isRendering = true;
	}

	private stopRendering() {
		this.isRendering = false;
	}

	public isCurrentlyRendering() {
		return this.isRendering;
	}

	public setDefaultCursor() {
		this.mapController.setDefaultCursor();
	}

	public setMoveCursor() {
		this.mapController.setCursor('move');
	}

	public setRotateCursor() {
		this.mapController.setRotateCursor(true);
	}

	public setDisabledCursor() {
		this.mapController.setCursor('not-allowed');
	}

	public setBuildMode(isBuildMode: boolean) {
		this.displayBuildModeLine = isBuildMode;

		if (!this.displayBuildModeLine) {
			this.clearGuideline();
		} else {
			this.updateDisplay();
		}
	}

	public setConfirmingPath(isConfirmingPath: boolean, link: LinkEntity) {
		this.isConfirmingPath = isConfirmingPath;
		this.displayBuildModeLine = false;

		this.clearGuideline();

		this.updateDisplay(link);
	}

	public clearDisplay(needRerender: boolean = false) {
		if (this.isCurrentlyRendering() || this.mapObjects.length === 0) {
			return;
		}

		this.startRendering();

		this.mapObjects.forEach(x => this.mapRenderer.removeObject(x));
		this.mapObjects = [];

		this.clearOtherLinksDisplay();

		if (needRerender) {
			this.mapRenderer.rerender();
		}

		this.stopRendering();
	}

	public isHoveringOverWaypoint(location: LeafletCoordinates): Readonly<[HoverState, string?]> {
		const mouseLocation = this.mapRenderer.project(location);

		// Check each waypoint for a hover state
		const hoverState = this.waypoints.iter
			.map((waypoint, i) => [
				this.isMouseLocationOverWaypoint(this.mapRenderer.project(waypoint), waypoint.heading, mouseLocation),
				i
			]).find(([x, _i]) => x !== HoverState.DEFAULT);

		return hoverState === undefined ?
			[HoverState.DEFAULT] : // This means we are not hovering over anything
			[hoverState[0], this.waypoints.iter[hoverState[1]].id]; // Return the object we are hovering over
	}

	public updateDisplay(link?: LinkEntity, otherLinks?: LinkEntity[]) {
		if (this.isCurrentlyRendering()) {
			return;
		}

		// Clear the current link/waypoints from the map
		this.clearDisplay();

		// Start rendering, so we can prevent other actions from causing a rerender
		this.startRendering();

		if (!!otherLinks) {
			this.updateOtherLinksDisplay(otherLinks);
		}

		// Remove the last returned link if it is not a full path (eg. we have deleted the waypoints after creating a
		// full path)
		if (link === undefined && !!this.lastReturnedLink && !this.waypoints.isFullPath()) {
			this.lastReturnedLink = undefined;
		}

		// Decide if we want to rerender the last link we received
		if (!!link) {
			this.lastReturnedLink = !this.waypoints.isFullPath() ? undefined : link;
		}
		const linkToRender = !this.waypoints.isFullPath() ? undefined : link ?? this.lastReturnedLink;

		// If we don't have a link to render, we can just render the waypoints
		if (!linkToRender) {
			this.waypoints.iter.forEach((x, i) => {
				// It can only be the last node if the path has at least two waypoints
				const isFinalNode = i === this.waypoints.length - 1 && this.waypoints.isFullPath();
				const nodeGraphic = new NodeGraphic(this.mapRenderer, x.toNode(isFinalNode), {
					isEditMode: true,
					isSelected: true
				});

				this.updateFinalWaypoint(nodeGraphic);

				nodeGraphic.isEditMode = true;
				this.mapObjects.push(nodeGraphic.getId());
				this.mapRenderer.addObject(nodeGraphic);
			});
		} else {
			// If we are confirming the path, we want to add the relationship between entity to map object
			const pathMapStore = this.isConfirmingPath ? this.mapStore : undefined;
			const path = new Path(linkToRender, this.mapRenderer, pathMapStore, {
				isSelected: !this.isConfirmingPath,
				isEditMode: !this.isConfirmingPath,
				allLookup: false,
				forceBuild: true
			});

			if (!this.isConfirmingPath) {
				this.updateFinalNodeFromPath(path);

				this.processErrorsFromLink(path);
			}

			this.mapObjects.push(path.getId());
			this.mapRenderer.addObject(path);
		}

		this.mapRenderer.rerender();

		this.stopRendering();
	}

	public updateOtherLinksDisplay(links: LinkEntity[]) {
		// Returning early for now as this has been causing some minor bugs. will need to be addressed when working on
		// the driving zone updates
		return;

		// Clear the existing links
		this.clearOtherLinksDisplay();

		links.forEach(x => {
			const mapObjectId = this.mapStore.getMapObjectId(x.getModelId(), 'link');
			const mapObject = this.mapRenderer.getObjectById(mapObjectId);
			const oldPath = mapObject?.getParent() as Path;


			if (!oldPath) {
				return;
			}

			oldPath.setRenderable(false);

			const path = new Path(x, this.mapRenderer, undefined, {
				isSelected: false,
				allLookup: false,
				forceBuild: true
			});
			this.mapRenderer.addObject(path);
			console.log('Adding link', path.getId());

			this.otherLinksChanged[x.id] = {
				oldId: oldPath?.getId(),
				newId: path.getId()
			};
		});
	}

	public clearOtherLinksDisplay() {
		Object.entries(this.otherLinksChanged).forEach(([_key, { oldId, newId }]) => {
			console.log('Clearing link', oldId, newId);
			this.mapRenderer.removeObject(newId);

			const path = this.mapRenderer.getObjectById(oldId) as Path;
			path?.setRenderable(true);
		});

		this.otherLinksChanged = {};
	}

	private processErrorsFromLink(path: Path) {
		const hasNodeError = path.getChildren().filter(x => x.getType() === 'node')
			.map(x => {
				const entity = x.getEntity();
				x.isError = entity.getErrorCount() !== 0;
				x.isWarning = entity.getWarningCount() !== 0;

				return x.isError || x.isWarning;
			}).some(y => y);

		path.getChildren()
			.filter(x => x.getType() === 'sublink')
			.map(x => {
				const s = x as Sublink;
				const entity = s.getSublinkEntity();
				const drivingZone = s.getChildren();

				x.isError = entity.getErrorCount() !== 0;
				x.isWarning = entity.getWarningCount() !== 0;

				drivingZone.forEach(dz => dz.setRenderable(!hasNodeError));
				x.setRenderable(!hasNodeError);

				return x.isError || x.isWarning;
			}).find(y => y);

		path.getChildren()
			.filter(x => x.getType() === 'link')
			.forEach(x => {
				const s = x as Link;
				const entity = s.getLinkEntity();

				x.isError = entity.getErrorCount() !== 0 || hasNodeError;
				x.isWarning = entity.getWarningCount() !== 0;

				return x.isError || x.isWarning;
			});
	}

	private updateFinalNodeFromPath(path: Path) {
		const lastWaypointId = this.waypoints.lastWaypoint?.id;

		// If we find a matching id, it should be safe to assume it is a NodeGraphic
		const nodeGraphic = path
			.getChildren()
			.find(x => {
				const entity = x.getEntity();
				return entity instanceof NodeEntity && entity.getModelId() === lastWaypointId;
			}) as NodeGraphic;

		this.updateFinalWaypoint(nodeGraphic);
	}

	private updateFinalWaypoint(nodeGraphic?: NodeGraphic) {
		if (this.displayBuildModeLine && !!nodeGraphic) {
			nodeGraphic.startGuideLine();

			this.lastWaypointId = nodeGraphic.getId();
		}
	}

	private clearGuideline() {
		if (!!this.lastWaypointId) {
			const node = this.mapRenderer.getObjectById(this.lastWaypointId) as NodeGraphic;
			node?.clearGuideLine();
		}
	}

	private isMouseLocationOverWaypoint(nodePoint: PixiCoordinates, nodeHeading: number, location: PixiCoordinates) {
		const waypointRadius = NodeGraphic.startEndNodeRadius.outerRadius;

		// Calculate the necessary x, y offsets to add to the nodePoint to get the heading hit areas
		const offset = offsetAlongHeadingPixi(NodeGraphic.headingLineLength, nodeHeading);
		const headingFrontHitArea = new PIXI.Circle(nodePoint.x + offset.x, nodePoint.y + offset.y, waypointRadius);
		const headingBackHitArea = new PIXI.Circle(nodePoint.x - offset.x, nodePoint.y - offset.y, waypointRadius);
		const nodeHitArea = new PIXI.Circle(nodePoint.x, nodePoint.y, waypointRadius);

		const { x, y } = location;
		if (headingFrontHitArea.contains(x, y)) {
			return HoverState.HEADING_FRONT;
		}
		if (headingBackHitArea.contains(x, y)) {
			return HoverState.HEADING_BACK;
		}
		if (nodeHitArea.contains(x, y)) {
			return HoverState.WAYPOINT;
		}

		return HoverState.DEFAULT;
	}
}