import React, { useRef } from 'react';
import { ReactNode, useEffect, useState } from 'react';
import MapProperties from './MapProperties';
import { disableMapDomEvents } from '../Map/Helpers/MapDOMEvents';
import classNames from 'classnames';
import { Button, Colors, Display } from '../../Components/Button/Button';
import MapController, { MapType } from '../Map/MapController';
import {
	BackgroundImageEntity,
	NodeEntity,
	BayEntity,
	LinkEntity,
	MapToolParamEntity,
	AreaEntity,
	SublinkEntity,
	BeaconEntity,
	SegmentEntity,
} from '../../../Models/Entities';
import LinkProperties from './LinkProperties';
import BayProperties from './BayProperties';
import {
	Area, Link, NodeGraphic, Sublink, Bay,
} from '..';
import SublinkProperties from './SublinkProperties';
import AreaProperties from './AreaProperties';
import NodeProperties from './NodeProperties';
import Location from '../Map/MapObjects/Location/Location';
import Beacon from '../Map/MapObjects/Beacon/Beacon';
import LocationProperties from './LocationProperties';
import BeaconProperties from './BeaconProperties';
import Segment from '../Map/MapObjects/Segment/Segment';
import SegmentProperties from './SegmentProperties';
import RulerProperties from './RulerProperties';
import ConnectivityProperties from './ConnectivityProperties';
import { RealWorldCoordinates } from '../Map/Helpers/Coordinates';
import PathProperties from "./PathProperties";
import {ValidPathDirections, WaypointList} from "../Map/MapStateHandlers/PathTool/Helpers/Waypoint";
import {nodetask} from "../../../Models/Enums";
import {ErrorsAndWarningsObject} from "./ErrorsAndWarningsProperties";

interface IPropertiesPanel {
	map: MapController;
	backgroundEntity: BackgroundImageEntity;
	mapToolParamEntity: MapToolParamEntity;
}
export interface IPropertiesPanelState {
	type: MapType;
	entity?: unknown;
	params?: IPropertiesPanelParams;
}

export interface IClothoidParams {
	isDirectionReadOnly: boolean;
	isTaskReadOnly: boolean;
	isHCMPGButtonReadonly: boolean;
	isWaypointLocationReadOnly: boolean;
	direction: ValidPathDirections;
	nextWaypointTask: nodetask;
	errorsAndWarnings: ErrorsAndWarningsObject;
}

export interface IPropertiesPanelParams {
	onUnselect?: () => void;
	isSnapped?: boolean;
	isReadOnly?: boolean;
	bayTypeOptions?: unknown;
	clothoidParams?: IClothoidParams;
}

export const convertToFixed = (value: string | number | undefined, decimalPlaces: number): string => {
	if (value === undefined) {
		return '';
	}

	if (typeof value === 'number') {
		return value.toFixed(decimalPlaces);
	}
	return Number(value).toFixed(decimalPlaces);
};

export const truncateNumber = (value: string | number | undefined) => {
	if (value === undefined) {
		return '';
	}

	let v = typeof value === 'number' ? value : Number(value ?? 0)
	return Math.trunc(v).toString();
};

export const isANumber = (value: any): boolean => {
	if (typeof value === 'string') {
		return !!(value.trim()?.length);
	}

	return value != null && !isNaN(value)
}

/**
 * Round numeric property to two decimal places
 * @param value
 * @returns
 */
export const roundNumber = (value: string) => value.match('\\..{3,}') ? Number(value).toFixed(1) : value;

/**
 * Fix number to two decimal places (appending zeros if necessary)
 * Only use this for read-only values as it will change input behaviour
 * @param value
 * @returns
 */
export const twoDecimalsFixedReadOnly = (value: string | number | undefined) => convertToFixed(value, 2);

export const submitFormOnEnter = (func: () => void) => {
	return (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			func();
		}
	};
};

/**
 * Manage properties panel to render. Different properties panel can be rendered based on the events emitted.
 *
 * @param props
 * - map: MapController is used for listening to events emitted from the MapController and MapEventHandler
 * - backgroundEntity: BackgroundImageEntity is used for setting up the initial state.
 * - mapToolParamEntity: MapToolParamEntity
 */
export default function PropertiesPanel({ map, backgroundEntity, mapToolParamEntity }: IPropertiesPanel) {
	const initialState: IPropertiesPanelState = {
		type: 'map' as MapType,
		entity: backgroundEntity,
		params: {
			isSnapped: false,
			isReadOnly: false,
			bayTypeOptions: [],
		}
	};
	const [state, setState] = useState<IPropertiesPanelState>(initialState);

	// A reference to the state is needed to access it within the useEffect hook
	const stateRef = useRef(state);
	useEffect(() => { stateRef.current = state; });

	useEffect(() => {
		// listen for events emitted from MapEventHandler
		map.getEventHandler().addListener('onPropertiesPanel',
			(type, entity, params) => {
				const currentState = stateRef.current;

				// @ts-ignore
				if (currentState.params?.onUnselect && !!entity && entity['id'] !== map.getHighlightedEntityId()) {
					currentState.params.onUnselect();
				}
				setState({
					type, entity, params,
				});
			});

		return () => {
			setState(initialState);

			map.getEventHandler().removeAllListeners('onPropertiesPanel');
		};
	}, []);

	let children;
	let entity;

	switch (state.type) {
		case 'link':
			if (state.entity instanceof Link) {
				entity = (state.entity as Link).getParent()?.getEntity() as LinkEntity;
			} else {
				entity = state.entity as LinkEntity;
			}
			children = <LinkProperties key={entity.id} entity={entity} mapParams={mapToolParamEntity} map={map} />;
			break;
		case 'sublink':
			if (state.entity instanceof Sublink) {
				entity = (state.entity as Sublink).sublinkEntity;
			} else {
				entity = state.entity as SublinkEntity;
			}
			children = <SublinkProperties  key={entity.id} entity={entity} map={map} />;
			break;
		case 'node':
			if (state.entity instanceof NodeGraphic) {
				entity = (state.entity as NodeGraphic).getEntity();
			} else {
				entity = state.entity as NodeEntity;
			}
			children = <NodeProperties entity={entity} map={map} />;
			break;
		case 'area':
			if (state.entity instanceof Area) {
				entity = (state.entity as Area).getEntity();
			} else {
				entity = state.entity as AreaEntity;
			}
			children = <AreaProperties  key={entity.id} entity={entity} map={map} />;
			break;
		case 'path':
			children = <PathProperties waypoints={state.entity as WaypointList} map={map} clothoidParams={state.params?.clothoidParams} />;
			break;
		case 'bay':
			{
				if (state.entity instanceof Bay) {
					entity = (state.entity as Bay).getEntity();
				} else {
					entity = state.entity as BayEntity;
				}
				children = (
					<BayProperties
						key={entity.id}
						entity={entity}
						map={map}
						isSnapped={state.params?.isSnapped}
						isBayTypeReadonly={state.params?.isReadOnly}
						bayOptions={state.params?.bayTypeOptions}
					/>
				);
			}
			break;
		case 'location':
			if (state.entity instanceof Location) {
				entity = (state.entity as Location).getEntity();
			} else {
				entity = state.entity as AreaEntity;
			}

			children = <LocationProperties key={entity.id} entity={entity} map={map} />;
			break;
		case 'beacon':
			if (state.entity instanceof Beacon) {
				entity = (state.entity as Beacon).getEntity();
			} else {
				entity = state.entity as BeaconEntity;
			}
			children = <BeaconProperties key={entity.id} entity={entity} map={map} />;

			break;
		case 'segment':
			if (state.entity instanceof Segment) {
				entity = (state.entity as Segment).getEntity();
			} else {
				entity = state.entity as SegmentEntity;
			}
			children = <SegmentProperties  key={entity.id} entity={entity} />;
			break;
		case 'ruler':
			children = <RulerProperties entity={state.entity as RealWorldCoordinates[]} map={map} />;
			break;
		case 'connection':
			children = <ConnectivityProperties />;
			break;
		case 'map':
		default:
			children = <MapProperties key={backgroundEntity.id} backgroundImage={backgroundEntity} />;
	}

	if (entity) {
		map.highlightObjectByEntityId(entity.id ?? entity._clientId, state.type);
	} else {
		map.unhighlightObject();
	}

	return (
		<PropertiesSidePanel map={map}>
			{children}
		</PropertiesSidePanel>
	);
}

interface IPropertiesSidePanel {
	map: MapController;
	children: ReactNode;
}

/**
 * Render properties side panel for a given map object or mode. It can also be hidden with the toggle button.
 * @param props Map object side panel child component to render
 * @constructor
 */
export function PropertiesSidePanel(props: IPropertiesSidePanel) {
	const { map, children } = props;
	const [displaySidePanel, setDisplaySidePanel] = useState(true);

	useEffect(() => {
		disableMapDomEvents('map-sidebar');
	}, []);

	const toggleSidePanel = () => {
		setDisplaySidePanel(!displaySidePanel);
		map.getEventHandler().emit('onShiftCursorLocationPanel', !displaySidePanel);
	};

	const toggleButton = (
		<div className="sidebar-button">
			<Button
				className="no-background"
				icon={{ icon: displaySidePanel ? 'chevrons-right' : 'chevrons-left', iconPos: 'icon-bottom' }}
				colors={Colors.White}
				display={Display.Text}
				onClick={toggleSidePanel}
			/>
		</div>
	);

	const content = (
		<div className={classNames('sidebar-info')}>
			{children}
		</div>
	);

	return (
		<div
			id="map-sidebar"
			className={classNames('sidebar', !displaySidePanel ? 'hide' : undefined)}
		>
			{toggleButton}
			{displaySidePanel ? content : null}
		</div>
	);
}
