import React from 'react';
import Modal from '../Modal/Modal';
import {
	Button, Colors, Display, Sizes,
} from '../Button/Button';
import { ButtonGroup } from '../Button/ButtonGroup';

interface IChangeUserPermissionsModalProps {
	onCloseModal: () => void;
	promote: boolean;
	onConfirm: () => void;
}

const TITLE_PROMOTE_USER = 'Promote to AHS Admin?';
const SUBTITLE_PROMOTE_USER = 'Can build maps, invite other users to the mine and edit organisation settings.';

const TITLE_DEMOTE_USER = 'Demote to AHS Builder?';
const SUBTITLE_DEMOTE_USER = 'Can build maps, but cannot invite other users or edit organisation settings.';

export function ChangeUserPermissionsModal(props: IChangeUserPermissionsModalProps) {
	const { onCloseModal } = props;

	return (
		<Modal
			isOpen
			label=""
			onRequestClose={onCloseModal}
			className="mat-modal user-permissions-modal"
			overlayClassName="map-file-upload-modal-overlay"
		>
			<RenderChangeUserPermissionsModalContent {...props} />
		</Modal>
	);
}

export function RenderChangeUserPermissionsModalContent(props: IChangeUserPermissionsModalProps) {
	const { onCloseModal, promote, onConfirm } = props;

	return (
		<>
			<div className="modal__header user-permissions-modal">
				{
					promote
						? <h3>{TITLE_PROMOTE_USER}</h3>
						: <h3>{TITLE_DEMOTE_USER}</h3>
				}
			</div>

			{
				promote
					? <p className="user-permissions-modal-subheading">{SUBTITLE_PROMOTE_USER}</p>
					: <p className="user-permissions-modal-subheading">{SUBTITLE_DEMOTE_USER}</p>
			}

			<ButtonGroup className="invite-modal-actions">
				<Button
					colors={Colors.Primary}
					display={Display.Outline}
					onClick={onCloseModal}
					sizes={Sizes.Medium}
				>
					Cancel
				</Button>
				<Button
					className="next-button"
					colors={Colors.Primary}
					display={Display.Solid}
					onClick={onConfirm}
					sizes={Sizes.Medium}
				>
					Confirm
				</Button>
			</ButtonGroup>
		</>
	);
}
