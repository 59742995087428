import MapRenderer from "../MapRenderer";
import {LeafletMouseEvent} from "leaflet";
import {PixiCoordinates} from "./Coordinates";
import {Graphics} from "pixi.js";
import * as uuid from 'uuid';
import {MapEventHandler} from "../../index";

class DebugObject {
    public id: string = uuid.v4();
    public iteration: number = 0;

    protected graphic: Graphics;
    protected renderer: MapRenderer;

    public finished: boolean = false;

    constructor(renderer: MapRenderer) {
        this.renderer = renderer;
    }

    public incrementIteration() {
        this.iteration++;
    }

    public render(): boolean {
        return this.finished;
    }

    public delete() {
        this.rmGraphic();
    }

    createGraphic(): Graphics {
        if (!!this.graphic) {
            this.rmGraphic();
        }
        this.graphic = new Graphics();
        this.graphic.name = this.id;

        this.renderer.getRootContainer()
            .addChild(this.graphic);

        return this.graphic;
    }

    rmGraphic() {
        this.graphic.removeFromParent();
    }

    getBlankGraphic(): Graphics {
        if (!!this.graphic) {
            this.graphic.clear();
            return this.graphic;
        } else {
            return this.createGraphic();
        }
    }
}

class DebugPoint extends DebugObject {
    public id: string = uuid.v4();
    public iteration: number = 0;

    public location: PixiCoordinates;

    constructor(location: PixiCoordinates, renderer: MapRenderer) {
        super(renderer);

        this.location = location;
    }

    render(): boolean {
        if (this.finished) {
            return this.finished;
        }

        const graphic = this.getBlankGraphic();

        const fillAlpha = 1 - (this.iteration * 0.005);
        graphic.beginFill(0x00ff00, fillAlpha);
        const { x, y } = this.location;
        graphic.drawCircle(x, y, 10);

        if (fillAlpha <= 0) {
            this.finished = true;
            this.rmGraphic();
        }

        return this.finished;
    }
}

class DebugLine extends DebugObject {
    private readonly from: PixiCoordinates;
    private to: PixiCoordinates;

    private startPoint: DebugPoint;
    private endPoint: DebugPoint;

    constructor(from: PixiCoordinates, renderer: MapRenderer) {
        super(renderer);

        this.from = from;
        this.startPoint = new DebugPoint(this.from, this.renderer);
    }

    public setToLocation(to: PixiCoordinates) {
        this.to = to;
    }

    public confirmToPoint() {
        this.endPoint = new DebugPoint(this.to, this.renderer);
    }

    public incrementIteration() {
        super.incrementIteration();

        this.startPoint.incrementIteration();
        this.endPoint?.incrementIteration();
    }

    delete() {
        this.startPoint.delete();
        this.endPoint.delete();

        this.rmGraphic();
    }

    render() {
        if (this.finished) {
            return this.finished;
        }

        const graphic = this.getBlankGraphic();

        this.startPoint.render();

        const fillAlpha = 1 - (this.iteration * 0.005);

        if (!!this.to) {
            graphic.lineStyle({
                width: 2,
                color: 0x00ff00,
                alpha: fillAlpha,
            });
            graphic.moveTo(this.from.x, this.from.y);
            graphic.lineTo(this.to.x, this.to.y);

            if (!!this.endPoint) {
                this.endPoint.render();
            }
        }

        if (fillAlpha <= 0) {
            this.finished = true;
            this.rmGraphic();
        }

        return this.finished;
    }
}


export default class MapDebugHelper {
    private readonly renderer: MapRenderer;
    private readonly eventHandler: MapEventHandler;

    private lines: { [key in string]: DebugLine } = {};
    private points: { [key in string]: DebugPoint } = {};

    private lineInProgress: DebugLine | undefined = undefined;

    private updateInterval: unknown;
    private renderTimeout: unknown;

    constructor(eventHandler: MapEventHandler) {
        this.renderer = eventHandler.getRenderer();
        this.eventHandler = eventHandler;
    }

    private isEnabled() {
        return this.eventHandler.isDebugMode();
    }

    public addDebugPointFromMouseLocation(event: LeafletMouseEvent) {
        if (!this.isEnabled()) {
            return;
        }

        this.addDebugPoint(this.renderer.project(event.latlng));

        this.startAnimationLoop();
    }

    public addDebugLineFromMouseLocation(event: LeafletMouseEvent) {
        if (!this.isEnabled()) {
            return;
        }

        const mouseLocation = this.renderer.project(event.latlng);

        if (!!this.lineInProgress) {
            this.lineInProgress.setToLocation(mouseLocation);
            this.lineInProgress.confirmToPoint();

            this.lines[this.lineInProgress.id] = this.lineInProgress;
            this.lineInProgress = undefined;

            this.startAnimationLoop();
        } else {
            this.lineInProgress = new DebugLine(mouseLocation, this.renderer);

            this.lineInProgress.render();
            this.render();
        }
    }

    public updateDebugLineFromMouseLocation(event: LeafletMouseEvent) {
        if (!this.isEnabled()) {
            return;
        }

        const mouseLocation = this.renderer.project(event.latlng);

        if (!this.lineInProgress) {
            return;
        }

        this.lineInProgress.setToLocation(mouseLocation);
        this.lineInProgress.render();
        this.render();
    }

    public addDebugLine(from: PixiCoordinates, to: PixiCoordinates): DebugLine {
        const line = new DebugLine(from, this.renderer);

        line.setToLocation(to);
        line.confirmToPoint();

        this.lines[line.id] = line;

        line.render();
        this.render();

        return line;
    }

    public addDebugPoint(coords: PixiCoordinates): DebugPoint {
        const point = new DebugPoint(coords, this.renderer);

        this.points[point.id] = point;

        point.render();
        this.render();

        return point;
    }

    public rmDebugObject(id: string): boolean {
        const point = this.points[id];
        if (!!point) {
            point.delete();

            return true;
        }

        const line = this.lines[id];
        if (!!line) {
            line.delete();

            return true;
        }

        return false;
    }

    private render() {
        if (this.renderTimeout !== undefined) {
            return; // Already going to render
        }

        this.renderTimeout = setTimeout(() => {
            this.renderer.rerender();
            this.renderTimeout = undefined;
        }, 0);
    }

    private animationInterval = 10;

    private startAnimationLoop() {
        if (this.updateInterval !== undefined) {
            return; // Don't need to start a new animation
        }

        this.updateInterval = setTimeout(this.animationIteration.bind(this), this.animationInterval);
    }

    private animationIteration() {
        const objectCount =
            this.animateObjects(this.points)
            + this.animateObjects(this.lines);

        if (objectCount === 0) {
            clearTimeout(this.updateInterval as any);
            this.updateInterval = undefined;
        } else {
            this.updateInterval = setTimeout(
                this.animationIteration.bind(this),
                this.animationInterval
            );
        }

        this.render();
    }


    private animateObjects(objects: { [key in string]: DebugObject }): number {
        const objs = Object.values(objects);

        const finishedObjs: string[] = [];
        objs.forEach(x => {
            x.incrementIteration();
            const finished = x.render();

            if (finished) {
                finishedObjs.push(x.id);
            }
        });

        finishedObjs.forEach(x => delete objects[x]);

        return objs.length - finishedObjs.length;
    }

}