import * as PIXI from 'pixi.js';
import { LatLng } from 'leaflet';
import MapObject from '../MapObject';
import L from 'leaflet';
import { BayEntity } from 'Models/Entities';
import { MapController } from 'Views/MapComponents';
import { getBayColour, getBayOutlineStyleOptions } from './BayStyles';
import { getLeafletLatLng, RealWorldCoordinates, realWorldCoordinates } from '../../Helpers/Coordinates';
import { getJsonObject } from '../../Helpers/GeoJSON';

const BAY_DEFAULT_WIDTH = 10;
const BAY_DEFAULT_LENGTH = 16;
const BAY_DEFAULT_EXTRA_LENGTH = 2;
const BAY_SHAPE_WIDTH = 4;
const BAY_CROSS_WIDTH = 4;
const HIT_AREA_THICKNESS = 8;
const CROSS_SEGMENT_LENGTH = 5;

interface IBayCoordinates {
	bottomLeft: PIXI.Point;
	bottomRight: PIXI.Point;
	bottomCenter: PIXI.Point,
	topRight: PIXI.Point;
	topCenter: PIXI.Point;
	topLeft: PIXI.Point;
	center: PIXI.Point;
}

/**
 * Renders bays in pixi
 */
export default class BayPixi extends MapObject<BayEntity> {
	private bayWidth: number;
	private bayLength: number;
	private bayPoints: IBayCoordinates;

	private pivotHandlesEnabled = false;

	private bayLocation: RealWorldCoordinates;

	constructor(bay: BayEntity, controller: MapController) {
		super(controller.getMapRenderer(), 'bay', bay);
		const lookup = controller.getMapLookup();
		const mapParams = controller.getImportVersion().maptoolparam;
		lookup?.addEntityToMapObject(bay.getModelId(), this);
		this.renderer = controller.getMapRenderer();
		this.bayWidth = BAY_DEFAULT_WIDTH;
		this.bayLength = BAY_DEFAULT_LENGTH;

		if (mapParams) {
			// slight adjustments to match original SVG
			this.bayWidth = mapParams.bayWidth;
			this.bayLength = mapParams.bayLength;
		} else {
			console.warn('No maptool params. Using default bayWidth / bayLength');
		}

		this.createGraphic(); // bay outline
		this.createGraphic(); // center cross
		this.createGraphic(); // bay outline rotate handles
	}

	public enableHandles() {
		this.pivotHandlesEnabled = true;
	}

	public disableHandles() {
		this.pivotHandlesEnabled = false;
	}

	getHandles() {
		return this.getGraphic(2);
	}

	getCrossHandle() {
		return this.getGraphic(1);
	}

	panToObject() {
		this.renderer.getMap().panTo(this.getCentrePoint());
	}

	projectedPoint(x: number, y: number) {
		const coordinates = this.renderer.project(realWorldCoordinates(y, x));
		const point = new PIXI.Point(coordinates.x, coordinates.y);

		return point;
	}

	setBayPoints(cx: number, cy: number, lineWidth: number) {
		const halfLineWidth = lineWidth / 2;
		const x = this.bayWidth / 2;
		const y = this.bayLength / 2; // distance from center to back
		const y1 = y + BAY_DEFAULT_EXTRA_LENGTH; // distance from center to front

		this.bayLocation = realWorldCoordinates(cy, cx);

		this.bayPoints = {
			bottomLeft: this.projectedPoint(cx - x, cy - y),
			bottomCenter: this.projectedPoint(cx, cy - y + 1.3),
			bottomRight: this.projectedPoint(cx + x, cy - y),
			topRight: this.projectedPoint(cx + x, cy + y),
			topCenter: this.projectedPoint(cx, cy + y1),
			topLeft: this.projectedPoint(cx - x, cy + y),
			center: this.projectedPoint(cx, cy),
		};
	}

	getBackInPoints() {
		const p = this.bayPoints;
		return [p.bottomLeft, p.bottomRight, p.topRight, p.topCenter, p.topLeft, p.bottomLeft];
	}

	getDriveThroughPoints() {
		const p = this.bayPoints;
		return [p.bottomLeft, p.bottomCenter, p.bottomRight, p.topRight, p.topCenter, p.topLeft, p.bottomLeft];
	}

	getBayOutlinePoints() {
		const bay = this.entity;
		let points = this.getBackInPoints();
		switch (bay.spotDir) {
			case 'DRIVETHROUGH':
				points = this.getDriveThroughPoints();
				break;
			case 'BACKIN':
				break;
			case 'INVALID':
				console.log(`Spotdir INVAID. Id: ${bay.id}. Using default image}`);
				break;
			default:
				console.error(`Spotdir ${bay.spotDir} not found. Id: ${bay.id}`);
				break;
		}
		return points;
	}

	setPivotAndAngle(graphic: PIXI.Graphics, pivot: PIXI.Point, angle: number) {
		const { x, y } = pivot;
		graphic.pivot.set(x, y);
		graphic.x = x;
		graphic.y = y;
		graphic.angle = angle;
	}

	render() {
		// Calculate and set bay points based on location coordinate
		const widthMultiplier = this.isHighlighted ? 2 : 1;
		const lineWidth = BAY_SHAPE_WIDTH * widthMultiplier;
		const zIndex = this.isHighlighted ? this.zIndexTop : this.zIndexBase;
		const json = getJsonObject(this.entity.bayLocation);
		this.setBayPoints(json.coordinates[0], json.coordinates[1], lineWidth);

		const bay = this.entity;

		// Outline of the bay
		const outlineGraphic = this.getGraphic();
		outlineGraphic.clear();
		outlineGraphic.zIndex = zIndex;

		const { center } = this.bayPoints;
		const angle = this.entity.heading;
		const bayColor = getBayColour(bay.bayType);
		const outlineOptions = getBayOutlineStyleOptions(bayColor, lineWidth);
		outlineGraphic.lineStyle(outlineOptions);

		// Draw the outline shape
		const outlinePoints = this.getBayOutlinePoints();
		outlineGraphic.drawPolygon(outlinePoints);

		// Pivot / Angle
		this.setPivotAndAngle(outlineGraphic, center, angle);

		// Draw the center cross
		const crossLength = CROSS_SEGMENT_LENGTH;
		const crossGraphic = this.getGraphic(1);
		crossGraphic.clear();
		crossGraphic.zIndex = zIndex;

		// Calculate cross coordinates
		const xC = center.x;
		const yC = center.y;
		const crossCoordinates = [[xC - crossLength, yC - crossLength], [xC + crossLength, yC + crossLength],
			[xC - crossLength, yC + crossLength], [xC + crossLength, yC - crossLength]];
		const crossPoints = crossCoordinates.map(xy => new L.Point(xy[0], xy[1]));

		// Draw center cross
		crossGraphic.lineStyle(BAY_CROSS_WIDTH, bayColor).moveTo(crossPoints[0].x, crossPoints[0].y);
		crossGraphic.lineTo(crossPoints[1].x, crossPoints[1].y);
		crossGraphic.moveTo(crossPoints[2].x, crossPoints[2].y);
		crossGraphic.lineTo(crossPoints[3].x, crossPoints[3].y);

		// Pivot / Angle
		this.setPivotAndAngle(crossGraphic, center, angle);
		
		this.renderLabel();

		// Hit area for bay outline
		const allPointsOutline = this.generateHitAreaFromLine(outlinePoints, HIT_AREA_THICKNESS);
		outlineGraphic.hitArea = new PIXI.Polygon(allPointsOutline);

		// Hit area for center cross
		crossGraphic.hitArea = new PIXI.Circle(this.bayPoints.center.x, this.bayPoints.center.y, HIT_AREA_THICKNESS);

		const pivotHandlesContainer = this.getGraphic(2);
		pivotHandlesContainer.removeChildren();

		if (this.pivotHandlesEnabled) {
			this.setPivotAndAngle(pivotHandlesContainer, this.bayPoints.center, this.getEntity().heading);

			outlinePoints.forEach(point => {
				const container = new PIXI.Container();
				container.interactive = true;
				container.hitArea = new PIXI.Circle(point.x, point.y, HIT_AREA_THICKNESS);

				pivotHandlesContainer.addChild(container);
			});
		}
	}

	private renderLabel() {
		if (this.entity.baySeq === undefined) {
			return;
		}

		this.removeTooltip();

		const centerPoint = this.getCentrePoint();
		centerPoint.lat += 1; // added 1 so the seq number doesn't cover the cross
		const hasErrors = this.getEntity().mapObjectErrorss?.length > 0;
		this.createTooltip(this.entity.baySeq.toString(), centerPoint, hasErrors);
	}

	public getBayPoints() {
		return this.bayPoints;
	}

	private getCentrePoint(): LatLng {
		const { center } = this.bayPoints;
		return getLeafletLatLng(this.renderer.unproject(center));
	}

	public getBayLocation() {
		return this.bayLocation;
	}
}
