import {SaveCommand, SaveCommandType} from "./SaveCommand";

export default class BreakLinkCommand extends SaveCommand {
	linkId: string;
	breakIndex: number;

	// The id of the new link that will be created by this action
	newLinkIdNumber: number;

	// If the break location of the link is in the middle of a sublink, we will also need to break the sublink
	newSublinkIdNumber: number;

	constructor(linkId: string, breakIndex: number, newSublinkIdNumber: number, newLinkIdNumber: number) {
		super(SaveCommandType.BreakLink);

		this.linkId = linkId;
		this.breakIndex = breakIndex;
		this.newSublinkIdNumber = newSublinkIdNumber;
		this.newLinkIdNumber = newLinkIdNumber;
	}
}