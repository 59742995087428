import Modal from "../../Modal/Modal";
import React, {useEffect, useState} from "react";
import alert from "../../../../Util/ToastifyUtils";
import {setCustomTag} from "../../../MapComponents/Map/Helpers/MapUtils";
import axios from "axios";
import {SERVER_URL} from "../../../../Constants";
import {LoadView} from "../LoadView";
import {Button, Colors, Display, Sizes} from "../../Button/Button";
import {ButtonGroup} from "../../Button/ButtonGroup";
import {
    FileUploadSection,
    IFileUploadModalProps,
    terrainFileValidation
} from "./FileUploadModal";
import {observer} from "mobx-react";
import {CustomValidator, Validator} from "../../../../Validators/CustomValidator";
import {observable} from "mobx";

class TerrainImportModel extends Validator<TerrainImportModel> {

    public importVersionId: string;

    @CustomValidator(async (value, model) => {
        if (value == undefined) {
            return 'The drivable area data is required. Please select a drivable area data file.'
        }

        return await terrainFileValidation(value);
    })
    @observable
    public terrainData: File;

    constructor(importVersionId: string) {
        super();

        this.importVersionId = importVersionId;
    }
}

export default function TerrainImportModal(props: IFileUploadModalProps) {
    const { onCloseModal } = props;

    return (
        <Modal
            isOpen
            label="File upload"
            onRequestClose={onCloseModal}
            className="map-file-upload-modal"
            overlayClassName="map-file-upload-modal-overlay"
        >
            <RenderTerrainModalContent {...props} />
        </Modal>
    );
}

export const RenderTerrainModalContent = observer((props: IFileUploadModalProps) => {
    const { title, onCloseModal, importVersion } = props;
    const [createImport, setCreateImport] = useState(new TerrainImportModel(importVersion!.id));
    const [displaySpinner, setDisplaySpinner] = useState(false);

    const uploadTerrainDataFiles = async () => {
        if (await createImport.validate()) {
            return;
        }

        setCustomTag('import-files', 'terrain-data');
        setDisplaySpinner(true);
        try {
            const formData = new FormData();
            formData.append('UploadFileData', createImport.terrainData);
            formData.append('ImportVersionId', createImport.importVersionId);

            const response = await axios.post(
                `${SERVER_URL}/api/entity/ImportVersionEntity/uploadTerrainData/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'mulitipart/form-data',
                    },
                },
            );

            if (response.data > 0) {
                alert('Terrain data uploaded successfully', 'success');
                window.location.reload();
            } else {
                alert('Empty terrain data', 'error');
            }
            setDisplaySpinner(false);
            onCloseModal();
        } catch (e: any) {
            setDisplaySpinner(false);
            alert(e.response.data.errors[0]?.message ?? 'Uploading files failed', 'error');
        }
    };

    return (
        <>
            {displaySpinner && <LoadView text="Importing terrain data..." timerInterval={50} />}
            <div className="modal__header">
                <h3>{title}</h3>
                <Button
                    icon={{ icon: 'cross', iconPos: 'icon-right' }}
                    className="cancel-button no-background"
                    display={Display.Text}
                    onClick={onCloseModal}
                    labelVisible={false}
                />
            </div>

            <FileUploadSection
                model={createImport}
                modelProperty="terrainData"
                title="DRIVABLE AREA DATA FILE"
                fileType="TerrainBoundaryYYYYMMDDHHMMSS.zip"
                required
                onAfterChange={async () => await createImport.validate('terrainData')}
                errors={createImport.getError('terrainData')}
                contentType=".zip"
            />

            <ButtonGroup className="modal-actions">
                <Button
                    colors={Colors.Primary}
                    display={Display.Outline}
                    onClick={onCloseModal}
                    sizes={Sizes.Medium}
                >
                    Cancel
                </Button>
                <Button
                    className="next-button"
                    colors={Colors.Primary}
                    icon={{ icon: 'chevron-right', iconPos: 'icon-right' }}
                    display={Display.Solid}
                    onClick={uploadTerrainDataFiles}
                    sizes={Sizes.Medium}
                >
                    Next
                </Button>
            </ButtonGroup>
        </>
    );
});
