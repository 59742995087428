/* eslint-disable no-nested-ternary */
import {
	AreaEntity, BeaconEntity, ImportVersionEntity, SegmentEntity,
} from '../../../Models/Entities';
import MapStore from '../Map/MapStore';
import { areaLocationType, locationTypeOptions } from '../../../Models/Enums';
import { MapType } from '../Map/MapController';
import classNames from 'classnames';
import { ILayersPanelItem } from './LayersPanelItem';
import * as uuid from 'uuid';
import { store } from 'Models/Store';

// FIXME remove this and replace with enum
const objectState: string[] = ['Unchanged', 'Added/Updated', 'Deleted'];

export default function buildFmsMapObjects(
	lookup: MapStore,
): ILayersPanelItem {
	const locationItems: ILayersPanelItem[] = categoriseLocations(lookup.getAllEntities(AreaEntity));
	const beaconItems: ILayersPanelItem[] = categoriseBeacons(lookup.getAllEntities(BeaconEntity));
	const segmentItems: ILayersPanelItem[] = categoriseSegments(lookup.getAllEntities(SegmentEntity));

	const fmsItems: ILayersPanelItem[] = [];
	if (locationItems.length > 0) {
		fmsItems.push({
			name: 'Locations',
			mapObjectType: 'location',
			labelStyling: 'icon-area-new icon-left',
			skipSearch: true,
			children: locationItems,
			isDisplayed: !store.isInit ? false : undefined, // prevent fms obj displaying on initial load
		});
	}

	if (beaconItems.length > 0) {
		fmsItems.push({
			name: 'Beacons',
			mapObjectType: 'beacon',
			labelStyling: 'icon-circle icon-left',
			skipSearch: true,
			children: beaconItems,
			isDisplayed: !store.isInit ? false : undefined,
		});
	}

	if (segmentItems.length > 0) {
		fmsItems.push({
			name: 'Segments',
			mapObjectType: 'segment',
			labelStyling: 'icon-segments icon-left',
			skipSearch: true,
			children: segmentItems,
			isDisplayed: !store.isInit ? false : undefined,
		});
	}

	return {
		name: 'FMS Map',
		mapObjectType: 'fms',
		skipSearch: true,
		labelStyling: 'bold-label',
		children: fmsItems,
		isDisplayed: !store.isInit ? false : undefined,
	};
}

function categoriseLocations(areas: AreaEntity[]): ILayersPanelItem[] {
	const locations: { [key in areaLocationType]: ILayersPanelItem[] } = {
		PARKING: [],
		DIG: [],
		DUMP: [],
		CRUSHER: [],
		STOCKPILE: [],
		INVALID: [],
	};

	areas.filter(x => x.isFmsLocation()).forEach(x => {
		locations[x.locType].push(convertLocationItem(x));
	});

	return Object.keys(locationTypeOptions).map(x => {
		return {
			name: locationTypeOptions[x],
			skipSearch: true,
			mapObjectType: 'location' as MapType,
			labelStyling: classNames('icon-area-filled icon-left',
				`${locationTypeOptions[x].replace(' ', '-').toLowerCase()}-location`),
			children: locations[x]
				.sort((a: ILayersPanelItem, b: ILayersPanelItem) => a.name.localeCompare(b.name)),
		};
	}).filter(x => x.children.length > 0);
}

function convertLocationItem(area: AreaEntity): ILayersPanelItem {
	return {
		name: area.areaName,
		entityId: area.id,
		mapObjectType: 'location',
	};
}

function categoriseBeacons(beacons: BeaconEntity[]): ILayersPanelItem[] {
	const accessBeacons: ILayersPanelItem[] = [];
	const routeBeacons: ILayersPanelItem[] = [];
	const addedAccessBeacons: ILayersPanelItem[] = [];
	const deletedAccessBeacons: ILayersPanelItem[] = [];
	const addedRouteBeacons: ILayersPanelItem[] = [];
	const deletedRouteBeacons: ILayersPanelItem[] = [];
	beacons.forEach(beacon => {
		const beaconItem = convertBeaconItem(beacon);
		if (beacon.isAccess) {
			if (beacon.state === 'NEW_OBJECT' || beacon.state === 'MODIFIED') {
				addedAccessBeacons.push(beaconItem);
			}
			if (beacon.state === 'DELETED') {
				deletedAccessBeacons.push(beaconItem);
			}
			if (beacon.state === 'IMPORTED') {
				accessBeacons.push(beaconItem);
			}
		} else {
			if (beacon.state === 'NEW_OBJECT' || beacon.state === 'MODIFIED') {
				addedRouteBeacons.push(beaconItem);
			}
			if (beacon.state === 'DELETED') {
				deletedRouteBeacons.push(beaconItem);
			}
			if (beacon.state === 'IMPORTED') {
				routeBeacons.push(beaconItem);
			}
		}
	});

	accessBeacons.sort((a, b) => a.name.localeCompare(b.name));
	addedAccessBeacons.sort((a, b) => a.name.localeCompare(b.name));
	deletedAccessBeacons.sort((a, b) => a.name.localeCompare(b.name));
	addedRouteBeacons.sort((a, b) => a.name.localeCompare(b.name));
	deletedRouteBeacons.sort((a, b) => a.name.localeCompare(b.name));
	routeBeacons.sort((a, b) => a.name.localeCompare(b.name));

	const beaconCategories: ILayersPanelItem[] = [];

	if (beacons.length > 0) {
		const accessBeaconCategories = objectState.map((x): ILayersPanelItem => ({
			id: uuid.v4(),
			name: x,
			mapObjectType: 'beacon',
			skipSearch: true,
			labelStyling: x === 'Unchanged'
				? 'icon-circle icon-left access-beacons' : x === 'Added/Updated'
					? 'icon-circle icon-left access-beacons-added'
					: 'icon-circle icon-left access-beacons-deleted',
			children: x !== 'Unchanged'
				? (x === 'Added/Updated' ? addedAccessBeacons : deletedAccessBeacons)
				: accessBeacons,
		})).filter(x => !!x.children && x.children.length > 0);

		beaconCategories.push({
			name: 'Access',
			skipSearch: true,
			mapObjectType: 'beacon',
			children: accessBeaconCategories,
		});
	}

	if (beacons.length > 0) {
		const routeBeaconCategories = objectState.map((x): ILayersPanelItem => ({
			id: uuid.v4(),
			name: x,
			mapObjectType: 'beacon',
			skipSearch: true,
			labelStyling: x === 'Unchanged'
				? 'icon-route-beacon icon-left route-beacons' : x === 'Added/Updated'
					? 'icon-route-beacon icon-left route-beacons-added'
					: 'icon-route-beacon icon-left route-beacons-deleted',
			children: x !== 'Unchanged'
				? (x === 'Added/Updated' ? addedRouteBeacons : deletedRouteBeacons)
				: routeBeacons,
		})).filter(x => !!x.children && x.children.length > 0);

		beaconCategories.push({
			name: 'Route',
			mapObjectType: 'beacon',
			skipSearch: true,
			children: routeBeaconCategories,
		});
	}

	return beaconCategories;
}

function convertBeaconItem(beacon: BeaconEntity): ILayersPanelItem {
	return {
		name: beacon.beaconId,
		entityId: beacon.id,
		mapObjectType: 'beacon',
	};
}

function categoriseSegments(segments: SegmentEntity[]): ILayersPanelItem[] {
	const addedSegments: ILayersPanelItem[] = [];
	const deletedSegments: ILayersPanelItem[] = [];
	const unchangedSegments: ILayersPanelItem[] = [];

	segments.forEach(segment => {
		const segmentItem = convertSegment(segment);
		if (segment.state === 'NEW_OBJECT' || segment.state === 'MODIFIED') {
			addedSegments.push(segmentItem);
		}
		if (segment.state === 'DELETED') {
			deletedSegments.push(segmentItem);
		}
		if (segment.state === 'IMPORTED') {
			unchangedSegments.push(segmentItem);
		}
	});

	addedSegments.sort((a, b) => a.name.localeCompare(b.name));
	deletedSegments.sort((a, b) => a.name.localeCompare(b.name));
	unchangedSegments.sort((a, b) => a.name.localeCompare(b.name));

	return objectState.map((x): ILayersPanelItem => ({
		id: uuid.v4(),
		name: x,
		mapObjectType: 'segment',
		skipSearch: true,
		labelStyling: x === 'Unchanged'
			? 'icon-segments icon-left segments' : x === 'Added/Updated'
				? 'icon-segments icon-left segments-added'
				: 'icon-segments icon-left segments-deleted',
		children: x !== 'Unchanged'
			? (x === 'Added/Updated' ? addedSegments : deletedSegments)
			: unchangedSegments,
	})).filter(x => !!x.children && x.children.length > 0);
}

function convertSegment(segment: SegmentEntity): ILayersPanelItem {
	return {
		name: `${segment.startBeacon.beaconId} - ${segment.endBeacon.beaconId}`,
		entityId: segment.id,
		mapObjectType: 'segment',
	};
}
