import {useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import MapController from '../Map/MapController';
import moment from "moment-timezone";
import {MOMENT_FORMAT_STRING} from "../../../Constants";
import {Moment} from "moment";

interface IAutoSaveIndicator {
	map: MapController;
	lastSaved: Date;
}

export default function AutoSaveIndicator(props: IAutoSaveIndicator) {
	const { map, lastSaved  } = props;

	const guessedTZ = useMemo(() => map.getImportVersion().map?.mineSite?.timezone ?? moment.tz.guess(), []);

	useEffect(() => {
		moment.updateLocale('en', {
			relativeTime: {
				s: 'a few seconds',
				ss: 'less than a minute',
			}
		});
		moment.relativeTimeThreshold('ss', 10);
	}, []);

	const getMomentFromDate = (time: Date): Moment =>
		moment.utc(time).tz(guessedTZ);

	const [saved, setSaved] = useState<boolean>(true);
	const [isOffline, setIsOffline] = useState<boolean>(false);
	const [timeWhenSaved, setTimeWhenSaved] = useState<Moment>(getMomentFromDate(lastSaved));
	const [showAgoTime, setShowAgoTime] = useState<boolean>(true)
	const [updateSavedTimeAgo, triggerUpdatedSavedTimeAgo] = useState<boolean>(false);

	const lastSavedDateTime = useMemo(() => timeWhenSaved.format(MOMENT_FORMAT_STRING), [timeWhenSaved]);
	const lastSavedTimeAgo = useMemo(() => timeWhenSaved.fromNow(), [timeWhenSaved, updateSavedTimeAgo]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			triggerUpdatedSavedTimeAgo(prev => !prev);
		}, 1_000);

		return () => {
			clearInterval(intervalId);
		}
	}, []);

	const onMouseOverCaptureHandler = () => {
		setShowAgoTime((prev) => !prev)
	};

	useEffect(() => {
		map.getEventHandler().addListener('onAutoSave', onAutoSaveActivity);
		return () => {
			map.getEventHandler().removeListener('onAutoSave', onAutoSaveActivity);
		};
	});

	const onAutoSaveActivity = (isSaved: boolean, savedTimeStamp?: boolean | Date) => {
		setSaved(() => isSaved);

		if (!!savedTimeStamp) {
			if (typeof savedTimeStamp === 'boolean') {
				setIsOffline(() => savedTimeStamp);
			} else {
				setTimeWhenSaved(() => getMomentFromDate(savedTimeStamp));
				setIsOffline(() => false);
			}
		}
	};

	const autoSaveIndicatorComponent = () => {
		return (
			<>
				{
					saved && (
						<>
							<p className="last-saved-label"
							   onMouseEnter={onMouseOverCaptureHandler}
							   onMouseLeave={onMouseOverCaptureHandler}>
								Last saved: {showAgoTime ? lastSavedTimeAgo : lastSavedDateTime}
							</p>
							<span className="icon icon-only icon-last-saved save-indicator-icon last-saved" />
						</>
					)
				}
				{
					!saved && (
						<>
							Saving...
							<span className="icon icon-only icon-sync save-indicator-icon saving" />
						</>
					)
				}
			</>
		);
	};

	const offlineIndicatorComponent = () => {
		return (
			<>
				<p className="last-saved-label offline">Offline</p>
				<span className="icon icon-only icon-offline save-indicator-icon offline" />
			</>
		);
	};

	return (
		<>
			{ isOffline ? offlineIndicatorComponent() : autoSaveIndicatorComponent() }
		</>
	);
}
