
/**
 * Fill and stroke colours for nodes
 */
export enum nodeColors {
	StartNode = 0x5674A0,
	EndNode = 0xFF8000,
	HaulingTaskNode = 0x464646,
	TaskNode = 0x2BE72B,
	WarningNode = 0xEED202,
	ErrorNode = 0xFF3A3A,
	NodeStroke = 0xFFFFFF,
	MidWaypoint = 0xA05699,
	DynamicConnectionStroke = 0x008000,
	DynamicConnectionFill = 0xFFFF00,
}

export default nodeColors;
