import React, { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import FileUpload from '../FileUpload/FileUpload';
import {
	Button, Colors, Display, Sizes,
} from '../Button/Button';
import { TextField } from '../TextBox/TextBox';
import If from '../If/If';
import { ButtonGroup } from '../Button/ButtonGroup';
import alert from '../../../Util/ToastifyUtils';
import Spinner from '../Spinner/Spinner';
import { store } from '../../../Models/Store';
import { observable, runInAction } from 'mobx';
import axios from 'axios';
import { LoadView } from './LoadView';
import { Combobox } from '../Combobox/Combobox';
import { isEmail } from 'Validators/Functions/Email';
import { SERVER_URL } from 'Constants';

interface IInviteUserModalProps {
    onCloseModal: () => void;
}

const ADMIN_HELP_TEXT = "Can build maps, invite other users to the mine and edit organisation settings.";
const BUILDER_HELP_TEXT = "Can build maps, but cannot invite other users or edit organisations settings.";

// This needs to use mobx as FileUpload uses mobx
class CreateUserClass {
	@observable
	public firstName: string = '';

	@observable
	public lastName: string = '';

	@observable
	public email: string = '';

	@observable
	public permissionLevel: string = "AHS Builder";
}

export function InviteUserModal(props: IInviteUserModalProps) {
	const { onCloseModal } = props;

	return (
		<Modal
			isOpen
			label="Invite User"
			onRequestClose={onCloseModal}
			className="mat-modal"
			overlayClassName="map-file-upload-modal-overlay"
		>
			<RenderInviteUserModalContent {...props} />
		</Modal>
	);
}



export function RenderInviteUserModalContent(props: IInviteUserModalProps) {
	const { onCloseModal } = props;
	const [createUser, setCreateUser] = useState(new CreateUserClass());
	const [displaySpinner, setDisplaySpinner] = useState(false);
	const [permissionText, setPermissionText] = useState(BUILDER_HELP_TEXT);

	const permissionOptions = [{
		display: 'AHS Builder',
		value: 'AHS Builder',
	}, {
		display: 'AHS Admin',
		value: 'AHS Admin',
	}];

	useEffect(() => {
		setCreateUser(new CreateUserClass());
	}, []);

	const validateInviteUserModal = () => {
		var nameRegex = new RegExp("^[a-zA-Z'-]+$");
		if (!createUser.firstName.length || !createUser.lastName.length || !createUser.email.length) {
			alert('All fields are required.', 'error');
			return false;
		}
        if (!nameRegex.test(createUser.firstName)){
            alert('Names should only contain letters, hypens and/or apostrophes.', 'error');
			return false;
        } 
        if (!nameRegex.test(createUser.lastName)){
            alert('Name should only contain letters, hypens and/or apostrophes.', 'error');
			return false;
        }
		if (!isEmail(createUser.email)) {
			alert('Email address format is incorrect.', 'error');
			return false;
		}
		return true;
	};

	const inviteUser = async () => {
		runInAction(() => {
			createUser.firstName = createUser.firstName.trim();
			createUser.lastName = createUser.lastName.trim();
			createUser.email = createUser.email.trim();
		});
		if (validateInviteUserModal()) {
			setDisplaySpinner(true);
			try {
                const formData = new FormData();
                
                formData.append('FirstName', createUser.firstName);  
                formData.append('LastName', createUser.lastName);
				formData.append('Email', createUser.email);
				formData.append('PermissionLevel', createUser.permissionLevel);
                const result = await axios.post(
                    `${SERVER_URL}/api/entity/MineUserEntity/inviteMineUser`,
                    formData,
					{
						headers: {
							'Content-Type': 'mulitipart/form-data',
						},
					},
                );
        
				setDisplaySpinner(false);
				
				if (result.data.success) {
					alert('Invite sent', 'success');
					window.location.reload();
				} else {
					alert(result.data.message, "error");
				}
			} catch (e: any) {
				setDisplaySpinner(false);
				alert("Request failed.", 'error');
			}
		} else {}
	};
	return (
		<>
			<div className="modal__header">
				<h3>New Team Member</h3>
				<Button
					icon={{ icon: 'cross', iconPos: 'icon-right' }}
					className="cancel-button no-background"
					display={Display.Text}
					onClick={onCloseModal}
					labelVisible={false}
				/>
			</div>

            <div className="mat-modal-field">
                <div className="mat-model-input">
                    <TextField
                        label="First name"
                        labelVisible
                        isRequired
                        model={createUser}
                        modelProperty="firstName"
                    />
                </div>
			</div>

            <div className="mat-modal-field">
                <div className="mat-model-input">
                    <TextField
                        label="Last name"
                        labelVisible
                        isRequired
                        model={createUser}
                        modelProperty="lastName"
                    />
                </div>
			</div>

            <div className="mat-modal-field">
                <div className="mat-model-input">
                    <TextField
                        label="Email address"
                        labelVisible
                        isRequired
                        model={createUser}
                        modelProperty="email"
                    />
                </div>
			</div>

            <div className="mat-modal-field">
                <div className="mat-modal-input">
					<Combobox
						model={createUser}
						options={permissionOptions}
						modelProperty={"permissionLevel"}
						label={"Permission Level"}
						searchable={false}
						onAfterChange={() => {
							const text = createUser.permissionLevel === "AHS Builder" ?
								BUILDER_HELP_TEXT : ADMIN_HELP_TEXT;
							setPermissionText(text);
						}}
						isRequired
					/>
					<p className="help-text">{permissionText}</p>
                </div>
			</div>

			<ButtonGroup className="invite-modal-actions">
				<Button
					colors={Colors.Primary}
					display={Display.Outline}
					onClick={onCloseModal}
					sizes={Sizes.Medium}
				>
					Cancel
				</Button>
				<Button
					className="next-button"
					colors={Colors.Primary}
					icon={{ icon: 'chevron-right', iconPos: 'icon-right' }}
					display={Display.Solid}
					onClick={inviteUser}
					sizes={Sizes.Medium}
				>
					Send Invite
				</Button>
			</ButtonGroup>
		</>
	);
}
