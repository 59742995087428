/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsMapToolParamEntity from 'Models/Security/Acl/VisitorsMapToolParamEntity';
import MineUserMapToolParamEntity from 'Models/Security/Acl/MineUserMapToolParamEntity';
import HitachiAdminMapToolParamEntity from 'Models/Security/Acl/HitachiAdminMapToolParamEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IMapToolParamEntityAttributes extends IModelAttributes {
	maxSpeedEmpty: number;
	maxSpeedLoaded: number;
	minSpeedEmpty: number;
	minSpeedLoaded: number;
	coordParamA: number;
	coordParamB: number;
	coordParamC: number;
	coordParamD: number;
	coordParamE: number;
	coordParamF: number;
	bermRightError: number;
	bermLeftError: number;
	nodeDistMin: number;
	nodeDistMax: number;
	maxSpeedBackward: number;
	ahtDeviationBufferWidth: number;
	ahtCloseLimitWidth: number;
	ahtCloseLimitWidthDynamic: number;
	mivCloseJudgeWidth: number;
	mivCloseJudgeWidthDynamic: number;
	truckWidth: number;
	truckLength: number;
	truckOffset: number;
	truckFrontMargin: number;
	truckWidthMargin: number;
	truckRearMargin: number;
	unloadingAngularVelocity: number;
	minRadius: number;
	forwardSpeed: number;
	maxRadius: number;
	backwardSpeed: number;
	wheelBase: number;
	backStartLineMargin: number;
	sideAccelGravityThr: number;
	loadingAngularVelocity: number;
	backEndLineMargin: number;
	forwardStartLineMargin: number;
	forwardEndLineMargin: number;
	exitDistance: number;
	enterDistance: number;
	viaMarginA: number;
	viaMarginB: number;
	reverseSearchDegStep: number;
	nodeLengthThr: number;
	sublinkLengthThr: number;
	curvatureDefault: number;
	gradientDefault: number;
	bermRightDefault: number;
	bermLeftDefault: number;
	releaseLengthThr: number;
	spotLimitDistance: number;
	spotLimitStepDistance: number;
	isAllNodesInBoundary: number;
	checkSpotInZone: number;
	checkTruckInZone: number;
	checkFinalPathInZone: number;
	checkBlockControl: number;
	vicinitySublinkDistance: number;
	areaExtensionWidth: number;
	areaExtensionLength: number;
	areaExtensionBack: number;
	areaExtensionLengthLimit: number;
	dumpPileWidth: number;
	dumpPileLength: number;
	dumpPileOffset: number;
	bayWidth: number;
	bayLength: number;
	bayToBay: number;
	ahtMaxWidth: number;
	ahtMaxLength: number;
	ahtMaxOffset: number;
	nodeIdMax: number;
	staticLinkIdMax: number;
	staticPathLinkMax: number;
	staticPathSublinkMax: number;
	staticPathNodeMax: number;
	staticPathInlinkSublinkMax: number;
	staticPathInSublinkNodeMax: number;
	dynamicPathLinkMax: number;
	dynamicPathSublinkMax: number;
	dynamicPathNodeMax: number;
	routeStaticPathLinkMax: number;
	routeStaticPathSublinkMax: number;
	routeStaticPathNodeMax: number;
	routeDynamicPathLinkToBayMax: number;
	routeDynamicPathLinkToExitMax: number;
	routeDynamicPathSublinkToBayMax: number;
	routeDynamicPathSublinkToExitMax: number;
	routeDynamicPathNodeToBayMax: number;
	routeDynamicPathNodeToExitMax: number;
	fmsMapSegmentMax: number;
	fmsMapRootBeaconMax: number;
	fmsMapRootBeaconAccessMax: number;
	ahtFollowingError: number;
	gnssAccuracyError: number;
	maxUphillGradient: number;
	maxDownhillGradient: number;
	turnbackPathAngle: number;
	crusherOffset: number;
	straightThresholdCurvature: number;
	curveMarginContinuationDistance: number;
	overHangMargin: number;
	priorityLoadingReversePoint: number;
	priorityLoadingGoingPathMoveTime: number;
	priorityLoadingBackPathMoveTime: number;
	priorityLoadingReverseRange: number;
	reverseAddMargin: number;
	ahsAreaStartingPointX: number;
	ahsAreaStartingPointY: number;
	ahsAreaWidthMax: number;
	ahsAreaLengthMax: number;
	oteSpillageMyselfWidth: number;
	oteSpillageOtherFrontOffset: number;
	oteSpillageOtherLength: number;
	oteSpillageOtherWidth: number;
	permissionResetMarginForward: number;
	permissionResetMarginBackward: number;
	permissionJudgeMargin: number;
	priorityLoadingBackPathMoveTimeLonger: number;
	priorityLoadingBackPathMoveTimeOnetime: number;
	priorityLoadingGoingPathMoveTimeOnetime: number;
	priorityLoadingReversePointOnetime: number;
	priorityLoadingReverseRangeOnetime: number;
	priorityLoadingBackPathMoveTimeLongerOnetime: number;
	areaExpansionAhtPolygonFrontBuffer: number;
	areaExpansionAhtPolygonFrontMargin: number;
	areaExpansionAhtPolygonRearMargin: number;
	areaExpansionAhtPolygonFrontLength: number;
	areaExpansionAhtPolygonRearLength: number;
	ahtMaxRearBodyWidth: number;
	areaExpansionBermMargin: number;
	nonOverHangMarginDistance: number;
	dynamicPathLoadedAngularVelocity: number;
	dynamicPathUnloadedAngularVelocity: number;
	priorityLoadingShortestOverlapping: number;
	priorityLoadingShortestOverlappingOnetime: number;
	priorityLoadingNoOverlapOutboundAndInbound: number;
	priorityLoadingNoOverlapOutboundAndInboundOnetime: number;
	priorityLoadingRunupDistance: number;
	priorityLoadingRunupDistanceOnetime: number;
	runupDistanceHighSpeed: number;
	runupDistanceSlowSpeed: number;
	priorityDumpingOteBackPathMoveTime: number;
	priorityDumpingOteGoingPathMoveTime: number;
	priorityDumpingOteReversePoint: number;
	priorityDumpingOteReverseRange: number;
	priorityDumpingOteBackPathMoveTimeLonger: number;
	priorityDumpingOteShortestOverlapping: number;
	priorityDumpingOteNoOverlapOutboundAndInbound: number;
	priorityDumpingOteRunupDistance: number;
	priorityDumpingPaddockBackPathMoveTime: number;
	priorityDumpingPaddockGoingPathMoveTime: number;
	priorityDumpingPaddockReversePoint: number;
	priorityDumpingPaddockReverseRange: number;
	priorityDumpingPaddockBackPathMoveTimeLonger: number;
	priorityDumpingPaddockShortestOverlapping: number;
	priorityDumpingPaddockNoOverlapOutboundAndInbound: number;
	priorityDumpingPaddockRunupDistance: number;
	backBufferMax: number;
	backBufferMin: number;
	backBufferStep: number;
	mapCheckForwardStartLineMargin: number;
	mapCheckForwardEndLineMargin: number;
	mapCheckBackStartLineMargin: number;
	mapCheckBackEndLineMargin: number;
	mapCheckForwardStartLineMarginDrySteering: number;
	mapCheckForwardEndLineMarginDrySteering: number;
	mapCheckBackStartLineMarginDrySteering: number;
	mapCheckBackEndLineMarginDrySteering: number;
	drySteeringLoadedForwardStartLineMargin: number;
	drySteeringUnLoadedForwardStartLineMargin: number;
	drySteeringLoadedForwardEndLineMargin: number;
	drySteeringUnloadedForwardEndLineMargin: number;
	drySteeringLoadedBackStartLineMargin: number;
	drySteeringUnLoadedBackStartLineMargin: number;
	drySteeringLoadedBackEndLineMargin: number;
	drySteeringUnLoadedBackEndLineMargin: number;
	drySteeringDynamicPathLoadedAngularVelocity: number;
	drySteeringDynamicPathUnloadedAngularVelocity: number;
	drySteeringMinRadiusReSpotDropCutBackPath: number;
	drySteeringMinRadius: number;
	drySteeringMaxRadius: number;

	importVersionId?: string;
	importVersion?: Models.ImportVersionEntity | Models.IImportVersionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('MapToolParamEntity', 'Map Tool Param')
// % protected region % [Customise your entity metadata here] end
export default class MapToolParamEntity extends Model
	implements IMapToolParamEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsMapToolParamEntity(),
		new MineUserMapToolParamEntity(),
		new HitachiAdminMapToolParamEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Max Speed Empty'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Max Speed Empty',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public maxSpeedEmpty: number;
	// % protected region % [Modify props to the crud options here for attribute 'Max Speed Empty'] end

	// % protected region % [Modify props to the crud options here for attribute 'Max Speed Loaded'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Max Speed Loaded',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public maxSpeedLoaded: number;
	// % protected region % [Modify props to the crud options here for attribute 'Max Speed Loaded'] end

	// % protected region % [Modify props to the crud options here for attribute 'Min Speed Empty'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Min Speed Empty',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public minSpeedEmpty: number;
	// % protected region % [Modify props to the crud options here for attribute 'Min Speed Empty'] end

	// % protected region % [Modify props to the crud options here for attribute 'Min Speed Loaded'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Min Speed Loaded',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public minSpeedLoaded: number;
	// % protected region % [Modify props to the crud options here for attribute 'Min Speed Loaded'] end

	// % protected region % [Modify props to the crud options here for attribute 'Coord Param A'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Coord Param A',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public coordParamA: number;
	// % protected region % [Modify props to the crud options here for attribute 'Coord Param A'] end

	// % protected region % [Modify props to the crud options here for attribute 'Coord Param B'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Coord Param B',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public coordParamB: number;
	// % protected region % [Modify props to the crud options here for attribute 'Coord Param B'] end

	// % protected region % [Modify props to the crud options here for attribute 'Coord Param C'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Coord Param C',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public coordParamC: number;
	// % protected region % [Modify props to the crud options here for attribute 'Coord Param C'] end

	// % protected region % [Modify props to the crud options here for attribute 'Coord Param D'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Coord Param D',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public coordParamD: number;
	// % protected region % [Modify props to the crud options here for attribute 'Coord Param D'] end

	// % protected region % [Modify props to the crud options here for attribute 'Coord Param E'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Coord Param E',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public coordParamE: number;
	// % protected region % [Modify props to the crud options here for attribute 'Coord Param E'] end

	// % protected region % [Modify props to the crud options here for attribute 'Coord Param F'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Coord Param F',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public coordParamF: number;
	// % protected region % [Modify props to the crud options here for attribute 'Coord Param F'] end

	// % protected region % [Modify props to the crud options here for attribute 'Berm Right Error'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Berm Right Error',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public bermRightError: number;
	// % protected region % [Modify props to the crud options here for attribute 'Berm Right Error'] end

	// % protected region % [Modify props to the crud options here for attribute 'Berm Left Error'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Berm Left Error',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public bermLeftError: number;
	// % protected region % [Modify props to the crud options here for attribute 'Berm Left Error'] end

	// % protected region % [Modify props to the crud options here for attribute 'Node Dist Min'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Node Dist Min',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public nodeDistMin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Node Dist Min'] end

	// % protected region % [Modify props to the crud options here for attribute 'Node Dist Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Node Dist Max',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public nodeDistMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Node Dist Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Max Speed Backward'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Max Speed Backward',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public maxSpeedBackward: number;
	// % protected region % [Modify props to the crud options here for attribute 'Max Speed Backward'] end

	// % protected region % [Modify props to the crud options here for attribute 'Aht Deviation Buffer Width'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Aht Deviation Buffer Width',
		displayType: 'textfield',
		order: 160,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public ahtDeviationBufferWidth: number;
	// % protected region % [Modify props to the crud options here for attribute 'Aht Deviation Buffer Width'] end

	// % protected region % [Modify props to the crud options here for attribute 'Aht Close Limit Width'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Aht Close Limit Width',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public ahtCloseLimitWidth: number;
	// % protected region % [Modify props to the crud options here for attribute 'Aht Close Limit Width'] end

	// % protected region % [Modify props to the crud options here for attribute 'Aht Close Limit Width Dynamic'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Aht Close Limit Width Dynamic',
		displayType: 'textfield',
		order: 180,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public ahtCloseLimitWidthDynamic: number;
	// % protected region % [Modify props to the crud options here for attribute 'Aht Close Limit Width Dynamic'] end

	// % protected region % [Modify props to the crud options here for attribute 'Miv Close Judge Width'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Miv Close Judge Width',
		displayType: 'textfield',
		order: 190,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public mivCloseJudgeWidth: number;
	// % protected region % [Modify props to the crud options here for attribute 'Miv Close Judge Width'] end

	// % protected region % [Modify props to the crud options here for attribute 'Miv Close Judge Width Dynamic'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Miv Close Judge Width Dynamic',
		displayType: 'textfield',
		order: 200,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public mivCloseJudgeWidthDynamic: number;
	// % protected region % [Modify props to the crud options here for attribute 'Miv Close Judge Width Dynamic'] end

	// % protected region % [Modify props to the crud options here for attribute 'Truck Width'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Truck Width',
		displayType: 'textfield',
		order: 210,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public truckWidth: number;
	// % protected region % [Modify props to the crud options here for attribute 'Truck Width'] end

	// % protected region % [Modify props to the crud options here for attribute 'Truck Length'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Truck Length',
		displayType: 'textfield',
		order: 220,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public truckLength: number;
	// % protected region % [Modify props to the crud options here for attribute 'Truck Length'] end

	// % protected region % [Modify props to the crud options here for attribute 'Truck Offset'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Truck Offset',
		displayType: 'textfield',
		order: 230,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public truckOffset: number;
	// % protected region % [Modify props to the crud options here for attribute 'Truck Offset'] end

	// % protected region % [Modify props to the crud options here for attribute 'Truck Front Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Truck Front Margin',
		displayType: 'textfield',
		order: 240,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public truckFrontMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Truck Front Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Truck Width Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Truck Width Margin',
		displayType: 'textfield',
		order: 250,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public truckWidthMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Truck Width Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Truck Rear Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Truck Rear Margin',
		displayType: 'textfield',
		order: 260,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public truckRearMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Truck Rear Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Unloading Angular Velocity'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Unloading Angular Velocity',
		displayType: 'textfield',
		order: 270,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public unloadingAngularVelocity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Unloading Angular Velocity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Min Radius'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Min Radius',
		displayType: 'textfield',
		order: 280,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public minRadius: number;
	// % protected region % [Modify props to the crud options here for attribute 'Min Radius'] end

	// % protected region % [Modify props to the crud options here for attribute 'Forward Speed'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Forward Speed',
		displayType: 'textfield',
		order: 290,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public forwardSpeed: number;
	// % protected region % [Modify props to the crud options here for attribute 'Forward Speed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Max Radius'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Max Radius',
		displayType: 'textfield',
		order: 300,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public maxRadius: number;
	// % protected region % [Modify props to the crud options here for attribute 'Max Radius'] end

	// % protected region % [Modify props to the crud options here for attribute 'Backward Speed'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Backward Speed',
		displayType: 'textfield',
		order: 310,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public backwardSpeed: number;
	// % protected region % [Modify props to the crud options here for attribute 'Backward Speed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Wheel Base'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Wheel Base',
		displayType: 'textfield',
		order: 320,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public wheelBase: number;
	// % protected region % [Modify props to the crud options here for attribute 'Wheel Base'] end

	// % protected region % [Modify props to the crud options here for attribute 'Back Start Line Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Back Start Line Margin',
		displayType: 'textfield',
		order: 330,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public backStartLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Back Start Line Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Side Accel Gravity Thr'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Side Accel Gravity Thr',
		displayType: 'textfield',
		order: 340,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public sideAccelGravityThr: number;
	// % protected region % [Modify props to the crud options here for attribute 'Side Accel Gravity Thr'] end

	// % protected region % [Modify props to the crud options here for attribute 'Loading Angular Velocity'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Loading Angular Velocity',
		displayType: 'textfield',
		order: 350,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public loadingAngularVelocity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Loading Angular Velocity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Back End Line Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Back End Line Margin',
		displayType: 'textfield',
		order: 360,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public backEndLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Back End Line Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Forward Start Line Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Forward Start Line Margin',
		displayType: 'textfield',
		order: 370,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public forwardStartLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Forward Start Line Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Forward EndLine Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Forward EndLine Margin',
		displayType: 'textfield',
		order: 380,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public forwardEndLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Forward EndLine Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Exit Distance'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Exit Distance',
		displayType: 'textfield',
		order: 390,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public exitDistance: number;
	// % protected region % [Modify props to the crud options here for attribute 'Exit Distance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Enter Distance'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Enter Distance',
		displayType: 'textfield',
		order: 400,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public enterDistance: number;
	// % protected region % [Modify props to the crud options here for attribute 'Enter Distance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Via Margin A'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Via Margin A',
		displayType: 'textfield',
		order: 410,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public viaMarginA: number;
	// % protected region % [Modify props to the crud options here for attribute 'Via Margin A'] end

	// % protected region % [Modify props to the crud options here for attribute 'Via Margin B'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Via Margin B',
		displayType: 'textfield',
		order: 420,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public viaMarginB: number;
	// % protected region % [Modify props to the crud options here for attribute 'Via Margin B'] end

	// % protected region % [Modify props to the crud options here for attribute 'Reverse Search Deg Step'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Reverse Search Deg Step',
		displayType: 'textfield',
		order: 430,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public reverseSearchDegStep: number;
	// % protected region % [Modify props to the crud options here for attribute 'Reverse Search Deg Step'] end

	// % protected region % [Modify props to the crud options here for attribute 'Node Length Thr'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Node Length Thr',
		displayType: 'textfield',
		order: 440,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public nodeLengthThr: number;
	// % protected region % [Modify props to the crud options here for attribute 'Node Length Thr'] end

	// % protected region % [Modify props to the crud options here for attribute 'Sublink Length Thr'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Sublink Length Thr',
		displayType: 'textfield',
		order: 450,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public sublinkLengthThr: number;
	// % protected region % [Modify props to the crud options here for attribute 'Sublink Length Thr'] end

	// % protected region % [Modify props to the crud options here for attribute 'Curvature Default'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Curvature Default',
		displayType: 'textfield',
		order: 460,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public curvatureDefault: number;
	// % protected region % [Modify props to the crud options here for attribute 'Curvature Default'] end

	// % protected region % [Modify props to the crud options here for attribute 'Gradient Default'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Gradient Default',
		displayType: 'textfield',
		order: 470,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public gradientDefault: number;
	// % protected region % [Modify props to the crud options here for attribute 'Gradient Default'] end

	// % protected region % [Modify props to the crud options here for attribute 'Berm Right Default'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Berm Right Default',
		displayType: 'textfield',
		order: 480,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public bermRightDefault: number;
	// % protected region % [Modify props to the crud options here for attribute 'Berm Right Default'] end

	// % protected region % [Modify props to the crud options here for attribute 'Berm Left Default'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Berm Left Default',
		displayType: 'textfield',
		order: 490,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public bermLeftDefault: number;
	// % protected region % [Modify props to the crud options here for attribute 'Berm Left Default'] end

	// % protected region % [Modify props to the crud options here for attribute 'Release Length Thr'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Release Length Thr',
		displayType: 'textfield',
		order: 500,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public releaseLengthThr: number;
	// % protected region % [Modify props to the crud options here for attribute 'Release Length Thr'] end

	// % protected region % [Modify props to the crud options here for attribute 'Spot Limit Distance'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Spot Limit Distance',
		displayType: 'textfield',
		order: 510,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public spotLimitDistance: number;
	// % protected region % [Modify props to the crud options here for attribute 'Spot Limit Distance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Spot Limit Step Distance'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Spot Limit Step Distance',
		displayType: 'textfield',
		order: 520,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public spotLimitStepDistance: number;
	// % protected region % [Modify props to the crud options here for attribute 'Spot Limit Step Distance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is All Nodes In Boundary'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Is All Nodes In Boundary',
		displayType: 'textfield',
		order: 530,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public isAllNodesInBoundary: number;
	// % protected region % [Modify props to the crud options here for attribute 'Is All Nodes In Boundary'] end

	// % protected region % [Modify props to the crud options here for attribute 'Check Spot In Zone'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Check Spot In Zone',
		displayType: 'textfield',
		order: 540,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public checkSpotInZone: number;
	// % protected region % [Modify props to the crud options here for attribute 'Check Spot In Zone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Check Truck In Zone'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Check Truck In Zone',
		displayType: 'textfield',
		order: 550,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public checkTruckInZone: number;
	// % protected region % [Modify props to the crud options here for attribute 'Check Truck In Zone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Check Final Path In Zone'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Check Final Path In Zone',
		displayType: 'textfield',
		order: 560,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public checkFinalPathInZone: number;
	// % protected region % [Modify props to the crud options here for attribute 'Check Final Path In Zone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Check Block Control'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Check Block Control',
		displayType: 'textfield',
		order: 570,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public checkBlockControl: number;
	// % protected region % [Modify props to the crud options here for attribute 'Check Block Control'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vicinity Sublink Distance'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Vicinity Sublink Distance',
		displayType: 'textfield',
		order: 580,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public vicinitySublinkDistance: number;
	// % protected region % [Modify props to the crud options here for attribute 'Vicinity Sublink Distance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Area Extension Width'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Area Extension Width',
		displayType: 'textfield',
		order: 590,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public areaExtensionWidth: number;
	// % protected region % [Modify props to the crud options here for attribute 'Area Extension Width'] end

	// % protected region % [Modify props to the crud options here for attribute 'Area Extension Length'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Area Extension Length',
		displayType: 'textfield',
		order: 600,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public areaExtensionLength: number;
	// % protected region % [Modify props to the crud options here for attribute 'Area Extension Length'] end

	// % protected region % [Modify props to the crud options here for attribute 'Area Extension Back'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Area Extension Back',
		displayType: 'textfield',
		order: 610,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public areaExtensionBack: number;
	// % protected region % [Modify props to the crud options here for attribute 'Area Extension Back'] end

	// % protected region % [Modify props to the crud options here for attribute 'Area Extension Length Limit'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Area Extension Length Limit',
		displayType: 'textfield',
		order: 620,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public areaExtensionLengthLimit: number;
	// % protected region % [Modify props to the crud options here for attribute 'Area Extension Length Limit'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dump Pile Width'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dump Pile Width',
		displayType: 'textfield',
		order: 630,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public dumpPileWidth: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dump Pile Width'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dump Pile Length'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dump Pile Length',
		displayType: 'textfield',
		order: 640,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public dumpPileLength: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dump Pile Length'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dump Pile Offset'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dump Pile Offset',
		displayType: 'textfield',
		order: 650,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public dumpPileOffset: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dump Pile Offset'] end

	// % protected region % [Modify props to the crud options here for attribute 'Bay Width'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Bay Width',
		displayType: 'textfield',
		order: 660,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public bayWidth: number;
	// % protected region % [Modify props to the crud options here for attribute 'Bay Width'] end

	// % protected region % [Modify props to the crud options here for attribute 'Bay Length'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Bay Length',
		displayType: 'textfield',
		order: 670,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public bayLength: number;
	// % protected region % [Modify props to the crud options here for attribute 'Bay Length'] end

	// % protected region % [Modify props to the crud options here for attribute 'Bay To Bay'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Bay To Bay',
		displayType: 'textfield',
		order: 680,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public bayToBay: number;
	// % protected region % [Modify props to the crud options here for attribute 'Bay To Bay'] end

	// % protected region % [Modify props to the crud options here for attribute 'Aht Max Width'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Aht Max Width',
		displayType: 'textfield',
		order: 690,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public ahtMaxWidth: number;
	// % protected region % [Modify props to the crud options here for attribute 'Aht Max Width'] end

	// % protected region % [Modify props to the crud options here for attribute 'Aht Max Length'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Aht Max Length',
		displayType: 'textfield',
		order: 700,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public ahtMaxLength: number;
	// % protected region % [Modify props to the crud options here for attribute 'Aht Max Length'] end

	// % protected region % [Modify props to the crud options here for attribute 'Aht Max Offset'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Aht Max Offset',
		displayType: 'textfield',
		order: 710,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public ahtMaxOffset: number;
	// % protected region % [Modify props to the crud options here for attribute 'Aht Max Offset'] end

	// % protected region % [Modify props to the crud options here for attribute 'Node Id Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Node Id Max',
		displayType: 'textfield',
		order: 720,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public nodeIdMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Node Id Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Static Link Id Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Static Link Id Max',
		displayType: 'textfield',
		order: 730,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public staticLinkIdMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Static Link Id Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Static Path Link Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Static Path Link Max',
		displayType: 'textfield',
		order: 740,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public staticPathLinkMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Static Path Link Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Static Path Sublink Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Static Path Sublink Max',
		displayType: 'textfield',
		order: 750,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public staticPathSublinkMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Static Path Sublink Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Static Path Node Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Static Path Node Max',
		displayType: 'textfield',
		order: 760,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public staticPathNodeMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Static Path Node Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Static Path Inlink Sublink Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Static Path Inlink Sublink Max',
		displayType: 'textfield',
		order: 770,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public staticPathInlinkSublinkMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Static Path Inlink Sublink Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Static Path In Sublink Node Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Static Path In Sublink Node Max',
		displayType: 'textfield',
		order: 780,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public staticPathInSublinkNodeMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Static Path In Sublink Node Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dynamic Path Link Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dynamic Path Link Max',
		displayType: 'textfield',
		order: 790,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public dynamicPathLinkMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dynamic Path Link Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dynamic Path Sublink Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dynamic Path Sublink Max',
		displayType: 'textfield',
		order: 800,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public dynamicPathSublinkMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dynamic Path Sublink Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dynamic Path Node Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dynamic Path Node Max',
		displayType: 'textfield',
		order: 810,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public dynamicPathNodeMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dynamic Path Node Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Route Static Path Link Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Route Static Path Link Max',
		displayType: 'textfield',
		order: 820,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public routeStaticPathLinkMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Route Static Path Link Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Route Static Path Sublink Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Route Static Path Sublink Max',
		displayType: 'textfield',
		order: 830,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public routeStaticPathSublinkMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Route Static Path Sublink Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Route Static Path Node Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Route Static Path Node Max',
		displayType: 'textfield',
		order: 840,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public routeStaticPathNodeMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Route Static Path Node Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Route Dynamic Path Link To Bay Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Route Dynamic Path Link To Bay Max',
		displayType: 'textfield',
		order: 850,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public routeDynamicPathLinkToBayMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Route Dynamic Path Link To Bay Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Route Dynamic Path Link To Exit Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Route Dynamic Path Link To Exit Max',
		displayType: 'textfield',
		order: 860,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public routeDynamicPathLinkToExitMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Route Dynamic Path Link To Exit Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Route Dynamic Path Sublink To Bay Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Route Dynamic Path Sublink To Bay Max',
		displayType: 'textfield',
		order: 870,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public routeDynamicPathSublinkToBayMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Route Dynamic Path Sublink To Bay Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Route Dynamic Path Sublink To Exit Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Route Dynamic Path Sublink To Exit Max',
		displayType: 'textfield',
		order: 880,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public routeDynamicPathSublinkToExitMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Route Dynamic Path Sublink To Exit Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Route Dynamic Path Node To Bay Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Route Dynamic Path Node To Bay Max',
		displayType: 'textfield',
		order: 890,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public routeDynamicPathNodeToBayMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Route Dynamic Path Node To Bay Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Route Dynamic Path Node To Exit Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Route Dynamic Path Node To Exit Max',
		displayType: 'textfield',
		order: 900,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public routeDynamicPathNodeToExitMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Route Dynamic Path Node To Exit Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Fms Map Segment Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Fms Map Segment Max',
		displayType: 'textfield',
		order: 910,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public fmsMapSegmentMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Fms Map Segment Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Fms Map Root Beacon Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Fms Map Root Beacon Max',
		displayType: 'textfield',
		order: 920,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public fmsMapRootBeaconMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Fms Map Root Beacon Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Fms Map Root Beacon Access Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Fms Map Root Beacon Access Max',
		displayType: 'textfield',
		order: 930,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public fmsMapRootBeaconAccessMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Fms Map Root Beacon Access Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Aht Following Error'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Aht Following Error',
		displayType: 'textfield',
		order: 940,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public ahtFollowingError: number;
	// % protected region % [Modify props to the crud options here for attribute 'Aht Following Error'] end

	// % protected region % [Modify props to the crud options here for attribute 'Gnss Accuracy Error'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Gnss Accuracy Error',
		displayType: 'textfield',
		order: 950,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public gnssAccuracyError: number;
	// % protected region % [Modify props to the crud options here for attribute 'Gnss Accuracy Error'] end

	// % protected region % [Modify props to the crud options here for attribute 'Max Uphill Gradient'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Max Uphill Gradient',
		displayType: 'textfield',
		order: 960,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public maxUphillGradient: number;
	// % protected region % [Modify props to the crud options here for attribute 'Max Uphill Gradient'] end

	// % protected region % [Modify props to the crud options here for attribute 'Max Downhill Gradient'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Max Downhill Gradient',
		displayType: 'textfield',
		order: 970,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public maxDownhillGradient: number;
	// % protected region % [Modify props to the crud options here for attribute 'Max Downhill Gradient'] end

	// % protected region % [Modify props to the crud options here for attribute 'Turnback Path Angle'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Turnback Path Angle',
		displayType: 'textfield',
		order: 980,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public turnbackPathAngle: number;
	// % protected region % [Modify props to the crud options here for attribute 'Turnback Path Angle'] end

	// % protected region % [Modify props to the crud options here for attribute 'Crusher Offset'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Crusher Offset',
		displayType: 'textfield',
		order: 990,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public crusherOffset: number;
	// % protected region % [Modify props to the crud options here for attribute 'Crusher Offset'] end

	// % protected region % [Modify props to the crud options here for attribute 'Straight Threshold Curvature'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Straight Threshold Curvature',
		displayType: 'textfield',
		order: 1000,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public straightThresholdCurvature: number;
	// % protected region % [Modify props to the crud options here for attribute 'Straight Threshold Curvature'] end

	// % protected region % [Modify props to the crud options here for attribute 'Curve Margin Continuation Distance'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Curve Margin Continuation Distance',
		displayType: 'textfield',
		order: 1010,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public curveMarginContinuationDistance: number;
	// % protected region % [Modify props to the crud options here for attribute 'Curve Margin Continuation Distance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Over Hang Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Over Hang Margin',
		displayType: 'textfield',
		order: 1020,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public overHangMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Over Hang Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Reverse Point'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading Reverse Point',
		displayType: 'textfield',
		order: 1030,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingReversePoint: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Reverse Point'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Going Path Move Time'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading Going Path Move Time',
		displayType: 'textfield',
		order: 1040,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingGoingPathMoveTime: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Going Path Move Time'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Back Path Move Time'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading Back Path Move Time',
		displayType: 'textfield',
		order: 1050,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingBackPathMoveTime: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Back Path Move Time'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Reverse Range'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading Reverse Range',
		displayType: 'textfield',
		order: 1060,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingReverseRange: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Reverse Range'] end

	// % protected region % [Modify props to the crud options here for attribute 'Reverse Add Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Reverse Add Margin',
		displayType: 'textfield',
		order: 1070,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public reverseAddMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Reverse Add Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Ahs Area Starting Point X'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Ahs Area Starting Point X',
		displayType: 'textfield',
		order: 1080,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public ahsAreaStartingPointX: number;
	// % protected region % [Modify props to the crud options here for attribute 'Ahs Area Starting Point X'] end

	// % protected region % [Modify props to the crud options here for attribute 'Ahs Area Starting Point Y'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Ahs Area Starting Point Y',
		displayType: 'textfield',
		order: 1090,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public ahsAreaStartingPointY: number;
	// % protected region % [Modify props to the crud options here for attribute 'Ahs Area Starting Point Y'] end

	// % protected region % [Modify props to the crud options here for attribute 'Ahs Area Width Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Ahs Area Width Max',
		displayType: 'textfield',
		order: 1100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public ahsAreaWidthMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Ahs Area Width Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Ahs Area Length Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Ahs Area Length Max',
		displayType: 'textfield',
		order: 1110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public ahsAreaLengthMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Ahs Area Length Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Ote Spillage Myself Width'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Ote Spillage Myself Width',
		displayType: 'textfield',
		order: 1120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public oteSpillageMyselfWidth: number;
	// % protected region % [Modify props to the crud options here for attribute 'Ote Spillage Myself Width'] end

	// % protected region % [Modify props to the crud options here for attribute 'Ote Spillage Other Front Offset'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Ote Spillage Other Front Offset',
		displayType: 'textfield',
		order: 1130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public oteSpillageOtherFrontOffset: number;
	// % protected region % [Modify props to the crud options here for attribute 'Ote Spillage Other Front Offset'] end

	// % protected region % [Modify props to the crud options here for attribute 'Ote Spillage Other Length'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Ote Spillage Other Length',
		displayType: 'textfield',
		order: 1140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public oteSpillageOtherLength: number;
	// % protected region % [Modify props to the crud options here for attribute 'Ote Spillage Other Length'] end

	// % protected region % [Modify props to the crud options here for attribute 'Ote Spillage Other Width'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Ote Spillage Other Width',
		displayType: 'textfield',
		order: 1150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public oteSpillageOtherWidth: number;
	// % protected region % [Modify props to the crud options here for attribute 'Ote Spillage Other Width'] end

	// % protected region % [Modify props to the crud options here for attribute 'Permission Reset Margin Forward'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Permission Reset Margin Forward',
		displayType: 'textfield',
		order: 1160,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public permissionResetMarginForward: number;
	// % protected region % [Modify props to the crud options here for attribute 'Permission Reset Margin Forward'] end

	// % protected region % [Modify props to the crud options here for attribute 'Permission Reset Margin Backward'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Permission Reset Margin Backward',
		displayType: 'textfield',
		order: 1170,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public permissionResetMarginBackward: number;
	// % protected region % [Modify props to the crud options here for attribute 'Permission Reset Margin Backward'] end

	// % protected region % [Modify props to the crud options here for attribute 'Permission Judge Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Permission Judge Margin',
		displayType: 'textfield',
		order: 1180,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public permissionJudgeMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Permission Judge Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Back Path Move Time Longer'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading Back Path Move Time Longer',
		displayType: 'textfield',
		order: 1190,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingBackPathMoveTimeLonger: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Back Path Move Time Longer'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Back Path Move Time Onetime'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading Back Path Move Time Onetime',
		displayType: 'textfield',
		order: 1200,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingBackPathMoveTimeOnetime: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Back Path Move Time Onetime'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Going Path Move Time Onetime'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading Going Path Move Time Onetime',
		displayType: 'textfield',
		order: 1210,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingGoingPathMoveTimeOnetime: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Going Path Move Time Onetime'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Reverse Point Onetime'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading Reverse Point Onetime',
		displayType: 'textfield',
		order: 1220,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingReversePointOnetime: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Reverse Point Onetime'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Reverse Range Onetime'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading Reverse Range Onetime',
		displayType: 'textfield',
		order: 1230,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingReverseRangeOnetime: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Reverse Range Onetime'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Back Path Move Time Longer Onetime'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading Back Path Move Time Longer Onetime',
		displayType: 'textfield',
		order: 1240,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingBackPathMoveTimeLongerOnetime: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Back Path Move Time Longer Onetime'] end

	// % protected region % [Modify props to the crud options here for attribute 'Area Expansion Aht Polygon Front Buffer'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Area Expansion Aht Polygon Front Buffer',
		displayType: 'textfield',
		order: 1250,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public areaExpansionAhtPolygonFrontBuffer: number;
	// % protected region % [Modify props to the crud options here for attribute 'Area Expansion Aht Polygon Front Buffer'] end

	// % protected region % [Modify props to the crud options here for attribute 'Area Expansion Aht Polygon Front Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Area Expansion Aht Polygon Front Margin',
		displayType: 'textfield',
		order: 1260,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public areaExpansionAhtPolygonFrontMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Area Expansion Aht Polygon Front Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Area Expansion Aht Polygon Rear Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Area Expansion Aht Polygon Rear Margin',
		displayType: 'textfield',
		order: 1270,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public areaExpansionAhtPolygonRearMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Area Expansion Aht Polygon Rear Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Area Expansion Aht Polygon Front Length'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Area Expansion Aht Polygon Front Length',
		displayType: 'textfield',
		order: 1280,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public areaExpansionAhtPolygonFrontLength: number;
	// % protected region % [Modify props to the crud options here for attribute 'Area Expansion Aht Polygon Front Length'] end

	// % protected region % [Modify props to the crud options here for attribute 'Area Expansion Aht Polygon Rear Length'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Area Expansion Aht Polygon Rear Length',
		displayType: 'textfield',
		order: 1290,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public areaExpansionAhtPolygonRearLength: number;
	// % protected region % [Modify props to the crud options here for attribute 'Area Expansion Aht Polygon Rear Length'] end

	// % protected region % [Modify props to the crud options here for attribute 'Aht Max Rear Body Width'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Aht Max Rear Body Width',
		displayType: 'textfield',
		order: 1300,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public ahtMaxRearBodyWidth: number;
	// % protected region % [Modify props to the crud options here for attribute 'Aht Max Rear Body Width'] end

	// % protected region % [Modify props to the crud options here for attribute 'Area Expansion Berm Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Area Expansion Berm Margin',
		displayType: 'textfield',
		order: 1310,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public areaExpansionBermMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Area Expansion Berm Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Non Over Hang Margin Distance'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Non Over Hang Margin Distance',
		displayType: 'textfield',
		order: 1320,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public nonOverHangMarginDistance: number;
	// % protected region % [Modify props to the crud options here for attribute 'Non Over Hang Margin Distance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dynamic Path Loaded Angular Velocity'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dynamic Path Loaded Angular Velocity',
		displayType: 'textfield',
		order: 1330,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public dynamicPathLoadedAngularVelocity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dynamic Path Loaded Angular Velocity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dynamic Path Unloaded Angular Velocity'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dynamic Path Unloaded Angular Velocity',
		displayType: 'textfield',
		order: 1340,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public dynamicPathUnloadedAngularVelocity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dynamic Path Unloaded Angular Velocity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Shortest Overlapping'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading Shortest Overlapping',
		displayType: 'textfield',
		order: 1350,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingShortestOverlapping: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Shortest Overlapping'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Shortest Overlapping Onetime'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading Shortest Overlapping Onetime',
		displayType: 'textfield',
		order: 1360,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingShortestOverlappingOnetime: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Shortest Overlapping Onetime'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading No Overlap Outbound And Inbound'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading No Overlap Outbound And Inbound',
		displayType: 'textfield',
		order: 1370,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingNoOverlapOutboundAndInbound: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading No Overlap Outbound And Inbound'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading No OverlapOutbound And Inbound Onetime'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading No OverlapOutbound And Inbound Onetime',
		displayType: 'textfield',
		order: 1380,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingNoOverlapOutboundAndInboundOnetime: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading No OverlapOutbound And Inbound Onetime'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Runup Distance'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading Runup Distance',
		displayType: 'textfield',
		order: 1390,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingRunupDistance: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Runup Distance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Runup Distance Onetime'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Loading Runup Distance Onetime',
		displayType: 'textfield',
		order: 1400,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityLoadingRunupDistanceOnetime: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Loading Runup Distance Onetime'] end

	// % protected region % [Modify props to the crud options here for attribute 'Runup Distance High Speed'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Runup Distance High Speed',
		displayType: 'textfield',
		order: 1410,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public runupDistanceHighSpeed: number;
	// % protected region % [Modify props to the crud options here for attribute 'Runup Distance High Speed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Runup Distance Slow Speed'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Runup Distance Slow Speed',
		displayType: 'textfield',
		order: 1420,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public runupDistanceSlowSpeed: number;
	// % protected region % [Modify props to the crud options here for attribute 'Runup Distance Slow Speed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote Back Path Move Time'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Ote Back Path Move Time',
		displayType: 'textfield',
		order: 1430,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingOteBackPathMoveTime: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote Back Path Move Time'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote Going Path Move Time'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Ote Going Path Move Time',
		displayType: 'textfield',
		order: 1440,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingOteGoingPathMoveTime: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote Going Path Move Time'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote Reverse Point'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Ote Reverse Point',
		displayType: 'textfield',
		order: 1450,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingOteReversePoint: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote Reverse Point'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote Reverse Range'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Ote Reverse Range',
		displayType: 'textfield',
		order: 1460,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingOteReverseRange: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote Reverse Range'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote Back Path Move Time Longer'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Ote Back Path Move Time Longer',
		displayType: 'textfield',
		order: 1470,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingOteBackPathMoveTimeLonger: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote Back Path Move Time Longer'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote Shortest Overlapping'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Ote Shortest Overlapping',
		displayType: 'textfield',
		order: 1480,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingOteShortestOverlapping: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote Shortest Overlapping'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote No Overlap Outbound And Inbound'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Ote No Overlap Outbound And Inbound',
		displayType: 'textfield',
		order: 1490,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingOteNoOverlapOutboundAndInbound: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote No Overlap Outbound And Inbound'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote Runup Distance'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Ote Runup Distance',
		displayType: 'textfield',
		order: 1500,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingOteRunupDistance: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Ote Runup Distance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock Back Path Move Time'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Paddock Back Path Move Time',
		displayType: 'textfield',
		order: 1510,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingPaddockBackPathMoveTime: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock Back Path Move Time'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock Going Path Move Time'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Paddock Going Path Move Time',
		displayType: 'textfield',
		order: 1520,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingPaddockGoingPathMoveTime: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock Going Path Move Time'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock Reverse Point'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Paddock Reverse Point',
		displayType: 'textfield',
		order: 1530,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingPaddockReversePoint: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock Reverse Point'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock Reverse Range'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Paddock Reverse Range',
		displayType: 'textfield',
		order: 1540,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingPaddockReverseRange: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock Reverse Range'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock Back Path Move Time Longer'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Paddock Back Path Move Time Longer',
		displayType: 'textfield',
		order: 1550,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingPaddockBackPathMoveTimeLonger: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock Back Path Move Time Longer'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock Shortest Overlapping'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Paddock Shortest Overlapping',
		displayType: 'textfield',
		order: 1560,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingPaddockShortestOverlapping: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock Shortest Overlapping'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock No Overlap Outbound And Inbound'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Paddock No Overlap Outbound And Inbound',
		displayType: 'textfield',
		order: 1570,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingPaddockNoOverlapOutboundAndInbound: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock No Overlap Outbound And Inbound'] end

	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock Runup Distance'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Priority Dumping Paddock Runup Distance',
		displayType: 'textfield',
		order: 1580,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priorityDumpingPaddockRunupDistance: number;
	// % protected region % [Modify props to the crud options here for attribute 'Priority Dumping Paddock Runup Distance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Back Buffer Max'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Back Buffer Max',
		displayType: 'textfield',
		order: 1590,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public backBufferMax: number;
	// % protected region % [Modify props to the crud options here for attribute 'Back Buffer Max'] end

	// % protected region % [Modify props to the crud options here for attribute 'Back Buffer Min'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Back Buffer Min',
		displayType: 'textfield',
		order: 1600,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public backBufferMin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Back Buffer Min'] end

	// % protected region % [Modify props to the crud options here for attribute 'Back Buffer Step'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Back Buffer Step',
		displayType: 'textfield',
		order: 1610,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public backBufferStep: number;
	// % protected region % [Modify props to the crud options here for attribute 'Back Buffer Step'] end

	// % protected region % [Modify props to the crud options here for attribute 'Map Check Forward Start Line Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Map Check Forward Start Line Margin',
		displayType: 'textfield',
		order: 1620,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public mapCheckForwardStartLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Map Check Forward Start Line Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Map Check Forward End Line Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Map Check Forward End Line Margin',
		displayType: 'textfield',
		order: 1630,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public mapCheckForwardEndLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Map Check Forward End Line Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Map Check Back Start Line Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Map Check Back Start Line Margin',
		displayType: 'textfield',
		order: 1640,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public mapCheckBackStartLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Map Check Back Start Line Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Map Check Back End Line Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Map Check Back End Line Margin',
		displayType: 'textfield',
		order: 1650,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public mapCheckBackEndLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Map Check Back End Line Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Map Check Forward Start Line Margin Dry Steering'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Map Check Forward Start Line Margin Dry Steering',
		displayType: 'textfield',
		order: 1660,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public mapCheckForwardStartLineMarginDrySteering: number;
	// % protected region % [Modify props to the crud options here for attribute 'Map Check Forward Start Line Margin Dry Steering'] end

	// % protected region % [Modify props to the crud options here for attribute 'Map Check Forward End Line Margin Dry Steering'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Map Check Forward End Line Margin Dry Steering',
		displayType: 'textfield',
		order: 1670,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public mapCheckForwardEndLineMarginDrySteering: number;
	// % protected region % [Modify props to the crud options here for attribute 'Map Check Forward End Line Margin Dry Steering'] end

	// % protected region % [Modify props to the crud options here for attribute 'Map Check Back Start Line Margin Dry Steering'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Map Check Back Start Line Margin Dry Steering',
		displayType: 'textfield',
		order: 1680,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public mapCheckBackStartLineMarginDrySteering: number;
	// % protected region % [Modify props to the crud options here for attribute 'Map Check Back Start Line Margin Dry Steering'] end

	// % protected region % [Modify props to the crud options here for attribute 'Map Check Back End Line Margin Dry Steering'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Map Check Back End Line Margin Dry Steering',
		displayType: 'textfield',
		order: 1690,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public mapCheckBackEndLineMarginDrySteering: number;
	// % protected region % [Modify props to the crud options here for attribute 'Map Check Back End Line Margin Dry Steering'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Loaded Forward Start Line Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dry Steering Loaded Forward Start Line Margin',
		displayType: 'textfield',
		order: 1700,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public drySteeringLoadedForwardStartLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Loaded Forward Start Line Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Un Loaded Forward Start Line Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dry Steering Un Loaded Forward Start Line Margin',
		displayType: 'textfield',
		order: 1710,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public drySteeringUnLoadedForwardStartLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Un Loaded Forward Start Line Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Loaded Forward End Line Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dry Steering Loaded Forward End Line Margin',
		displayType: 'textfield',
		order: 1720,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public drySteeringLoadedForwardEndLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Loaded Forward End Line Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Unloaded Forward End Line Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dry Steering Unloaded Forward End Line Margin',
		displayType: 'textfield',
		order: 1730,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public drySteeringUnloadedForwardEndLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Unloaded Forward End Line Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Loaded Back Start Line Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dry Steering Loaded Back Start Line Margin',
		displayType: 'textfield',
		order: 1740,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public drySteeringLoadedBackStartLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Loaded Back Start Line Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Un Loaded Back Start Line Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dry Steering Un Loaded Back Start Line Margin',
		displayType: 'textfield',
		order: 1750,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public drySteeringUnLoadedBackStartLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Un Loaded Back Start Line Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Loaded Back End Line Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dry Steering Loaded Back End Line Margin',
		displayType: 'textfield',
		order: 1760,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public drySteeringLoadedBackEndLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Loaded Back End Line Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Un Loaded Back End Line Margin'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dry Steering Un Loaded Back End Line Margin',
		displayType: 'textfield',
		order: 1770,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public drySteeringUnLoadedBackEndLineMargin: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Un Loaded Back End Line Margin'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Dynamic Path Loaded Angular Velocity'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dry Steering Dynamic Path Loaded Angular Velocity',
		displayType: 'textfield',
		order: 1780,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public drySteeringDynamicPathLoadedAngularVelocity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Dynamic Path Loaded Angular Velocity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Dynamic Path Unloaded Angular Velocity'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dry Steering Dynamic Path Unloaded Angular Velocity',
		displayType: 'textfield',
		order: 1790,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public drySteeringDynamicPathUnloadedAngularVelocity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Dynamic Path Unloaded Angular Velocity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Min Radius Re Spot Drop Cut Back Path'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dry Steering Min Radius Re Spot Drop Cut Back Path',
		displayType: 'textfield',
		order: 1800,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public drySteeringMinRadiusReSpotDropCutBackPath: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Min Radius Re Spot Drop Cut Back Path'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Min Radius'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dry Steering Min Radius',
		displayType: 'textfield',
		order: 1810,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public drySteeringMinRadius: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Min Radius'] end

	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Max Radius'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Dry Steering Max Radius',
		displayType: 'textfield',
		order: 1820,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public drySteeringMaxRadius: number;
	// % protected region % [Modify props to the crud options here for attribute 'Dry Steering Max Radius'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Import Version'] off begin
		name: 'Import Version',
		displayType: 'reference-combobox',
		order: 1830,
		referenceTypeFunc: () => Models.ImportVersionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'importVersions',
			oppositeEntity: () => Models.ImportVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Import Version'] end
	})
	public importVersionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public importVersion: Models.ImportVersionEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IMapToolParamEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IMapToolParamEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.maxSpeedEmpty !== undefined) {
				this.maxSpeedEmpty = attributes.maxSpeedEmpty;
			}
			if (attributes.maxSpeedLoaded !== undefined) {
				this.maxSpeedLoaded = attributes.maxSpeedLoaded;
			}
			if (attributes.minSpeedEmpty !== undefined) {
				this.minSpeedEmpty = attributes.minSpeedEmpty;
			}
			if (attributes.minSpeedLoaded !== undefined) {
				this.minSpeedLoaded = attributes.minSpeedLoaded;
			}
			if (attributes.coordParamA !== undefined) {
				this.coordParamA = attributes.coordParamA;
			}
			if (attributes.coordParamB !== undefined) {
				this.coordParamB = attributes.coordParamB;
			}
			if (attributes.coordParamC !== undefined) {
				this.coordParamC = attributes.coordParamC;
			}
			if (attributes.coordParamD !== undefined) {
				this.coordParamD = attributes.coordParamD;
			}
			if (attributes.coordParamE !== undefined) {
				this.coordParamE = attributes.coordParamE;
			}
			if (attributes.coordParamF !== undefined) {
				this.coordParamF = attributes.coordParamF;
			}
			if (attributes.bermRightError !== undefined) {
				this.bermRightError = attributes.bermRightError;
			}
			if (attributes.bermLeftError !== undefined) {
				this.bermLeftError = attributes.bermLeftError;
			}
			if (attributes.nodeDistMin !== undefined) {
				this.nodeDistMin = attributes.nodeDistMin;
			}
			if (attributes.nodeDistMax !== undefined) {
				this.nodeDistMax = attributes.nodeDistMax;
			}
			if (attributes.maxSpeedBackward !== undefined) {
				this.maxSpeedBackward = attributes.maxSpeedBackward;
			}
			if (attributes.ahtDeviationBufferWidth !== undefined) {
				this.ahtDeviationBufferWidth = attributes.ahtDeviationBufferWidth;
			}
			if (attributes.ahtCloseLimitWidth !== undefined) {
				this.ahtCloseLimitWidth = attributes.ahtCloseLimitWidth;
			}
			if (attributes.ahtCloseLimitWidthDynamic !== undefined) {
				this.ahtCloseLimitWidthDynamic = attributes.ahtCloseLimitWidthDynamic;
			}
			if (attributes.mivCloseJudgeWidth !== undefined) {
				this.mivCloseJudgeWidth = attributes.mivCloseJudgeWidth;
			}
			if (attributes.mivCloseJudgeWidthDynamic !== undefined) {
				this.mivCloseJudgeWidthDynamic = attributes.mivCloseJudgeWidthDynamic;
			}
			if (attributes.truckWidth !== undefined) {
				this.truckWidth = attributes.truckWidth;
			}
			if (attributes.truckLength !== undefined) {
				this.truckLength = attributes.truckLength;
			}
			if (attributes.truckOffset !== undefined) {
				this.truckOffset = attributes.truckOffset;
			}
			if (attributes.truckFrontMargin !== undefined) {
				this.truckFrontMargin = attributes.truckFrontMargin;
			}
			if (attributes.truckWidthMargin !== undefined) {
				this.truckWidthMargin = attributes.truckWidthMargin;
			}
			if (attributes.truckRearMargin !== undefined) {
				this.truckRearMargin = attributes.truckRearMargin;
			}
			if (attributes.unloadingAngularVelocity !== undefined) {
				this.unloadingAngularVelocity = attributes.unloadingAngularVelocity;
			}
			if (attributes.minRadius !== undefined) {
				this.minRadius = attributes.minRadius;
			}
			if (attributes.forwardSpeed !== undefined) {
				this.forwardSpeed = attributes.forwardSpeed;
			}
			if (attributes.maxRadius !== undefined) {
				this.maxRadius = attributes.maxRadius;
			}
			if (attributes.backwardSpeed !== undefined) {
				this.backwardSpeed = attributes.backwardSpeed;
			}
			if (attributes.wheelBase !== undefined) {
				this.wheelBase = attributes.wheelBase;
			}
			if (attributes.backStartLineMargin !== undefined) {
				this.backStartLineMargin = attributes.backStartLineMargin;
			}
			if (attributes.sideAccelGravityThr !== undefined) {
				this.sideAccelGravityThr = attributes.sideAccelGravityThr;
			}
			if (attributes.loadingAngularVelocity !== undefined) {
				this.loadingAngularVelocity = attributes.loadingAngularVelocity;
			}
			if (attributes.backEndLineMargin !== undefined) {
				this.backEndLineMargin = attributes.backEndLineMargin;
			}
			if (attributes.forwardStartLineMargin !== undefined) {
				this.forwardStartLineMargin = attributes.forwardStartLineMargin;
			}
			if (attributes.forwardEndLineMargin !== undefined) {
				this.forwardEndLineMargin = attributes.forwardEndLineMargin;
			}
			if (attributes.exitDistance !== undefined) {
				this.exitDistance = attributes.exitDistance;
			}
			if (attributes.enterDistance !== undefined) {
				this.enterDistance = attributes.enterDistance;
			}
			if (attributes.viaMarginA !== undefined) {
				this.viaMarginA = attributes.viaMarginA;
			}
			if (attributes.viaMarginB !== undefined) {
				this.viaMarginB = attributes.viaMarginB;
			}
			if (attributes.reverseSearchDegStep !== undefined) {
				this.reverseSearchDegStep = attributes.reverseSearchDegStep;
			}
			if (attributes.nodeLengthThr !== undefined) {
				this.nodeLengthThr = attributes.nodeLengthThr;
			}
			if (attributes.sublinkLengthThr !== undefined) {
				this.sublinkLengthThr = attributes.sublinkLengthThr;
			}
			if (attributes.curvatureDefault !== undefined) {
				this.curvatureDefault = attributes.curvatureDefault;
			}
			if (attributes.gradientDefault !== undefined) {
				this.gradientDefault = attributes.gradientDefault;
			}
			if (attributes.bermRightDefault !== undefined) {
				this.bermRightDefault = attributes.bermRightDefault;
			}
			if (attributes.bermLeftDefault !== undefined) {
				this.bermLeftDefault = attributes.bermLeftDefault;
			}
			if (attributes.releaseLengthThr !== undefined) {
				this.releaseLengthThr = attributes.releaseLengthThr;
			}
			if (attributes.spotLimitDistance !== undefined) {
				this.spotLimitDistance = attributes.spotLimitDistance;
			}
			if (attributes.spotLimitStepDistance !== undefined) {
				this.spotLimitStepDistance = attributes.spotLimitStepDistance;
			}
			if (attributes.isAllNodesInBoundary !== undefined) {
				this.isAllNodesInBoundary = attributes.isAllNodesInBoundary;
			}
			if (attributes.checkSpotInZone !== undefined) {
				this.checkSpotInZone = attributes.checkSpotInZone;
			}
			if (attributes.checkTruckInZone !== undefined) {
				this.checkTruckInZone = attributes.checkTruckInZone;
			}
			if (attributes.checkFinalPathInZone !== undefined) {
				this.checkFinalPathInZone = attributes.checkFinalPathInZone;
			}
			if (attributes.checkBlockControl !== undefined) {
				this.checkBlockControl = attributes.checkBlockControl;
			}
			if (attributes.vicinitySublinkDistance !== undefined) {
				this.vicinitySublinkDistance = attributes.vicinitySublinkDistance;
			}
			if (attributes.areaExtensionWidth !== undefined) {
				this.areaExtensionWidth = attributes.areaExtensionWidth;
			}
			if (attributes.areaExtensionLength !== undefined) {
				this.areaExtensionLength = attributes.areaExtensionLength;
			}
			if (attributes.areaExtensionBack !== undefined) {
				this.areaExtensionBack = attributes.areaExtensionBack;
			}
			if (attributes.areaExtensionLengthLimit !== undefined) {
				this.areaExtensionLengthLimit = attributes.areaExtensionLengthLimit;
			}
			if (attributes.dumpPileWidth !== undefined) {
				this.dumpPileWidth = attributes.dumpPileWidth;
			}
			if (attributes.dumpPileLength !== undefined) {
				this.dumpPileLength = attributes.dumpPileLength;
			}
			if (attributes.dumpPileOffset !== undefined) {
				this.dumpPileOffset = attributes.dumpPileOffset;
			}
			if (attributes.bayWidth !== undefined) {
				this.bayWidth = attributes.bayWidth;
			}
			if (attributes.bayLength !== undefined) {
				this.bayLength = attributes.bayLength;
			}
			if (attributes.bayToBay !== undefined) {
				this.bayToBay = attributes.bayToBay;
			}
			if (attributes.ahtMaxWidth !== undefined) {
				this.ahtMaxWidth = attributes.ahtMaxWidth;
			}
			if (attributes.ahtMaxLength !== undefined) {
				this.ahtMaxLength = attributes.ahtMaxLength;
			}
			if (attributes.ahtMaxOffset !== undefined) {
				this.ahtMaxOffset = attributes.ahtMaxOffset;
			}
			if (attributes.nodeIdMax !== undefined) {
				this.nodeIdMax = attributes.nodeIdMax;
			}
			if (attributes.staticLinkIdMax !== undefined) {
				this.staticLinkIdMax = attributes.staticLinkIdMax;
			}
			if (attributes.staticPathLinkMax !== undefined) {
				this.staticPathLinkMax = attributes.staticPathLinkMax;
			}
			if (attributes.staticPathSublinkMax !== undefined) {
				this.staticPathSublinkMax = attributes.staticPathSublinkMax;
			}
			if (attributes.staticPathNodeMax !== undefined) {
				this.staticPathNodeMax = attributes.staticPathNodeMax;
			}
			if (attributes.staticPathInlinkSublinkMax !== undefined) {
				this.staticPathInlinkSublinkMax = attributes.staticPathInlinkSublinkMax;
			}
			if (attributes.staticPathInSublinkNodeMax !== undefined) {
				this.staticPathInSublinkNodeMax = attributes.staticPathInSublinkNodeMax;
			}
			if (attributes.dynamicPathLinkMax !== undefined) {
				this.dynamicPathLinkMax = attributes.dynamicPathLinkMax;
			}
			if (attributes.dynamicPathSublinkMax !== undefined) {
				this.dynamicPathSublinkMax = attributes.dynamicPathSublinkMax;
			}
			if (attributes.dynamicPathNodeMax !== undefined) {
				this.dynamicPathNodeMax = attributes.dynamicPathNodeMax;
			}
			if (attributes.routeStaticPathLinkMax !== undefined) {
				this.routeStaticPathLinkMax = attributes.routeStaticPathLinkMax;
			}
			if (attributes.routeStaticPathSublinkMax !== undefined) {
				this.routeStaticPathSublinkMax = attributes.routeStaticPathSublinkMax;
			}
			if (attributes.routeStaticPathNodeMax !== undefined) {
				this.routeStaticPathNodeMax = attributes.routeStaticPathNodeMax;
			}
			if (attributes.routeDynamicPathLinkToBayMax !== undefined) {
				this.routeDynamicPathLinkToBayMax = attributes.routeDynamicPathLinkToBayMax;
			}
			if (attributes.routeDynamicPathLinkToExitMax !== undefined) {
				this.routeDynamicPathLinkToExitMax = attributes.routeDynamicPathLinkToExitMax;
			}
			if (attributes.routeDynamicPathSublinkToBayMax !== undefined) {
				this.routeDynamicPathSublinkToBayMax = attributes.routeDynamicPathSublinkToBayMax;
			}
			if (attributes.routeDynamicPathSublinkToExitMax !== undefined) {
				this.routeDynamicPathSublinkToExitMax = attributes.routeDynamicPathSublinkToExitMax;
			}
			if (attributes.routeDynamicPathNodeToBayMax !== undefined) {
				this.routeDynamicPathNodeToBayMax = attributes.routeDynamicPathNodeToBayMax;
			}
			if (attributes.routeDynamicPathNodeToExitMax !== undefined) {
				this.routeDynamicPathNodeToExitMax = attributes.routeDynamicPathNodeToExitMax;
			}
			if (attributes.fmsMapSegmentMax !== undefined) {
				this.fmsMapSegmentMax = attributes.fmsMapSegmentMax;
			}
			if (attributes.fmsMapRootBeaconMax !== undefined) {
				this.fmsMapRootBeaconMax = attributes.fmsMapRootBeaconMax;
			}
			if (attributes.fmsMapRootBeaconAccessMax !== undefined) {
				this.fmsMapRootBeaconAccessMax = attributes.fmsMapRootBeaconAccessMax;
			}
			if (attributes.ahtFollowingError !== undefined) {
				this.ahtFollowingError = attributes.ahtFollowingError;
			}
			if (attributes.gnssAccuracyError !== undefined) {
				this.gnssAccuracyError = attributes.gnssAccuracyError;
			}
			if (attributes.maxUphillGradient !== undefined) {
				this.maxUphillGradient = attributes.maxUphillGradient;
			}
			if (attributes.maxDownhillGradient !== undefined) {
				this.maxDownhillGradient = attributes.maxDownhillGradient;
			}
			if (attributes.turnbackPathAngle !== undefined) {
				this.turnbackPathAngle = attributes.turnbackPathAngle;
			}
			if (attributes.crusherOffset !== undefined) {
				this.crusherOffset = attributes.crusherOffset;
			}
			if (attributes.straightThresholdCurvature !== undefined) {
				this.straightThresholdCurvature = attributes.straightThresholdCurvature;
			}
			if (attributes.curveMarginContinuationDistance !== undefined) {
				this.curveMarginContinuationDistance = attributes.curveMarginContinuationDistance;
			}
			if (attributes.overHangMargin !== undefined) {
				this.overHangMargin = attributes.overHangMargin;
			}
			if (attributes.priorityLoadingReversePoint !== undefined) {
				this.priorityLoadingReversePoint = attributes.priorityLoadingReversePoint;
			}
			if (attributes.priorityLoadingGoingPathMoveTime !== undefined) {
				this.priorityLoadingGoingPathMoveTime = attributes.priorityLoadingGoingPathMoveTime;
			}
			if (attributes.priorityLoadingBackPathMoveTime !== undefined) {
				this.priorityLoadingBackPathMoveTime = attributes.priorityLoadingBackPathMoveTime;
			}
			if (attributes.priorityLoadingReverseRange !== undefined) {
				this.priorityLoadingReverseRange = attributes.priorityLoadingReverseRange;
			}
			if (attributes.reverseAddMargin !== undefined) {
				this.reverseAddMargin = attributes.reverseAddMargin;
			}
			if (attributes.ahsAreaStartingPointX !== undefined) {
				this.ahsAreaStartingPointX = attributes.ahsAreaStartingPointX;
			}
			if (attributes.ahsAreaStartingPointY !== undefined) {
				this.ahsAreaStartingPointY = attributes.ahsAreaStartingPointY;
			}
			if (attributes.ahsAreaWidthMax !== undefined) {
				this.ahsAreaWidthMax = attributes.ahsAreaWidthMax;
			}
			if (attributes.ahsAreaLengthMax !== undefined) {
				this.ahsAreaLengthMax = attributes.ahsAreaLengthMax;
			}
			if (attributes.oteSpillageMyselfWidth !== undefined) {
				this.oteSpillageMyselfWidth = attributes.oteSpillageMyselfWidth;
			}
			if (attributes.oteSpillageOtherFrontOffset !== undefined) {
				this.oteSpillageOtherFrontOffset = attributes.oteSpillageOtherFrontOffset;
			}
			if (attributes.oteSpillageOtherLength !== undefined) {
				this.oteSpillageOtherLength = attributes.oteSpillageOtherLength;
			}
			if (attributes.oteSpillageOtherWidth !== undefined) {
				this.oteSpillageOtherWidth = attributes.oteSpillageOtherWidth;
			}
			if (attributes.permissionResetMarginForward !== undefined) {
				this.permissionResetMarginForward = attributes.permissionResetMarginForward;
			}
			if (attributes.permissionResetMarginBackward !== undefined) {
				this.permissionResetMarginBackward = attributes.permissionResetMarginBackward;
			}
			if (attributes.permissionJudgeMargin !== undefined) {
				this.permissionJudgeMargin = attributes.permissionJudgeMargin;
			}
			if (attributes.priorityLoadingBackPathMoveTimeLonger !== undefined) {
				this.priorityLoadingBackPathMoveTimeLonger = attributes.priorityLoadingBackPathMoveTimeLonger;
			}
			if (attributes.priorityLoadingBackPathMoveTimeOnetime !== undefined) {
				this.priorityLoadingBackPathMoveTimeOnetime = attributes.priorityLoadingBackPathMoveTimeOnetime;
			}
			if (attributes.priorityLoadingGoingPathMoveTimeOnetime !== undefined) {
				this.priorityLoadingGoingPathMoveTimeOnetime = attributes.priorityLoadingGoingPathMoveTimeOnetime;
			}
			if (attributes.priorityLoadingReversePointOnetime !== undefined) {
				this.priorityLoadingReversePointOnetime = attributes.priorityLoadingReversePointOnetime;
			}
			if (attributes.priorityLoadingReverseRangeOnetime !== undefined) {
				this.priorityLoadingReverseRangeOnetime = attributes.priorityLoadingReverseRangeOnetime;
			}
			if (attributes.priorityLoadingBackPathMoveTimeLongerOnetime !== undefined) {
				this.priorityLoadingBackPathMoveTimeLongerOnetime = attributes.priorityLoadingBackPathMoveTimeLongerOnetime;
			}
			if (attributes.areaExpansionAhtPolygonFrontBuffer !== undefined) {
				this.areaExpansionAhtPolygonFrontBuffer = attributes.areaExpansionAhtPolygonFrontBuffer;
			}
			if (attributes.areaExpansionAhtPolygonFrontMargin !== undefined) {
				this.areaExpansionAhtPolygonFrontMargin = attributes.areaExpansionAhtPolygonFrontMargin;
			}
			if (attributes.areaExpansionAhtPolygonRearMargin !== undefined) {
				this.areaExpansionAhtPolygonRearMargin = attributes.areaExpansionAhtPolygonRearMargin;
			}
			if (attributes.areaExpansionAhtPolygonFrontLength !== undefined) {
				this.areaExpansionAhtPolygonFrontLength = attributes.areaExpansionAhtPolygonFrontLength;
			}
			if (attributes.areaExpansionAhtPolygonRearLength !== undefined) {
				this.areaExpansionAhtPolygonRearLength = attributes.areaExpansionAhtPolygonRearLength;
			}
			if (attributes.ahtMaxRearBodyWidth !== undefined) {
				this.ahtMaxRearBodyWidth = attributes.ahtMaxRearBodyWidth;
			}
			if (attributes.areaExpansionBermMargin !== undefined) {
				this.areaExpansionBermMargin = attributes.areaExpansionBermMargin;
			}
			if (attributes.nonOverHangMarginDistance !== undefined) {
				this.nonOverHangMarginDistance = attributes.nonOverHangMarginDistance;
			}
			if (attributes.dynamicPathLoadedAngularVelocity !== undefined) {
				this.dynamicPathLoadedAngularVelocity = attributes.dynamicPathLoadedAngularVelocity;
			}
			if (attributes.dynamicPathUnloadedAngularVelocity !== undefined) {
				this.dynamicPathUnloadedAngularVelocity = attributes.dynamicPathUnloadedAngularVelocity;
			}
			if (attributes.priorityLoadingShortestOverlapping !== undefined) {
				this.priorityLoadingShortestOverlapping = attributes.priorityLoadingShortestOverlapping;
			}
			if (attributes.priorityLoadingShortestOverlappingOnetime !== undefined) {
				this.priorityLoadingShortestOverlappingOnetime = attributes.priorityLoadingShortestOverlappingOnetime;
			}
			if (attributes.priorityLoadingNoOverlapOutboundAndInbound !== undefined) {
				this.priorityLoadingNoOverlapOutboundAndInbound = attributes.priorityLoadingNoOverlapOutboundAndInbound;
			}
			if (attributes.priorityLoadingNoOverlapOutboundAndInboundOnetime !== undefined) {
				this.priorityLoadingNoOverlapOutboundAndInboundOnetime = attributes.priorityLoadingNoOverlapOutboundAndInboundOnetime;
			}
			if (attributes.priorityLoadingRunupDistance !== undefined) {
				this.priorityLoadingRunupDistance = attributes.priorityLoadingRunupDistance;
			}
			if (attributes.priorityLoadingRunupDistanceOnetime !== undefined) {
				this.priorityLoadingRunupDistanceOnetime = attributes.priorityLoadingRunupDistanceOnetime;
			}
			if (attributes.runupDistanceHighSpeed !== undefined) {
				this.runupDistanceHighSpeed = attributes.runupDistanceHighSpeed;
			}
			if (attributes.runupDistanceSlowSpeed !== undefined) {
				this.runupDistanceSlowSpeed = attributes.runupDistanceSlowSpeed;
			}
			if (attributes.priorityDumpingOteBackPathMoveTime !== undefined) {
				this.priorityDumpingOteBackPathMoveTime = attributes.priorityDumpingOteBackPathMoveTime;
			}
			if (attributes.priorityDumpingOteGoingPathMoveTime !== undefined) {
				this.priorityDumpingOteGoingPathMoveTime = attributes.priorityDumpingOteGoingPathMoveTime;
			}
			if (attributes.priorityDumpingOteReversePoint !== undefined) {
				this.priorityDumpingOteReversePoint = attributes.priorityDumpingOteReversePoint;
			}
			if (attributes.priorityDumpingOteReverseRange !== undefined) {
				this.priorityDumpingOteReverseRange = attributes.priorityDumpingOteReverseRange;
			}
			if (attributes.priorityDumpingOteBackPathMoveTimeLonger !== undefined) {
				this.priorityDumpingOteBackPathMoveTimeLonger = attributes.priorityDumpingOteBackPathMoveTimeLonger;
			}
			if (attributes.priorityDumpingOteShortestOverlapping !== undefined) {
				this.priorityDumpingOteShortestOverlapping = attributes.priorityDumpingOteShortestOverlapping;
			}
			if (attributes.priorityDumpingOteNoOverlapOutboundAndInbound !== undefined) {
				this.priorityDumpingOteNoOverlapOutboundAndInbound = attributes.priorityDumpingOteNoOverlapOutboundAndInbound;
			}
			if (attributes.priorityDumpingOteRunupDistance !== undefined) {
				this.priorityDumpingOteRunupDistance = attributes.priorityDumpingOteRunupDistance;
			}
			if (attributes.priorityDumpingPaddockBackPathMoveTime !== undefined) {
				this.priorityDumpingPaddockBackPathMoveTime = attributes.priorityDumpingPaddockBackPathMoveTime;
			}
			if (attributes.priorityDumpingPaddockGoingPathMoveTime !== undefined) {
				this.priorityDumpingPaddockGoingPathMoveTime = attributes.priorityDumpingPaddockGoingPathMoveTime;
			}
			if (attributes.priorityDumpingPaddockReversePoint !== undefined) {
				this.priorityDumpingPaddockReversePoint = attributes.priorityDumpingPaddockReversePoint;
			}
			if (attributes.priorityDumpingPaddockReverseRange !== undefined) {
				this.priorityDumpingPaddockReverseRange = attributes.priorityDumpingPaddockReverseRange;
			}
			if (attributes.priorityDumpingPaddockBackPathMoveTimeLonger !== undefined) {
				this.priorityDumpingPaddockBackPathMoveTimeLonger = attributes.priorityDumpingPaddockBackPathMoveTimeLonger;
			}
			if (attributes.priorityDumpingPaddockShortestOverlapping !== undefined) {
				this.priorityDumpingPaddockShortestOverlapping = attributes.priorityDumpingPaddockShortestOverlapping;
			}
			if (attributes.priorityDumpingPaddockNoOverlapOutboundAndInbound !== undefined) {
				this.priorityDumpingPaddockNoOverlapOutboundAndInbound = attributes.priorityDumpingPaddockNoOverlapOutboundAndInbound;
			}
			if (attributes.priorityDumpingPaddockRunupDistance !== undefined) {
				this.priorityDumpingPaddockRunupDistance = attributes.priorityDumpingPaddockRunupDistance;
			}
			if (attributes.backBufferMax !== undefined) {
				this.backBufferMax = attributes.backBufferMax;
			}
			if (attributes.backBufferMin !== undefined) {
				this.backBufferMin = attributes.backBufferMin;
			}
			if (attributes.backBufferStep !== undefined) {
				this.backBufferStep = attributes.backBufferStep;
			}
			if (attributes.mapCheckForwardStartLineMargin !== undefined) {
				this.mapCheckForwardStartLineMargin = attributes.mapCheckForwardStartLineMargin;
			}
			if (attributes.mapCheckForwardEndLineMargin !== undefined) {
				this.mapCheckForwardEndLineMargin = attributes.mapCheckForwardEndLineMargin;
			}
			if (attributes.mapCheckBackStartLineMargin !== undefined) {
				this.mapCheckBackStartLineMargin = attributes.mapCheckBackStartLineMargin;
			}
			if (attributes.mapCheckBackEndLineMargin !== undefined) {
				this.mapCheckBackEndLineMargin = attributes.mapCheckBackEndLineMargin;
			}
			if (attributes.mapCheckForwardStartLineMarginDrySteering !== undefined) {
				this.mapCheckForwardStartLineMarginDrySteering = attributes.mapCheckForwardStartLineMarginDrySteering;
			}
			if (attributes.mapCheckForwardEndLineMarginDrySteering !== undefined) {
				this.mapCheckForwardEndLineMarginDrySteering = attributes.mapCheckForwardEndLineMarginDrySteering;
			}
			if (attributes.mapCheckBackStartLineMarginDrySteering !== undefined) {
				this.mapCheckBackStartLineMarginDrySteering = attributes.mapCheckBackStartLineMarginDrySteering;
			}
			if (attributes.mapCheckBackEndLineMarginDrySteering !== undefined) {
				this.mapCheckBackEndLineMarginDrySteering = attributes.mapCheckBackEndLineMarginDrySteering;
			}
			if (attributes.drySteeringLoadedForwardStartLineMargin !== undefined) {
				this.drySteeringLoadedForwardStartLineMargin = attributes.drySteeringLoadedForwardStartLineMargin;
			}
			if (attributes.drySteeringUnLoadedForwardStartLineMargin !== undefined) {
				this.drySteeringUnLoadedForwardStartLineMargin = attributes.drySteeringUnLoadedForwardStartLineMargin;
			}
			if (attributes.drySteeringLoadedForwardEndLineMargin !== undefined) {
				this.drySteeringLoadedForwardEndLineMargin = attributes.drySteeringLoadedForwardEndLineMargin;
			}
			if (attributes.drySteeringUnloadedForwardEndLineMargin !== undefined) {
				this.drySteeringUnloadedForwardEndLineMargin = attributes.drySteeringUnloadedForwardEndLineMargin;
			}
			if (attributes.drySteeringLoadedBackStartLineMargin !== undefined) {
				this.drySteeringLoadedBackStartLineMargin = attributes.drySteeringLoadedBackStartLineMargin;
			}
			if (attributes.drySteeringUnLoadedBackStartLineMargin !== undefined) {
				this.drySteeringUnLoadedBackStartLineMargin = attributes.drySteeringUnLoadedBackStartLineMargin;
			}
			if (attributes.drySteeringLoadedBackEndLineMargin !== undefined) {
				this.drySteeringLoadedBackEndLineMargin = attributes.drySteeringLoadedBackEndLineMargin;
			}
			if (attributes.drySteeringUnLoadedBackEndLineMargin !== undefined) {
				this.drySteeringUnLoadedBackEndLineMargin = attributes.drySteeringUnLoadedBackEndLineMargin;
			}
			if (attributes.drySteeringDynamicPathLoadedAngularVelocity !== undefined) {
				this.drySteeringDynamicPathLoadedAngularVelocity = attributes.drySteeringDynamicPathLoadedAngularVelocity;
			}
			if (attributes.drySteeringDynamicPathUnloadedAngularVelocity !== undefined) {
				this.drySteeringDynamicPathUnloadedAngularVelocity = attributes.drySteeringDynamicPathUnloadedAngularVelocity;
			}
			if (attributes.drySteeringMinRadiusReSpotDropCutBackPath !== undefined) {
				this.drySteeringMinRadiusReSpotDropCutBackPath = attributes.drySteeringMinRadiusReSpotDropCutBackPath;
			}
			if (attributes.drySteeringMinRadius !== undefined) {
				this.drySteeringMinRadius = attributes.drySteeringMinRadius;
			}
			if (attributes.drySteeringMaxRadius !== undefined) {
				this.drySteeringMaxRadius = attributes.drySteeringMaxRadius;
			}
			if (attributes.importVersionId !== undefined) {
				this.importVersionId = attributes.importVersionId;
			}
			if (attributes.importVersion !== undefined) {
				if (attributes.importVersion === null) {
					this.importVersion = attributes.importVersion;
				} else if (attributes.importVersion instanceof Models.ImportVersionEntity) {
					this.importVersion = attributes.importVersion;
					this.importVersionId = attributes.importVersion.id;
				} else {
					this.importVersion = new Models.ImportVersionEntity(attributes.importVersion);
					this.importVersionId = this.importVersion.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		importVersion {
			${Models.ImportVersionEntity.getAttributes().join('\n')}
			${Models.ImportVersionEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'importVersion',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(MapToolParamEntity.prototype, 'created');
CRUD(modifiedAttr)(MapToolParamEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
