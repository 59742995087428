import { BackgroundImageEntity } from '../../../Models/Entities';
import React from 'react';
import CollapsibleProperty from '../CollapsibleProperty';
import InputField from "./PropertiesPanelComponents/InputField";

/**
 * Render properties side panel for the background image.
 * @param backgroundImage BackgroundImageEntity
 * @constructor
 */
export default function MapProperties({ backgroundImage }: { backgroundImage: BackgroundImageEntity }) {
	const derivedProperties = {
		worldHeight: ((backgroundImage.yCoordinateNW - backgroundImage.yCoordinateSE) / 1000).toFixed(3),
		worldWidth: ((backgroundImage.xCoordinateSE - backgroundImage.xCoordinateNW) / 1000).toFixed(3),
		eastingNW: (backgroundImage.xCoordinateNW).toFixed(2),
		northingNW: (backgroundImage.yCoordinateNW).toFixed(2),
		eastingSE: (backgroundImage.xCoordinateSE).toFixed(2),
		northingSE: (backgroundImage.yCoordinateSE).toFixed(2),
		horizontalScale: backgroundImage.xScale.toFixed(2),
		verticalScale: backgroundImage.yScale.toFixed(2)
	};

	return (
		<>
			<h6>Image Properties</h6>
			<InputField model={backgroundImage} label="Width" modelProperty="imageWidth" propertyUnit="px" isReadOnly />
			<InputField model={backgroundImage} label="Height" modelProperty="imageHeight" propertyUnit="px" isReadOnly />
			<div className="section-divider" />

			<CollapsibleProperty propertyTitle="World File Properties" displayProperty>
				<InputField model={derivedProperties} label="Width" modelProperty="worldWidth" propertyUnit="km" isReadOnly />
				<InputField model={derivedProperties} label="Height" modelProperty="worldHeight" propertyUnit="km" isReadOnly />

				<div className="sub-heading">
					<p>Top Left Coordinates</p>
				</div>
				<InputField model={derivedProperties} label="Easting" modelProperty="eastingNW" propertyUnit="m" isReadOnly />
				<InputField model={derivedProperties} label="Northing" modelProperty="northingNW" propertyUnit="m" isReadOnly />

				<div className="sub-heading">
					<p>Bottom Right Coordinates</p>
				</div>
				<InputField model={derivedProperties} label="Easting" modelProperty="eastingSE" propertyUnit="m" isReadOnly />
				<InputField model={derivedProperties} label="Northing" modelProperty="northingSE" propertyUnit="m" isReadOnly />

				<div className="sub-heading">
					<p>Scaling</p>
				</div>
				<InputField model={derivedProperties} label="Horizontal" modelProperty="horizontalScale" propertyUnit="m/px" isReadOnly />
				<InputField model={derivedProperties} label="Vertical" modelProperty="verticalScale" propertyUnit="m/px" isReadOnly />
			</CollapsibleProperty>
			<div className="section-divider" />
		</>
	);
}
