import {SaveCommand, SaveCommandType} from "./SaveCommand";

export default class CreateConnectivityCommand extends SaveCommand {
	linkId: string;
	nextLinkId: string;

	constructor(linkId: string, nextLinkId: string) {
		super(SaveCommandType.CreateConnectivity);

		this.linkId = linkId;
		this.nextLinkId = nextLinkId;
	}
}