import { LinkEntity, NodeEntity, SublinkEntity } from 'Models/Entities';
import { MapRenderer, NodeGraphic } from 'Views/MapComponents';
import MapStore from '../MapStore';

export default class LinkConnectivityDisplayHelper {
	private readonly _renderer: MapRenderer;
	private readonly _lookup: MapStore;
	private linkEntity: LinkEntity;
	private endNodeGraphicOfSelectedLink: NodeGraphic | undefined;
	private startNodeGraphicOfSelectedLink: NodeGraphic | undefined;
	/**
	 * Below node entities are important for rendering
	 * the connectivity graphic
	 */
	public connectivityToNode: NodeEntity | undefined;
	public connectivityFromNode: NodeEntity | undefined;
	public endNodeForSelectedLink: NodeEntity | undefined;
	public startNodeForSelectedLink: NodeEntity | undefined;

	constructor(renderer: MapRenderer, lookup: MapStore, linkEntity: LinkEntity) {
		this._renderer = renderer;
		this._lookup = lookup;
		this.linkEntity = linkEntity;
	}

	private getLookup = () => this._lookup;
	private getRenderer = () => this._renderer;

	/**
	 * Renders the connectivity graphic for the updated link
	 * @param entity
	 */
	public renderUpdatedConnectivity(entity: LinkEntity) {
		this.hideConnectivity();
		/**
		 * Make sure to set the updated link before
		 * rendering the connectivity graphic
		 */
		this.setLinkEntity(entity);
		this.renderConnectivity();
	}

	private resetLink() {
		this.endNodeGraphicOfSelectedLink = undefined;
		this.startNodeGraphicOfSelectedLink = undefined;
		this.connectivityFromNode = undefined;
		this.connectivityToNode = undefined;
	}

	/**
	 * Renders connectivity line between two connected links
	 */
	public renderConnectivity = () => {
		this.resetLink();
		const firstNodesOfLinkTos: NodeEntity[] = [];
		const lastNodesOfLinkFroms: NodeEntity[] = [];
		/**
		 * Fetch link 'TO's and 'FROM's
		 */
		const linkToEntitiesForSelectedLink = this.linkEntity.nextLinks();
		const linkFromEnititesForSelectedLink = this.linkEntity.previousLinks();
		/**
		 * Proceed only if the sublinks are in order
		 * It is important to set the sublinks in order to
		 * get the last node of the link for the connectivity.
		 */
		let orderedSublinks = this.ensureSublinksAreInOrder();
		if (!orderedSublinks) {
			return;
		}

		this.linkEntity.sublinkss = orderedSublinks;
		/**
		 * Push all the first nodes of the linkToEntitiesForSelectedLink entities in firstNodesOfLinkTos array
		 */
		if (linkToEntitiesForSelectedLink) {
			linkToEntitiesForSelectedLink.forEach(linkTo => {
				/**
				 * BugFix - The start node of the link is not set after respectively join and break link operation
				 * So, get the first node using the sublink instead of the link.
				 * Sublinks should be in order for this to work
				 * TODO - Find a better way to fix this bug.
				 */
				const _startSublink = this.getLookup().getFirstSublinkForLink(linkTo.id);
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				const _orderedSublinks = linkTo.getSublinks();
				const firstNode = this.getLookup()
					.getFirstNodeForSublink(_orderedSublinks[0]?.getModelId());
				if (firstNode) {
					firstNodesOfLinkTos.push(firstNode);
				}

				// TODO: this will be needed for undo/redo of break/join
				// const _link = this.getLookup().getLinkById(linkTo.getModelId());
				// if (!!_link) {
				// 	const _orderedSublinks = this.getLookup().getOrderedSublinksWithPreviousIds(_link);
				// 	if (!!orderedSublinks && orderedSublinks.length > 0) {
				// 		const firstNode = this.getLookup()
				// 			.getFirstNodeForSublink(_orderedSublinks![0].getModelId());
				// 		if (firstNode) {
				// 			firstNodesOfLinkTos.push(firstNode);
				// 		}
				// 	} else {
				// 		console.error('sublink not found');
				// 	}
				// } else {
				// 	console.error('link not found');
				// }
			});
		}
		/**
		 * Push all the first nodes of the linkToEntitiesForSelectedLink entities in firstNodesOfLinkTos array
		 */
		if (linkFromEnititesForSelectedLink) {
			linkFromEnititesForSelectedLink.forEach(linkFrom => {
				/**
				 * Get sublinks in order first
				 * It is important to set the sublinks in order to
				 * get the last node of the link for the connectivity.
				 *
				 * Set the sublinks in order only if the link is imported.
				 * All newly created sublinks are always in order.
				 */
				if (linkFrom.isImported) {
					orderedSublinks = this.ensureSublinksAreInOrder(linkFrom);
					if (!orderedSublinks) return;

					linkFrom.sublinkss = orderedSublinks;
				}

				const lastSublinkIndex = linkFrom.sublinkss.length - 1;
				const lastNode = linkFrom.lastNode();
				// TODO: this will be needed for undo/redo of break/join
				// const lastSublink = linkFrom.sublinkss[lastSublinkIndex];
				// const lastNode = this.getLookup()
				// 	.getLastNodeForSublinkWithPreviousIds(lastSublink);
				if (lastNode) {
					lastNodesOfLinkFroms.push(lastNode);
				}
			});
		}
		/**
		 * Mark all the connected nodes for rerender.
		 * This includes all the nodes from both firstNodesOfLinkTos and lastNodesOfLinkFroms arrays
		 */
		const LinkToConnectivityToBeRerendred = this.getAndMarkNodeGraphicForRerender(firstNodesOfLinkTos, true);
		const LinkFromConnectivityToBeRerendred = this.getAndMarkNodeGraphicForRerender(lastNodesOfLinkFroms, false);
		/**
		 * Re-render if at least one of the results is true
		 */
		if (LinkToConnectivityToBeRerendred || LinkFromConnectivityToBeRerendred) {
			this.getRenderer().rerender();
		}

		/**
		 * Set/reset the connectivity to and from nodes
		 * for the connectivity render validation
		 * Join cannot occur on a branching or merging point, so
		 * the lengths of lastNodesOfLinkFroms and _nextLinks should be only one
		 * the lengths of firstNodesOfLinkTos and _previousLinks should be only one
		 */
		this.connectivityFromNode = undefined;
		this.connectivityToNode = undefined;
		// Handle connectivityFromNode
		if (lastNodesOfLinkFroms.length === 1) {
			const [node] = lastNodesOfLinkFroms;
			const _linkOfLastNode = this.getLookup().getLinkByIdNumber(node.linkIdNumber);
			if (_linkOfLastNode) {
				const _nextLinks = _linkOfLastNode.nextLinks();
				if (!!_nextLinks && _nextLinks.length === 1) {
					this.connectivityFromNode = node;
				}
			}
		}
		// Handle connectivityToNode
		if (firstNodesOfLinkTos.length === 1) {
			const [node] = firstNodesOfLinkTos;
			const _linkOfLastNode = this.getLookup().getLinkByIdNumber(node.linkIdNumber);
			if (_linkOfLastNode) {
				const _previousLinks = _linkOfLastNode.previousLinks();
				if (!!_previousLinks && _previousLinks.length === 1) {
					this.connectivityToNode = node;
				}
			}
		}
	}

	/**
	 * Set the link entity
	 * @param linkEntity
	 */
	public setLinkEntity(linkEntity: LinkEntity) {
		this.linkEntity = linkEntity;
	}

	/**
	 * Get the link entity
	 */
	public getLinkEntity() {
		return this.linkEntity;
	}

	/**
	 * Hides the connectivity, marks the node graphic and re-renders
	 */
	public hideConnectivity = () => {
		// console.log('hideConnectivity: processing');
		let reRender = false;
		if (this.startNodeGraphicOfSelectedLink) {
			// verify that object still exists (there are edge cases where it does not)
			const id = this.getRenderer().getObjectById(this.startNodeGraphicOfSelectedLink.getId());
			if (!!id) {
				this.startNodeGraphicOfSelectedLink.hideConnectivity();
				this.getRenderer().markObjectToRerender(this.startNodeGraphicOfSelectedLink.getId());
				reRender = true;
			}
		}
		if (this.endNodeGraphicOfSelectedLink) {
			// verify that object still exists (there are edge cases where it does not)
			const id = this.getRenderer().getObjectById(this.endNodeGraphicOfSelectedLink.getId());
			if (!!id) {
				this.endNodeGraphicOfSelectedLink.hideConnectivity();
				this.getRenderer().markObjectToRerender(this.endNodeGraphicOfSelectedLink.getId());
				reRender = true;
			}
		}
		if (reRender) {
			this.getRenderer().rerender();
		}
		this.resetLink();
	}

	/**
	 * Returns the first node of the selected link
	 */
	private getFirstNodeForLink = () => {
		this.startNodeForSelectedLink = this.getLookup()
			.getFirstNodeForLink(this.linkEntity.getModelId());
		return this.startNodeForSelectedLink;
	}

	/**
	 * Returns the last node of the selected link
	 * Set the sublinks of the selected link in order
	 * before calling this function
	 */
	private getLastNodeForLink = () => {
		this.endNodeForSelectedLink = this.linkEntity.lastNode();
		// TODO: this will be needed for undo/redo of break/join
		// const lastSublink = this.linkEntity.sublinkss[this.linkEntity.sublinkss.length - 1];
		// this.endNodeForSelectedLink = this.getLookup().getLastNodeForSublinkWithPreviousIds(lastSublink);
		return this.endNodeForSelectedLink;
	}

	/**
	 * gets and marks node graphic for rerender
	 * @param endNodes
	 * @param isNextLinks
	 * @returns true if object(s) is/are marked for rerender
	 */
	private getAndMarkNodeGraphicForRerender = (endNodes: NodeEntity[], isNextLinks: boolean): boolean => {
		if (endNodes.length === 0) {
			return false;
		}

		const endNodeOfSelectedLink = isNextLinks ? this.getLastNodeForLink() : this.getFirstNodeForLink();
		if (!endNodeOfSelectedLink) {
			return false;
		}
		/**
		 * Get the map object id for the given node entity
		 */
		const endNodeIdOfTheSelectedLink = this.getLookup()
			.getMapObjectId(endNodeOfSelectedLink?.getModelId(), 'node');
		if (!endNodeIdOfTheSelectedLink) {
			return false;
		}
		/**
		 * Get the node graphic of node map object at the either end of the link
		 */
		const nodeGraphicOfSelectedLink = this.getRenderer()
			.getObjectById(endNodeIdOfTheSelectedLink) as NodeGraphic;
		if (!nodeGraphicOfSelectedLink) {
			return false;
		}
		/**
		 * Set the node graphic to appropriate global variables for disposal
		 */
		if (isNextLinks) {
			this.endNodeGraphicOfSelectedLink = nodeGraphicOfSelectedLink;
		} else {
			this.startNodeGraphicOfSelectedLink = nodeGraphicOfSelectedLink;
		}
		/**
		 * Finally call showConnectivity function for all the nodes passed
		 * in the parameter after getting their coords
		 */
		endNodes.forEach(endNode => {
			const startNodeCoords = this.getRenderer().project(endNode);
			const endNodeCoords = this.getRenderer().project(endNodeOfSelectedLink);

			// Get the visible of the node's PIXI.DisplayObject and set it to its connectivity
			const mapObjectId = this.getLookup().getMapObjectId(this.linkEntity.id, 'link');
			const mapObject = this.getRenderer().getObjectById(mapObjectId);
			const isVisible = !!mapObject ? mapObject.getContainers().some(x => x.visible) : true;

			nodeGraphicOfSelectedLink.showConnectivity(startNodeCoords, endNodeCoords, isVisible);
		});
		/**
		 * Mark the node graphic for rerender and return true
		 */
		this.getRenderer().markObjectToRerender(endNodeIdOfTheSelectedLink);
		return true;
	}

	/**
	 * Sets the sublinks of the given link entity in order
	 * @param linkEntity
	 * @returns sublink entities if the first sublink if found else returns undefined
	 */
	private ensureSublinksAreInOrder = (linkEntity?: LinkEntity): SublinkEntity[] | undefined => {
		const link = linkEntity ?? this.linkEntity;
		const firstSublink = this.getLookup().getFirstSublinkForLink(link.getModelId());
		if (!firstSublink) {
			return;
		}

		return link.getSublinks();
	}
}
