import { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import {
	Button, Colors, Display, Sizes,
} from '../Button/Button';
import { ButtonGroup } from '../Button/ButtonGroup';
import alert from '../../../Util/ToastifyUtils';
import axios from 'axios';
import { LoadView } from './LoadView';
import InputWrapper, { InputType } from '../Inputs/InputWrapper';
import { AreaEntity, BayEntity, ImportVersionEntity, LinkEntity, SublinkEntity } from '../../../Models/Entities';
import { MapController } from '../../MapComponents';
import If from '../If/If';
import classNames from 'classnames';
import React from 'react';
import { SERVER_URL } from 'Constants';
import { mapObjectState } from 'Models/Enums';
import { setCustomTag } from 'Views/MapComponents/Map/Helpers/MapUtils';
import { store } from "../../../Models/Store";

interface IFileUploadModalProps {
    title: string;
	importVersion: ImportVersionEntity;
	mapController: MapController | undefined;
    onCloseModal: () => void;
}

const getExportWarningShowConditions = (importVersion: ImportVersionEntity): boolean => {
	return (importVersion.areaEdited && !importVersion.areaPublished)
		&& ((importVersion.bayEdited && !importVersion.bayPublished)
			|| (importVersion.pathEdited && !importVersion.pathPublished));
};

export function PublishAndExportModal(props: IFileUploadModalProps) {
	const { onCloseModal, importVersion } = props;
	const getModalClassNames = () => {
		return classNames('map-publish-and-export-modal',
			getExportWarningShowConditions(importVersion) ? 'area-edit-modal' : '');
	};

	return (
		<Modal
			isOpen
			label="File export"
			onRequestClose={onCloseModal}
			className={getModalClassNames()}
			overlayClassName="map-file-upload-modal-overlay"
		>
			<RenderPublishAndExportModalContent {...props} />
		</Modal>
	);
}

interface DisplayExportModalAlertProps {
	isLoadingBayChanged: boolean;
	isOnlyLoadingBayChanged: boolean;
	areaExportWarning: boolean;
}

function DisplayExportModalAlert(props: DisplayExportModalAlertProps) {
	let text = undefined;

	if (props.areaExportWarning) {
		text = "Area updates have been made. Distribute changes to the AHC server, then import the current Area data, before you can export the AHC map and FMS map or Bay data";
	} else if (props.isOnlyLoadingBayChanged) {
		text = "The bay data cannot be exported as it only contains loading bay updates. Loading bay updates are ignored during bay data export.";
	} else if (props.isLoadingBayChanged) {
		text = "The bay data contains loading bay updates, which will not be part of the export. Loading bay updates are ignored during bay data export.";
	}

	if (text === undefined) {
		return <></>;
	}

	return (
		<div className="export-area-text">
			<span className="icon icon-information icon-only" />
			<p>
				{text}
			</p>
		</div>
	);
}

export function RenderPublishAndExportModalContent(props: IFileUploadModalProps) {
	const {
		title, onCloseModal, importVersion, mapController,
	} = props;

	const [isLoadingBayChanged, setIsLoadingBayChanged] = useState<boolean>(false)
	const [isOnlyLoadingBayChanged, setIsOnlyLoadingBayChanged] = useState<boolean>(false)

	const [displaySpinner, setDisplaySpinner] = useState(false);
	const [areaChecked, setAreaChecked] = useState(false);
	const [ahcMapChecked, setAhcMapChecked] = useState(false);
	const [bayDataChecked, setBayDataChecked] = useState(false);

	const emitPublishedEvent = (mapObject: string) => {
		mapController?.getEventHandler().emit('onImportVersionStatusChanged', mapObject, false, []);
	};

	const handleImportVersionPublishStatus = (exportOption: string) => {
		switch (exportOption) {
			case 'ExportAreaOnly':
				if (importVersion.areaEdited) {
					importVersion.areaEdited = false;
					importVersion.areaPublished = true;
					emitPublishedEvent('area');
				}
				break;
			case 'ExportAHCOnly':
				if (importVersion.pathEdited) {
					importVersion.pathEdited = false;
					importVersion.pathPublished = true;
					emitPublishedEvent('path');
				}
				break;
			case 'ExportBayOnly':
				if (importVersion.bayEdited) {
					importVersion.bayEdited = false;
					importVersion.bayPublished = true;
					emitPublishedEvent('bay');
				}
				break;
			case 'ExportAHCBay':
				if (importVersion.bayEdited) {
					importVersion.bayEdited = false;
					importVersion.bayPublished = true;
					emitPublishedEvent('bay');
				}
				if (importVersion.pathEdited) {
					importVersion.pathEdited = false;
					importVersion.pathPublished = true;
					setTimeout(() => {
						emitPublishedEvent('path');
					}, 1);
				}
				break;
		}

		// Reset initial import version status by using status after publication
		importVersion.saveMapStatus();
	};

	const handleExport = async () => {
		const localDate = new Date();
		const timeZoneOffset = Math.abs(localDate.getTimezoneOffset());

		const options = { importVersionId: importVersion?.id, timeZoneOffset: timeZoneOffset, exportOption: '' };

		if (!areaChecked && !ahcMapChecked && !bayDataChecked) {
			alert('No option selected. Please choose one option at least.', 'error');
		}

		if (areaChecked || ahcMapChecked || bayDataChecked) {
			setDisplaySpinner(true);
		}

		if (areaChecked) {
			options.exportOption = 'ExportAreaOnly';
			setCustomTag('publish-and-export', 'area-only');
		}
		if (ahcMapChecked && !bayDataChecked) {
			options.exportOption = 'ExportAHCOnly';
			setCustomTag('publish-and-export', 'ahc-only');
		}
		if (bayDataChecked && !ahcMapChecked) {
			options.exportOption = 'ExportBayOnly';
			setCustomTag('publish-and-export', 'bay-only');
		}
		if (ahcMapChecked && bayDataChecked) {
			options.exportOption = 'ExportAHCBay';
			setCustomTag('publish-and-export', 'ahc-and-bay');
		}

		if (options.exportOption !== '') {
			try {
				const response: any = await axios.post(
					`${SERVER_URL}/api/entity/ImportVersionEntity/ExportAHCMapData`,
					options,
					{
						headers: {
							'Content-Type': 'application/json; application/octet-stream',
						},
						responseType: 'blob',
					},
				);

				if (response.data) {
					// HTTP header names are case-insensitive, according to RFC 2616:
					// 4.2: Each header field consists of a name followed by a colon (":") and the field value. Field names are case-insensitive.
					// https://stackoverflow.com/questions/5258977/are-http-headers-case-sensitive
					// getting the file name from the response header
					const responseHeaderValue: any = response.headers["content-disposition"];
					const filenameRegex = /filename=AHSUploadMap\d*\.zip/g;
					const _fileName = responseHeaderValue.match(filenameRegex);
					const fileName: string = _fileName[0].replace("filename=", "");
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();

					const lookup = mapController!.getMapLookup();
					let areaIds: string[] = [];
					let bayIds: string[] = [];
					let linkIds: string[] = [];
					switch (options.exportOption) {
						case 'ExportAreaOnly':
							areaIds = lookup.getAllEntities(AreaEntity).filter(a => a.state === 'NEW_OBJECT' || a.state === 'MODIFIED').map(a => a.id);
							break;
						case 'ExportBayOnly':
							bayIds = lookup.getAllEntities(BayEntity).filter(b => b.state === 'NEW_OBJECT' || b.state === 'MODIFIED').map(b => b.id);
							break;
						case 'ExportAHCOnly':
							linkIds = lookup.getAllEntities(LinkEntity)
								.filter(l => {
									const updateSublinkState = l.sublinkss.some(s => {
										const updateNodeState = s.nodess.some(n => n.state === 'NEW_OBJECT' || n.state === 'MODIFIED');
										return s.state === 'NEW_OBJECT' || s.state === 'MODIFIED' || updateNodeState;
									});
									return l.state === 'NEW_OBJECT' || l.state === 'MODIFIED' || updateSublinkState;
								})
								.map(l => l.id);
							break;
						case 'ExportAHCBay':
							bayIds = lookup.getAllEntities(BayEntity).filter(b => b.state === 'NEW_OBJECT' || b.state === 'MODIFIED').map(b => b.id);
							linkIds = lookup.getAllEntities(LinkEntity)
								.filter(l => {
									const updateSublinkState = l.sublinkss.some(s => {
										const updateNodeState = s.nodess.some(n => n.state === 'NEW_OBJECT' || n.state === 'MODIFIED');
										return s.state === 'NEW_OBJECT' || s.state === 'MODIFIED' || updateNodeState;
									});
									return l.state === 'NEW_OBJECT' || l.state === 'MODIFIED' || updateSublinkState;
								})
								.map(l => l.id);
							break;
						default:
							console.error('Wrong option selected.');
							break;
					}

					const { data } = await axios.post(
						`${SERVER_URL}/api/entity/MapEntity/updatePublicationState`,
						{
							areaIds: areaIds,
							bayIds: bayIds,
							linkIds: linkIds,
						},
					);

					if (data) {
						const updatedAreaIds = data.item1 as [];
						const updatedBayIds = data.item2 as [];
						const updatedLinkIds = data.item3 as [];
	
						updatedAreaIds.forEach(areaId => {
							const areaEntity = lookup.getEntity(areaId, AreaEntity);
							areaEntity.state = getCorrespondingState(areaEntity.state);
							mapController!.getEventHandler().emit('onMapObjectUpdate', areaEntity, areaId);
						});
	
						updatedBayIds.forEach(bayId => {
							const bayEntity = lookup.getEntity(bayId, BayEntity);
							bayEntity.state = getCorrespondingState(bayEntity.state);
							mapController!.getEventHandler().emit('onMapObjectUpdate', bayEntity, bayId);
						});
	
						updatedLinkIds.forEach(linkId => {
							const linkEntity = lookup.getEntity(linkId, LinkEntity);
							linkEntity.state = getCorrespondingState(linkEntity.state);
							linkEntity.sublinkss.forEach(sublink => {
								sublink.state = getCorrespondingState(sublink.state);
								sublink.nodess.forEach(node => {
									node.state = getCorrespondingState(node.state);
								});
							})
							mapController!.getEventHandler().emit('onMapObjectUpdate', linkEntity, linkId);
						});
					}
					
					// HIT-919, To simplify Import Version Status after publication, reset Undo/Redo stack
					handleImportVersionPublishStatus(options.exportOption);
					setDisplaySpinner(false);
					onCloseModal();
				}
			} catch (e: any) {
				setDisplaySpinner(false);
				const response = JSON.parse(await e.response.data.text());
				alert(response.errors[0]?.message ?? 'Exporting map failed', 'error');
			}
		}
	};

	const getCorrespondingState = (state: mapObjectState): mapObjectState => {
		switch(state){
			case 'NEW_OBJECT':
				return 'PUBLISHED_NEW';
			case 'MODIFIED':
				return 'PUBLISHED_MODIFIED';
			default:
				return state;
		}
	};

	const getDisableExportConditions = (): boolean => {
		return !areaChecked && !ahcMapChecked && !bayDataChecked;
	};

	const getDisableAreaExportConditions = (): boolean => {
		const areaChangesThatAreNotPublished = !importVersion.areaEdited || (importVersion.areaPublished ?? false);

		return areaChangesThatAreNotPublished;
	};

	const getDisablePathExportConditions = (): boolean => {
		const isAreaNotOriginal = importVersion.areaEdited || importVersion.areaPublished;
		const fmsHasNotBeenGenerated = !importVersion.fmsGenerated;
		const pathChangesThatAreNotPublished = !importVersion.pathEdited || (importVersion.pathPublished ?? false);

		return isAreaNotOriginal || fmsHasNotBeenGenerated || pathChangesThatAreNotPublished;
	};

	const getDisableBayExportConditions = (): boolean => {
		const isAreaNotOriginal = importVersion.areaEdited || importVersion.areaPublished;
		const bayChangesThatAreNotPublished = !importVersion.bayEdited || (importVersion.bayPublished ?? false);

		return isAreaNotOriginal || bayChangesThatAreNotPublished || isOnlyLoadingBayChanged;
	};
	
	useEffect(() => {
		const storeBays: BayEntity[] | undefined = mapController?.getMapLookup().getAllEntities(BayEntity);
		storeBays?.map((val: BayEntity)=>{
			if (val.state !== 'IMPORTED') {
				if ((val.bayType === 'LOADING') && !isLoadingBayChanged) {
					setIsLoadingBayChanged(true)
					setIsOnlyLoadingBayChanged(true)
				}
				else {
					if(!isOnlyLoadingBayChanged){
						setIsOnlyLoadingBayChanged(false)
					}
				}
			}
		})
	}, []);

	return (
		<div className="export-modal">
			{displaySpinner && <LoadView text="Exporting map data..." timerInterval={50} />}
			<div className="modal__header">
				<h3>{title}</h3>
				<Button
					icon={{ icon: 'cross', iconPos: 'icon-right' }}
					className="cancel-button no-background"
					display={Display.Text}
					onClick={onCloseModal}
					labelVisible={false}
				/>
			</div>
			<div className="file-export-section">
				<h4>
					All exports are encrypted .zip files
				</h4>
				<div className="section-divider" />
				<div>
					<InputWrapper inputType={InputType.CHECKBOX}>
						<input
							type="checkbox"
							onChange={() => { setAreaChecked(!areaChecked); }}
							disabled={getDisableAreaExportConditions()}
						/>
						<label aria-disabled={(ahcMapChecked || bayDataChecked)}> Area data </label>
					</InputWrapper>
					<InputWrapper inputType={InputType.CHECKBOX}>
						<input
							type="checkbox"
							onChange={() => { setAhcMapChecked(!ahcMapChecked); }}
							disabled={getDisablePathExportConditions()}
						/>
						<label aria-disabled={areaChecked}> AHC map and FMS map </label>
					</InputWrapper>
					<InputWrapper inputType={InputType.CHECKBOX}>
						<input
							type="checkbox"
							onChange={() => { setBayDataChecked(!bayDataChecked); }}
							disabled={getDisableBayExportConditions()}
						/>
						<label aria-disabled={areaChecked}> Bay data </label>
					</InputWrapper>
				</div>
				<DisplayExportModalAlert
					isLoadingBayChanged={isLoadingBayChanged}
					isOnlyLoadingBayChanged={isOnlyLoadingBayChanged}
					areaExportWarning={getExportWarningShowConditions(importVersion)} />
			</div>
			<div>
				<ButtonGroup className={classNames('modal-actions',
					getExportWarningShowConditions(importVersion) ? 'export-btn-group' : '')}
				>
					<Button
						colors={Colors.Primary}
						display={Display.Outline}
						onClick={onCloseModal}
						sizes={Sizes.Medium}
					>
						Cancel
					</Button>
					<Button
						className="next-button"
						colors={Colors.Primary}
						icon={{ icon: 'chevron-right', iconPos: 'icon-right' }}
						display={Display.Solid}
						onClick={handleExport}
						sizes={Sizes.Medium}
						disabled={getDisableExportConditions()}
					>
						Export
					</Button>
				</ButtonGroup>
			</div>

		</div>
	);
}
