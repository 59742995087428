import InputWrapper, {InputType} from "../../../Components/Inputs/InputWrapper";
import classNames from "classnames";
import React from "react";

interface IRenderToggleButton {
    label: string;
    customStyle?: string;
    isChecked?: boolean;
    onChange: () => void;
    isDisabled?: boolean;
}

export function RenderToggleButton(props: IRenderToggleButton) {
    const { label, customStyle, isChecked, onChange, isDisabled } = props;
    const className = !!customStyle ? customStyle : 'properties-input';

    const id = label.replaceAll(' ', '_');

    return (
        <div className={className}>
            <div className="label">
                <p>{label}</p>
            </div>
            <InputWrapper
                inputType={InputType.CHECKBOX}
                labelVisible={false}
                className="info-fields switch-wrapper"
            >
                <label className='switch' htmlFor={id}>
                    <input
                        id={id}
                        type="checkbox"
                        checked={isChecked ?? false}
                        onChange={onChange}
                        disabled={isDisabled ?? false}
                    ></input>
                    <span className={classNames('slider', isDisabled ? 'inactive' : '',)}></span>
                </label>
            </InputWrapper>
        </div>
    );
}
