import {MapController, MapEventHandler} from "../../../../index";
import {IClothoidParams} from "../../../../MapProperties/PropertiesSidePanel";
import {action, observable, reaction} from "mobx";
import {IReactionDisposer} from "mobx/lib/core/reaction";
import {Direction, type ValidPathDirections, Waypoint, WaypointList} from "./Waypoint";
import {type nodetask} from "../../../../../../Models/Enums";
import PathManager from "./PathManager";
import {ErrorsAndWarningsObject} from "../../../../MapProperties/ErrorsAndWarningsProperties";

export default class PathPropertiesPanelHelper {

	private mapController: MapController;
	private mapEventHandler: MapEventHandler;

	private readonly observableWaypoints: WaypointList;

	private clothoidParams: IClothoidParams;

	private changeHandler: IReactionDisposer;

	private readonly pathManager: PathManager;

	constructor(pathManager: PathManager, mapController: MapController, waypoints: WaypointList) {
		this.pathManager = pathManager;
		this.mapController = mapController;
		this.mapEventHandler = mapController.getEventHandler();

		this.observableWaypoints = waypoints;

		// Set the properties to the initial state
		this.resetProperties();
	}

	public resetProperties() {
		// Check if we need to pass the new params object to the properties panel
		const propertiesPanelNeedsResetting = this.clothoidParams !== undefined;

		this.clothoidParams = observable.object({
			nextWaypointTask: 'HAULING',
			direction: 'forward',
			isDirectionReadOnly: false,
			isHCMPGButtonReadonly: false,
			isTaskReadOnly: false,
			isWaypointLocationReadOnly: true,
			errorsAndWarnings: new ErrorsAndWarningsObject()
		});

		this.handleChanges();

		if (propertiesPanelNeedsResetting) {
			this.displayPanel();
		}
	}

	public displayConfirmButton(enabled: boolean = false) {
		if (this.mapController.isDisplayConfirmButton && this.mapController.isActionConfirmAllowed() === enabled) {
			return;
		}

		this.mapEventHandler.emit('toggleConfirmCreation', true, !enabled);
	}

	public hideConfirmButton() {
		this.mapEventHandler.emit('toggleConfirmCreation', false);
	}

	public enableConfirmButton() {
		this.displayConfirmButton(true);
	}

	public disableConfirmButton() {
		this.displayConfirmButton(false);
	}

	public getErrorsAndWarnings(): ErrorsAndWarningsObject {
		return this.clothoidParams.errorsAndWarnings;
	}

	public handleDisplayConfirmButton() {
		// The confirm button is dependant on:
		// 	- The number of waypoints
		// 	- If the path has an error

		const hasWaypoints = this.observableWaypoints.hasWaypoint();
		const isFullPath = this.observableWaypoints.isFullPath();

		if (!hasWaypoints) {
			this.hideConfirmButton();
		} else {
			this.displayConfirmButton(isFullPath);
		}
	}

	@action
	public handleDirectionPropertySetting() {
		if (this.pathManager.isEditing()) {
			// Calculate the direction of the path based on the waypoints
			// The calculation is done by comparing all the waypoint directions except the last one (because the last
			// waypoint direction refers to the next path segment generation)
			// If all waypoint directions are the same, we use the first direction, otherwise we use mixed
			this.clothoidParams.direction = this.observableWaypoints
				.iter
				.filter((_, i, arr) => i < arr.length - 1)
				.reduce((acc: ValidPathDirections | undefined, waypoint: Waypoint) => {
					if (acc === undefined) {
						return waypoint.direction;
					}

					return acc === waypoint.direction ? acc : 'mixed';
				}, undefined) ?? 'forward';
			return;
		}

		const lastWaypoint = this.observableWaypoints.lastWaypoint;
		if (!!lastWaypoint) {
			this.clothoidParams.direction = lastWaypoint.direction;
		}
	}

	@action
	public handleDirectionPropertyReadOnly() {
		const hasPath = this.observableWaypoints.isFullPath();
		const isFirstWaypointSnapped = this.observableWaypoints.firstWaypoint?.isSnapped ?? false;

		this.clothoidParams.isDirectionReadOnly = isFirstWaypointSnapped || hasPath;
	}

	@action
	public handleHcmPgButtonReadOnly() {
		// It is readonly if we have placed any waypoints in create mode
		this.clothoidParams.isHCMPGButtonReadonly = this.observableWaypoints.hasWaypoint()
			&& !this.pathManager.isEditing();
	}

	@action
	public handleTaskReadOnly() {
		// Always readonly if we are editing the path
		this.clothoidParams.isTaskReadOnly = this.pathManager.isEditing();
	}

	@action
	public handleWaypointLocationReadOnly() {
		this.clothoidParams.isWaypointLocationReadOnly = !this.pathManager.isEditing();
	}

	public getPathProperties() {
		return this.clothoidParams;
	}

	@action
	public setDirectionProperty(direction: ValidPathDirections) {
		if (this.clothoidParams.isDirectionReadOnly) {
			return;
		}

		this.clothoidParams.direction = direction;
	}

	@action
	public setTaskProperty(task: nodetask) {
		this.clothoidParams.nextWaypointTask = task;
	}

	public setDirectionPropertyAndUpdate(direction: ValidPathDirections) {
		this.setDirectionProperty(direction)
		this.handleUpdateDirection();
		this.pathManager.processPathUpdate();
	}

	public setTaskPropertyAndUpdate(task: nodetask) {
		this.setTaskProperty(task)
		this.handleUpdateTask();
		this.pathManager.processPathUpdate();
	}

	public getExpectedTaskForWaypoint(): nodetask {
		const nextWaypointTask = this.clothoidParams.nextWaypointTask;

		// The first node should always be hauling
		if (!this.observableWaypoints.hasWaypoint()) {
			return 'HAULING';
		}

		// Update the previous waypoint task if needed
		const lastWaypoint = this.observableWaypoints.lastWaypoint;
		if (!!lastWaypoint && lastWaypoint.task === 'PARKING') {
			// A mid-waypoint can never be a parking waypoint
			lastWaypoint.setTask('HAULING');
		}

		return nextWaypointTask;
	}

	public getExpectedDirectionForWaypoint(task: nodetask): Direction {
		const lastWaypoint = this.observableWaypoints.lastWaypoint;

		if (!!lastWaypoint && task === 'REVERSEPOINT') {
			// Reverse the direction if the next waypoint is a reverse point
			return lastWaypoint.direction === 'forward' ? 'reverse' : 'forward';
		}

		// Return the direction of the last waypoint (we can't switch direction unless there is a reverse point)
		return lastWaypoint?.direction ?? this.clothoidParams.direction;
	}

	public handleUpdateTask() {
		if (!this.observableWaypoints.isFullPath()) {
			return;
		}

		const currentNodeTask = this.getPathProperties().nextWaypointTask;
		const lastWaypoint = this.observableWaypoints.lastWaypoint;

		// If we are converting to/from a reverse point, we need to update the previous waypoints direction
		if (lastWaypoint.task !== currentNodeTask) {
			if (lastWaypoint.task === 'REVERSEPOINT' || currentNodeTask === 'REVERSEPOINT') {
				lastWaypoint.reverseDirection();
				this.handleDirectionPropertySetting();
			}

			// Update the waypoint after we've reversed the direction
			lastWaypoint.setTask(currentNodeTask);
		}
	}

	public handleUpdateDirection() {
		const currentNodeDirection = this.getPathProperties().direction;

		// We can't update the direction of the node if the properties displayed is mixed
		if (currentNodeDirection === 'mixed') {
			return;
		}

		const lastWaypoint = this.observableWaypoints.lastWaypoint;

		// If we are converting to/from a reverse point, we need to update the previous waypoints direction
		if (!!lastWaypoint && lastWaypoint.direction !== currentNodeDirection) {
			// Update the waypoint after we've reversed the direction
			lastWaypoint.setDirection(currentNodeDirection);
		}
	}

	public handleChangeToToolMode() {
		this.handleHcmPgButtonReadOnly();
		this.handleTaskReadOnly();
		this.handleWaypointLocationReadOnly();

		this.handleDirectionPropertySetting();
	}

	private handleChanges() {
		// Deallocate the old change handler if needed
		if (!!this.changeHandler) {
			this.changeHandler();
		}

		this.changeHandler = reaction(() => this.observableWaypoints.waypoints.length, () => {
			this.handleDirectionPropertyReadOnly();
			this.handleHcmPgButtonReadOnly();
			this.handleWaypointLocationReadOnly();

			this.handleDirectionPropertySetting();

			this.handleDisplayConfirmButton();
		});
	}

	public displayPanel() {
		this.mapController.getEventHandler().emit('onPropertiesPanel', 'path', this.observableWaypoints, {
			clothoidParams: this.clothoidParams
		});
	}

	public hidePanel() {
		this.mapController.getEventHandler().emit('onPropertiesPanel', 'map');

		this.hideConfirmButton();
	}

	public dispose() {
		this.hidePanel();
		this.hideConfirmButton();

		!!this.changeHandler ? this.changeHandler() : undefined;
	}
}