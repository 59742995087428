import {SaveCommand, SaveCommandType} from "./SaveCommand";
import {LinkEntity} from "../../../../Models/Entities";

export default class CreatePathCommand extends SaveCommand {

	link: LinkEntity;

	constructor(link: LinkEntity) {
		super(SaveCommandType.CreatePath);

		this.link = link;
	}

	public getData(): any {
		return {
			link: this.link.toJSON({
				linkFroms: {},
				linkTos: {},
				sublinkss: { nodess: {} },
			}),
		};
	}
}