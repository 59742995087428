/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import {RouteComponentProps} from 'react-router-dom';
import {observer} from 'mobx-react';
import {getFrontendNavLinks} from 'Views/FrontendNavLinks';
import Navigation, {Orientation} from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import SettingsPageComponent from '../Components/SettingsPage/SettingsPageComponent';
import {observable, runInAction} from 'mobx';
import {MineSiteEntity} from '../../Models/Entities';
import {store} from '../../Models/Store';
import {SERVER_URL} from '../../Constants';
import PageNotFound from './PageNotFound';
import Spinner from "../Components/Spinner/Spinner";

// % protected region % [Add any extra imports here] end

export interface SettingsPageProps extends RouteComponentProps {
    // % protected region % [Add any extra props here] off begin
    // % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] off begin
class SettingsPage extends React.Component<SettingsPageProps> {
// % protected region % [Add any customisations to default class definition here] end

    // % protected region % [Add class properties here] on begin
    @observable
    private mineSiteEntity: MineSiteEntity;

    private isMineUser = true;

    private refetchMineSite = () => {
        this.fetchMineSite();

        let logoUrl = '';
        if (!!this.mineSiteEntity.logoId) {
            logoUrl = `${SERVER_URL}/api/files/${this.mineSiteEntity.logoId}`;
        }
        store.setMineSiteSettings(this.mineSiteEntity.name, logoUrl);
    }

    private fetchMineSite = () => {
        MineSiteEntity.fetch<MineSiteEntity>().then(result => {
            if (result.length !== 1) return;
            runInAction(() => {
                this.mineSiteEntity = result[0];
            });
        });
    }

    componentDidMount() {
        if (store.isAHSMineAdmin) {
            this.fetchMineSite();
        } else {
            this.isMineUser = false;
        }
    }

    // % protected region % [Add class properties here] end

    render() {
        const {
            match,
            location,
            history,
            staticContext,
            // % protected region % [Destructure any additional props here] off begin
            // % protected region % [Destructure any additional props here] end
        } = this.props;

        // % protected region % [Add logic before rendering contents here] off begin
        // % protected region % [Add logic before rendering contents here] end

        // eslint-disable-next-line prefer-const
        let contents = (
            // eslint-disable-next-line max-len
            <SecuredPage groups={['Super Administrators', 'MineUser', 'HitachiAdmin']}>
                {
                    // % protected region % [Alter navigation here] on begin
                }
                {this.isMineUser ? (
                    <>
                        <Navigation
                            linkGroups={getFrontendNavLinks(this.props)}
                            orientation={Orientation.VERTICAL}
                            match={this.props.match}
                            location={this.props.location}
                            history={this.props.history}
                            staticContext={this.props.staticContext}
                            alwaysExpanded
                        />
                        {
                            // % protected region % [Alter navigation here] end
                        }
                        <div className="body-content">
                            {
                                // % protected region % [Add code for 623f31ed-7c0a-4f1d-8028-5934b0ef2c40 here] on begin
                            }
                            {!!this.mineSiteEntity
                                ? (
                                    <SettingsPageComponent
                                        mineSiteEntity={this.mineSiteEntity}
                                        onUpdateSuccess={this.refetchMineSite}
                                    />
                                ) : <Spinner/>}
                            {
                                // % protected region % [Add code for 623f31ed-7c0a-4f1d-8028-5934b0ef2c40 here] end
                            }
                        </div>
                    </>
                ) : <PageNotFound {...this.props} />
                }
            </SecuredPage>
        );

        // % protected region % [Override contents here] off begin
        // % protected region % [Override contents here] end

        return contents;
    }
}

// % protected region % [Override export here] off begin
export default SettingsPage;
// % protected region % [Override export here] end
