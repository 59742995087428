import {SaveCommand, SaveCommandType} from "./SaveCommand";

export default class DeleteConnectivityCommand extends SaveCommand {
	public linkId: string;
	public nextLinkId?: string;

	constructor(linkId: string, nextLinkId?: string) {
		super(SaveCommandType.DeleteConnectivity);

		this.linkId = linkId;
		this.nextLinkId = nextLinkId;
	}
}