import Modal from '../Modal/Modal';
import {Button, Display, Sizes} from '../Button/Button';
import {Alignment, ButtonGroup} from '../Button/ButtonGroup';


interface IRenderArchiveModalProps {
    isOpenModal: boolean;
    isMap: boolean;
    closeModal: () => void;
    archive: () => void;
}

/**
 * Renders the modal confirming the archive operation
 */
export function RenderArchiveModal(props: IRenderArchiveModalProps) {
    const {isOpenModal, isMap, closeModal, archive} = props;
    return (
        <Modal
            isOpen={isOpenModal}
            className="archive-modal"
            label="ArchiveModal"
        >
            {isMap ?
                <>
                    <p className="modal-question">Are you sure you want to archive this map import?</p>
                    <p>Once archived, this map import and its associated map data will no longer be available.</p>
                </>
                : <>
                    <p className="modal-question">Are you sure you want to archive this map import?</p>
                    <p>Once archived, this map import will no longer be available in the list of all imports.</p>
                </>}

            <ButtonGroup alignment={Alignment.HORIZONTAL} className="modal-btn-group">
                <Button
                    className="modal-button"
                    display={Display.Outline}
                    onClick={closeModal}
                    sizes={Sizes.Medium}
                >
                    No
                </Button>
                <Button
                    className="modal-button"
                    display={Display.Solid}
                    onClick={archive}
                    sizes={Sizes.Medium}
                >
                    Yes
                </Button>
            </ButtonGroup>
        </Modal>
    );
};
