/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as Models from 'Models/Entities';
import {
	IModelAttributes,
	Model,
	ReferencePath,
	attribute,
	entity, geoJsonField,
} from 'Models/Model';
import HitachiAdminSublinkEntity from 'Models/Security/Acl/HitachiAdminSublinkEntity';
import MineUserSublinkEntity from 'Models/Security/Acl/MineUserSublinkEntity';
import VisitorsSublinkEntity from 'Models/Security/Acl/VisitorsSublinkEntity';
import { IAcl } from 'Models/Security/IAcl';
import * as AttrUtils from 'Util/AttributeUtils';
import {
	getCreatedModifiedCrudOptions,
	makeEnumFetchFunction,
	makeFetchOneToManyFunc,
} from 'Util/EntityUtils';
import * as Validators from 'Validators';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { action, observable } from 'mobx';
import { CRUD } from '../CRUDOptions';
import * as Enums from '../Enums';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { MapObjectErrorsEntity, MapObjectWarningsEntity } from 'Models/Entities';
import { jsonReplacerFn } from 'Models/Model';
import { JOIN_SUBLINK_RESULT } from '../../Constants';
import { calcDistanceBetweenNodes } from '../../Views/MapComponents/Map/Helpers/MapUtils';
import { mapObjectState } from '../Enums';
import { store } from '../Store';
import LinkEntity from './LinkEntity';
import NodeEntity from './NodeEntity';
import {isNullOrUndefined} from "../../Util/TypeGuards";
// % protected region % [Add any further imports here] end

export interface ISublinkEntityAttributes extends IModelAttributes {
	version: string;
	sublinkId: number;
	isImported: boolean;
	state: Enums.mapObjectState;
	startNode: string;

	mapObjectErrorss: Array<
		| Models.MapObjectErrorsEntity
		| Models.IMapObjectErrorsEntityAttributes
	>;
	mapObjectWarningss: Array<
		| Models.MapObjectWarningsEntity
		| Models.IMapObjectWarningsEntityAttributes
	>;
	nodess: Array<
		| Models.NodeEntity
		| Models.INodeEntityAttributes
	>;
	linkId?: string;
	link?: Models.LinkEntity | Models.ILinkEntityAttributes;
	previousSublinkId?: string;
	previousSublink?: Models.SublinkEntity | Models.ISublinkEntityAttributes;
	nextSublink?: Models.SublinkEntity |
		Models.ISublinkEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] on begin
	drivingZone: string;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('SublinkEntity', 'Sublink')
// % protected region % [Customise your entity metadata here] end
export default class SublinkEntity extends Model
	implements ISublinkEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsSublinkEntity(),
		new MineUserSublinkEntity(),
		new HitachiAdminSublinkEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Version'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Version',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public version: string;
	// % protected region % [Modify props to the crud options here for attribute 'Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Sublink Id'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Sublink Id',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public sublinkId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Sublink Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Imported'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Is Imported',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isImported: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Imported'] end

	// % protected region % [Modify props to the crud options here for attribute 'State'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'State',
		displayType: 'enum-combobox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.mapObjectStateOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.mapObjectStateOptions),
		displayFunction: (attr: Enums.mapObjectState) => Enums.mapObjectStateOptions[attr],
	})
	public state: Enums.mapObjectState;
	// % protected region % [Modify props to the crud options here for attribute 'State'] end

	// % protected region % [Modify props to the crud options here for attribute 'Start Node'] off begin
	@Validators.Uuid()
	@observable
	@attribute()
	@CRUD({
		name: 'Start Node',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public startNode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Start Node'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Map Object Errors'] off begin
		name: 'Map Object Errorss',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.MapObjectErrorsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'mapObjectErrorss',
			oppositeEntity: () => Models.MapObjectErrorsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Map Object Errors'] end
	})
	public mapObjectErrorss: Models.MapObjectErrorsEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Map Object Warnings'] off begin
		name: 'Map Object Warningss',
		displayType: 'reference-multicombobox',
		order: 70,
		referenceTypeFunc: () => Models.MapObjectWarningsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'mapObjectWarningss',
			oppositeEntity: () => Models.MapObjectWarningsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Map Object Warnings'] end
	})
	public mapObjectWarningss: Models.MapObjectWarningsEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Nodes'] off begin
		name: 'Nodess',
		displayType: 'reference-multicombobox',
		order: 80,
		referenceTypeFunc: () => Models.NodeEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'nodess',
			oppositeEntity: () => Models.NodeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Nodes'] end
	})
	public nodess: Models.NodeEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Link'] off begin
		name: 'Link',
		displayType: 'reference-combobox',
		order: 90,
		referenceTypeFunc: () => Models.LinkEntity,
		// % protected region % [Modify props to the crud options here for reference 'Link'] end
	})
	public linkId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public link: Models.LinkEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Previous Sublink'] off begin
		name: 'Previous Sublink',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.SublinkEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'previousSublinks',
			oppositeEntity: () => Models.SublinkEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Previous Sublink'] end
	})
	public previousSublinkId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public previousSublink: Models.SublinkEntity;

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Next Sublink'] off begin
		name: 'Next Sublink',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.SublinkEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'nextSublinks',
			oppositeEntity: () => Models.SublinkEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Next Sublink'] end
	})
	public nextSublink?: Models.SublinkEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	@attribute()
	@geoJsonField()
	public drivingZone: string;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ISublinkEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ISublinkEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.version !== undefined) {
				this.version = attributes.version;
			}
			if (attributes.sublinkId !== undefined) {
				this.sublinkId = attributes.sublinkId;
			}
			if (attributes.isImported !== undefined) {
				this.isImported = attributes.isImported;
			}
			if (attributes.state !== undefined) {
				this.state = attributes.state;
			}
			if (attributes.startNode !== undefined) {
				this.startNode = attributes.startNode;
			}
			if (attributes.mapObjectErrorss !== undefined && Array.isArray(attributes.mapObjectErrorss)) {
				for (const model of attributes.mapObjectErrorss) {
					if (model instanceof Models.MapObjectErrorsEntity) {
						this.mapObjectErrorss.push(model);
					} else {
						this.mapObjectErrorss.push(new Models.MapObjectErrorsEntity(model));
					}
				}
			}
			if (attributes.mapObjectWarningss !== undefined && Array.isArray(attributes.mapObjectWarningss)) {
				for (const model of attributes.mapObjectWarningss) {
					if (model instanceof Models.MapObjectWarningsEntity) {
						this.mapObjectWarningss.push(model);
					} else {
						this.mapObjectWarningss.push(new Models.MapObjectWarningsEntity(model));
					}
				}
			}
			if (attributes.nodess !== undefined && Array.isArray(attributes.nodess)) {
				for (const model of attributes.nodess) {
					if (model instanceof Models.NodeEntity) {
						this.nodess.push(model);
					} else {
						this.nodess.push(new Models.NodeEntity(model));
					}
				}

				// If the first node has a previousNodeId, we need to sort the nodes
				if (this.nodess.length > 0 && this.nodess[0].previousNodeId !== undefined) {
					this.nodess = this.nodeSort(this.nodess);
				}
			}
			if (attributes.linkId !== undefined) {
				this.linkId = attributes.linkId;
			}
			if (attributes.link !== undefined) {
				if (attributes.link === null) {
					this.link = attributes.link;
				} else if (attributes.link instanceof Models.LinkEntity) {
					this.link = attributes.link;
					this.linkId = attributes.link.id;
				} else {
					this.link = new Models.LinkEntity(attributes.link);
					this.linkId = this.link.id;
				}
			}
			if (attributes.previousSublinkId !== undefined) {
				this.previousSublinkId = attributes.previousSublinkId;
			}
			if (attributes.previousSublink !== undefined) {
				if (attributes.previousSublink === null) {
					this.previousSublink = attributes.previousSublink;
				} else if (attributes.previousSublink instanceof Models.SublinkEntity) {
					this.previousSublink = attributes.previousSublink;
					this.previousSublinkId = attributes.previousSublink.id;
				} else {
					this.previousSublink = new Models.SublinkEntity(attributes.previousSublink);
					this.previousSublinkId = this.previousSublink.id;
				}
			}
			if (attributes.nextSublink !== undefined) {
				if (attributes.nextSublink === null) {
					this.nextSublink = attributes.nextSublink;
				} else if (attributes.nextSublink instanceof Models.SublinkEntity) {
					this.nextSublink = attributes.nextSublink;
				} else {
					this.nextSublink = new Models.SublinkEntity(attributes.nextSublink);
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.drivingZone !== undefined) {
				const drivingZoneType = typeof attributes.drivingZone;
				if (drivingZoneType !== 'string') {
					// This addresses the issue when the drivingZone attribute is passed
					// as an object (e.g. after recalculation of driving zones). The drivingZone
					// must always be a string in the entity
					this.drivingZone = JSON.stringify(attributes.drivingZone);
				} else {
					this.drivingZone = attributes.drivingZone;
				}
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		mapObjectErrorss {
			${Models.MapObjectErrorsEntity.getAttributes().join('\n')}
		}
		mapObjectWarningss {
			${Models.MapObjectWarningsEntity.getAttributes().join('\n')}
		}
		nodess {
			${Models.NodeEntity.getAttributes().join('\n')}
		}
		link {
			${Models.LinkEntity.getAttributes().join('\n')}
		}
		nextSublink {
			${Models.SublinkEntity.getAttributes().join('\n')}
		}
		previousSublink {
			${Models.SublinkEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			mapObjectErrorss: {},
			mapObjectWarningss: {},
			nodess: {},
			nextSublink: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'mapObjectErrorss',
							'mapObjectWarningss',
							'nodess',
							'nextSublink',
							'previousSublink',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.sublinkId.toString();
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on beginsr
	public getDistance() {
		const nodes: NodeEntity[] = this.getNodes();

		let totalLength = 0;
		for (let i = 1; i < nodes.length; i++) {
			const previousNode = nodes[i - 1];
			const currentNode = nodes[i];

			totalLength += calcDistanceBetweenNodes(previousNode, currentNode);
		}

		return totalLength;
	}

	public getNodes() {
		if (!!this.nodess) {
			return this.nodess;
		}

		const result: NodeEntity[] = [];

		const finalNode = this.getLastNode();

		if (!finalNode) {
			return this.nodess;
		}

		let node = this.getFirstNode();
		while (node !== undefined && node.getModelId() !== finalNode?.getModelId()) {
			result.push(node);

			const nodes = node.getNextNodeAcrossConnectivities();
			if (nodes.length !== 1) {
				break;
			}

			[node] = nodes;
		}

		// If there are no nodes when reaching the last node, there is an issue (all sublinks need at least 1 node)
		if (result.length !== 0) {
			result.push(finalNode);
		} else {
			// Fallback to return the nodes assigned to this sublink
			return this.nodess;
		}

		return result;
	}

	public getLink(): LinkEntity | undefined {
		if (!this.linkId) {
			return undefined;
		}

		if (!!this.link && this.link.id === this.linkId) {
			return this.link;
		}

		return store.mapStore.getEntity(this.linkId, LinkEntity);
	}

	public isLastSublink(): boolean {
		return this.getNextSublink() === undefined;
	}

	public isFirstSublink() {
		return this.getPreviousSublink() === undefined;
	}

	public getFirstNode(useStore: boolean = true): NodeEntity | undefined {
		let firstNode = store.mapStore.getFirstNodeForSublink(this.id);

		if (!useStore || isNullOrUndefined(firstNode)) {
			// The first node is the first node without a previoud id
			return this.getNodes().find(node => isNullOrUndefined(node.previousNodeId));
		}

		return firstNode;
	}

	public getLastNode(): NodeEntity | undefined {
		return this.getNodes().find(node => node.isLastNode());
	}

	public getOrderedNodes(): NodeEntity[] {
		const result = [];

		let node = this.getFirstNode();
		while (node !== undefined) {
			result.push(node);
			node = node.getNextNode();
		}

		return result;
	}

	public getNextSublink(): SublinkEntity | undefined {
		if (!!this.nextSublink) {
			return this.nextSublink;
		}

		return store.mapStore.getNextSublinkById(this.getModelId());
	}

	public getPreviousSublink(): SublinkEntity | undefined {
		if (!this.previousSublinkId) {
			return undefined;
		}

		if (!!this.previousSublink && this.previousSublinkId === this.previousSublink.id) {
			return this.previousSublink;
		}

		return store.mapStore.getEntity(this.previousSublinkId, SublinkEntity);
	}

	getIdDebugString() {
		return `${this.sublinkId}/${this.id}`;
	}

	/**
	 * Checks previous sublink ref and link ref
	 */
	validateRefs() {
		if (!!this.previousSublink) {
			if (this.previousSublinkId !== this.previousSublink.id) {
				throw Error(`SublinkEntity refs mismatch ${this.getIdDebugString()} refs mismatch. Expected Sublink Id ${this.previousSublinkId} got ${this.previousSublink.id}`);
			}
		}
		if (!!this.link) {
			if (this.link.id !== this.linkId) {
				throw Error(`SublinkEntity refs mismatch ${this.getIdDebugString()}. Expected Link Id ${this.linkId} got ${this.link.id}`);
			}
		}
	}

	public removeNode(n: NodeEntity, linkTobeUpdated: LinkEntity) {
		store.mapStore.markEntityToUpdate(linkTobeUpdated);
		this.nodess = this.nodess.filter(x => x.id !== n.id);
		store.mapStore.performUpdateOfEntity(linkTobeUpdated);
	}

	/**
	 * Creates a new sublink in the existing link with nodes from the referenced index
	 *
	 * @param atIndex references the first node of the newly created sublink
	 * @param nextAvaialbleIdForSublink must be pre-determined before operation takes place
	 * @returns the newly created sublink
	 */
	public splitSublink(atIndex: number, nextAvaialbleIdForSublink: number): SublinkEntity | undefined {
		if (atIndex <= 1 && atIndex >= this.nodess.length - 2) {
			// Unable to split between the first two nodes, or the last two
			console.log('splitSublink: Unable to split between the first two nodes, or the last two');
			return undefined;
		}

		const nextSublink = this.getNextSublink();
		const nodes = this.getNodes();

		const currentLink = this.getLink()!;
		const link = store.mapStore.markEntityToUpdate<LinkEntity>(currentLink); // deleteEntity
		currentLink.validateStructure('splitSublink2');

		// Remove refs within entity to avoid data being overwritten when assigning in previousSublinkId or previousNodeId attrs
		// This issue occurs when the id is updated, but the ref is not (the ref data overwrites the new id value)
		link.sublinkss.forEach(sl => {
			sl.setMapObjectState(this.getObjectStateFromImportStatus(sl.isImported));
			if (!!sl.previousSublink) {
				(sl.previousSublink as any) = undefined;
			}
			sl.nodess.forEach(n => {
				if (!!n.previousNode) {
					(n.previousNode as any) = undefined;
				}
			});
		});

		// by doing splice action updating original nodes also
		const secondSublinkNodes = nodes.splice(atIndex + 1);

		// update the state
		link.setMapObjectState(this.getObjectStateFromImportStatus(link.isImported));		
		
		// Create the new sublink and move the nodes there
		const newSublink = new SublinkEntity({
			version: this.version,
			linkId: this.linkId,
			previousSublinkId: this.getModelId(),
			state: 'NEW_OBJECT',
			isImported: false,
			sublinkId: nextAvaialbleIdForSublink,
		});

		// Update the nodes
		secondSublinkNodes.forEach(node => node.assignAttributes({
			sublinkId: newSublink.getModelId(),
			sublinkIdNumber: newSublink.sublinkId,
			sublink: newSublink,
			state: this.getObjectStateFromImportStatus(node.isImported),
		}));
		// The previousNodeId and previousNode of first node of second sublink should be null
		(secondSublinkNodes[0].previousNodeId as any) = null;
		(secondSublinkNodes[0].previousNode as any) = null;
		(secondSublinkNodes[secondSublinkNodes.length - 1].nextNode as any) = null;
		newSublink.nodess = secondSublinkNodes;

		// firstSublinkNodes.forEach(node => node.setMapObjectState('MODIFIED')); // why? 
		// I don't see any value - HITMAT-2210 - All nodes from the resulting first sublink in the direction of travel 
		// (which keeps the ID of the broken sublink) keep their existing status.

		// nextNode ref of last node of first sublink must be changed to null
		(nodes[nodes.length - 1].nextNode as any) = null;

		// Update the current sublink with the new nodes and values
		this.nodess.forEach(n => n.validateRefs());
		this.setMapObjectState(this.getObjectStateFromImportStatus(this.isImported));

		// Update the original next sublink
		if (!!nextSublink) {
			nextSublink.assignAttributes({
				previousSublinkId: newSublink.getModelId(),
				previousSublink: undefined, // or newSublink 
			});
		} else {
			// New sublink will be last sublink, must have null ref (otherwise leave as undefined)
			(newSublink.nextSublink as any) = null;
		}

		// Remove the next references
		this.nextSublink = undefined;
		newSublink.nodess.forEach(n => n.validateRefs());
		// Update the link entity and make sublinks be in order (note: correct order is not strictly necessary)
		const sublinkIndex = link.sublinkss.findIndex(sl => sl.id === this.id);
		link.sublinkss.forEach(x => x.validateRefs());
		link.sublinkss.splice(sublinkIndex + 1, 0, newSublink);
		link.sublinkss.forEach(x => x.validateRefs());
		store.mapStore.performUpdateOfEntity(link); // createEntity
		const newSublinks = link.getSublinks();
		newSublinks.forEach(x => {
			x.validateRefs();
			x.getNodes().forEach(y => y.validateRefs());
		});

		return newSublink;
	}

	/**
	 * Joins this sublink to the next sublink
	 */
	public joinSublink(sublinkNodeMax : number) : JOIN_SUBLINK_RESULT {
		// First fetch all the data needed to perform the operation from the mapStore
		const link = this.getLink();
		if (!link) {
			return JOIN_SUBLINK_RESULT.NoLink;
		}

		// Sublinks and nodes are ordered correctly, with associated prev/next refs reset

		// join nextSublink to this sublink
		const nextSublink = this.getNextSublink();
		if (!nextSublink) {
			return JOIN_SUBLINK_RESULT.NextSublinkNotFound;
		}
		
		// check that the new expanded sublink will not exceed the maximum allowed nodes
		if (this.nodess.length + nextSublink.nodess.length > sublinkNodeMax) {
			return JOIN_SUBLINK_RESULT.MaxNodesExceeded;
		}

		console.log(`join sublink ${this.id} with ${nextSublink.id}`);

		const nextSublinkNodes = nextSublink.getNodes();

		// connecting last node of this sublink to first node of next sublink
		const nextSublinkFirstNode = nextSublink.getFirstNode();
		const thisSublinkLastNode = this.getLastNode();

		if (!thisSublinkLastNode || !nextSublinkFirstNode) {
			console.log(`Couldn't find first/last nodes`);
			return JOIN_SUBLINK_RESULT.FirstOrLastNodeNotFound;
		}

		// Where B is this sublink and C is nextSublink, the following operations is performed
		// Original sublinks: A -> B -> C -> D -> E Updated sublinks: A -> B -> D -> E 
		const newNextSublink = store.mapStore.getNextSublinkById(nextSublink.getModelId());

		// Only make changes after marking the object as one to update
		const updateLink = store.mapStore.markEntityToUpdate(link);

		if (!!newNextSublink) {
			// Where C has a nextSublink D, connect B -> D
			newNextSublink.previousSublinkId = this.getModelId();
		}

		updateLink.setMapObjectState(this.getObjectStateFromImportStatus(updateLink.isImported));
		this.setMapObjectState(this.getObjectStateFromImportStatus(this.isImported));

		// The first node of the next sublink will be the next node of the last node of this sublink
		nextSublinkFirstNode.previousNodeId = thisSublinkLastNode.getModelId();

		// Update the nodes with the data from this sublink
		nextSublinkNodes.forEach(node => {
			node.assignAttributes({
				sublinkId: this.getModelId(),
				sublinkIdNumber: this.sublinkId,
				sublink: this,
			});
			node.setMapObjectState(this.getObjectStateFromImportStatus(node.isImported));
		});

		// Add the next sublink's nodes to the current sublink
		this.nodess = this.nodess.concat(nextSublinkNodes);

		// Remove the next sublink
		updateLink.sublinkss = updateLink.sublinkss
			.filter(x => x.getModelId() !== nextSublink.getModelId());

		// update all sublinks status 
		link.sublinkss.forEach(sublink => sublink.setMapObjectState(this.getObjectStateFromImportStatus(sublink.isImported)));
		
		store.mapStore.performUpdateOfEntity(updateLink);
		updateLink.validateStructure('after join');
		return JOIN_SUBLINK_RESULT.Success;
	}

	public sortNodes() {
		this.nodess = this.nodeSort(this.getNodes());
	}

	private nodeSort(nodes: NodeEntity[]) {
		return this.sortEntityList(nodes, 'previousNodeId');
	}

	/* *******************
	 * Validation - Error
	 * ****************** */
	public getErrors() {
		if (!this.mapObjectErrorss) {
			this.mapObjectErrorss = [];
		}
		return this.mapObjectErrorss;
	}

	public addErrors(errors: string[]) {
		errors.map(error => this.addError(error));
	}

	public addError(error: string): boolean {
		if (!this.hasError(error)) {
			const newError = new MapObjectErrorsEntity({
				errorMessage: error,
				sublinkId: this.getModelId(),
			});
			this.getErrors().push(newError);

			return true;
		}

		return false;
	}

	public resetAndAddNewErrors(errors: string[]) {
		this.resetError();
		this.addErrors(errors);
	}

	public removeError(error: string): boolean {
		const errorToDelete = this.getErrors().findIndex(x => x.errorMessage === error);
		if (errorToDelete !== -1) {
			this.getErrors().splice(errorToDelete, 1);
			return true;
		}

		return false;
	}

	public resetError() {
		this.mapObjectErrorss = [];
	}

	public hasError(error: string): boolean {
		return this.getErrors().find(x => x.errorMessage === error) !== undefined;
	}

	public getErrorCount() {
		return this.getErrors().length;
	}

	/* *******************
	 * Validation - Warning
	 * ****************** */
	public getWarnings() {
		if (!this.mapObjectWarningss) {
			this.mapObjectWarningss = [];
		}
		return this.mapObjectWarningss;
	}

	public addWarnings(warnings: string[]) {
		warnings.map(warning => this.addWarning(warning));
	}

	public addWarning(warning: string): boolean {
		if (!this.hasWarning(warning)) {
			const newWarning = new MapObjectWarningsEntity({
				warningMessage: warning,
				sublinkId: this.getModelId(),
			});
			this.getWarnings().push(newWarning);

			return true;
		}

		return false;
	}

	public resetAndAddNewWarnings(warnings: string[]) {
		this.resetWarnings();
		this.addWarnings(warnings);
	}

	public removeWarning(warning: string): boolean {
		const warningToDelete = this.getWarnings().findIndex(x => x.warningMessage === warning);
		if (warningToDelete !== -1) {
			this.getWarnings().splice(warningToDelete, 1);
			return true;
		}

		return false;
	}

	public resetWarnings() {
		this.mapObjectWarningss = [];
	}

	public hasWarning(warning: string): boolean {
		return this.getWarnings().find(x => x.warningMessage === warning) !== undefined;
	}

	public getWarningCount() {
		return this.getWarnings().length;
	}

	public setMapObjectState(state: mapObjectState) {
		// Ignore the case where this is already a new object (it doesn't matter if it is modified after, it's still new)
		if (this.state === 'NEW_OBJECT' && state === 'MODIFIED') {
			return;
		}

		this.state = state;
	}

	private  getObjectStateFromImportStatus(isImported: boolean) {
		return isImported ? 'MODIFIED' : 'NEW_OBJECT';
	}
	
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(SublinkEntity.prototype, 'created');
CRUD(modifiedAttr)(SublinkEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
