import React from 'react';
import { observer } from 'mobx-react';
import { action, observable, runInAction } from 'mobx';
import { MineUserEntity } from '../../../Models/Entities';
import { store } from '../../../Models/Store';
import { Combobox, ComboboxOption } from '../Combobox/Combobox';
import If from '../If/If';
import { ChangeUserPermissionsModal } from './UserPermissionsModal';

interface MineUserPermissionsComboboxProps {
	mineUser: MineUserEntity;
}

@observer
export default class MineUserPermissionsCombobox extends React.Component<MineUserPermissionsComboboxProps> {
	@observable
	private mineUser: MineUserEntity = this.props.mineUser;

	@observable
	private isAhsAdminProp: boolean = this.props.mineUser.isAhsAdmin;

	@observable
	private isModalOpen: boolean = false;

	@action
	private permissionOptions = () => {
		this.mineUser.isAhsAdmin = this.mineUser.isAhsAdmin ?? false;

		const options: ComboboxOption<boolean>[] = [
			{ display: 'AHS Admin', value: true },
			{ display: 'AHS Builder', value: false },
		];

		return options;
	};

	private setSelectedMineUserPermission = async () => {
		const _mineUser = new MineUserEntity();
		_mineUser.isAhsAdmin = this.mineUser.isAhsAdmin;

		await _mineUser.updateWhere(undefined, ['isAhsAdmin'], [this.mineUser.id]);

		if (this.mineUser.id === store.userId) window.location.reload();

		runInAction(() => {
			this.isModalOpen = false;
		});
	};

	@action
	private closeModal = () => {
		this.isModalOpen = false;
		this.mineUser.isAhsAdmin = this.isAhsAdminProp;
	}

	render() {
		const { mineUser } = this.props;

		return (
			<>
				<Combobox
					model={this.mineUser}
					modelProperty="isAhsAdmin"
					options={this.permissionOptions()}
					isDisabled={!store.isHitachiAdmin && !store.isAHSMineAdmin}
					label="none"
					searchable={false}
					onAfterChange={() => {
						runInAction(() => {
							this.isModalOpen = true;
						});
					}}
					labelVisible={false}
				/>
				<If condition={this.isModalOpen}>
					<ChangeUserPermissionsModal
						onCloseModal={this.closeModal}
						promote={mineUser.isAhsAdmin}
						onConfirm={this.setSelectedMineUserPermission}
					/>
				</If>
			</>
		);
	}
}
