import {SaveCommand, SaveCommandType} from "./SaveCommand";

export default class DeleteTurnSignalCommand extends SaveCommand {
	linkId: string;
	turnSignalId: string;

	constructor(linkId: string, turnSignalId: string) {
		super(SaveCommandType.DeleteTurnSignal);

		this.linkId = linkId;
		this.turnSignalId = turnSignalId;
	}
}