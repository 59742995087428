import MapStateHandler from "../MapStateHandler";
import {LeafletMouseEvent} from "leaflet";
import {LeafletCoordinates} from "../../Helpers/Coordinates";
import Link from "../../MapObjects/Link/Link";
import {LinkEntity} from "../../../../../Models/Entities";
import LinkConnectivityEditHelper from "../../MapStateHandlerHelpers/LinkConnectivityEditHelper";
import LinkConnectivityDisplayHelper from "../../MapStateHandlerHelpers/LinkConnectivityDisplayHelper";
import Signal from "../../MapObjects/Signal/Signal";
import LinkOperationsHelper from "../../MapStateHandlerHelpers/LinkOperationsHelper";
import {defaultContextMenuOptions} from "../../../RightClickContextMenu";
import DeletePathCommand from "../../../ChangeTracker/ChangeTypes/DeletePathCommand";

type ConfirmedPathSelectHandlerParams = Link | undefined;

export default class ConfirmedPathSelectHandler extends MapStateHandler<ConfirmedPathSelectHandlerParams> {

	private link: LinkEntity;
	private linkMapObject: Link;

	private displayLinkFeaturesTimeout: any;
	private signalSetMapObject: Signal;
	private isDisplayingTurnSignal: boolean = false;
	private isLinkShownInLayersPanel: boolean = true;

	private linkConnectivityDisplayHelper: LinkConnectivityDisplayHelper;

	private disableDispose = false;

	onInit(initialState: ConfirmedPathSelectHandlerParams): Promise<void> | void {
		if (initialState === undefined) {
			return;
		}

		this.link = initialState.getLinkEntity();
		this.linkMapObject = initialState;

		this.linkConnectivityDisplayHelper = new LinkConnectivityDisplayHelper(
			this.getRenderer(),
			this.getLookup(),
			this.link,
		);

        // Reset the display of the properties panel + side panel.
        this.getEventHandler().emit('onPropertiesPanel', 'link', this.link);

		// Get the current status of different link features that we need to display
		this.isDisplayingTurnSignal = this.getLookup().getIsViewMenuShown().signalSet;
		this.isLinkShownInLayersPanel = this.getLookup().getLinkLayersPanelShownStatus(this.link.id) ?? true;

		this.displayLinkFeaturesTimeout = setTimeout(() => this.displayLinkFeatures(), 100);

		this.getEventHandler().addListener('onUpdateSignal', this.onUpdateSignal);
	}

	onRightClick(event: LeafletMouseEvent) {
		const mapObject = this.getController().getMapObjectAtCoordinates(event.latlng);
		if (!!mapObject) {
			if (mapObject instanceof Link) {
				mapObject?.getId() !== this.linkMapObject.getId()
					? this.getEventHandler().setMapEventState('selector', { mapObject })
					: null;
				LinkOperationsHelper.linkRightClickMenu(event, this.getEventHandler(), mapObject);
			} else {
				LinkOperationsHelper.handleConnectivityRightClick(event,
					mapObject,
					this.linkConnectivityDisplayHelper,
					this.getEventHandler());
			}
		} else {
			this.getEventHandler().emit('onCustomContextMenu', defaultContextMenuOptions, event);
		}
	}

	onClick(event: LeafletMouseEvent): Promise<void> | void {
		if (!this.checkIfClickedOnLink(event.latlng)) {
			this.isDisplayingTurnSignal = this.getLookup().getIsViewMenuShown().signalSet;
			this.isLinkShownInLayersPanel = this.getLookup().getLinkLayersPanelShownStatus(this.link.id) ?? true;
			this.resetDisplayFeatures();

			const mapObject = this.getController().getMapObjectAtCoordinates(event.latlng);
			this.getEventHandler().setMapEventState('selector', { mapObject });
		}
	}

	onDoubleClick(event: LeafletMouseEvent): Promise<void> | void {
		if (this.checkIfClickedOnLink(event.latlng)) {
			this.transitionToEditMode();
		} else {
			this.exit();
		}
	}

	onKeyPress(event: KeyboardEvent): Promise<void> | void {
		if (this.getEventHandler().isBackspace(event)) {
			this.deleteLink();
		} else if (this.getEventHandler().isEnter(event)) {
			this.transitionToEditMode();
		}
	}

	onEscapePressed(event: KeyboardEvent): Promise<void> | void {
		this.getEventHandler().setActiveTool('selector');
	}

	onRequestUpdate(entity?: unknown) {
		if (entity) {
			const _entity = entity as LinkEntity;
			this.linkConnectivityDisplayHelper.renderUpdatedConnectivity(_entity);
		}
	}

	dispose(): Promise<void> | void {
		if (this.disableDispose) {
			return;
		}

		this.isDisplayingTurnSignal = this.getLookup().getIsViewMenuShown().signalSet;
		this.isLinkShownInLayersPanel = this.getLookup().getLinkLayersPanelShownStatus(this.link.id) ?? true;
		if (!this.getEventHandler().getToSelector()) {
			this.getController().unhighlightObject();
		}
		this.exit();

		this.getEventHandler().removeListener('onUpdateSignal', this.onUpdateSignal);
	}

	getLinkConnectivityDisplayHelper() {
		return this.linkConnectivityDisplayHelper;
	}

	private onUpdateSignal = (signalSetMapObject: Signal) => {
		this.signalSetMapObject = signalSetMapObject;
	}

	private displayLinkFeatures() {
		this.linkConnectivityDisplayHelper.renderConnectivity();

		const signalSetEntity = this.getLookup().getSignalSetByLinkId(this.link.id);
		if (!!signalSetEntity) {
			this.signalSetMapObject = this.getLookup().getMapObjectByEntity(signalSetEntity, 'signal') as Signal;
			if (this.isLinkShownInLayersPanel && !!this.signalSetMapObject) {
				this.signalSetMapObject.displayGraphic(true);
				this.getRenderer().rerender();
			}
		}
	}

	private hideLinkFeatures() {
		this.linkConnectivityDisplayHelper?.hideConnectivity();

		this.signalSetMapObject?.displayGraphic(this.isLinkShownInLayersPanel && this.isDisplayingTurnSignal);
		if (!!this.signalSetMapObject) {
			this.getRenderer().rerender();
		}
	}

	private checkIfClickedOnLink(coords: LeafletCoordinates): boolean {
		const object = this.getController().getMapObjectAtCoordinates(coords, ['link']);

		return !!object && object instanceof Link && object.getId() === this.linkMapObject.getId();
	}

	private transitionToEditMode() {
		this.disableDispose = true;
		this.hideLinkFeatures();
		this.getEventHandler().setMapEventState('edit_confirmed_path', this.linkMapObject);
	}

	private exit() {
		// Remove the link features from the display
		this.resetDisplayFeatures();
	}

	private resetDisplayFeatures() {
		clearTimeout(this.displayLinkFeaturesTimeout);

		this.hideLinkFeatures();
	}

	private deleteLink() {
		const eventHandler = this.getEventHandler();
		const renderer = this.getRenderer();
		const controller = this.getController();

		// Remove all the connectivities from the link
		LinkConnectivityEditHelper.removeConnectivityFromAssociatedLinks(this.link, this.getLookup());

		// Remove the link from the map display
		controller.removeMapObject(this.link, this.linkMapObject, true);
		renderer.removeObject(this.linkMapObject.getParent()?.getId() ?? '');
		renderer.rerender();

		controller.getTracker().addChange(new DeletePathCommand(this.link.id));

		eventHandler.emit('onPropertiesPanel', 'map');
		eventHandler.setMapEventState('selector');
	}
}