import { BeaconEntity } from 'Models/Entities';
import * as PIXI from 'pixi.js';
import { LatLng } from 'leaflet';
import MapObject from '../MapObject';
import MapRenderer from '../../MapRenderer';
import MapStore from '../../MapStore';
import { getLeafletLatLng, realWorldCoordinates } from '../../Helpers/Coordinates';
import { store } from 'Models/Store';

interface INodeRadius {
	/** Radius of node including stroke */
	outerRadius: number;
	/** Radius of node exluding stroke */
	innerRadius: number;
}

interface IBeaconCoordinates {
	northing: number;
	easting: number
}

// must exceed the highest zIndex above
const BEACON_ZINDEX_TOP = 100;

/**
 * Renders everything associated with a beacon in Pixi, including
 */
export default class Beacon extends MapObject<BeaconEntity> {
	private beaconCoordinates: IBeaconCoordinates;
	private radius: number;

	static readonly startEndNodeRadius: INodeRadius = { outerRadius: 10, innerRadius: 9 };

	/**
	 * Creates an instance of node graphic.
	 * @param renderer
	 * @param entity
	 * @param lookup
	 * @param isInitialLoad used to hide on initial load
	 */
	constructor(renderer: MapRenderer, entity: BeaconEntity, lookup: MapStore, isInitialLoad?: boolean) {
		super(renderer, 'beacon', entity);
		lookup?.addEntityToMapObject(entity.id, this);
		// currently, the only sublayering is access beacons above route beacons
		this.zIndexBase = this.entity.isAccess ? 1 : 0;
		this.zIndexTop = BEACON_ZINDEX_TOP;
		// FMS invisible by default
		const isVisible = isInitialLoad !== true;
		this.createGraphic(isVisible);
	}

	public dispose() {
		// Make sure to dispose of the tooltip
		this.removeTooltip();
	}

	private getOuterCircleStrokeColour() {
		return this.entity.isAccess ? 0xFFFF00 : 0xFFFFFF;
	}

	private getOuterCircleFillColour() {
		const isDeleted = this.entity.state === 'DELETED';
		const notImportedObject = (this.entity.state === 'NEW_OBJECT' || this.entity.state === 'MODIFIED');
		return isDeleted ? 0x0804000 : notImportedObject ? 0x800080 : 0x008000;
	}

	private getInnerCircleStrokeColour() {
		return 0xFFFFFF;
	}

	private getInnerCircleFillColour() {
		return this.entity.direction === 'ENTER' ? 0xFF8000 : 0x5674A0;
	}

	public render() {
		const beaconEntity = this.entity;

		const {coordinates} = JSON.parse(beaconEntity.beaconLocation);
		this.beaconCoordinates = { northing: coordinates[1], easting: coordinates[0] };
		const { northing, easting } = this.beaconCoordinates;
		const radiusMeters = beaconEntity.radius;
		const { direction } = beaconEntity;
		const projectedCoordinates = this.renderer.project(this.beaconCoordinates);

		// Determine radius in projected pixels
		const extendToRadius = this.renderer.project(realWorldCoordinates(northing + radiusMeters, easting + radiusMeters));
		this.radius = Math.abs(extendToRadius.x - projectedCoordinates.x);

		const widthMultiplier = this.isHighlighted ? 2 : 1;
		const { startEndNodeRadius } = Beacon;

		const graphic = this.getGraphic();
		graphic.clear();
		graphic.zIndex = this.isHighlighted ? this.zIndexTop : this.zIndexBase;

		// Stroke width is the same as start or end node
		const innerStrokeWidth = startEndNodeRadius.outerRadius - startEndNodeRadius.innerRadius;
		const outerStrokeWidth = innerStrokeWidth * widthMultiplier;
		graphic.lineStyle(outerStrokeWidth, this.getOuterCircleStrokeColour());
		graphic.beginFill(this.getOuterCircleFillColour(), 0.5);
		graphic.position.set(projectedCoordinates.x, projectedCoordinates.y);
		graphic.drawCircle(0, 0, this.radius);
		graphic.endFill();

		if (direction === 'ENTER' || direction === 'EXIT') {
			// Inner node for entry/exit
			graphic.lineStyle(innerStrokeWidth, this.getInnerCircleStrokeColour());
			graphic.beginFill(this.getInnerCircleFillColour());
			graphic.drawCircle(0, 0, startEndNodeRadius.innerRadius);
			graphic.endFill();
		}

		graphic.hitArea = new PIXI.Circle(0, 0, this.radius + (outerStrokeWidth / 2));
		this.renderLabel(!store.isInit);
	}

	public panToObject() {
		this.renderer.getMap().panTo(this.renderer.getLeafletCoords(this.beaconCoordinates));
	}

	/**
	 * 
	 * @param isHide use to handles edge case on first load
	 */
	private renderLabel(isHide: boolean) {
		const mapCoords = this.renderer.getLeafletCoords(this.beaconCoordinates);
		this.createTooltip(this.entity.beaconId, getLeafletLatLng(mapCoords), undefined, isHide);
	}
}
