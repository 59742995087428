import Modal from "../../Modal/Modal";
import React, {useEffect, useState} from "react";
import alert from "../../../../Util/ToastifyUtils";
import {setCustomTag} from "../../../MapComponents/Map/Helpers/MapUtils";
import axios from "axios";
import {SERVER_URL} from "../../../../Constants";
import {LoadView} from "../LoadView";
import {Button, Colors, Display, Sizes} from "../../Button/Button";
import {ButtonGroup} from "../../Button/ButtonGroup";
import {
    backgroundImageFileValidation,
    FileUploadSection,
    IFileUploadModalProps,
} from "./FileUploadModal";
import {observer} from "mobx-react";
import {CustomValidator, Validator} from "../../../../Validators/CustomValidator";
import {observable} from "mobx";

class BackgroundImageImportModel extends Validator<BackgroundImageImportModel> {

    importVersionId: string;

    @CustomValidator(async (value, model) => {
        return await backgroundImageFileValidation(value);
    })
    @observable
    public backgroundData: File;

    constructor(importVersionId: string) {
        super();

        this.importVersionId = importVersionId;
    }
}

export default function BackgroundImportModal(props: IFileUploadModalProps) {
    const { onCloseModal } = props;

    return (
        <Modal
            isOpen
            label="File upload"
            onRequestClose={onCloseModal}
            className="map-file-upload-modal"
            overlayClassName="map-file-upload-modal-overlay"
        >
            <RenderBackgroundModalContent {...props} />
        </Modal>
    );
}

export const RenderBackgroundModalContent = observer((props: IFileUploadModalProps) => {
    const { title, onCloseModal, importVersion } = props;
    const [createImport, setCreateImport] = useState(new BackgroundImageImportModel(importVersion!.id));
    const [displaySpinner, setDisplaySpinner] = useState(false);

    const uploadBackgroundImageFiles = async () => {
        if (await createImport.validate()) {
            return;
        }

        setCustomTag('import-files', 'background-image');
        setDisplaySpinner(true);
        try {
            const formData = new FormData();
            formData.append('UploadFileData', createImport.backgroundData);
            formData.append('ImportVersionId', importVersion?.id ?? '');

            const response = await axios.post(
                `${SERVER_URL}/api/entity/ImportVersionEntity/uploadBackgroundData/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'mulitipart/form-data',
                    },
                },
            );

            if (response.data) {
                alert('Background data uploaded successfully', 'success');
                window.location.reload();
            } else {
                alert('Empty background data', 'error');
            }
            setDisplaySpinner(false);
            onCloseModal();
        } catch (e: any) {
            setDisplaySpinner(false);
            alert(e.response.data.errors[0]?.message ?? 'Uploading files failed', 'error');
        }
    };

    return (
        <>
            {displaySpinner && <LoadView text="Importing background image..." timerInterval={50} />}
            <div className="modal__header">
                <h3>{title}</h3>
                <Button
                    icon={{ icon: 'cross', iconPos: 'icon-right' }}
                    className="cancel-button no-background"
                    display={Display.Text}
                    onClick={onCloseModal}
                    labelVisible={false}
                />
            </div>

            <FileUploadSection
                model={createImport}
                modelProperty="backgroundData"
                contentType=".zip"
                required
                title="BACKGROUND IMAGE DATA FILE"
                fileType="BackGroundImageYYYYMMDDHHMMSS.zip"
                onAfterChange={async () => await createImport.validate('backgroundData')}
                errors={createImport.getError('backgroundData')}
            />

            <ButtonGroup className="modal-actions">
                <Button
                    colors={Colors.Primary}
                    display={Display.Outline}
                    onClick={onCloseModal}
                    sizes={Sizes.Medium}
                >
                    Cancel
                </Button>
                <Button
                    className="next-button"
                    colors={Colors.Primary}
                    icon={{ icon: 'chevron-right', iconPos: 'icon-right' }}
                    display={Display.Solid}
                    onClick={uploadBackgroundImageFiles}
                    sizes={Sizes.Medium}
                >
                    Next
                </Button>
            </ButtonGroup>
        </>
    );
});
