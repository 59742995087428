import React from 'react';
import { SegmentEntity } from '../../../Models/Entities';
import CollapsibleProperty from '../CollapsibleProperty';
import InputField from "./PropertiesPanelComponents/InputField";

export default function SegmentProperties({ entity } : { entity: SegmentEntity}) {
	const segment = entity;

	return (
		<>
			<h6>Segment Identification</h6>
			{
				segment.linkss.map(link => (
					<InputField
						model={link}
						label="Associated Link ID"
						modelProperty="linkId"
						isReadOnly
						key={link.linkId}
					/>
				))
			}
			<InputField model={segment} label="Description" modelProperty="description" isReadOnly isTextArea />
			<div className="section-divider" />

			<CollapsibleProperty propertyTitle="Segment Properties" displayProperty>
				<InputField model={segment.startBeacon} label="Start Beacon" modelProperty="beaconId" isReadOnly />
				<InputField model={segment.endBeacon} label="End Beacon" modelProperty="beaconId" isReadOnly />
			</CollapsibleProperty>
			<div className="section-divider" />
		</>
	);
}
