import * as PIXI from 'pixi.js';

/**
 * Checks if a given point lies on any edge of a given polygon.
 *
 * The function iterates over each edge of the polygon. For each edge, it checks if the point lies on that edge.
 * If the point lies on any edge, the function returns true. If the point does not lie on any edge, the function returns false.
 *
 * @param {PIXI.Polygon} polygon - The polygon to check. This should be an instance of PIXI.Polygon.
 * @param {PIXI.Point} point - The point to check. This should be an instance of PIXI.Point.
 * @returns {boolean} - Returns true if the point lies on any edge of the polygon, and false otherwise.
 */
export function pointOnPolygon(polygon: PIXI.Polygon, point : PIXI.Point) {
    for (let i = 0; i < polygon.points.length; i += 2) {
        let x1 = polygon.points[i];
        let y1 = polygon.points[i + 1];
        let x2 = polygon.points[(i + 2) % polygon.points.length];
        let y2 = polygon.points[(i + 3) % polygon.points.length];

        let dx = x2 - x1;
        let dy = y2 - y1;

        if ((dx * (point.y - y1) === dy * (point.x - x1)) && (point.x >= Math.min(x1, x2) && point.x <= Math.max(x1, x2)) && (point.y >= Math.min(y1, y2) && point.y <= Math.max(y1, y2))) {
            return true;
        }
    }
    return false;
}
