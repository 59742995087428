import { Area, MapController, MapLookup, MapRenderer } from 'Views/MapComponents';
import * as PIXI from 'pixi.js';
import DrivingArea from '../MapObjects/Area/DrivingArea';
import { PixiCoordinates, RealWorldCoordinates, isRealWorldCoordinates } from '../Helpers/Coordinates';
import { AreaEntity, DrivingAreaEntity } from 'Models/Entities';
import MapObject from '../MapObjects/MapObject';
import { pointOnPolygon } from '../../../../Util/PolygonUtils';
import {Point} from "pixi.js";

export interface IAreaInfo {
	area: AreaEntity;
	polygon: PIXI.Polygon;
}

/**
 * Base class containing shared methods for validation
 */
export default class MapValidator {
	protected renderer: MapRenderer;
	protected lookup: MapLookup;
	protected controller: MapController;

	private drivingAreas: DrivingAreaEntity[];

	constructor(controller: MapController) {
		this.renderer = controller.getMapRenderer();
		this.lookup = controller.getMapLookup();
		this.controller = controller;
		this.drivingAreas = this.lookup.getDrivingAreas();
	}

	public static setMapObjectError(renderer: MapRenderer, mapObject: MapObject<unknown>, isRerender: boolean = true, hasError: boolean) {
		if (mapObject.isError !== hasError) {
			mapObject.isError = hasError;
			renderer.markObjectToRerender(mapObject.getId());
		}
		if (isRerender) {
			renderer.rerender();
		}
	}

	public static setMapObjectWarning(renderer: MapRenderer, mapObject: MapObject<unknown>, isRerender: boolean = true, hasWarning: boolean) {
		mapObject.isWarning = hasWarning;
		renderer.markObjectToRerender(mapObject.getId());
		if (isRerender) {
			renderer.rerender();
		}
	}

	public static setMapObjectTooltipErrorWarning(mapObject: MapObject<unknown>, hasError: boolean, hasWarning: boolean) {
		mapObject.isError = hasError;
		mapObject.isWarning = hasWarning;
		mapObject.setTooltipDisplay(true, hasError, hasWarning);
	}

	/**
	 * Check if two segments intersect. Library methods could not be used due to strange results
	 * that are most likely due to precision (which is accounted for in this method)
	 * @param pointAStart
	 * @param pointAEnd
	 * @param pointBStart
	 * @param pointBEnd
	 * @returns {boolean} True if the segments intersect
	 */
	public checkInterSegmentIntersection(pointAStart: PIXI.IPointData, pointAEnd: PIXI.IPointData,
		pointBStart: PIXI.IPointData, pointBEnd: PIXI.IPointData) {
		const line1StartX = pointAStart.x; const line1StartY = pointAStart.y;
		const line1EndX = pointAEnd.x; const line1EndY = pointAEnd.y;
		const line2StartX = pointBStart.x; const line2StartY = pointBStart.y;
		const line2EndX = pointBEnd.x; const
			line2EndY = pointBEnd.y;

		// eslint-disable-next-line max-len
		const denominator = ((line2EndY - line2StartY) * (line1EndX - line1StartX)) - ((line2EndX - line2StartX) * (line1EndY - line1StartY));
		if (denominator === 0) {
			return false;
		}

		let a = line1StartY - line2StartY;
		let b = line1StartX - line2StartX;
		const numerator1 = ((line2EndX - line2StartX) * a) - ((line2EndY - line2StartY) * b);
		const numerator2 = ((line1EndX - line1StartX) * a) - ((line1EndY - line1StartY) * b);
		a = numerator1 / denominator;
		b = numerator2 / denominator;

		// Ugly hack to deal with precision issues
		const epsilon = 0.0000000000001;
		a += epsilon;
		b += epsilon;

		return (a > 0 && a < 1) && (b > 0 && b < 1);
	}

	/**
	 *
	 * @param polygonPointsA
	 * @param polygonPointsB
	 * @returns true if there's a line intersection
	 */
	checkPolygonLineIntersection(polygonPointsA: PIXI.IPointData[], polygonPointsB: PIXI.IPointData[]) {
		for (let i = 0; i < polygonPointsA.length - 1; i++) {
			const pointAStart = polygonPointsA[i];
			const pointAEnd = polygonPointsA[i + 1];
			for (let j = 0; j < polygonPointsB.length - 1; j++) {
				const pointBStart = polygonPointsB[j];
				const pointBEnd = polygonPointsB[j + 1];
				// eslint-disable-next-line max-len
				const isLineIntersection = this.checkInterSegmentIntersection(pointAStart, pointAEnd, pointBStart, pointBEnd);
				if (isLineIntersection) {
					return true;
				}
			}
		}
		return false;
	}

	/**
	 *
	 * @param polygonPointsA
	 * @param polygonPointsB
	 * @returns true if there's an intersection
	 */
	checkPolygonIntersection(polygonPointsA: PIXI.IPointData[], polygonPointsB: PIXI.IPointData[], checkLineIntersection: boolean = true) {
		// first check if ant point is within
		// then check if any line intersects with any line of
		const polygonA = new PIXI.Polygon(polygonPointsA);
		const polygonB = new PIXI.Polygon(polygonPointsB);
		const isWithinPolygon = polygonPointsA.some(p => polygonB.contains(p.x, p.y))
			|| polygonPointsB.some(p => polygonA.contains(p.x, p.y));
		if (isWithinPolygon) {
			return isWithinPolygon;
		}
		if (checkLineIntersection) {
			const isLineIntersection = this.checkPolygonLineIntersection(polygonPointsA, polygonPointsB);
			if (isLineIntersection) {
				return true;
			}
		}
		// No intersection
		return false;
	}

	/**
	 * Checks if given driving area contains a particular point and return bounds
	 * of containing polygon. Bounds can be perimeter of hole.
	 * Generally, a point should not be in a hole, except for the special
	 * condition where an imported area is already so.
	 * @param drivingArea
	 * @param pixiCoords
	 * @returns
	 */
	public static getBoundingPoints(drivingArea: DrivingArea, pixiCoords: PixiCoordinates):
	{ points: PIXI.Point[], isHole: boolean } | undefined {
		const outerPoints = drivingArea.getOuterPoints();
		const innerPoints = drivingArea.getInnerPoints();
		const outerPolygon = new PIXI.Polygon(outerPoints);
		const insidePermimeter = outerPolygon.contains(pixiCoords.x, pixiCoords.y);
		if (insidePermimeter) {
			// Given point is within this driving area
			if (!!innerPoints) {
				// Driving area contains holes
				const holePoints = innerPoints.find(points => {
					const hole = new PIXI.Polygon(points);
					return hole.contains(pixiCoords.x, pixiCoords.y);
				});
				if (!!holePoints) {
					// Point is within the hole of this driving area
					return { points: holePoints, isHole: true };
				}
			}
			// Point is at a valid point in drivable area (within peremeter but not in a hole)
			return { points: outerPoints, isHole: false };
		}
		return undefined;
	}

	/**
	 * Checks whether or not a series of points are fully contained within a drivable area
	 *
	 * @param points
	 * @returns driving area if fully contained, otherwise undefined
	 */
	public checkDrivableArea(points: PIXI.Point[], drivingZoneMapObjectId: string) {
		let drivingArea = this.lookup.getDrivingAreaObjByDringZoneObjId(drivingZoneMapObjectId);

		if (!!drivingArea) {
			return this.checkPointIntersectionWithDriveableArea(points, drivingArea) ? drivingArea : undefined;
		}

		return undefined;
	}

	public checkPointIntersectionWithDriveableArea(points: PIXI.Point[], driveableArea: DrivingArea): boolean {
		const outerPoints = driveableArea.getOuterPoints();
		const innerPoints = driveableArea.getInnerPoints();
		return this.checkDrivableAreaIntersection(outerPoints, innerPoints, points);
	}

	public checkDrivableAreaIntersection(outerPoints: Point[], innerPoints: Point[][], drivingZonePoints: Point[]): boolean {
		// const innerPoints = drivingArea.getInnerPoints();
		const outerPolygon = new PIXI.Polygon(outerPoints);
		const areaAllPointsInDrivingArea = drivingZonePoints.every(p => outerPolygon.contains(p.x, p.y));
		if (!areaAllPointsInDrivingArea) {
			// one or more points outside of driving area perim
			return false;
		}

		// Check if an edge intersects the outer polygon
		const intersectsOuterPolygon = this.checkPolygonLineIntersection(outerPoints, drivingZonePoints);
		if (intersectsOuterPolygon) {
			return false;
		}

		// Then test that no edge intersects or is contained with any of the inner perims (holes)
		if (!!innerPoints) {
			// check if any point is within a hole (do this first for efficiency)
			const insideHole = innerPoints.some(holePoints => drivingZonePoints
				.some(p => (new PIXI.Polygon(holePoints)).contains(p.x, p.y)));
			if (insideHole) {
				return false;
			}
			// Check if any edge intersects hole
			// eslint-disable-next-line max-len
			const intersectsHole = innerPoints.some(holePoints => this.checkPolygonIntersection(holePoints, drivingZonePoints));
			if (intersectsHole) {
				return false;
			}
		}

		return true;
	}

	/**
	 * Returns the area of the current bay
	 * @param coords - coordinates of the bay
	 * @param controller - controller to get the map renderer
	 */
	public static getAreaAtCoords = (coords: RealWorldCoordinates | PixiCoordinates, controller: MapController): IAreaInfo | undefined => {
		const lookup = controller.getMapLookup();
		const renderer = controller.getMapRenderer();
		let pixiCoords = isRealWorldCoordinates(coords) ? renderer.project(coords as RealWorldCoordinates) : coords as PixiCoordinates;
		const { x, y } = pixiCoords;

		let currentPolygon: PIXI.Polygon | undefined;

		const areaEntity = lookup.getAllEntities(AreaEntity)
			.filter(area => area.areaType === 'AREAAUTONOMOUS')
			.find(area => {
				const areaObjectId = lookup.getMapObjectId(area.id, 'area');
				if (!areaObjectId) {
					return false;
				}

				const areaObject = renderer.getObjectById(areaObjectId) as Area;
				const polygon = new PIXI.Polygon(areaObject.getPoints());
				currentPolygon = polygon;
				return polygon.contains(x, y) || pointOnPolygon(polygon, {x, y});
			});
		if (!!areaEntity && !!currentPolygon) {
			return {
				area: areaEntity,
				polygon: currentPolygon,
			}
		}
		return undefined;
	}

}
