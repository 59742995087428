import { LeafletMouseEvent } from "leaflet";
import { createRef, useEffect, useRef, useState } from "react";
import { MapController } from ".";

interface IRightClickContextMenuProps {
    map: MapController | undefined;
}

export interface IRightClickContextMenuOptions {
    text:string;
    disabled:boolean;
    separator?: boolean;
    onClick?: () => void;
}

export const contextMenuOptions: IRightClickContextMenuOptions[] = [
    { text: "Break link", disabled: false, separator: false },
    { text: "Break sublink", disabled: false, separator: false },
];

export const defaultContextMenuOptions = [
    { text: "No actions available", disabled: true },
];

export default function RightClickContextMenu(props: IRightClickContextMenuProps) {
    const [disabled, setDisabled] = useState(false);
    const [show, setShow] = useState(false);
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const {map} = props;
    const [menuOptions, setMenuOptions] = useState<IRightClickContextMenuOptions[]>([]);
    const contextMenuRef = useRef<HTMLDivElement>(null);

    const menuCallbacks: Record<string, (() => void) | undefined> = {};
    const handleClick = (text: string) => {
        const onClick = menuCallbacks[text];
        if (onClick) {
            onClick();
        }
    }

    useEffect(() => {
        map?.getEventHandler().addListener('onCustomContextMenu', onCreateConfirm);

        const hideMenu = () => setShow(false);

        const  onMouseDown = (e: MouseEvent ) => {
            if (!!e.target && e.target instanceof HTMLDivElement) {
                const divE1 = e.target;
                if(divE1.classList.contains('custom-context-menu') && !!divE1.textContent)
                {
                    handleClick(divE1.textContent);
                }
            }
            
        }

        document.addEventListener('mousedown', onMouseDown);
        document.addEventListener("click", hideMenu);
        document.addEventListener('mouseup', (e: MouseEvent) => {
            if (show) {
                e.stopPropagation();
            }
            map?.getEventHandler().closeContextMenu();
            setShow(false)
        })
        document.addEventListener('wheel', hideMenu);
        document.addEventListener('keydown', hideMenu);
        document.getElementsByTagName('canvas')[0]?.addEventListener('wheel', hideMenu);
        document.getElementsByClassName('sidebar-button')[0]?.addEventListener('mousedown', hideMenu);

        return () => {
            map?.getEventHandler().removeListener('onCustomContextMenu', onCreateConfirm);
            document.removeEventListener('mousedown', onMouseDown);
            document.removeEventListener("click", hideMenu);
            document.removeEventListener('wheel', hideMenu);
            document.removeEventListener('keydown', hideMenu);
            document.getElementsByTagName('canvas')[0]?.removeEventListener('wheel', hideMenu);
            document.getElementsByClassName('sidebar-button')[0]?.removeEventListener('mousedown', hideMenu);
        
            setShow(false);
        };
    }, []);

    useEffect(() => {
        if (show && !!contextMenuRef.current) {
            const contextMenuDiv = contextMenuRef.current;
            const { x, y } = anchorPoint;

            const offset = 5;

            const menuBar = document.querySelector('div.admin__top-bar')?.getBoundingClientRect();
            const height = (menuBar?.height ?? 0) - offset;

            contextMenuDiv.style.top = ``;
            contextMenuDiv.style.bottom = ``;
            contextMenuDiv.style.right = ``;
            contextMenuDiv.style.left = ``;

            const elementBoundingRect = contextMenuDiv.getBoundingClientRect();

            if ((y + elementBoundingRect.height) < window.innerHeight) {
                contextMenuDiv.style.top = `${y - height}px`;
            } else {
                contextMenuDiv.style.bottom = `${window.innerHeight - y + offset}px`;
            }

            if ((x + elementBoundingRect.width) < window.innerWidth) {
                contextMenuDiv.style.left = `${x}px`;
            } else {
                contextMenuDiv.style.right = `${window.innerWidth - x}px`;
            }
        }
    }, [show, anchorPoint, menuOptions]);

    const onCreateConfirm = (contextMenuOptions: IRightClickContextMenuOptions[], event: LeafletMouseEvent) => {
        setMenuOptions(contextMenuOptions);
        setShow(true);

        map?.getEventHandler().openContextMenu();

        contextMenuOptions.forEach((item) => {
            setDisabled(item.separator === true);
            menuCallbacks[item.text] = item.onClick;
        });

        const x = event.originalEvent.pageX;
        const y = event.originalEvent.pageY;
        event.originalEvent.preventDefault();
        event.originalEvent.stopPropagation();

        setAnchorPoint({ x, y });
    };

    if (!show) {
        return <></>;
    }

    return (
        <div className="context-menu-parent" id ="context-menu-parent"
         style={{ position: "absolute", zIndex: 10000 }}
         ref={contextMenuRef}
         onContextMenu = {e => e.preventDefault()}>
            {menuOptions.map((item) =>
                <div 
                    className={item.disabled === false ? "custom-context-menu clickable-custom-menu" : "custom-context-menu-disabled"}
                    key={item.text}>
                    {item.text}
                </div>
            )}
            <div className={ !disabled ? "no-separator" :"separator-contextmenu" }/>
        </div>
    )
}