import React, { useState } from 'react';
import {LinkEntity, NodeEntity, SublinkEntity} from '../../../Models/Entities';
import MapController from '../Map/MapController';
import { nodetask, nodetaskOptions } from '../../../Models/Enums';
import { Button, Colors, Display } from '../../Components/Button/Button';
import CollapsibleProperty from '../CollapsibleProperty';
import { observable, runInAction } from 'mobx';
import ErrorsAndWarnings from './ErrorsAndWarnings';
import PathToolHelper from '../Map/MapStateHandlerHelpers/PathToolHelper';
import NodeValidator from '../Map/MapValidators/NodeValidator';
import {RenderInformationCombobox} from "./PropertiesPanelComponents/RenderInformationCombobox";
import InputField from "./PropertiesPanelComponents/InputField";
import {SetNodeTaskCommand} from "../ChangeTracker/ChangeTypes/SetNodeTaskCommand";

/**
 * Render properties side panel for a selected node.
 * NodeProperties panel can be used to view node information
 * @param props
 * - entity: AreaEntity
 * @constructor
 */
export default function NodeProperties({ entity, map } : { entity: NodeEntity, map: MapController }) {
	const node = entity;
	const tasks: nodetask[] = ['HAULING', 'REVERSEPOINT', 'PARKING', 'DUMPINGCRUSHER'];
	const tasksForTransitionIn: nodetask[] = ['HAULING', 'PARKING'];

	const nodeTypeOptionsCombobox = Object
		.entries(nodetaskOptions)
		.map(([value, display]) => ({ display, value }))
		.filter(x => tasks.includes(x.value as nodetask));

	const nodeTypeOptionsForTransitionIn = nodeTypeOptionsCombobox
		.filter(x => tasksForTransitionIn.includes(x.value as nodetask));

	const [displayAdvanced, setDisplayAdvanced] = useState(false);
	const [isTaskReadonly, setIsTaskReadonly] = useState(false);

	const derivedProperties = { 
		easting: node.easting.toFixed(2),
		northing: node.northing.toFixed(2),
		speed: node.speed.toFixed(1),
		gradient: Number(node.gradient/10).toFixed(1),
		up: node.up.toFixed(2)
	}

	const isNodeTaskReadonly = () => {
		const isMatchRules = NodeValidator.isMatchStartParkingNodeRules(node, map);

		if (isMatchRules || (!isMatchRules && node.isFirstNode() && node.task === 'PARKING')) {
			return false;
		}
		return true;
	}

	const refreshNodeSelection = async (nodeEntity: NodeEntity) => {
		// inspired from refreshLinkProperties
		const eventHandler = map?.getEventHandler();
		if (eventHandler) {			
			setTimeout(() => {
				eventHandler.emit('onPropertiesPanel', 'node', nodeEntity);
				eventHandler.emit('onMapObjectSelectedInMap', nodeEntity, 'node');				
			}, 100);
		}
	};
	
	const updateNodeTask = async () => {
		if (node.task === 'HAULING') {
			const nextNode = node.getNextNode();
			if (!!nextNode) {
				runInAction(() => {
					node.speed = nextNode.speed;
				});
			} else {
				console.error(`nextNode not found. Speed could not be set`);
			}
		} else {
			runInAction(() => {
				node.speed = 0;
			});
		}
		if (node.state === 'IMPORTED') {
			runInAction(() => {
				node.state = node.isImported ? 'MODIFIED' : 'NEW_OBJECT';
			});
		}
		setIsTaskReadonly(isNodeTaskReadonly());

		// TODO: this actually requires regeneration of clothoid path if driving zones are to be updated correctly
		map?.getEventHandler().emit('requestUpdate', node);
		const lookup = map.getMapLookup();

		map.getTracker().addChange(new SetNodeTaskCommand(node.getModelId(), node.task));

		let nodeEntity: NodeEntity = lookup.getEntity(node, 'node');
		// to select node again in the viewer, update properties and select item
		map?.highlightObjectByEntityId(nodeEntity.getModelId(),'node');
		map?.getEventHandler().emit('onPropertiesPanel', 'node', nodeEntity);
		await refreshNodeSelection(nodeEntity);
	};

	const moreButton = (
		<Button
			className="more-button no-background"
			icon={{ icon: 'more-horizontal', iconPos: 'icon-right' }}
			colors={Colors.White}
			display={Display.Text}
			onClick={() => setDisplayAdvanced(prevState => !prevState)}
		/>
	);

	return (
		<>
			<h6>Node Identification</h6>
			<InputField model={node} label="ID" modelProperty="nodeId" propertyUnit="" isNumber isReadOnly />
			<InputField model={node} label="Parent Sublink ID" modelProperty="sublinkIdNumber" propertyUnit="" isReadOnly />
			<InputField model={node} label="Parent Link ID" modelProperty="linkIdNumber" propertyUnit="" isNumber isReadOnly />
			<div className="section-divider" />
			<CollapsibleProperty propertyTitle="Node Location" displayProperty>
				<InputField model={derivedProperties} label="Easting" modelProperty="easting" propertyUnit="m" isNumber isReadOnly />
				<InputField model={derivedProperties} label="Northing" modelProperty="northing" propertyUnit="m" isNumber isReadOnly />
				<InputField model={derivedProperties} label="Elevation" modelProperty="up" propertyUnit="m" isNumber isReadOnly />
			</CollapsibleProperty>
			<div className="section-divider" />

			{displayAdvanced
				&& <AdvancedNodeProperties node={node} hidePanel={() => setDisplayAdvanced(false)} map={map} />}

			<CollapsibleProperty propertyTitle="Node Properties" displayProperty moreButton={moreButton}>
				<RenderInformationCombobox
					model={node}
					label="Task"
					modelProperty="task"
					options={isTaskReadonly || isNodeTaskReadonly() ? nodeTypeOptionsCombobox : nodeTypeOptionsForTransitionIn}
					readonly={isTaskReadonly || isNodeTaskReadonly()}
					onAfterChange={updateNodeTask}
				/>

				<InputField model={derivedProperties} label="Speed" modelProperty="speed" propertyUnit="km/h" isNumber isReadOnly />
				<InputField
					model={derivedProperties}
					label="Gradient"
					modelProperty="gradient"
					// Reverse the sign of the gradient value from the database (explanation: HIT-489)
					propertyUnit={String.fromCharCode(176)}
					isNumber
					isReadOnly
				/>
			</CollapsibleProperty>

			<div className="section-divider" />
			<ErrorsAndWarnings mapObject={node} mapController={map} />
			<div className="section-divider" />
		</>
	);
}

// eslint-disable-next-line max-len
function AdvancedNodeProperties({ node, hidePanel, map } : { node: NodeEntity, hidePanel: () => void, map?: MapController }) {

	const updateCurveRadius = (value : number) => {
		const radius = Number(value);
		// curvature radius rules
		return (radius === 0 || Number.isNaN(radius) || (1 / radius) > 1000000) ? '∞' : (1 / radius).toFixed(1);
	}

	const _derivedProperties = {
		curveRadius: updateCurveRadius(node.curvature),
		interNodeDist : getInternodedist(),
	}

	function getInternodedist() {
		if (node.interNodeDist === 0 && !node.previousNodeId) {
			return "-";
		}
		return node.interNodeDist.toFixed(2);
	}

	return (
		<div className="sidebar-popout">
			<div className="additional-properties-input">
				<div className="section-header">
					<h6>Advanced Node Properties</h6>
					<Button
						className="close-button no-background"
						icon={{ icon: 'cross', iconPos: 'icon-bottom' }}
						colors={Colors.White}
						display={Display.Text}
						onClick={hidePanel}
					/>
				</div>
				<div className="section-divider" />
				<InputField model={_derivedProperties} label="Curve Radius" modelProperty="curveRadius" propertyUnit="m" isNumber isReadOnly />
				<InputField model={_derivedProperties} label="Distance to Previous" modelProperty="interNodeDist" propertyUnit="m" isNumber isReadOnly />
			</div>
		</div>
	);
}
