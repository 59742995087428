export interface IMapParameters {
	name: string;
	description: string;
	value?: number | string | undefined;
    unit: string;
}

export const AHTParameters: IMapParameters[] = [
	{
		name: "TruckLength",
		description: "AHT length",
		unit: "m",
	},
	{
		name: "TruckWidth",
		description: "AHT width",
		unit: "m",
	},
	{
		name: "TruckOffset",
		description: "Offset from the centre of the AHT to the centre of the rear axle",
		unit: "m",
	},
	{
		name: "AhtMaxLength",
		description: "Maximum AHT length across all AHT models",
		unit: "m",
	},
	{
		name: "AhtMaxWidth",
		description: "Maximum AHT width across all AHT models",
		unit: "m"
	},
	{
		name: "AhtMaxOffset",
		description: "Maximum offset from the centre of the AHT to the centre of the rear axle across all AHT models",
		unit: "m"
	},
	{
		name: "AhtMaxRearBodyWidth",
		description: "Maximum rear body width across all AHT models",
		unit: "m"
	},
	{
		name: "TruckFrontMargin",
		description: "AHT front margin",
		unit: "m"
	},
	{
		name: "TruckRearMargin",
		description: "AHT rear margin",
		unit: "m"
	},
	{
		name: "TruckWidthMargin",
		description: "AHT side margin",
		unit: "m"
	},
	{
		name: "AHTDeviationBufferWidth",
		description: "AHT deviation buffer",
		unit: "m"
	},
	{
		name: "AHTFollowingError",
		description: "AHT following error",
		unit: "m"
	},
	{
		name: "GNSSAccuracyError",
		description: "AHT GNSS accuracy error",
		unit: "m"
	},
	{
		name: "AHTCloseLimitWidth",
		description: "AHT close limit",
		unit: "m"
	},
	{
		name: "AHTCloseLimitWidthDynamic",
		description: "AHT close limit (dynamic)",
		unit: "m"
	},
	{
		name: "WheelBase",
		description: "AHT wheelbase",
		unit: "m"
	},
	{
		name: "MinRadius",
		description: "AHT minimum turning radius",
		unit: "m"
	},
	{
		name: "SideAccelGravityThr",
		description: "AHT maximum lateral acceleration",
		unit: "G"
	},
	{
		name: "UnloadingAngularVelocity",
		description: "AHT maximum angular velocity when empty",
		unit: "°/sec"
	},
	{
		name: "LoadingAngularVelocity",
		description: "AHT maximum angular velocity when loaded",
		unit: "°/sec"
	}
];

export const SpeedParameters: IMapParameters[] = [
	{
		name: "MaxSpeedEmpty",
		description: "AHT maximum forward speed allowed when empty",
		unit: "km/h",
	},
	{
		name: "MaxSpeedLoaded",
		description: "AHT maximum forward speed allowed when loaded",
		unit: "km/h",
	},
	{
		name: "MinSpeedEmpty",
		description: "AHT minimum forward/reverse speed allowed when empty",
		unit: "km/h",
	},
	{
		name: "MinSpeedLoaded",
		description: "AHT minimum forward/reverse speed allowed when loaded",
		unit: "km/h",
	},
	{
		name: "ForwardSpeed",
		description: "AHT maximum forward speed for path creation",
		unit: "km/h",
	},
	{
		name: "BackwardSpeed",
		description: "AHT maximum reverse speed for path creation",
		unit: "km/h",
	},
	{
		name: "MaxSpeedBackward",
		description: "AHT maximum reverse speed allowed",
		unit: "km/h",
	},
];

export const GeneralPathParameters: IMapParameters[] = [
	{
		name: "BackStartLineMargin",
		description: "Back start line margin",
		unit: "m"
	},
	{
		name: "BackEndLineMargin",
		description: "Back end line margin",
		unit: "m"
	},
	{
		name: "ForwardStartLineMargin",
		description: "Forward start line margin",
		unit: "m"
	},
	{
		name: "ForwardEndLineMargin",
		description: "Forward end line margin",
		unit: "m"
	},
	{
		name: "TurnbackPathAngle",
		description: "Turnback path angle",
		unit: "°"
	},
	{
		name: "ViaMarginA",
		description: "Via margin A",
		unit: "m"
	},
	{
		name: "ViaMarginB",
		description: "Via margin B",
		unit: "m"
	},
	{
		name: "OverHangMargin",
		description: "Overhang margin",
		unit: "m"
	},
	{
		name: "NonOverHangMarginDistance",
		description: "Non overhang margin",
		unit: "m"
	},
	{
		name: "ReverseAddMargin",
		description: "Reverse add line margin",
		unit: "m"
	},
	{
		name: "StraightThresholdCurvature",
		description: "Straight threshold curvature",
		unit: "m-1"
	},
	{
		name: "CurveMarginContinuationDistance",
		description: "Curve continuation distance margin",
		unit: "m"
	},
	{
		name: "VicinitySublinkDistance",
		description: "Vicinity sublink distance",
		unit: "m"
	},
	{
		name: "EnterDistance",
		description: "Enter distance",
		unit: "m"
	},
	{
		name: "ExitDistance",
		description: "Exit distance",
		unit: "m"
	},
	{
		name: "NodeIDMax",
		description: "Maximum node ID allowed",
		unit: "-"
	},
	{
		name: "NodeDistMin",
		description: "Minimum inter-node distance allowed",
		unit: "m"
	},
	{
		name: "NodeDistMax",
		description: "Maximum inter-node distance allowed",
		unit: "m"
	},
	{
		name: "MaxUphillGradient",
		description: "Maximum uphill gradient allowed",
		unit: "°"
	},
	{
		name: "MaxDownhillGradient",
		description: "Maximum downhill gradient allowed",
		unit: "°"
	},
	{
		name: "NodeLengthThr",
		description: "Node length threshold",
		unit: "m"
	},
	{
		name: "SublinkLengthThr",
		description: "Sublink length threshold",
		unit: "m"
	},
	{
		name: "CurvatureDefault",
		description: "Default curvature",
		unit: "m-1"
	},
	{
		name: "GradientDefault",
		description: "Default gradient",
		unit: "° * 10"
	},
	{
		name: "BermRightDefault",
		description: "Default berm right",
		unit: "dm"
	},
	{
		name: "BermLeftDefault",
		description: "Default berm left",
		unit: "dm"
	},
	{
		name: "MinRadius",
		description: "Minimum turning radius",
		unit: "m"
	},
	{
		name: "MaxRadius",
		description: "Maximum turning radius",
		unit: "m"
	}
];

export const StaticPathParameters: IMapParameters[] = [
	{
		name: "StaticLinkIDMax",
		description: "Maximum static path link ID allowed",
		unit: "-"
	},
	{
		name: "StaticPathLinkMax",
		description: "Maximum number of static path links allowed",
		unit: "-"
	},
	{
		name: "StaticPathSublinkMax",
		description: "Maximum number of static path sublinks allowed",
		unit: "-"
	},
	{
		name: "StaticPathNodeMax",
		description: "Maximum number of static path nodes allowed",
		unit: "-"
	},
	{
		name: "StaticPathInlinkSublinkMax",
		description: "Maximum number of sublinks allowed within a link",
		unit: "-"
	},
	{
		name: "StaticPathInSublinkNodeMax",
		description: "Maximum number of nodes allowed within a sublink",
		unit: "-"
	},
	{
		name: "RouteStaticPathLinkMax",
		description: "Maximum number of links allowed within a route",
		unit: "-"
	},
	{
		name: "RouteStaticPathSublinkMax",
		description: "Maximum number of sublinks allowed within a route",
		unit: "-"
	},
	{
		name: "RouteStaticPathNodeMax",
		description: "Maximum number of nodes allowed within a route",
		unit: "-"
	}
];

export const DynamicPathParameters: IMapParameters[] = [
	{
		name: "DynamicPathLinkMax",
		description: "Maximum number of dynamic path links allowed",
		unit: "-"
	},
	{
		name: "DynamicPathSublinkMax",
		description: "Maximum number of dynamic path sublinks allowed",
		unit: "-"
	},
	{
		name: "DynamicPathNodeMax",
		description: "Maximum number of dynamic path nodes allowed",
		unit: "-"
	},
	{
		name: "RouteDynamicPathLinkToBayMax",
		description: "Maximum number of links allowed for a dynamic entry path",
		unit: "-"
	},
	{
		name: "RouteDynamicPathLinkToExitMax",
		description: "Maximum number of links allowed for a dynamic exit path",
		unit: "-"
	},
	{
		name: "RouteDynamicPathSublinkToBayMax",
		description: "Maximum number of sublinks allowed for a dynamic entry path",
		unit: "-"
	},
	{
		name: "RouteDynamicPathSublinkToExitMax",
		description: "Maximum number of sublinks allowed for a dynamic exit path",
		unit: "-"
	},
	{
		name: "RouteDynamicPathNodeToBayMax",
		description: "Maximum number of nodes allowed for a dynamic entry path",
		unit: "-"
	},
	{
		name: "RouteDynamicPathNodeToExitMax",
		description: "Maximum number of nodes allowed for a dynamic exit path",
		unit: "-"
	},
	{
		name: "ReverseSearchDegStep",
		description: "Reverse search degree step",
		unit: "°"
	},
	{
		name: "PriorityLoadingReversePoint",
		description: "Reverse point priority check [infinity bay]",
		unit: "-"
	},
	{
		name: "PriorityLoadingGoingPathMoveTime",
		description: "Going path travel time priority check [infinity bay]",
		unit: "-"
	},
	{
		name: "PriorityLoadingBackPathMoveTime",
		description: "Return path travel time priority check (short) [infinity bay]",
		unit: "-"
	},
	{
		name: "PriorityLoadingReverseRange",
		description: "Priority loading reverse range [infinity bay]",
		unit: "-"
	},
	{
		name: "PriorityLoadingBackPathMoveTimeLonger",
		description: "Return path travel time priority check (long) [infinity bay]",
		unit: "-"
	},
	{
		name: "PriorityLoadingBackPathMoveTimeOnetime",
		description: "Return path travel time priority check (short) [single AHT bay]",
		unit: "-"
	},
	{
		name: "PriorityLoadingGoingPathMoveTimeOnetime",
		description: "Going path travel time priority check [single AHT bay]",
		unit: "-"
	},
	{
		name: "PriorityLoadingReversePointOnetime",
		description: "Reverse point priority check [single AHT bay]",
		unit: "-"
	},
	{
		name: "PriorityLoadingReverseRangeOnetime",
		description: "Priority loading reverse range [single AHT bay]",
		unit: "-"
	},
	{
		name: "PriorityLoadingBackPathMoveTimeLongerOnetime",
		description: "Return path travel time priority check (long) [single AHT bay]",
		unit: "-"
	},
	{
		name: "SpotLimitDistance",
		description: "Spot limit distance",
		unit: "m"
	},
	{
		name: "SpotLimitStepDistance",
		description: "Spot limit step distance",
		unit: "m"
	}
];

export const ErrorsWarningsParameters: IMapParameters[] = [
	{
		name: "MinRadius",
		description: "Minimum turning radius for “curvature” error (CT)",
		unit: "m"
	},
	{
		name: "NodeDistMin",
		description: "Minimum inter-node distance for “node distance too short” error (NDS)",
		unit: "m"
	},
	{
		name: "NodeDistMax",
		description: "Maximum inter-node distance for “node distance too long” error (NDL)",
		unit: "m"
	},
	{
		name: "BackStartLineMargin",
		description: "Back start line margin for “turnback path straight distance” error (TSD)",
		unit: "m"
	},
	{
		name: "BackEndLineMargin",
		description: "Back end line margin for “turnback path straight distance” error (TSD)",
		unit: "m"
	},
	{
		name: "ForwardStartLineMargin",
		description: "Forward start line margin for “turnback path straight distance” error (TSD)",
		unit: "m"
	},
	{
		name: "ForwardEndLineMargin",
		description: "Forward end line margin for “turnback path straight distance” error (TSD)",
		unit: "m"
	},
	{
		name: "TurnbackPathAngle",
		description: "Maximum turnback path angle for “turnback path straight distance” error (TSD)",
		unit: "°"
	},
	{
		name: "MaxSpeedEmpty",
		description: "Maximum forward speed for “over speed” error (OS)",
		unit: "km/h"
	},
	{
		name: "MinSpeedEmpty",
		description: "Minimum forward/reverse speed for “less than speed” error (LS)",
		unit: "km/h"
	},
	{
		name: "MaxSpeedBackward",
		description: "Maximum reverse speed for “over speed” error (OS)",
		unit: "km/h"
	},
	{
		name: "StaticPathInlinkSublinkMax",
		description: "Maximum number of sublinks allowed within a link for “exceeded maximum number of sublinks per link” error (ESL)",
		unit: "-"
	},
	{
		name: "StaticPathInSublinkNodeMax",
		description: "Maximum number of nodes allowed within a sublink for “exceeded maximum number of nodes per sublink” error (ENS)",
		unit: "-"
	},
	{
		name: "BermRightError",
		description: "Berm right distance error",
		unit: "m"
	},
	{
		name: "BermLeftError",
		description: "Berm left distance error",
		unit: "m"
	}
];

export const DrivableAreaBoundaryParameters: IMapParameters[] = [
	{
		name: "Is_AllNodesInBoundary",
		description: "Node within boundary check",
		unit: "-"
	},
	{
		name: "CheckSpotInZone",
		description: "Spot within boundary check",
		unit: "-"
	},
	{
		name: "CheckTruckInZone",
		description: "AHT within boundary check",
		unit: "-"
	},
	{
		name: "CheckFinalPathInZone",
		description: "Path within boundary check",
		unit: "-"
	}
];

export const DrivingPermissionParameters: IMapParameters[] = [
	{
		name: "Permission_Reset_Margin_Forward",
		description: "Permission reset length forward",
		unit: "m"
	},
	{
		name: "Permission_Reset_Margin_Backward",
		description: "Permission reset length backward",
		unit: "m"
	},
	{
		name: "Permission_Judge_Margin",
		description: "Permission judge margin",
		unit: "m"
	},
	{
		name: "CheckBlockControl",
		description: "Block control check",
		unit: "-"
	}
];

export const DumpParameters: IMapParameters[] = [
	{
		name: "BayLength",
		description: "Bay length",
		unit: "m"
	},
	{
		name: "BayWidth",
		description: "Bay width",
		unit: "m"
	},
	{
		name: "BayToBay",
		description: "Minimum inter-bay distance",
		unit: "m"
	},
	{
		name: "DumpPileLength",
		description: "Dump pile length",
		unit: "m"
	},
	{
		name: "DumpPileWidth",
		description: "Dump pile width",
		unit: "m"
	},
	{
		name: "DumpPileOffset",
		description: "Dump pile offset",
		unit: "m"
	},
	{
		name: "OteOffset",
		description: "OTE dumping node offset",
		unit: "m"
	},
	{
		name: "CrusherOffset",
		description: "Crusher dumping node offset",
		unit: "m"
	},
	{
		name: "OteSpillageMyselfWidth",
		description: "OTE spillage myself width",
		unit: "m"
	},
	{
		name: "OteSpillageOtherLength",
		description: "OTE spillage other length",
		unit: "m"
	},
	{
		name: "OteSpillageOtherWidth",
		description: "OTE spillage other width",
		unit: "m"
	},
	{
		name: "OteSpillageOtherFrontOffset",
		description: "OTE spillage other front offset",
		unit: "m"
	}
];

export const AHSAreaParameters: IMapParameters[] = [
	{
		name: "AHSAreaStartingPointX",
		description: "AHS Area bottom left corner Easting coordinate",
		unit: "m"
	},
	{
		name: "AHSAreaStartingPointY",
		description: "AHS Area bottom left corner Northing coordinate",
		unit: "m"
	},
	{
		name: "AHSAreaWidthMax",
		description: "AHS Area width along the Easting axis",
		unit: "m"
	},
	{
		name: "AHSAreaLengthMax",
		description: "AHS Area length along the Northing axis",
		unit: "m"
	}
];

export const AreaExtensionParameters: IMapParameters[] = [
	{
		name: "AreaExtensionLength",
		description: "Area extension length",
		unit: "m"
	},
	{
		name: "AreaExtensionWidth",
		description: "Area extension width",
		unit: "m"
	},
	{
		name: "AreaExtensionBack",
		description: "Area extension back",
		unit: "m"
	},
	{
		name: "AreaExtensionLengthLimit",
		description: "Area extension length limit",
		unit: "m"
	},
	{
		name: "AreaExpansionAHTPolygonFrontBuffer",
		description: "Area extension: length of front protect zone of the vehicle",
		unit: "m"
	},
	{
		name: "AreaExpansionAHTPolygonFrontMargin",
		description: "Area extension: front width margin of the vehicle",
		unit: "m"
	},
	{
		name: "AreaExpansionAHTPolygonRearMargin",
		description: "Area extension: rear width margin of the vehicle",
		unit: "m"
	},
	{
		name: "AreaExpansionAHTPolygonFrontLength",
		description: "Area extension: length of protection of the vehicle front",
		unit: "m"
	},
	{
		name: "AreaExpansionAHTPolygonRearLength",
		description: "Area extension: length of protection of the vehicle rear",
		unit: "m"
	},
	{
		name: "AreaExpansionBermMargin",
		description: "Area extension: berm length margin",
		unit: "m"
	}
];

export const FMSMapParameters: IMapParameters[] = [
	{
		name: "FMSMapSegmentMax",
		description: "Maximum number of segments allowed for the FMS map",
		unit: "-"
	},
	{
		name: "FMSMapRootBeaconMax",
		description: "Maximum number of route beacons allowed for the FMS map",
		unit: "-"
	},
	{
		name: "FMSMapRootBeaconAccessMax",
		description: "Maximum number of access route beacons allowed for the FMS map",
		unit: "-"
	}
];

export const MapDisplayCoordsParameters: IMapParameters[] = [
	{
		name: "CoordParam_A",
		description: "Coordinates parameter A",
		unit: "-"
	},
	{
		name: "CoordParam_B",
		description: "Coordinates parameter B",
		unit: "-"
	},
	{
		name: "CoordParam_C",
		description: "Coordinates parameter C",
		unit: "-"
	},
	{
		name: "CoordParam_D",
		description: "Coordinates parameter D",
		unit: "-"
	},
	{
		name: "CoordParam_E",
		description: "Coordinates parameter E",
		unit: "-"
	},
	{
		name: "CoordParam_F",
		description: "Coordinates parameter F",
		unit: "-"
	}
];

export const MIVParameters: IMapParameters[] = [
	{
		name: "MIVCloseJudgeWidth",
		description: "MIV close judge width",
		unit: "m"
	},
	{
		name: "MIVCloseJudgeWidthDynamic",
		description: "MIV close judge width (dynamic)",
		unit: "m"
	}
];

export const UncategorisedParameters: IMapParameters[] = [];
