import { MapObjectType } from './../Map/MapObjects/MapObject';
import { IMenuShownStatus, initialMenuShownStatus } from '../EditMap';
import MapController, { MapType } from '../Map/MapController';
import { ILayersPanelItem } from './LayersPanelItem';
import NodeGraphic from '../Map/MapObjects/Node/NodeGraphic';
import DynamicScaleObjectHelper from '../Map/MapStateHandlerHelpers/DynamicScaleObjectHelper';
import Signal from "../Map/MapObjects/Signal/Signal";

/**
 * Sets the display of the object on the map (and any children objects for the objectItem) and rerenders the map
 *
 * @param objectItem the item to hide (including children)
 * @param map controller, which has the renderer and lookup table
 * @param displayGraphic whether to display or hide the object
 */
export default function setObjectDisplayAndRerender(
	objectItem: ILayersPanelItem,
	map: MapController | undefined,
	displayGraphic: boolean,
	rerender: boolean = true,
	isViewMenuShown?: IMenuShownStatus,
	toggleFromViewMenu: boolean = false,
	type?: MapObjectType,
) {
	if (!map || (!objectItem.mapObjectType && !objectItem.entityId)) return;

	setObjectDisplay(objectItem, map, displayGraphic, isViewMenuShown ?? initialMenuShownStatus,
		toggleFromViewMenu, type);

	if (rerender) {
		const renderer = map.getMapRenderer();
		renderer.rerender();
		// Update dynamically scaled graphics
		DynamicScaleObjectHelper.updateDynamicObjectDisplay(map);
		// Apply show/hide label as per user choice in view menu view menu
		renderer.updateMapLabels();
	}
}

/**
 * Set the display of an object without re-rendering the map
 *
 * @param objectItem the item to hide (including children)
 * @param map controller, which has the renderer and lookup table
 * @param displayGraphic whether to display or hide the object
 */
function setObjectDisplay(
	objectItem: ILayersPanelItem,
	map: MapController,
	displayGraphic: boolean,
	isViewMenuShown: IMenuShownStatus,
	toggleFromViewMenu: boolean,
	type?: MapType | 'ahs' | 'fms',
) {
	// TODO: refactor
	if (!objectItem.mapObjectType && !objectItem.entityId) return;
	const renderer = map.getMapRenderer();

	// If toggel from layers panel, set isDisplayed
	if (!toggleFromViewMenu) {
		objectItem.isDisplayed = displayGraphic;
	}
	
	if (!!objectItem.entityId) {
		const mapObjectId = map.getMapLookup().getMapObjectId(objectItem.entityId, objectItem.mapObjectType ?? '');
		const mapObject = renderer.getObjectById(mapObjectId);
		if (mapObject !== undefined) { // mapObject is undefined for the undefined bays
			if (toggleFromViewMenu) { // from view menu
				// TODO: refactor. If using the global isViewMenuShown in MapStore, the status should've updated
				// Currently, isViewMenuShown is from the state of the LayersPanel and it hasn't been updated.
				if (isViewMenuShown[type!]) { // type's menu status: false -> true (show)
					// If view menu status is ticked, following the visibility in the layers panel
					if (objectItem.mapObjectType === type) {
						mapObject.displayGraphic(objectItem.isDisplayed ?? true);
					}
				} else { // type's menu status: true -> false (hide)
					// If view menu status is not ticked, don't care about the visibility in the layers panel
					if (objectItem.mapObjectType === type) {
						if (type === 'node') {
							// start and end nodes are always shown even if node view menu status is hidden
							// However, if start and end nodes are set to hidden in the Map objects panel, they are hidden
							const isStartOrEnd = (objectItem.isDisplayed ?? true) && (mapObject as NodeGraphic).isStartOrEnd();
							mapObject.displayGraphic(isStartOrEnd, !toggleFromViewMenu, type, isViewMenuShown);
						} 
						if (type === 'sublink') {
							mapObject.displayGraphic(false, !toggleFromViewMenu, type, isViewMenuShown);
						}
					}
				}
			} else { // from layers panel
				if (objectItem.mapObjectType === 'node') {
					mapObject.displayGraphic(displayGraphic, !toggleFromViewMenu, (mapObject as NodeGraphic).isStartOrEnd() ? "startOrEndNode" : objectItem.mapObjectType, isViewMenuShown);
				}
				else {
					mapObject.displayGraphic(displayGraphic, !toggleFromViewMenu, objectItem.mapObjectType, isViewMenuShown);

					// Set the signal status when show/hide link
					if (objectItem.mapObjectType === 'link' && !!objectItem.entityId) {
						const signalSetEntity = map.getMapLookup().getSignalSetByLinkId(objectItem.entityId);
						if (!!signalSetEntity) {
							const signalSetMapObject = map.getMapLookup().getMapObjectByEntity(signalSetEntity, 'signal') as Signal;
							if (!!signalSetMapObject) {
								// When a Link status in the map objects panel is shown and selected
								// Or when a Link status in the map objects panel is shown and signal view menu is shown
								if ((displayGraphic && (mapObject.isHighlighted ?? false)) ||
									(displayGraphic && isViewMenuShown.signalSet)) {
									signalSetMapObject.displayGraphic(true);
								}
								else {
									signalSetMapObject.displayGraphic(false);
								}	
							}
						}
					}	
				}
			}
		}
	}

	

	objectItem.children?.forEach((child: ILayersPanelItem) => {
		// TODO: refactor
		setObjectDisplay(child, map, displayGraphic, isViewMenuShown, toggleFromViewMenu, type ?? objectItem.mapObjectType);
	});
}
