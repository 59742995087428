import React from 'react';
import { AreaEntity } from '../../../Models/Entities';
import MapController from '../Map/MapController';
import { locationTypeOptions } from 'Models/Enums';
import CollapsibleProperty from '../CollapsibleProperty';
import {RenderInformationCombobox} from "./PropertiesPanelComponents/RenderInformationCombobox";
import InputField from "./PropertiesPanelComponents/InputField";

export default function LocationProperties({ entity } : { entity: AreaEntity, map?: MapController }) {
	const area = entity;

	const locationTypeOptionsCombobox = Object
		.entries(locationTypeOptions)
		.map(([value, display]) => ({ display, value }));

	return (
		<>
			<h6>Location Identification</h6>
			<InputField model={area} label="Name" modelProperty="areaName" isReadOnly />

			<div className="section-divider" />

			<CollapsibleProperty propertyTitle="Location Properties" displayProperty>
				<RenderInformationCombobox
					model={area}
					label="Type"
					modelProperty="locType"
					options={locationTypeOptionsCombobox}
					readonly
				/>
			</CollapsibleProperty>

			<div className="section-divider" />
		</>
	);
}
