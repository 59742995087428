import { NodeEntity } from "Models/Entities";
import { IPointData } from "pixi.js";
import MapEventHandler from "../MapEventHandler";
import { PixiCoordinates, realWorldCoordinates, RealWorldCoordinates } from "./Coordinates";

export function calcDistanceBetweenNodes(a: NodeEntity, b: NodeEntity) {
	return calcDistanceBetweenCoords(a.northing, a.easting, b.northing, b.easting);
}

export function calcDistanceBetweenCoords(x1: number, y1: number, x2: number, y2: number) {
	return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
}

/**
 * Calculate offset that would need to be added to travel "distance" along "headingDegrees" in projected coordinates
 * @param distance
 * @param headingDegrees
 * @returns
 */
export function offsetAlongHeadingPixi(distance: number, headingDegrees: number): IPointData {
	const { x, y } = _offsetAlongHeading(distance, headingDegrees);
	return { x: x, y: -y };
}

/**
 * Calculate offset that would need to be added to travel "distance" along "headingDegrees" in RealWorldCoordinates
 * @param distance
 * @param headingDegrees
 * @returns
 */
export function offsetAlongHeadingRealWorld(distance: number, headingDegrees: number): RealWorldCoordinates {
	const { x, y } = _offsetAlongHeading(distance, headingDegrees);
	return realWorldCoordinates(y, x);
}

export function getOffsetAlongHeading(coords: RealWorldCoordinates, distance: number, heading: number): RealWorldCoordinates {
	const coordsDiff = offsetAlongHeadingRealWorld(distance, heading);

	return {
		northing: coords.northing + coordsDiff.northing,
		easting: coords.easting + coordsDiff.easting,
	}
}

/**
 * Calculates angle between fromPoint and toPoint
 * e.g. angle between center of node and mouse pointer location
 * TODO: refactor as this has same functionality as getAngleBetweenPoints
 * @param fromPoint
 * @param toPoint
 * @param isReverse use 180 degrees offset (for dragging back of heading graphic)
 * @returns
 */
export function calcHeading(
	fromPoint: RealWorldCoordinates,
	toPoint: RealWorldCoordinates,
	isReverse?: boolean,
): number {
	const dx = toPoint.easting - fromPoint.easting;
	const dy = -(toPoint.northing - fromPoint.northing);
	const offset = !!isReverse ? 180 : 0;
	const angle = ((180 - Math.atan2(dx, dy) * (180 / Math.PI)) + offset) % 360;

	return angle;
}

export function calcHeadingPixi(
	fromPoint: PixiCoordinates,
	toPoint: PixiCoordinates,
	isReverse?: boolean,
): number {
	const dx = toPoint.x - fromPoint.x;
	const dy = -(toPoint.y - fromPoint.y);
	const offset = !!isReverse ? 180 : 0;
	const angle = ((180 - Math.atan2(dx, dy) * (180 / Math.PI)) + offset) % 360;

	return angle;
}

function _offsetAlongHeading(distance: number, headingDegrees: number): { x: number, y: number} {
	const x = distance * Math.sin(degToRad(headingDegrees));
	const y = distance * Math.cos(degToRad(headingDegrees));
	return { x: x, y: y };
}

function degToRad(degrees: number): number {
	return degrees * (Math.PI / 180);
}

export function setCustomTag(tagKey: string, tagValue: string | string[]) {
	if (!isJestTest()) {
		//@ts-ignore
		window.clarity('set', tagKey, tagValue);
	}
}

export function isJestTest() {
	return process.env.JEST_WORKER_ID !== undefined;
}

// used for debug to keep track of caller fn (e.g. used in cases of 'what is calling x method?')
export const trc_caller = (s: string, extraInfo?: string) => {
	let calledBy = 'unknown';
	const extra = !!extraInfo ? `(${extraInfo})` : '';
	try {
		let stack = (new Error().stack)?.split('\n');
		if (!stack)
			return;
		calledBy = stack[2].trim().split(' ')[1];
		const originalCaller =  stack[3].trim().split(' ')[1];
		// console.log(`${originalCaller} => ${calledBy}`);
		console.log(`${calledBy}${extra}: ${s} [${originalCaller}]`);
	} catch(e) {
		console.log(`${calledBy}${extra}: ${s}`);
	}
};
export const trc_disable = (s: string, extraInfo?: string) => {};
