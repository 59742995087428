import React, { useEffect, useState } from 'react';
import MapController from '../Map/MapController';
import { RealWorldCoordinates } from '../Map/Helpers/Coordinates';
import { calcDistanceBetweenCoords } from '../Map/Helpers/MapUtils';
import InputField from "./PropertiesPanelComponents/InputField";

interface DerivedProperties {
	totalLength: string,
}

/**
 * Render properties side panel for measuring tool.
 * Ruler properties panel can be used to view measurement information
 * @param props
 * - entity: RealWorldCoordinates[]
 * @constructor
 */
export default function RulerProperties({ entity, map } : { entity: RealWorldCoordinates[], map: MapController }) {
	let rulerNodes = entity;
	const initialState = {
		totalLength: '0',
	};
	const [derivedProperties, setDerivedProperties] = useState<DerivedProperties>(initialState);

	useEffect(() => {
		if (map) {
			map.getEventHandler().addListener('onUpdateMeasuredLength', onUpdateMeasuredLength);
		}
		return () => {
			if (map) {
				map.getEventHandler().removeListener('onUpdateMeasuredLength', onUpdateMeasuredLength);
			}
		};
	}, []);

	// For updating totalLength when map state goes to ruler from edit_ruler
	useEffect(() => {
		setDerivedProperties({
			totalLength: calculateTotalLength(),
		});
	}, [entity]);

	const onUpdateMeasuredLength = (realCoordsList: RealWorldCoordinates[]) => {
		rulerNodes = realCoordsList;
		setDerivedProperties({
			totalLength: calculateTotalLength(),
		});
	};

	const calculateTotalLength = () => {
		const totalLength = rulerNodes.reduce((acc, cur, index, arr) => {
			if (index > 0) {
				const prevNode = arr[index - 1];
				const distance = calcDistanceBetweenCoords(prevNode.easting, prevNode.northing, cur.easting, cur.northing);
				return acc + distance;
			}
			return acc;
		}, 0);
		return totalLength.toFixed(2);
	}

	return (
		<>
			<h6>Measure Distance</h6>
			<InputField model={derivedProperties} label="Total" modelProperty="totalLength" propertyUnit="m" isReadOnly />
			<div className="section-divider" />
		</>
	);
}
