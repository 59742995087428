import MapObject from '../MapObject';
import MapRenderer from '../../MapRenderer';
import * as PIXI from 'pixi.js';
import { PixiCoordinates } from '../../Helpers/Coordinates';
import { calcDistanceBetweenCoords } from '../../Helpers/MapUtils';
import { RULER_SCALE_THRESHOLD } from 'Constants';

enum graphicTextIndex {
	OpenPolygon = 0,
	Nodes = 1,
	DynamicLine = 2,
	DynamicText = 3,
	DynamicTextBG = 4,
}

const RULER_NODE_STROKE_COLOR = 0x000000;
const RULER_NODE_FILL_COLOUR = 0xFFFFFF;
const RULER_NODE_FILL_OPACITY = 1;
const RULER_NODE_RADIUS = 3;

const RULER_LINE_WIDTH = 1.5;
const RULER_LINE_COLOUR = 0xFFFFFF;

const RULER_TEXT_BACKGROUND_COLOUR = 0xFFFFFF;
const RULER_TEXT_BACKGROUND_OPACITY = 0.85;
const RULER_TEXT_BACKGROUND_BORDER_RADIUS = 3;
const RULER_TEXT_WIDTH = 18;
const RULER_TEXT_HEIGHT = 12;
const RULER_TEXT_DIGIT_WIDTH_DELTA = 4;
const RULER_TEXT_UNIT = "m";

const TEXT_SCALE_FACTOR = 18;
const TEXT_BACKGROUND_SCALE_FACTOR = 3;
const POLYGON_NODE_SCALE_FACTOR_1 = 2.8;
const POLYGON_NODE_SCALE_FACTOR_2 = 2.5;

// Sits above all other AHS objects
export default class Ruler extends MapObject<PixiCoordinates[]> {
	private points: PIXI.Point[] = [];
	private lengthTexts: PIXI.Text[] = [];
	private lengthTextBGs: PIXI.Graphics[] = [];
	private straightLineId: number | undefined;
	private textAndBackgoundId: number | undefined;
	private parentContainer: PIXI.Container;

	private polyNodeScale = 1;
	private textScale = 1 / TEXT_SCALE_FACTOR;
	private textBGScale = 1;

	constructor(point: PixiCoordinates[], renderer: MapRenderer) {
		super(renderer, 'ruler', point);

		this.createGraphic(); // ruler open polygon
		this.createGraphic(); // ruler nodes
		this.createGraphic(); // dynamic line grahic (between the last node and the mouse cursor location)
		this.createText("0.00 m");  // dynamic text
		this.createGraphic(); // dynamic text background

		this.parentContainer = renderer.getContainer('ruler');

		// Set initial scale
		const isUpdateInitialScale = renderer.isUpdateDynamicScaleObjects(RULER_SCALE_THRESHOLD);
		if (isUpdateInitialScale) {
			this.setScales(false);
		}
	}

	public addPoint(pixiCoords: PixiCoordinates) {
		this.addPointToEntity(pixiCoords);
		const point = new PIXI.Point(pixiCoords.x, pixiCoords.y);
		this.points.push(point);
	}

	private addPointToEntity(pixiCoords: PixiCoordinates) {
		this.getEntity().push(pixiCoords);
	}

	public getPoints() {
		return this.points;
	}

	public deletePreviousPoint(): boolean {
		if (this.points.length !== this.getEntity().length) {
			console.error("This shouldn't happen.");
			return false;
		}
		if (this.points.length === 0 && this.getEntity().length === 0) {
			console.error("Can't delete point from empty array.");
			return false;
		}

		this.points = this.points.slice(0, this.points.length - 1);
		this.getEntity().pop();
		return true;
	}

	public addLengthTextAndBackground(distance: string) {
		const lengthText = this.createText(distance + " " + RULER_TEXT_UNIT);
		this.parentContainer.addChild(lengthText); // add child to ruler container after setting first node
		this.lengthTexts.push(lengthText);

		const lengthTextBG = this.createGraphic(); // create graphic for text background if doesn't exist
		this.parentContainer.addChild(lengthTextBG); // add child to ruler container after setting first node
		this.lengthTextBGs.push(lengthTextBG);
	}

	public removePreviousLengthTextAndBackground(): boolean {
		if (this.lengthTexts.length !== this.lengthTextBGs.length) {
			console.error("This shouldn't happen.");
			return false;
		}
		if (this.lengthTexts.length === 0) {
			console.error("Can't delete length text from empty array.");
			return false;
		}
		if (this.lengthTextBGs.length === 0) {
			console.error("Can't delete length text background graphic from empty array.");
			return false;
		}

		const index = this.lengthTexts.length - 1;
		// Remove text and graphic from parent container 'ruler' and from this.containers property
		this.parentContainer.removeChild(this.lengthTexts[index]);
		this.parentContainer.removeChild(this.lengthTextBGs[index]);
		this.containers.pop(); // remove text background
		this.containers.pop(); // remove text
		
		this.lengthTexts = this.lengthTexts.slice(0, index);
		this.lengthTextBGs = this.lengthTextBGs.slice(0, index);
		return true;
	}

	/**
	 * Renders the line graphic
	 * It must be called after startLine and has its
	 * position updated according to the mouse position
	 * @param end
	 */
	private _renderLine(end: PixiCoordinates) {
		// dynamic line grahic
		const graphic = this.getGraphic(graphicTextIndex.DynamicLine);
		graphic.clear();
		graphic.zIndex = this.zIndexTop + 1;
		const from = this.points[this.points.length - 1];
		const to = end;

		graphic.lineStyle(RULER_LINE_WIDTH * this.polyNodeScale, RULER_LINE_COLOUR)
			.moveTo(from.x, from.y)
			.lineTo(to.x, to.y);
	}

	/**
	 * Initialises this positioning of the dynamic line grahic (between the last node and the mouse cursor location)
	 * and calls its rendering method
	 */
	public startLine() {
		if (this.straightLineId === undefined) {
			const renderLine = () => {
				this.isAnimating('line');
				this._renderLine(this.renderer.project(this.renderer.mousePosition));
				this.renderer.rerender();
				this.straightLineId = requestAnimationFrame(renderLine);
			};

			this.straightLineId = requestAnimationFrame(renderLine);
		} else {
			this.clearLine();
			this.startLine();
		}
	}

	/**
	 * Removes and clears the dynamic line
	 */
	public clearLine() {
		const graphic = this.getGraphic(graphicTextIndex.DynamicLine);
		graphic.clear();
		if (this.straightLineId) {
			cancelAnimationFrame(this.straightLineId);
			this.straightLineId = undefined;
			this.renderer.rerender();
		} else {
			console.warn('clearLine no id found');
		}
	}

	/**
	 * Renders the dynamic text and its background
	 * It must be called after startTextAndBackground and has its
	 * position updated according to the mouse position
	 * @param end
	 */
	 private _renderTextAndBackground(distance: string, end: PixiCoordinates) {
		const from = this.points[this.points.length - 1];
		const to = end;
		const position = this.getMiddlePoint(from, to as PIXI.Point);

		// dynamic text
		const text = this.getText(graphicTextIndex.DynamicText);
		text.scale.set(this.textScale);
		text.text = distance + " " + RULER_TEXT_UNIT;
		text.position.set(position.x, position.y);
		text.anchor.set(0.5);

		// dynamic text background
		const graphicTextBG = this.getGraphic(graphicTextIndex.DynamicTextBG);
		graphicTextBG.scale.set(this.textBGScale);
		const numOfDigits = text.text.replace('.', '').length - 2;
		this.drawTextBackground(graphicTextBG, position, numOfDigits);
	}

	/**
	 * Initialises this positioning of the dynamic text and its background
	 * and calls their rendering method
	 */
	 public startTextAndBackground() {
		if (this.textAndBackgoundId === undefined) {
			const renderTextAndBackground = () => {
				this.isAnimating('textandbg');
				const coords = this.renderer.mousePosition;
				const to = this.renderer.project(coords);
				const prevPoint = this.points[this.points.length - 1];
				const previousNode = this.renderer.getRealWorldCoords(this.renderer.unproject(prevPoint));
				const currentNode = coords;
				const distance = calcDistanceBetweenCoords(previousNode.easting, previousNode.northing, currentNode.easting, currentNode.northing);
				this._renderTextAndBackground(distance.toFixed(2), to);
				this.renderer.rerender();
				this.textAndBackgoundId = requestAnimationFrame(renderTextAndBackground);
			};
			this.textAndBackgoundId = requestAnimationFrame(renderTextAndBackground);
		} else {
			console.log('ignoring dupicate startTextAndBackground');
		}
	}

	/**
	 * Removes and clears the dynamic text and its background
	 */
	public clearTextAndBackground() {
		const text = this.getText(graphicTextIndex.DynamicText);
		text.text = "";
		const graphic = this.getGraphic(graphicTextIndex.DynamicTextBG);
		graphic.clear();
		if (this.textAndBackgoundId) {
			console.log('clearing text and background');
			cancelAnimationFrame(this.textAndBackgoundId);
			this.textAndBackgoundId = undefined;
			this.renderer.rerender();
		} else {
			console.warn('clearTextAndBackground no id found');
		}
	}

	private setScales(isReset: boolean) {
		// To make nodes smaller or equal to the size of the connectivity endpoints
		// Set different scale factors for nodes when the connectivity endpoints
		// start scaling (this.pixiZoom < OBJECT_SCALE_THRESHOLD)
		// and stop scaling (this.pixiZoom >= OBJECT_SCALE_THRESHOLD)
		const isSmallerThanObjectScaleThreshold = this.renderer.isUpdateDynamicScaleObjects();
		const _newGraphicScale = 1 / this.renderer.pixiScale * POLYGON_NODE_SCALE_FACTOR_1;
		// Add Math.max to ensure nodes are not smaller than the area tool node
		const _newGraphicScale2 = Math.max(1 / this.renderer.pixiScale * POLYGON_NODE_SCALE_FACTOR_2, 1);
		const _newGraphicScale3 = isSmallerThanObjectScaleThreshold ? _newGraphicScale : _newGraphicScale2;
		const newGraphicScale = isReset ? 1 : _newGraphicScale3;
		this.polyNodeScale = newGraphicScale;
		const newTextScale = isReset ? ( 1 / TEXT_SCALE_FACTOR ) : 1 / (this.renderer.pixiScale * (TEXT_SCALE_FACTOR / 3));
		this.textScale = newTextScale;
		const newGraphicScale2 = isReset ? 1 : 1 / this.renderer.pixiScale * TEXT_BACKGROUND_SCALE_FACTOR;
		this.textBGScale = newGraphicScale2;
	}

	public _updateScale(isReset: boolean) {
		this.setScales(isReset);

		// The graphics.scale.set() is used to scale the entire graphics object, including all
		// the drawn shapes and lines. It doesn't specifically control the thickness of lines.
		// To change the thickness of a line, redraw it.		
		this.drawPolygon();

		// One container includes multiple nodes. Cannot use the graphics.scale.set().
		// Redraw them.
		this.drawNodes();

		this.lengthTexts.forEach((lengthText, i) => {
			lengthText.scale.set(this.textScale);

			const graphicTextBG = this.lengthTextBGs[i];
			graphicTextBG.scale.set(this.textBGScale);
		});

		this.renderer.pixiCurrentAppliedZoom = this.renderer.pixiZoom;		
	}

	private getMiddlePoint(previousPoint: PIXI.Point, currentPoint: PIXI.Point) {
		const x = (previousPoint.x + currentPoint.x) / 2;
		const y = (previousPoint.y + currentPoint.y) / 2;
		const middlePoint = new PIXI.Point(x, y);
		return middlePoint;
	}

	private drawPolygon() {
		const graphic = this.getGraphic(graphicTextIndex.OpenPolygon);
		graphic.clear();
		graphic.zIndex = this.zIndexTop;
		graphic.lineStyle(RULER_LINE_WIDTH * this.polyNodeScale, RULER_LINE_COLOUR);
		const polygon = new PIXI.Polygon(this.points);
		polygon.closeStroke = false;
		graphic.drawPolygon(polygon);

	}

	private drawNodes() {
		const graphic = this.getGraphic(graphicTextIndex.Nodes);
		graphic.clear();
		graphic.zIndex = this.zIndexTop + 2; // sits above ruler open polygon and the dynamic line

		this.points.forEach(p => {
			graphic.lineStyle(0.5 * this.polyNodeScale, RULER_NODE_STROKE_COLOR);
			graphic.beginFill(RULER_NODE_FILL_COLOUR, RULER_NODE_FILL_OPACITY);
			graphic.drawCircle(p.x, p.y, RULER_NODE_RADIUS * this.polyNodeScale);
			graphic.endFill();
		});
	}

	private drawTextBackground(graphic: PIXI.Graphics, position: PIXI.IPointData, digits: number) {
		graphic.clear();
		graphic.zIndex = this.zIndexTop + 3; // sits above ruler nodes

		// Dynamically adjust RULER_TEXT_WIDTH, offsetWidth for different digit of distance.
		graphic.beginFill(RULER_TEXT_BACKGROUND_COLOUR, RULER_TEXT_BACKGROUND_OPACITY);
		const offsetWidth = (RULER_TEXT_WIDTH + RULER_TEXT_DIGIT_WIDTH_DELTA * digits) / 2;
		const offsetHeight = RULER_TEXT_HEIGHT / 2;
		graphic.position.set(position.x, position.y);
		graphic.pivot.set(offsetWidth, offsetHeight);

		// draw a rounded rectangle
		graphic.drawRoundedRect(0, 0, (RULER_TEXT_WIDTH + RULER_TEXT_DIGIT_WIDTH_DELTA * digits), RULER_TEXT_HEIGHT, RULER_TEXT_BACKGROUND_BORDER_RADIUS);
		graphic.endFill();

	}

	render() {
		if (this.points.length === 0) {
			this.getGraphic(graphicTextIndex.OpenPolygon).clear();
			this.getGraphic(graphicTextIndex.Nodes).clear();
			this.getGraphic(graphicTextIndex.DynamicLine).clear();
			this.getText(graphicTextIndex.DynamicText).text = "";
			this.getGraphic(graphicTextIndex.DynamicTextBG).clear();
			return;
		}

		this.drawPolygon(); // ruler open polygon
		this.drawNodes(); // ruler nodes

		// texts and their backgrounds
		this.lengthTexts.forEach((lengthText, i) => {
			const previousPoint = this.points[i];
			const currentPoint = this.points[i + 1];
			const position = this.getMiddlePoint(previousPoint, currentPoint);

			lengthText.position.set(position.x, position.y);
			lengthText.anchor.set(0.5);
			lengthText.scale.set(this.textScale);

			const graphicTextBG = this.lengthTextBGs[i];
			graphicTextBG.scale.set(this.textBGScale);
			const numOfDigits = lengthText.text.replace('.', '').length - 2;			
			this.drawTextBackground(graphicTextBG, position, numOfDigits);
		});
	}
}
