import React, { useState } from 'react';
import { Button, Colors, Display } from 'Views/Components/Button/Button';

interface ICollapsiblePropertyProps {
    className?: string;
    propertyTitle: string;
    displayProperty: boolean;
    children: React.ReactNode;
    moreButton?: React.ReactNode;
    plusButton?: React.ReactNode;
}

/**
 * Render collapsible property.
 * @param props
 * @constructor
 */
export default function CollapsibleProperty(props: ICollapsiblePropertyProps) {
	const {
		propertyTitle, displayProperty, children, moreButton, plusButton, className,
	} = props;
	const clazz = className ? `property-button ${className}` : 'property-button';
	const [display, setDisplay] = useState(displayProperty);

	const toggleSideProperty = () => setDisplay(!display);

	return (
		<>
			<div className="sidebar-property-header">
				<h6>{propertyTitle}</h6>
				<div className={clazz}>
					<Button
						className="toggle-property no-background"
						display={Display.Text}
						colors={Colors.White}
						icon={{ icon: display ? 'chevron-up' : 'chevron-down', iconPos: 'icon-right' }}
						onClick={toggleSideProperty}
					/>
					{moreButton}
					{plusButton}
				</div>
			</div>
			{display ? children : null}
		</>
	);
}
