/* eslint-disable import/prefer-default-export */
import L from 'leaflet';
import {store} from "../../../../Models/Store";

/**
 * Prevents events that occur on the dom element to propagate to the map
 *
 * @param id selector for the element in the dom
 */
export function disableMapDomEvents(id: string) {
	const element = L.DomUtil.get(id);
	if (element != null) {
		L.DomEvent.disableClickPropagation(element);
		L.DomEvent.disableScrollPropagation(element);

		// Start hack to fix combobox item click on properties panel
		L.DomEvent.on(element, 'mousedown mouseup touchstart dblclick', event => {
			const pointerEvent = event as any;

			if (pointerEvent.target !== undefined && pointerEvent.target['type'] === undefined) {
				const target = pointerEvent.target;
				const parent = target.parentElement;

				// Allow dropdown items to get click event
				// Parent element gets checked first because it is more likely to be true (& will short circuit the second check)
				if (parent.classList.contains('dropdown') || target.classList.contains('dropdown')) {
					event.preventDefault();
				}
			}

			if (store.eventHandler.isDraggingMouse && event.type === 'mouseup') {
				return;
			}

			// Stop propagation by default
			event.stopPropagation();
		});
		// end hack
	}
}
