import React from 'react';
import { Button } from 'Views/Components/Button/Button';

interface IInlineValidationProps {
	onTick?: () => void;
    onCross?: () => void;
	hideErrorMessage?: () => void;
    isActiveAndError?: boolean;
    error?: string;
    unit?: string;
}

export default function InlineValidation(props: IInlineValidationProps) {
	const {
		onTick, onCross, hideErrorMessage, unit,
	} = props;
	let classNameCheck = 'inline-button icon-only';
	let classNameCross = 'inline-button cross icon-only';

	const onCheck = () => {
		if (onTick) {
			onTick();
		}
	};

	const onCancel = () => {
		if (onCross) {
			onCross();
		}
	};

	// handling three possible positions for the check and cross buttons based on the units.
	if (unit?.length === 1) {
		classNameCheck = 'inline-button unit-tick icon-only';
		classNameCross = 'inline-button unit-cross icon-only';
	}
	if (!!unit && unit?.length > 1) {
		classNameCheck = 'inline-button unit-long-tick icon-only';
		classNameCross = 'inline-button unit-long-cross icon-only'
	}

	return (
		<>
			<div className="inline-validation">
				<Button
					className={classNameCheck}
					icon={{ icon: 'check' }}
					onClick={onCheck}
					buttonProps={{
						tabIndex: -1, // Disallow tabbing to these buttons (instead we want to tab to the next input)
						onBlur: hideErrorMessage,
					}}
				/>
				<Button
					className={classNameCross}
					icon={{ icon: 'cross' }}
					onClick={onCancel}
					buttonProps={{
						tabIndex: -1 // Disallow tabbing to these buttons (instead we want to tab to the next input)
					}}
				/>
			</div>
		</>
	);
}
