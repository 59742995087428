import MapObject from '../MapObject';
import { NodeEntity, SublinkEntity } from '../../../../../Models/Entities';
import MapRenderer from '../../MapRenderer';
import { LatLng, Point } from 'leaflet';
import * as PIXI from 'pixi.js';
import MapStore from "../../MapStore";
import DrivingZone from '../DrivingZone/DrivingZone';
import { getLeafletLatLng, pixiCoordinates, PixiCoordinates } from '../../Helpers/Coordinates';
import geoJsonToPoints from '../../Helpers/GeoJSON';
import DrivingArea from '../Area/DrivingArea';
import MapValidator from '../../MapValidators/MapValidator';

const LINE_COLOUR = 0x9A9A9A;
const LINE_THICKNESS = 40;
const HIT_AREA_THICKNESS = 20;

/**
 * SubLink class - styles and renders sublinks
 */
export default class SubLink extends MapObject<PixiCoordinates[]> {

	public sublinkEntity: SublinkEntity;
	private drivingZoneObject: DrivingZone | undefined;

	constructor(points: PixiCoordinates[], renderer: MapRenderer, sublinkEntity: SublinkEntity, lookup?: MapStore) {
		super(renderer, 'sublink', points);
		// TODO: sublinkEntity with client_id only should never be added
		if (!sublinkEntity.id) {
			console.log('Sublink added with _clientId only!');
		}
		lookup?.addEntityToMapObject(sublinkEntity.getModelId(), this);
		this.sublinkEntity = sublinkEntity;
		this.createGraphic();

		if (!!sublinkEntity.drivingZone) {
			if (sublinkEntity.drivingZone === 'null') {
				// FIXME: break/joim bug where sometimes dz is undefined
				console.log(`driving zone not defined on subLinkId ${this.sublinkEntity.sublinkId} (${this.sublinkEntity.id}) linkId ${this.sublinkEntity.linkId}`);
			} else {
				this.createDrivingZoneObject();
			}
		} else {
			console.log('no Driving zone!!');
		}
	}

	public createDrivingZoneObject() {
		const drivingZone = this.sublinkEntity.drivingZone;
		if (!!this.drivingZoneObject) {
			// should never reach here, but handle it anyway!
			this.drivingZoneObject.setEntity(geoJsonToPoints(drivingZone, this.renderer) as PIXI.Point[]);
		} else {
			this.drivingZoneObject = new DrivingZone(drivingZone, this.renderer);
			this.addChild(this.drivingZoneObject);
			this.renderer.addObject(this.drivingZoneObject);

			this.drivingZoneObject.isError = this.sublinkEntity.getErrorCount() !== 0;
			this.drivingZoneObject.isWarning = this.sublinkEntity.getWarningCount() !== 0 && !this.drivingZoneObject.isError;

			// Save DringZone mapObjectId and DrivingArea mapObject lookup
			// for optimising the performance of checkDrivableArea()
			const lookup = this.renderer.getController().getMapLookup();
			const drivingAreas = lookup.getDrivingAreas();
			for (let i = 0; i < drivingAreas.length; i++) {
				const entity = drivingAreas[i];
				const id = lookup.getMapObjectId(entity.id, 'driving_area');
				if (!!id) {
					const _drivingArea = this.renderer.getObjectById(id) as DrivingArea;
					// Test individual driving areas
					const boundingPoints = MapValidator.getBoundingPoints(_drivingArea, this.drivingZoneObject.getEntity()[0]);
					if (!!boundingPoints) {
						if (!boundingPoints.isHole) {
							lookup.setDrivingAreaObjByDringZoneObjId(this.drivingZoneObject.getId(), _drivingArea);
						}
					}
				}
			}
		}
		return this.drivingZoneObject;
	}

	public getSublinkEntity() {
		return this.sublinkEntity;
	}

	public getDrivingZoneObject() {
		return this.drivingZoneObject;
	}

	panToObject() {
		this.renderer.getMap().panTo(this.getCentrePoint());
	}

	private getCentrePoint(): LatLng {
		return MapObject.getCentreOfPoints(this.getEntity(), this.renderer);
	}

	/**
	 * Renders sublink in Pixi
	 */
	render() {
		const graphic = this.getGraphic();
		const points = this.getEntity();

		const widthMultiplier = this.isHighlighted ? 2 : 1;

		graphic.clear();

		if (points.length === 0) {
			return;
		}
		const lineOptions: PIXI.ILineStyleOptions = {
			width: LINE_THICKNESS * widthMultiplier,
			join: PIXI.LINE_JOIN.MITER,
			color: LINE_COLOUR,
			alpha: 1,
			alignment: 0.5,
		}
		graphic.lineStyle(lineOptions);

		const polygon = new PIXI.Polygon(points);
		polygon.closeStroke = false;
		graphic.zIndex = this.isHighlighted ? this.zIndexTop : this.zIndexBase;
		graphic.drawPolygon(polygon);

		const allPoints = this.generateHitAreaFromLine(points, HIT_AREA_THICKNESS * widthMultiplier);
		graphic.hitArea = new PIXI.Polygon(allPoints);
	}
}
