import _ from "lodash";
import {Model} from "../../../../Models/Model";

export enum SaveCommandType {
	// Create
	CreateArea,
	CreatePath,
	CreateBay,
	CreateMultipleBays,
	CreateTurnSignal,
	CreateConnectivity,

	// Update
	UpdateArea,
	UpdatePath,
	UpdateBay,
	UpdateConnectivity, // Shorthand for delete and create
	UpdateTurnSignal, // Shorthand for delete and create

	// Delete
	DeleteArea,
	DeletePath,
	DeleteBay,
	DeleteTurnSignal,
	DeleteConnectivity,

	// Link Interactions
	BreakLink,
	JoinLink,
	BreakSublink,
	JoinSublink,

	// Misc.
	PropertyUpdate,
	SetNodeTask,
	SetLinkSpeed,
}

export abstract class SaveCommand {
	readonly changeType: SaveCommandType;

	constructor(changeType: SaveCommandType) {
		this.changeType = changeType;
	}

	public getData(): any {
		const changeObjectForSending = _.omit(this, ['changeType'])
		return Object.entries(changeObjectForSending).reduce((obj, [key, value]) => {
			// Process the model objects to convert them to JSON
			obj[key] = value instanceof Model ? value.toJSON() : value;

			return obj;
		}, {});
	}
}
