import MapObject from '../MapObject';
import MapRenderer from '../../MapRenderer';
import MapStore from '../../MapStore';
import {
	LinkEntity,
	NodeEntity,
	SignalSetEntity,
} from '../../../../../Models/Entities';
import NodeGraphic from '../Node/NodeGraphic';
import Link from '../Link/Link';
import SubLink from '../SubLink/SubLink';
import { PixiCoordinates } from '../../Helpers/Coordinates';
import Signal from '../Signal/Signal';
import TurnSignalHelper from '../../MapStateHandlerHelpers/TurnSignalHelper';
import { MapLookup } from 'Views/MapComponents';

export interface IPathOptions {
	isSelected: boolean,
	isEditMode?: boolean,
	// forces use of buildPathManually (build path without lookup)
	forceBuild: boolean,
	 // determines whether or not the LinkEntity is added to lookup (buildPathManually only)
	allLookup: boolean,
	// Midwaypoints (with NodeEntity.ismidwaypoint set to true) are distinct from reverseWaypoint
	// ReverseWaypoints can already be distinguished via their task (hence the DB flag isn't needed)
	reverseWaypointIds?: string[]
}

interface ILinkNodesAndPoints {
	linkNodes: NodeEntity[],
	linkPoints: PixiCoordinates[]
}

interface IBuildPathManuallyParams {
	fullPath: boolean,
}

/**
 * Path consists of series of connected links where
 * each link contains sublinks, nodes, and driving zone.
 * Upon loading of map data, Path will be contructed from data found
 * in mapLookup. Otherwise, Path data will be generated manually.
 * 
 * IMPORTANT: Two basic code paths:
 * 1. Paths that are not supposed to be added to lookup (e.g. temp paths for creation/editing)
 * use buildPathManually
 * 2. Path that are added to lookup
 * 
 * Both methods use core logic found in calcPointsAndAddEntities
 */
export default class Path extends MapObject<LinkEntity> {
	private mapLookup?: MapStore;
	private options?: IPathOptions;

	constructor(link: LinkEntity, renderer: MapRenderer, lookup?: MapStore, options?: IPathOptions) {
		super(renderer, 'path', link);
		lookup?.addEntityToMapObject(link.id, this);

		this.mapLookup = lookup;
		this.options = options;

		this.buildPath();
	}

	/**
	 * Builds path via data in mapLookup or manual generation from link
	 * Adds Link, SubLink, NodeGraphic, and DrivingZone as children for rendering
	 */
	private buildPath() {
		const link = this.getEntity();
		if (!this.mapLookup || this.options?.forceBuild) {
			// doesn't require lookup
			this.buildPathManually();
			return;
		}

		const { linkNodes, linkPoints } = this.calcPointsAndAddEntities(this.mapLookup);
		this.addSignalAsChild(linkNodes, linkPoints);
		this.addChild(new Link(linkPoints, this.renderer, link, this.mapLookup));
	}

	private addSignalAsChild(linkNodes: NodeEntity[], linkPoints: PixiCoordinates[]) {
		const link = this.getEntity();
		// HITMAT-285: if a link contains more than one turn signal, assume there is one turn signal and no negative start value
		const signal: SignalSetEntity | undefined = link.signalSetss.length > 0 ? link.signalSetss[0] : undefined;
		if (!!signal) {
			const distSignalStartToLinkStart = signal.signalStart;
			const signalLength = signal.signalEnd - signal.signalStart;
	
			// TODO: don't care about converting negative start now
			if(!!linkNodes && distSignalStartToLinkStart >= 0) {
				const signalPoints = TurnSignalHelper.getSignalPoints(linkNodes, linkPoints, distSignalStartToLinkStart, signalLength, this.renderer);
				this.addChild(new Signal(signalPoints, this.renderer, signal, this.mapLookup));
			}
		}
	}

	// Core logic for creating Node/Sublink entities are calculating points for link
	private calcPointsAndAddEntities(lookup?: MapLookup, buildManuallyParams?: IBuildPathManuallyParams): ILinkNodesAndPoints {
		const link = this.getEntity();
		const linkNodes: NodeEntity[] = [];
		const linkPoints: PixiCoordinates[] = [];
		const sublinks = link.getSublinks()
		sublinks.forEach(sublink => {
			const sublinkPoints: PixiCoordinates[] = [];
			const nodes = sublink.getNodes();
			nodes.forEach(node => {
				if (!!buildManuallyParams) {
					// when placing a start node to build a new path, the end node doesn't have location set - such node cannot be rendered
					// TODO: Check if there is any other cases
					if (node.northing === undefined || node.easting === undefined) {
						// TODO: if there is any better way to handle this case.
						console.log('A node is undefined.');
						return;
					}
				}
				linkNodes.push(node);
				const nodePoint = this.renderer.project(node);
				linkPoints.push(nodePoint);
				sublinkPoints.push(nodePoint);
				this.addChild(new NodeGraphic(this.renderer, node, {
					...this.options,
					isReverseMidWaypoint: !!this.options?.reverseWaypointIds ? this.options.reverseWaypointIds.includes(node.id) : false,
				}, lookup));
			});
			// if buildManuallyParams is undefined always add sublink (default)
			if (!buildManuallyParams || sublinkPoints.length > 1) {
				this.addChild(new SubLink(sublinkPoints, this.renderer, sublink, lookup));
			}
		});
		return {
			linkNodes: linkNodes,
			linkPoints: linkPoints
		};
		// Signal and Link itself are added via custom logic in the calling methods
	}

	/**
	 * Builds path manually from Link, using link connectivity data to find
	 * all links in path
	 * Assumes the data will be in order
	 */
	public buildPathManually() {
		const link = this.getEntity();

		// Ensure that confirmed path can be selected
		const lookup = this.options?.forceBuild ? this.mapLookup : undefined;
		// Temporary hack to disable generation of path entities (other than nodes) 
		// until a complete path has been created
		// TODO: Find better way
		let fullPath = true;

		// run in "build manually" mode by including buildManuallyParams
		const { linkNodes, linkPoints } = this.calcPointsAndAddEntities(lookup, {
			fullPath: fullPath
		});

		if (linkPoints.length > 1) {
			this.addSignalAsChild(linkNodes, linkPoints);
			this.addChild(new Link(linkPoints, this.renderer, link, lookup));
			if (this.options?.allLookup) {
				lookup?.addPath(link);
			}
		}
	}
}
