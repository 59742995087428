
/**
 * Render properties side panel for Connectivity tool.
 */
export default function ConnectivityProperties() {
	return (
		<>
			<h6>Connectivity Properties</h6>
		</>
	);
}
