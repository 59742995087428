import {LinkEntity} from "../../../../Models/Entities";
import {SaveCommand, SaveCommandType} from "./SaveCommand";

export class SetLinkSpeedCommand extends SaveCommand {
	linkId: string;
	isDefaultSpeed: boolean;
	currentSpeed: number;

	constructor(linkId: string, defaultSpeedEnabled: boolean, currentSpeed: number) {
		super(SaveCommandType.SetLinkSpeed);

		this.linkId = linkId;
		this.isDefaultSpeed = defaultSpeedEnabled;
		this.currentSpeed = currentSpeed;
	}

	/**
	 * Assumes the changes have already been made to the link
	 * @param link
	 */
	public static fromLink(link: LinkEntity): SetLinkSpeedCommand {
		return new SetLinkSpeedCommand(link.getModelId(), link.isDefaultSpeed, link.constantSpeed);
	}
}
