import {SaveCommand, SaveCommandType} from "./SaveCommand";
import {SignalSetEntity} from "../../../../Models/Entities";

export default class CreateTurnSignalCommand extends SaveCommand {

	linkId: string;
	turnSignal: SignalSetEntity;

	constructor(linkId: string, turnSignal: SignalSetEntity) {
		super(SaveCommandType.CreateTurnSignal);

		this.linkId = linkId;
		this.turnSignal = turnSignal;
	}
}