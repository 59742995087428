import MapEventHandler from '../MapEventHandler';
import MapController from '../MapController';
import { LeafletCoordinates } from '../Helpers/Coordinates';
import { runInAction } from 'mobx';

export default class MapStateHandler<T> {
	private readonly eventHandler: MapEventHandler;
	private readonly controller: MapController;

	public isInitialized: boolean = false;

	private lastUpdateOnMap: number = 0;
	private timeoutIdOnMap: NodeJS.Timeout | undefined;

	constructor(eventHandler: MapEventHandler, controller: MapController) {
		this.eventHandler = eventHandler;
		this.controller = controller;
	}

	public getEventHandler() {
		return this.eventHandler;
	}

	public getController() {
		return this.controller;
	}

	public getRenderer() {
		return this.getController().getMapRenderer();
	}

	public getLookup() {
		return this.getController().getMapLookup();
	}

	public getLatLng(event: L.LeafletMouseEvent) {
		if ((event.originalEvent.target as Element).tagName === 'CANVAS') {

			const debounceMilliseconds = 100;
			const updateLocation = () => runInAction(() => {
				this.lastUpdateOnMap = Date.now();
				let location = this.getRenderer().getRealWorldCoords(event.latlng);				
				const mousePos = this.getRenderer().mousePosition;
				if (location.easting !== mousePos.easting || location.northing !== mousePos.northing) {
					location = mousePos;
				}
				this.getEventHandler().emit('onCursorCoordsChange', location);
				if (this.timeoutIdOnMap !== undefined) {
					clearTimeout(this.timeoutIdOnMap);
					this.timeoutIdOnMap = undefined;
				}
			});
			if ((Date.now() - this.lastUpdateOnMap) > debounceMilliseconds) {
				updateLocation();
			} else if (this.timeoutIdOnMap === undefined) {
				this.timeoutIdOnMap = setTimeout(() => {
					updateLocation();
				}, debounceMilliseconds);
			}
		}
	}
	
	
	sleep = (ms:number) => new Promise((resolve) => setTimeout(resolve, ms));

	public async waitForConfirmingStatus(maxWaitTime = 10000): Promise<boolean> {
		let elapsedTime = 0;
		const interval = 20;
		while (elapsedTime < maxWaitTime) {
			try {
				const controller = this.getController();
				const mapLookup = controller.getMapLookup();
				const tracker = controller.getTracker();
				if (!mapLookup.confirmButtonConfirming && !tracker.isSaving) {
					return true;
				} else {
					await this.sleep(interval);
					elapsedTime += interval;
				}
			} catch (error) {
				console.log(error);
				return false;
			}
		}
		this.getController().getMapLookup().confirmButtonConfirming = false;
		console.warn('waitForConfirmingStatus Maximum wait time exceeded');
		return false;
	}

	public onInit(initialState: T): Promise<void> | void {
	}

	public onRequestUpdate(entity?: unknown): Promise<void> | void {
	}

	public onClick(event: L.LeafletMouseEvent): Promise<void> | void {
	}

	public onRightClick(event: L.LeafletMouseEvent): Promise<void> | void {
	}

	public onMove(event: L.LeafletMouseEvent): Promise<void> | void {
	}

	public onDoubleClick(event: L.LeafletMouseEvent): Promise<void> | void {
	}

	public onDragStart(event: L.LeafletMouseEvent, originalCoordinates: LeafletCoordinates): Promise<void> | void {
	}

	public onDragMove(event: L.LeafletMouseEvent): Promise<void> | void {
	}

	public onDragEnd(event: L.LeafletMouseEvent): Promise<void> | void {
	}

	public onKeyPress(event: KeyboardEvent): Promise<void> | void {
	}

	public onEscapePressed(event: KeyboardEvent): Promise<void> | void {
	}

	public onConfirm(): Promise<void> | void {
	}

	public dispose(): Promise<void> | void {
		// Do anything needed before deselecting this state
	}
	
	public setIgnoreDispose() {
	}
}
