import {SaveCommand, SaveCommandType} from "./SaveCommand";

export default class JoinLinkCommand extends SaveCommand {
	public linkFromId: string;
	public linkToId: string;

	constructor(linkFromId: string, linkToId: string) {
		super(SaveCommandType.JoinLink);

		this.linkFromId = linkFromId;
		this.linkToId = linkToId;
	}
}