import { AreaEntity } from '../../../../../Models/Entities';
import L, { LatLng } from 'leaflet';
import MapObject from '../MapObject';
import MapRenderer from '../../MapRenderer';
import * as PIXI from 'pixi.js';
import { DashLine } from '../../Helpers/DashLine';
import geoJsonToPoints, { pixiPointsToGeoJSONPolygon } from '../../Helpers/GeoJSON';
import MapStore from '../../MapStore';
import { areaType } from 'Models/Enums';
import { PixiCoordinates } from '../../Helpers/Coordinates';

export const AREA_LINE_WIDTH = 4;
const HIT_AREA_THICKNESS = 4;
const AREA_FILL_ALPHA = 0.5;
const DASH_LENGTH = 30;
const DASH_GAP_LENGTH = 10;
const AREA_NODE_FILL_COLOUR = 0xFFFFFF;

// zIndex of Area sublayer within Area layer
const areaTypeZIndex: { [key in areaType]: number } = {
	AAREAINVALID: 0,
	AREAAUTONOMOUS: 1,
	AREAEXCLUSION: 3,
	AREALOCKOUT: 2,
	AREAOBSTACLE: 4,
	AREABARRIER: 5,
	AREAAHS: 6,
	AREADRIVABLE: 0,
	AREAMAXTYPE: 0,
};

// must exceed the highest zIndex above
const AREA_TYPE_ZINDEX_TOP = 100;

interface StyleOptions {
	lineOptions?: PIXI.ILineStyleOptions,
	fillOptions?: PIXI.IFillStyleOptions,
	isDashed: boolean
}

enum graphicIndex {
	Polygon = 0,
	Node = 1,
	Line = 2,
}

/**
 * Render AreaEntity in Pixi and display tooltip
 */
export default class Area extends MapObject<AreaEntity> {
	private points: PIXI.Point[];

	// When isEdit is set, areaNodes are rendered and the line width
	// is the 'unselected' width.
	private isEdit = false;

	public static readonly areaNodeRadius = 3;
	private _selectPointIndex = -1;

	// ensures only once instance
	public static straightLineId: number | undefined;

	private coordinates: any;

	constructor(area: AreaEntity, renderer: MapRenderer, lookup: MapStore) {
		super(renderer, 'area', area);
		lookup?.addEntityToMapObject(area.id, this);
		this.setPointsFromEntity();

		this.zIndexBase = areaTypeZIndex[area.areaType];

		this.zIndexTop = AREA_TYPE_ZINDEX_TOP;

		// Area shape
		this.createGraphic(); // graphicIndex.Polygon
		// Area node
		this.createGraphic(); // graphicIndex.Node
		// dynamic line grahic between the last node and the mouse cursor location
		this.createGraphic(); // graphicIndex.Line
	}

	getPoints() {
		return this.points;
	}

	setPointsFromEntity() {
		const { polygon } = this.getEntity();
		if (!!polygon && polygon !== '') {
			this.setPoints(geoJsonToPoints(polygon, this.renderer, true) as PIXI.Point[]);
		} else {
			this.setPoints([]);
		}
	}

	/**
	 * Take current points and return geoJSON string that can be assigned to entity
	 * @returns
	 */
	getPointsAsGeoJSONString() {
		return JSON.stringify(pixiPointsToGeoJSONPolygon(this.points, this.renderer));
	}

	/**
	 * Give a deep copy of the points, this is useful
	 * for reverting the geometry
	 * @returns 
	 */
	getCopyPoints() {
		return this.points.map(p => p.clone())
	}

	public reset() {
		this.points = [];
		this._selectPointIndex = -1;
		this.clearLine();
		Area.straightLineId = undefined;
	}

	/**
	 * Returns copy of points without closing node
	 * @returns
	 */
	getPointsWithoutClose() {
		// TODO: fix. returns COPY or actual points depending on condition
		return this.isClosed() ? this.points.slice(0, this.points.length - 1) : this.points;
	}

	panToObject() {
		this.renderer.getMap().panTo(this.getCentrePoint());
	}

	render() {
		const { lineOptions, fillOptions, isDashed } = this.getStyleOptions();

		const graphic = this.getGraphic(graphicIndex.Polygon);
		graphic.clear();

		if (this.points.length === 0) {
			// if there are no points, clear all the graphics
			this.getGraphic(graphicIndex.Node).clear();
			this.getGraphic(graphicIndex.Line).clear();
			return;
		}

		// Put at top level if selected or in edit mode
		graphic.zIndex = (this.isHighlighted || this.isEdit) ? this.zIndexTop : this.zIndexBase;

		if (!isDashed) {
			graphic.lineStyle(lineOptions);

			// For creation of open polygon (for use in create area)
			const lastPoint = this.points[this.points.length - 1];
			const polygon = new PIXI.Polygon(this.points);
			polygon.closeStroke = this.isClosed();
			let hasFill = false;
			if (fillOptions != null && polygon.closeStroke) {
				hasFill = true;
				graphic.beginFill(fillOptions.color, fillOptions.alpha);
			}

			graphic.drawPolygon(polygon);
			if (hasFill) {
				graphic.endFill();
			}
		} else {
			// IMPORTANT: PIXI.LINE_JOIN.MITER prevents rendering issues
			const dash = new DashLine(graphic, {
				...lineOptions,
				dash: [DASH_LENGTH, DASH_GAP_LENGTH],
				join: PIXI.LINE_JOIN.MITER,
			});
			dash.drawPolygon(this.points);
		}
		this.renderLabel();
		const allPoints = this.generateHitAreaFromLine(this.points, HIT_AREA_THICKNESS);
		graphic.hitArea = new PIXI.Polygon(allPoints);
		this.renderNodes();
	}

	isClosed() {
		const { points } = this;
		return points.length > 2
			&& points[0].equals(points[points.length - 1]);
	}

	renderNodes() {
		const graphic = this.getGraphic(graphicIndex.Node);
		const { lineOptions } = this.getStyleOptions();
		graphic.clear();

		const isClosed = this.isClosed();

		if (this.isEdit || !isClosed) {
			graphic.zIndex = this.zIndexTop + 1; // sits above area line
			const lineColour = lineOptions?.color ?? 0xFFFFFF;

			// First and last points are the same on complete polygon
			// so render only the first one
			// let ignoreLast = false;
			// const endIndex = this.points.length - 1;
			// if (endIndex > 0) {
			// ignoreLast = this.points[0].equals(this.points[endIndex]);
			// }
			// const points = ignoreLast ? this.points.slice(0, endIndex) : this.points;
			const points = isClosed ? this.points.slice(0, this.points.length - 1) : this.points;

			points.forEach((p, i) => {
				const isSelected = this.selectPointIndex === i;

				// Invert fill/line colour for selected/unselected
				const fillAndLine: { fillColour: PIXI.ColorSource, lineColour: PIXI.ColorSource } = isSelected
					? { fillColour: lineColour, lineColour: AREA_NODE_FILL_COLOUR }
					: { fillColour: AREA_NODE_FILL_COLOUR, lineColour: lineColour };
				graphic.lineStyle(0.5, fillAndLine.lineColour);
				graphic.beginFill(fillAndLine.fillColour, 1);
				graphic.drawCircle(p.x, p.y, Area.areaNodeRadius);
				graphic.endFill();
			});
		}
	}

	/**
	 * Renders the line graphic
	 * It must be called after startLine and has its
	 * position updated according to the mouse position
	 * @param end
	 */
	private renderLine(end: PixiCoordinates) {
		const graphic = this.getGraphic(graphicIndex.Line);
		graphic.clear();
		graphic.zIndex = AREA_TYPE_ZINDEX_TOP + 1;
		const from = this.coordinates;
		const to = end;

		const { lineOptions } = this.getStyleOptions();
		graphic.lineStyle(lineOptions)
			.moveTo(from.x, from.y)
			.lineTo(to.x, to.y);
	}

	/**
	 * Initialises this positioning of the line grahic
	 * and calls its rendering method
	 */
	public startLine() {
		if (this.isClosed()) {
			console.error('Cannot start line on closed shape');
			return;
		}
		this.coordinates = this.points[this.points.length - 1];
		console.log('starting line');
		if (Area.straightLineId === undefined) {
			const renderLine = () => {
				this.isAnimating();
				this.renderLine(this.renderer.project(this.renderer.mousePosition));
				this.renderer.rerender();
				Area.straightLineId = requestAnimationFrame(renderLine);
			};
			Area.straightLineId = requestAnimationFrame(renderLine);
		} else {
			this.clearLine();
			this.startLine();
		}
	}

	/**
	 * Removes and clears the line
	 */
	public clearLine() {
		const graphic = this.getGraphic(graphicIndex.Line);
		graphic.clear();
		if (Area.straightLineId) {
			cancelAnimationFrame(Area.straightLineId);
			Area.straightLineId = undefined;
			this.renderer.rerender();
		} else {
			console.warn('clearLine no id found');
		}
	}

	public enableEditMode() {
		this.isEdit = true;
	}

	public disableEditMode() {
		this.isEdit = false;
		this.resetSelectPoint();
	}

	public resetSelectPoint() {
		this.selectPointIndex = -1;
	}

	public isAreaNodeSelected() {
		return this.selectPointIndex > -1;
	}

	public updateSelectedPoint(coords: PixiCoordinates) {
		const selectedIndex = this.selectPointIndex;
		this.points[selectedIndex].x = coords.x;
		this.points[selectedIndex].y = coords.y;
		if (selectedIndex === 0) {
			const endIndex = this.points.length - 1;
			this.points[endIndex].x = coords.x;
			this.points[endIndex].y = coords.y;
		}
	}

	public addPoint(point: PIXI.Point) {
		this.points.push(point);
	}

	/**
	 * Insert node at midpoint of indexBefore and indexBefore + 1
	 * Index of inserted point will be indexBefore + 1
	 * selectPointIndex updated to inserted node index
	 * @param indexBefore
	 */
	public insertPoint(indexBefore: number) {
		const points = this.getPoints();
		const insertNodeIndex = indexBefore + 1;
		const pointA = points[indexBefore];
		const pointB = points[insertNodeIndex];
		const midPoint = new PIXI.Point((pointA.x + pointB.x) / 2, (pointA.y + pointB.y) / 2);
		points.splice(insertNodeIndex, 0, midPoint);
		this.selectPointIndex = insertNodeIndex;
	}

	public deletePreviousPoint(): boolean {
		if (this.points.length === 0) {
			console.error("Can't delete point from empty area");
			return false;
		}
		this.points = this.points.slice(0, this.points.length - 1);
		return true;
	}

	public setPoints(points: PIXI.Point[]) {
		this.points = points;
	}

	/**
	 * Deletes a desired node
	 * @param index
	 * @returns true if success
	 * @returns false if failure
	 */
	public deleteDesiredPoint(index: number): PIXI.Point | undefined {
		const totalPointsCount = this.points.length;

		/*
		* The index should be valid to delete the node from
		* */
		if (!(index > -1 && index < totalPointsCount)) {
			console.error('Can\'t delete a point of an invalid index');
			return undefined;
		}
		/*
		* Perform the delete operation and return the deleted node
		* */
		const deletedPoint: PIXI.Point = this.points[index];
		this.points.splice(index, 1);
		if (index === 0 || index === totalPointsCount - 1) {
			/*
			* Handle special case:
			* When first node is deleted, set the closing node to equal it
			* */
			this.points[totalPointsCount - 2].copyFrom(this.points[0]);
		}
		return deletedPoint;
	}

	public getSelectedPoint(): PixiCoordinates | undefined {
		let selectedPoint: PixiCoordinates | undefined;
		if (this.isAreaNodeSelected()) {
			selectedPoint = this.points[this.selectPointIndex];
		}
		return selectedPoint;
	}

	public get selectPointIndex() {
		return this._selectPointIndex;
	}

	public set selectPointIndex(index: number) {
		this._selectPointIndex = index;
	}

	private renderLabel() {
		const hasErrors = this.getEntity().mapObjectErrorss?.length > 0;
		this.createTooltip(this.getEntity().areaName, this.getCentrePoint(), hasErrors);
	}

	private getCentrePoint(): LatLng {
		return this.getCentreOfPoints(this.points, this.renderer);
	}

	public dispose() {
		this.clearLine();
		this.removeTooltip();
	}

	/**
	 * Styling information for each area type
	 * Includes fill/stroke colours and line width
	 * @returns style options
	 */
	private getStyleOptions(): StyleOptions {
		const { areaType, locType } = this.getEntity();

		// Double the width if selected but not in Edit mode
		const widthMultiplier = (this.isHighlighted && !this.isEdit) ? 2 : 1;

		const lineOptions: PIXI.ILineStyleOptions = { width: AREA_LINE_WIDTH * widthMultiplier };
		const fillOptions: PIXI.IFillStyleOptions = { alpha: AREA_FILL_ALPHA };
		let isDashed = false;

		switch (areaType) {
			case 'AREAAHS':
				lineOptions.color = 0xFFFF00;
				isDashed = true;
				break;
			case 'AREAAUTONOMOUS':
				switch (locType) {
					case 'DIG':
						lineOptions.color = 0x0000FF;
						fillOptions.color = 0x804040;
						break;
					case 'PARKING':
						lineOptions.color = 0x0000FF;
						fillOptions.color = 0x800000;
						break;
					case 'DUMP':
						lineOptions.color = 0x0000FF;
						fillOptions.color = 0x00C462;
						break;
					case 'CRUSHER':
						lineOptions.color = 0x0000FF;
						fillOptions.color = 0x0000FF;
						break;
					case 'STOCKPILE':
						lineOptions.color = 0x0000FF;
						fillOptions.color = 0x0000A0;
						break;
					default:
						fillOptions.color = 0x0000FF;
						break;
				}
				break;
			case 'AREABARRIER':
				lineOptions.color = 0xD3D3D3;
				isDashed = true;
				break;
			case 'AREALOCKOUT':
				lineOptions.color = 0xFF0000;
				break;
			case 'AREAOBSTACLE':
				lineOptions.color = 0xFFFF00;
				fillOptions.color = 0x000000;
				break;
			case 'AREAEXCLUSION':
				lineOptions.color = 0xFFFFFF;
				fillOptions.color = 0xFF0000;
				break;
			default:
				console.log(`No style for areaType ${areaType}`);
				break;
		}

		return {
			lineOptions,
			fillOptions: fillOptions.color === undefined ? undefined : fillOptions,
			isDashed,
		};
	}
}
