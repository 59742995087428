/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

export type exportType =
	// % protected region % [Override exportType keys here] off begin
	'PATH' |
	'AREA' |
	'BAY' |
	'PATHANDBAY';
	// % protected region % [Override exportType keys here] end

export const exportTypeOptions: { [key in exportType]: string } = {
	// % protected region % [Override exportType display fields here] off begin
	PATH: 'Path',
	AREA: 'Area',
	BAY: 'Bay',
	PATHANDBAY: 'PathAndBay',
	// % protected region % [Override exportType display fields here] end
};

export type importEditStatus =
	// % protected region % [Override importEditStatus keys here] off begin
	'ORIGINAL' |
	'UNPUBLISHED_CHANGES' |
	'PUBLISHED';
	// % protected region % [Override importEditStatus keys here] end

export const importEditStatusOptions: { [key in importEditStatus]: string } = {
	// % protected region % [Override importEditStatus display fields here] off begin
	ORIGINAL: 'Original',
	UNPUBLISHED_CHANGES: 'Unpublished Changes',
	PUBLISHED: 'Published',
	// % protected region % [Override importEditStatus display fields here] end
};

export type terraindatatype =
	// % protected region % [Override terraindatatype keys here] off begin
	'NONE' |
	'OUTER' |
	'INNER';
	// % protected region % [Override terraindatatype keys here] end

export const terraindatatypeOptions: { [key in terraindatatype]: string } = {
	// % protected region % [Override terraindatatype display fields here] off begin
	NONE: 'NONE',
	OUTER: 'OUTER',
	INNER: 'INNER',
	// % protected region % [Override terraindatatype display fields here] end
};

export type allowedDirection =
	// % protected region % [Override allowedDirection keys here] off begin
	'ENTER' |
	'EXIT' |
	'BOTH' |
	'NONE';
	// % protected region % [Override allowedDirection keys here] end

export const allowedDirectionOptions: { [key in allowedDirection]: string } = {
	// % protected region % [Override allowedDirection display fields here] off begin
	ENTER: 'Enter',
	EXIT: 'Exit',
	BOTH: 'Both',
	NONE: 'None',
	// % protected region % [Override allowedDirection display fields here] end
};

export type areaLocationType =
	// % protected region % [Override areaLocationType keys here] off begin
	'INVALID' |
	'DIG' |
	'DUMP' |
	'STOCKPILE' |
	'PARKING' |
	'CRUSHER';
	// % protected region % [Override areaLocationType keys here] end

export const areaLocationTypeOptions: { [key in areaLocationType]: string } = {
	// % protected region % [Override areaLocationType display fields here] on begin
	INVALID: 'Not Applicable',
	DIG: 'Loading',
	DUMP: 'Dumping',
	STOCKPILE: 'Stockpile',
	PARKING: 'Parking',
	CRUSHER: 'Crusher',
	// % protected region % [Override areaLocationType display fields here] end
};

export type areaType =
	// % protected region % [Override areaType keys here] off begin
	'AAREAINVALID' |
	'AREAAUTONOMOUS' |
	'AREAEXCLUSION' |
	'AREALOCKOUT' |
	'AREAOBSTACLE' |
	'AREABARRIER' |
	'AREAAHS' |
	'AREADRIVABLE' |
	'AREAMAXTYPE';
	// % protected region % [Override areaType keys here] end

export const areaTypeOptions: { [key in areaType]: string } = {
	// % protected region % [Override areaType display fields here] on begin
	AAREAINVALID: 'Invalid',
	AREAAUTONOMOUS: 'Autonomous',
	AREALOCKOUT: 'Lockout',
	AREABARRIER: 'Barrier',
	AREAOBSTACLE: 'Obstacle',
	AREAEXCLUSION: 'Exclusion',
	AREAAHS: 'AOZ',
	AREADRIVABLE: 'Drivable',
	AREAMAXTYPE: 'AREAMAXTYPE',
	// % protected region % [Override areaType display fields here] end
};

export type baySource =
	// % protected region % [Override baySource keys here] off begin
	'AHC_SERVER' |
	'MAP_BUILDER';
	// % protected region % [Override baySource keys here] end

export const baySourceOptions: { [key in baySource]: string } = {
	// % protected region % [Override baySource display fields here] off begin
	AHC_SERVER: 'AHC Server',
	MAP_BUILDER: 'Map Builder',
	// % protected region % [Override baySource display fields here] end
};

export type bayType =
	// % protected region % [Override bayType keys here] off begin
	'INVALID' |
	'LOADING' |
	'DUMPCRUSHER' |
	'DUMPOVEREDGE' |
	'DUMPPADDOCK' |
	'PARKING' |
	'FUELLING';
	// % protected region % [Override bayType keys here] end

export const bayTypeOptions: { [key in bayType]: string } = {
	// % protected region % [Override bayType display fields here] on begin
	INVALID: 'Invalid',
	DUMPPADDOCK: 'Paddock Dumping',
	DUMPOVEREDGE: 'Over The Edge Dumping',
	DUMPCRUSHER: 'Crusher Dumping',
	PARKING: 'Parking',
	FUELLING: 'Fuelling',
	LOADING: 'Loading',
	// % protected region % [Override bayType display fields here] end
};

export type baystate =
	// % protected region % [Override baystate keys here] off begin
	'INVALID' |
	'OPEN' |
	'CLOSED' |
	'DESTROYED' |
	'ASSIGNED' |
	'ASNCOMPLETE' |
	'FULL' |
	'UNUSABLE' |
	'CALLINGTRUCK';
	// % protected region % [Override baystate keys here] end

export const baystateOptions: { [key in baystate]: string } = {
	// % protected region % [Override baystate display fields here] on begin
	INVALID: 'Invalid',
	OPEN: 'Open',
	CLOSED: 'Closed',
	DESTROYED: 'Destroyed',
	ASSIGNED: 'Assigned',
	ASNCOMPLETE: 'ASN Complete',
	FULL: 'Full',
	UNUSABLE: 'Unusable',
	CALLINGTRUCK: 'Calling Truck',
	// % protected region % [Override baystate display fields here] end
};

export type callingType =
	// % protected region % [Override callingType keys here] off begin
	'INVALID' |
	'INFINITE' |
	'SINGLEAHT';
	// % protected region % [Override callingType keys here] end

export const callingTypeOptions: { [key in callingType]: string } = {
	// % protected region % [Override callingType display fields here] on begin
	INVALID: 'Invalid',
	SINGLEAHT: 'Single',
	INFINITE: 'Infinite',
	// % protected region % [Override callingType display fields here] end
};

export type crossDir =
	// % protected region % [Override crossDir keys here] off begin
	'AUTO' |
	'LEFT' |
	'RIGHT';
	// % protected region % [Override crossDir keys here] end

export const crossDirOptions: { [key in crossDir]: string } = {
	// % protected region % [Override crossDir display fields here] off begin
	AUTO: 'Auto',
	LEFT: 'Left',
	RIGHT: 'Right',
	// % protected region % [Override crossDir display fields here] end
};

export type endType =
	// % protected region % [Override endType keys here] off begin
	'INVALID' |
	'MACHINE' |
	'AREA';
	// % protected region % [Override endType keys here] end

export const endTypeOptions: { [key in endType]: string } = {
	// % protected region % [Override endType display fields here] off begin
	INVALID: 'Invalid',
	MACHINE: 'Machine',
	AREA: 'Area',
	// % protected region % [Override endType display fields here] end
};

export type mapObjectState =
	// % protected region % [Override mapObjectState keys here] off begin
	'IMPORTED' |
	'MODIFIED' |
	'DELETED' |
	'NEW_OBJECT' |
	'PUBLISHED_MODIFIED' |
	'PUBLISHED_NEW';
	// % protected region % [Override mapObjectState keys here] end

export const mapObjectStateOptions: { [key in mapObjectState]: string } = {
	// % protected region % [Override mapObjectState display fields here] off begin
	IMPORTED: 'Imported',
	MODIFIED: 'Modified',
	DELETED: 'Deleted',
	NEW_OBJECT: 'New Object',
	PUBLISHED_MODIFIED: 'Published Modified',
	PUBLISHED_NEW: 'Published New',
	// % protected region % [Override mapObjectState display fields here] end
};

export type nodetask =
	// % protected region % [Override nodetask keys here] off begin
	'NONE' |
	'STATIONARY' |
	'HAULING' |
	'QUEUING' |
	'SPOTTING' |
	'LOADING' |
	'LOADINGCOMPLETE' |
	'PAUSED' |
	'USTOPPING' |
	'DUMPINGCRUSHER' |
	'DUMPINGOTE' |
	'DUMPINGPADDOCK' |
	'DUMPINGCOMPLETE' |
	'REVERSEPOINT' |
	'STARTENGINE' |
	'STOPENGINE' |
	'PARKING' |
	'REMOTECONTROL';
	// % protected region % [Override nodetask keys here] end

export const nodetaskOptions: { [key in nodetask]: string } = {
	// % protected region % [Override nodetask display fields here] on begin
	NONE: 'None',
	STATIONARY: 'Stationary',
	HAULING: 'Hauling',
	QUEUING: 'Queuing',
	SPOTTING: 'Spotting',
	LOADING: 'Loading',
	LOADINGCOMPLETE: 'Loading Complete',
	PAUSED: 'Paused',
	USTOPPING: 'U Stopping',
	DUMPINGCRUSHER: 'Crusher Dumping',
	DUMPINGOTE: 'Dumping OTE',
	DUMPINGPADDOCK: 'Dumping Paddock',
	DUMPINGCOMPLETE: 'Dumping Complete',
	REVERSEPOINT: 'Reverse Point',
	STARTENGINE: 'Start Engine',
	STOPENGINE: 'Stop Engine',
	PARKING: 'Parking',
	REMOTECONTROL: 'Remote Control',
	// % protected region % [Override nodetask display fields here] end
};

export type signalType =
	// % protected region % [Override signalType keys here] off begin
	'NONE' |
	'LEFT' |
	'RIGHT';
	// % protected region % [Override signalType keys here] end

export const signalTypeOptions: { [key in signalType]: string } = {
	// % protected region % [Override signalType display fields here] off begin
	NONE: 'None',
	LEFT: 'Left',
	RIGHT: 'Right',
	// % protected region % [Override signalType display fields here] end
};

export type spotDir =
	// % protected region % [Override spotDir keys here] off begin
	'INVALID' |
	'DRIVETHROUGH' |
	'BACKIN';
	// % protected region % [Override spotDir keys here] end

export const spotDirOptions: { [key in spotDir]: string } = {
	// % protected region % [Override spotDir display fields here] on begin
	INVALID: 'Invalid',
	BACKIN: 'Back In',
	DRIVETHROUGH: 'Drive Through',
	// % protected region % [Override spotDir display fields here] end
};

// % protected region % [Add any extra enums here] on begin
export const locationTypeOptions: { [key in areaLocationType]: string } = {
	DIG: 'Dig',
	PARKING: 'Park',
	DUMP: 'Waste Dump',
	CRUSHER: 'Crusher',
	STOCKPILE: 'Stockpile',
	INVALID: 'Not Applicable',
};

export type fmsObjectStatus =
	'DELETED' |
	'UNCHANGED' |
	'ADDED';

export type connectivity = 
	'PREVIOUS' |
	'NEXT'; 

export const connectivityOptions: { [key in connectivity]: string } = {
	PREVIOUS: 'Previous',
	NEXT: 'Next'
}

export type turnSignal = 
	'LEFT' |
	'RIGHT'; 

export const turnSignalOptions: { [key in turnSignal]: string } = {
	LEFT: 'Left',
	RIGHT: 'Right'
}

export const parametersOptions = {
	AHT: 'AHT parameters',
	SPEED: 'Speed parameters',
	GENERALPATH: 'General path parameters',
	STATICPATH: 'Static path parameters',
	DYNAMICPATH: 'Dynamic path parameters',
	ERRORSWARNINGS: 'Errors and Warnings',
	DRIVABLEAREABOUNDARY: 'Drivable area boundary parameters',
	DRIVINGPERMISSION: 'Driving permission parameters',
	DUMP: 'Dump parameters',
	AHSAREA: 'AHS area parameters',
	AREAEXTENSION: 'Area extension parameters',
	FMSMAP: 'FMS map parameters',
	MAPDISPLAYCOORDS: 'Map display coordinates parameters',
	MIV: 'MIV parameters',
	UNCATEGORISED: 'Uncategorised',
}
// % protected region % [Add any extra enums here] end
