/* eslint-disable object-curly-newline */
/* eslint-disable no-continue */
import {
	AreaEntity,
	LinkEntity,
	MapObjectErrorsEntity,
	MapObjectWarningsEntity,
	NodeEntity,
	SublinkEntity
} from 'Models/Entities';
import { Area, DrivingZone, MapRenderer, NodeGraphic, Path, Sublink } from 'Views/MapComponents';
import * as PIXI from 'pixi.js';
import MapValidator from './MapValidator';
import MapObject from '../MapObjects/MapObject';
import { getNodeName, getSublinkName } from 'Views/MapComponents/LayersPanel/AhsMapObjects';
import MapStore from '../MapStore';

export default class PathValidator extends MapValidator {
	public setMapObjectError(mapObject: MapObject<unknown>, isRerender: boolean = true, hasError: boolean = true, forceMarkRerender: boolean = false) {
		if (mapObject.isError !== hasError || forceMarkRerender) {
			// only re-render if the obj changes
			mapObject.isError = hasError;
			this.renderer.markObjectToRerender(mapObject.getId());
		}
		if (isRerender) {
			this.renderer.rerender();
		}
	}

	public setMapObjectWarning(mapObject: MapObject<unknown>, isRerender: boolean = true, hasWarning: boolean = true, forceMarkRerender: boolean = false) {
		if (mapObject.isWarning !== hasWarning || forceMarkRerender) {
			// only re-render if the obj changes
			mapObject.isWarning = hasWarning;
			this.renderer.markObjectToRerender(mapObject.getId());
		}
		if (isRerender) {
			this.renderer.rerender();
		}
	}

	/**
     * Add an error to the link entity
     * @param errorMessage
     * @param linkEntity
     * @returns the newly created MapObjectErrorsEntity
     */
	private addLinkError(errorMessage: string, linkEntity: LinkEntity) {
		// TODO: This may be able to be updated to reuse addObjectError();
		const errorEntity = new MapObjectErrorsEntity({
			errorMessage: errorMessage,
			linkId: linkEntity.getModelId(),
			link: linkEntity,
		});
		linkEntity.mapObjectErrorss.push(errorEntity);
		return errorEntity;
	}

	/**
	 * Add an error to the sublink entity and add to error table
	 * @param errorMessage
	 * @param sublinkEntity
	 * @returns the newly created MapObjectErrorsEntity
	 */
	private addSublinkError(errorMessage: string, sublinkEntity: SublinkEntity) {
		// If it's a temp sublinkEntity for clothoid, the error won't be added because it cannot getEntity()
		this.lookup.addObjectError(sublinkEntity.id, SublinkEntity, errorMessage);
		let errorEntity = sublinkEntity.mapObjectErrorss.find(e => e.sublinkId === sublinkEntity.id && e.errorMessage === errorMessage);
		// Return error for sendRequest and sendClothoidPathRequest to check if there is any error
		if (!errorEntity) {
			errorEntity = new MapObjectErrorsEntity({
				errorMessage: errorMessage,
				sublinkId: sublinkEntity.getModelId(),
				sublink: sublinkEntity,
			});
			sublinkEntity.mapObjectErrorss.push(errorEntity);
		}
		return errorEntity;
	}

	/**
	 * Add a warning to the sublink entity and add to warning table
	 * @param warningMessage
	 * @param sublinkEntity
	 * @returns the newly created MapObjectWarningsEntity
	 */
	public addSublinkWarning(warningMessage: string, sublinkEntity: SublinkEntity) {
		// If it's a temp sublinkEntity for clothoid, the warning won't be added because it cannot getEntity()
		this.lookup.addObjectWarning(sublinkEntity.id, SublinkEntity, warningMessage);
		let warningEntity = sublinkEntity.mapObjectWarningss.find(w => w.sublinkId === sublinkEntity.id && w.warningMessage === warningMessage);
		// Return warning for sendRequest and sendClothoidPathRequest to check if there is any warning
		if (!warningEntity) {
			warningEntity = new MapObjectWarningsEntity({
				warningMessage: warningMessage,
				sublinkId: sublinkEntity.getModelId(),
				sublink: sublinkEntity,
			});
			sublinkEntity.mapObjectWarningss.push(warningEntity);
		}
		this.controller.getEventHandler().emit('onMapObjectUpdate', sublinkEntity, sublinkEntity.id);
		return warningEntity;
	}

	/**
	 * clear warning to the sublink entity
	 * @param warningMessage
	 * @param sublinkEntity
	 * @returns the cleared MapObjectWarningsEntity
	 */
	public removeSublinkWarning(warningCode: string, sublinkEntity: SublinkEntity) {
		this.lookup.removeObjectWarning(sublinkEntity.id, SublinkEntity, warningCode);
		let warningEntity = sublinkEntity.mapObjectWarningss.find(w => w.sublinkId === sublinkEntity.id && w.warningMessage === warningCode);
		if (!warningEntity) {
			sublinkEntity.mapObjectWarningss = sublinkEntity.mapObjectWarningss.filter(w => w !== warningEntity);
		}
		this.controller.getEventHandler().emit('onMapObjectUpdate', sublinkEntity, sublinkEntity.id);
		return warningEntity;
	}

	/**
     * Add an error to the node entity
     * @param errorMessage
     * @param nodeEntity
     * @returns the newly created MapObjectErrorsEntity
     */
	public addNodeError(errorMessage: string, nodeEntity: NodeEntity) {
		// lookup.addObjectError(nodeEntity.id, NodeEntity, errorMessage);
		// return nodeEntity.mapObjectErrorss.find(e => e.sublinkId === nodeEntity.id && e.errorMessage === errorMessage);
		if (!nodeEntity.hasError(errorMessage)) {
			const errorEntity = new MapObjectErrorsEntity({
				errorMessage: errorMessage,
				nodeId: nodeEntity.getModelId(),
				node: nodeEntity,
			});
			nodeEntity.mapObjectErrorss.push(errorEntity);
			return errorEntity;
		}
		return undefined;
	}

	/**
	 * Used for full map validation
	 */
	public validateSublinksDrivingZones(disablePathInterferenceCheck: boolean = false) {
		const sublinkEntities = this.lookup.getAllEntities(SublinkEntity);
		sublinkEntities.forEach(sl => {
			this.validateSublinkDrivingZone(sl, disablePathInterferenceCheck);
		});

		this.renderer.rerender();
	}

	public validateSublinkDrivingZone(sublinkEntity: SublinkEntity, disablePathInterferenceCheck: boolean = false) {
		const sl = this.lookup.getMapObjectByEntity(sublinkEntity, 'sublink') as Sublink;
		const dz = sl?.getDrivingZoneObject();

		if (!!dz) {
			const sublinkInfo = !!sublinkEntity.sublinkId ? `${getSublinkName(sublinkEntity.sublinkId)}` : '';
			const points = dz.getEntity() as PIXI.Point[];
			const drivingArea = this.checkDrivableArea(points, dz.getId());
			const errorMessage = `${sublinkInfo} interference with drivable area`;
			if (!drivingArea) {
				this.addSublinkError(errorMessage, sublinkEntity);
			} else {
				this.lookup.removeObjectError(sublinkEntity.id, SublinkEntity, errorMessage);

			}

			// Check entity error count and render error styling
			const hasError = sublinkEntity.getErrorCount() > 0;
			const isRerender = false; // Prevent from rendering by default all validated sublinks
			// Mark to rerender to hide interference styling and show up error styling 
			// after disable Path Interference from menu bar
			const forceMarkRerender = disablePathInterferenceCheck;
			this.setMapObjectError(dz, isRerender, hasError, forceMarkRerender);
			// Update the label in the layers panel
			this.controller.getEventHandler().emit('onMapObjectUpdate', sublinkEntity, sublinkEntity.id);
		}
	}

	/**
	 * Validate and display all sublink zone interference on the map
	 */
	public validatePathsInterference() {
		const checkForSelectedSublink = false;
		const sublinkEntities = this.lookup.getAllEntities(SublinkEntity);
		// Reset
		sublinkEntities.forEach(_sl => {
			const sl = this.lookup.getMapObjectByEntity(_sl, 'sublink') as Sublink;
			if (!!sl) {
				const dz = sl.getDrivingZoneObject();
				if (!!dz) {
					dz.isInterference = false;
				}
			}
		});
		this.lookup.setInterferePathDrivingZones([]);

		// Check and set
		const objIds: string[] = []; // used to show/hide all sublink zone interference when selecting/de-selecting a sublink
		sublinkEntities.forEach((sl, i) => {
			this.validatePathInterference(sl, objIds, checkForSelectedSublink, i);
		});
		this.lookup.setInterferePathDrivingZones(objIds);

		this.renderer.rerender();
	}

	/**
	 * Validate a specific sublink zone interference on the map. It's also used for validatePathsInterference method.
	 * @param _sl the sublink that is going to be checked
	 * @param objIds the sublink that is going to be checked
	 * @param checkForSelectedSublink if a sublink is selected on the map
	 * @param position the index of the sublink in the array that is derived from getAllEntities(). It's only used for checking all sublink zone interference
	 */
	public validatePathInterference(_sl: SublinkEntity, objIds:string[], checkForSelectedSublink: boolean, position?: number) {
		const linkId = _sl.linkId;
		const sublinkEntities = this.lookup.getAllEntities(SublinkEntity);

		// If it's the last sublink, check if there are any links connected from it via connectivity.
		// the first sublinks of the links connected shouldn't be regarded as intersected
		const connectedFirstSublinks: SublinkEntity[] = [];
		if (!_sl.nextSublink && !!linkId) {
			const linkEntity = this.lookup.getEntity(linkId, LinkEntity);
			linkEntity.nextLinks().forEach(l => {
				const connectedFirstSublink = l.firstSublink();
				if (!!connectedFirstSublink) {
					connectedFirstSublinks.push(connectedFirstSublink);
				}
			});
		}

		// If it's the first sublink, check if there are any links connected to it via connectivity.
		// the last sublinks of the links connected shouldn't be regarded as intersected
		const connectedLastSublinks: SublinkEntity[] = [];
		if (!_sl.previousSublink && !!linkId) {
			const linkEntity = this.lookup.getEntity(linkId, LinkEntity);
			linkEntity.previousLinks().forEach(l => {
				const connectedLastSublink = l.lastSublink();
				if (!!connectedLastSublink) {
					connectedLastSublinks.push(connectedLastSublink);
				}
			});
		}

		let _j = 0;
		// To optimise path interference validation,
		// if it's checking all sublink zone interference on the map, only check the sublinks after _sl in the array
		// because previous sublinks in the array has been checked when running validatePathInterference() in validatePathsInterference()
		if (!checkForSelectedSublink && !!position) {
			_j = position +1;
		}

		const sl = this.lookup.getMapObjectByEntity(_sl, 'sublink') as Sublink;
		if (!!sl) {
			const dz = (sl as Sublink).getDrivingZoneObject();
			if (!!dz) {
				const points1 = dz.getEntity() as PIXI.Point[];
				for (let j = _j; j < sublinkEntities.length; j++) {
					// If two sublinks are in the same link, they aren’t regarded as intersecting with each other
					if (sublinkEntities[j].linkId === linkId) continue;
	
					// If two sublinks are connected via connectivity, they aren’t regarded as intersecting with each other
					if (connectedFirstSublinks.some(s => s.id === sublinkEntities[j].id)) continue;
					if (connectedLastSublinks.some(s => s.id === sublinkEntities[j].id)) continue;
	
					const sl2 = this.lookup.getMapObjectByEntity(sublinkEntities[j], 'sublink') as Sublink;
					if (!!sl2) {
						const dz2 = sl2.getDrivingZoneObject();
						if (!!dz2) {
							let points2 = dz2.getEntity() as PIXI.Point[];
		
							const isIntersection = this.checkPolygonIntersection(points1, points2, false);
							if (isIntersection) {
								dz2.isInterference = isIntersection;
								if (checkForSelectedSublink) {
									this.renderer.markObjectToRerender(dz2.getId());
								}
								objIds.push(dz2.getId());
								// Selected sublink uses the normal sublink selection styling
								if (!checkForSelectedSublink && !dz.isInterference) {
									dz.isInterference = isIntersection;
									objIds.push(dz.getId());
								}
							}
						}
					}
				}
				this.renderer.markObjectToRerender(dz.getId());
			}
		}
	}

	/**
	 * Checks whether or not a link that is fully contained within an area by checking driving zones
	 * @param lookup
	 * @param renderer
	 * @param linkEntity
	 * @param areaEntity
	 * @returns true if a link that is fully contained, otherwise false
	 */
	public checkLinkDrivingZoneInArea (lookup: MapStore, renderer: MapRenderer, linkEntity: LinkEntity, areaEntity: AreaEntity) {
		const sublinkEntities = linkEntity.getSublinks() as SublinkEntity[];
		const allLinkDrivingZonesInArea = sublinkEntities.every(sublinkEntity => {
			const sl = lookup.getMapObjectByEntity(sublinkEntity, 'sublink') as Sublink;
			const dz = sl.getDrivingZoneObject();

			if (!!dz) {
				const isDrivingZoneInArea = this.checkDrivingZoneInArea(lookup, renderer, dz, areaEntity);
				return isDrivingZoneInArea;
			}
			return false;
		});

		return allLinkDrivingZonesInArea;
	}

	/**
	 * Checks whether or not a driving zone is fully contained within an area
	 * @param lookup
	 * @param renderer
	 * @param drivingZone
	 * @param areaEntity
	 * @returns true if the points of a driving zone are fully contained, otherwise false
	 */
	public checkDrivingZoneInArea (lookup: MapStore, renderer: MapRenderer, drivingZone: DrivingZone, areaEntity: AreaEntity) {
		const id = lookup.getMapObjectId(areaEntity.id, 'area');
		if (!!id) {
			const areaMapObject = renderer.getObjectById(id) as Area;
			const areaPolygon = new PIXI.Polygon(areaMapObject.getPoints());
			const dzPoints = drivingZone.getEntity() as PIXI.Point[];
			const dzAllPointsInArea = dzPoints.every(point => areaPolygon.contains(point.x, point.y));
			if (!dzAllPointsInArea) {
				return dzAllPointsInArea;
			}

			const polygonPointsA = areaMapObject.getPoints();
			const polygonPointsB = dzPoints;
			const isLineIntersection = this.checkPolygonLineIntersection(polygonPointsA, polygonPointsB);
			return !isLineIntersection;
		}

		return false;
	};

	/**
	 * Process clothoid errors coming back from the server. If errors exist, render error styling
	 * @param pathObject
	 * @param linkEntity
	 * @param validationData response data from clothoid request
	 * @param isAddErrorsToEntity whether or not errors should be added to entity
	 * @returns true if there's an error that should block cpnfirm (node errors only)
	 */
	public processAndRenderClothoidErrors(
			pathObject: Path,
			linkEntity: LinkEntity,
			validationData: null, // IPathValidationData, // delete this function in future if we are not using
			isAddErrorsToEntity: boolean = true
		): boolean {
		
		// linkErrorResult contains a MapErrorFlag that is always returned regardless of whether there's an error.
		// When this flag is anything other than NoError, an error exists on the link.
		// Sublink errors and node errors contain an array of error flags. Hence, there can be multiple errors
		// on a single object. The no error case is an empty array.
		// const { linkErrors, sublinkErrors, nodeErrors } = validationData;
		//
		let isDisableConfirm = false;
		//
		// // TODO: these objects contain the error information to be displayed in properties panel
		// // perhaps send and event to the properties panel with the error information
		//
		// const linkTwoNodeError = linkEntity.sublinkss.length === 1 && linkEntity.sublinkss[0].nodess.length === 2;
		//
		// const ENABLE_ERRORS = true; // flag to disable errors for debug purposes
		//
		// // Clear all errors initially
		// linkEntity.sublinkss
		// 	.forEach(x =>  x.nodess
		// 		.forEach(y => y.resetError()));
		//
		// // Error exists (this is also the return value)
		// const isError = ENABLE_ERRORS ? linkErrors.length > 0 || sublinkErrors.some(x => !x.errors.includes('NoError'))
		//  	|| nodeErrors.some(x => !x.errors.includes('NoError')) || linkTwoNodeError : false;
		// // Process invividual node errors
		// if (isError) {
		// 	// Get node entities associated with the errors
		// 	const nodeEntityWithError: NodeEntity[] = [];
		// 	nodeErrors.forEach(x => {
		// 		const nodeEntity = linkEntity.sublinkss[x.sublinkIndex].nodess[x.index];
		// 		nodeEntityWithError.push(nodeEntity);
		// 		if (isAddErrorsToEntity) {
		// 			x.errors.forEach((errorFlag) => {
		// 				const errorMessage = `${errorFlag} on ${getNodeName(nodeEntity.nodeId)}`;
		// 				this.addLinkError(errorMessage, linkEntity);
		// 				this.addNodeError(errorFlag, nodeEntity);
		// 			});
		// 		}
		// 	});
		//
		// 	// Confirm should be blocked if there are node and link errors
		// 	isDisableConfirm = nodeEntityWithError.length > 0 || linkErrors.length > 0 || linkTwoNodeError;
		//
		// 	// it's necessary to use the path object to access the mapobjects because they not added to
		// 	// maplookup at this stage (paths are being dynamically generated)
		// 	// rerender happens after the loop
		// 	const isRerender = false;
		// 	pathObject.getChildren().forEach(child => {
		// 		switch (child.getType()) {
		// 			case 'node':
		// 				{
		// 					// set isError to temp nodegraphic object for updating style
		// 					const nodeEntityId = (child.getEntity() as NodeEntity).getModelId();
		// 					const node = nodeEntityWithError.find(x => nodeEntityId === x.getModelId());
		// 					if (!!node) {
		// 						this.setMapObjectError(child, isRerender);
		// 					}
		// 				}
		// 				break;
		// 			case 'sublink':
		// 				// hide sublink and driving zone
		// 				child.setRenderable(false);
		// 				break;
		// 			case 'link':
		// 				this.setMapObjectError(child, isRerender);
		// 				break;
		// 			default:
		// 				break;
		// 		}
		// 	});
		// 	this.renderer.rerender();
		// }
		// true if there is node error
		return isDisableConfirm;
	}
}
